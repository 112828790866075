import { render, staticRenderFns } from "./specialRecordTabs.vue?vue&type=template&id=53b6e492&scoped=true&"
import script from "./specialRecordTabs.vue?vue&type=script&lang=js&"
export * from "./specialRecordTabs.vue?vue&type=script&lang=js&"
import style0 from "./specialRecordTabs.vue?vue&type=style&index=0&id=53b6e492&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b6e492",
  null
  
)

export default component.exports