<!--
 * @Author: your name
 * @Date: 2022-04-22 14:10:47
 * @LastEditTime: 2022-12-14 10:40:36
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/detailComponent/materialDetails.vue
-->
<template>
  <div class="materialDetails">
    <jywsDialog
			:visible="visible"
			title="资料详情"
			width="960px"
			roger=""
			bottom-name="关闭"
			typePrimary="primary"
			@updataClose="closeModal"
			@updataWin="updataWin"
		>
      <div class="materialDetails-content">
        <div class="flex-right-account-left-content-table">
          <div class="flex-right-account-left-content-table-item borderb1">
            <div class="table-item-left borderr1">创建时间：</div>
            <div class="table-item-right borderr1">{{ item.createTime ? $moment(item.createTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}</div>
            <div class="table-item-left borderr1">所属设备：</div>
            <div class="table-item-right">{{ item.objectName || '-' }}</div>
          </div>
          <div class="flex-right-account-left-content-table-item">
            <div class="table-item-left borderr1">标题：</div>
            <div class="table-item-right borderr1">{{ item.title || '-' }}</div>
            <div class="table-item-left borderr1">附件：</div>
            <div class="table-item-right">
              <span v-if="item.pdfName" style="color: #1980FF;" @click="download(item.pdfName)">下载</span>
              <span v-else>无</span>
            </div>
          </div>
        </div>
        <div style="border-top: 1px solid #E8E8E8;padding-top: 20px;width: 100%;">
          <div v-html="item.text" style="white-space: pre-wrap;"></div>
        </div>
      </div>
    </jywsDialog>
  </div>
</template>

<script>
import jywsDialog from '@/components/jywsDialog.vue'
export default {
  name: 'MaterialDetails',
  props: {
    // 所用组件
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    visible: {
			type: Boolean,
			default: false,
		},
  },
  components: {
		jywsDialog
	},
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {},
  methods: {
    download(url){
			window.open(url)
		},
    closeModal() {
      this.$emit('closeModal')
    },
    updataWin() {
      this.$emit('closeModal')
    }
  },
}
</script>

<style scoped lang="less">
.materialDetails-content {
  max-height: 582px;
  overflow: auto;
}
.flex-right-account-left-content-table {
	border: 1px solid #E8E8E8;
	margin-bottom: 16px;
}
.flex-right-account-left-content-table-item {
	min-height: 40px;
	display: flex;
}
.table-item-left {
	flex: 1;
	height: 100%;
	padding: 10px 12px;
	text-align: right;
	background: #FAFAFA;
}
.table-item-right {
	flex: 2;
	height: 100%;
	padding: 10px 12px;
	text-align: left;
	color: #666666;
  overflow: hidden; //溢出隐藏
  text-overflow:ellipsis; //超出显示省略号
  white-space: nowrap;//禁止自动换行
}
.flex-right-account-right {
	width: 202px;
	flex-shrink: 0;
}
.border1 {
	border: 1px solid #E8E8E8;
}
.borderr1 {
	border-right: 1px solid #E8E8E8;
}
.borderb1 {
	border-bottom: 1px solid #E8E8E8;
}
.blueLine {
  width: 3px;
  height: 16px;
  background: #1890FF;
}
.flex-title {
  display: flex;
  align-items: center
}
.flex-title-name {
  margin-left: 10px;
}
.paddingb16 {
  padding-bottom: 16px;
}
</style>
