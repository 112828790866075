<!--
 * @Author: your name
 * @Date: 2022-04-22 14:10:47
 * @LastEditTime: 2022-12-15 09:48:10
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/components/detailComponent/missionDetails.vue
-->
<template>
  <div class="missionDetails">
    <jywsDialog
			:visible="visible"
			title="工单详情"
			width="960px"
			roger=""
			bottom-name="关闭"
			typePrimary="primary"
			@updataClose="closeModal"
			@updataWin="updataWin"
		>
      <div class="missionDetails-content">
        <div class="flex-right-account-left-content-table">
          <div class="flex-right-account-left-content-table-item borderb1">
            <div class="table-item-left borderr1">设备简称：</div>
            <div class="table-item-right borderr1">{{ infoList.objectName || '-' }}</div>
            <div class="table-item-left borderr1">工单类型：</div>
            <div class="table-item-right">{{ infoList.type | type(infoList.type) }}</div>
          </div>
          <div class="flex-right-account-left-content-table-item">
            <div class="table-item-left borderr1">执行人：</div>
            <div class="table-item-right borderr1">{{ infoList.startUserName || '-' }}</div>
            <div class="table-item-left borderr1">评价人：</div>
            <div class="table-item-right">{{ infoList.assessUserName || '-' }}</div>
          </div>
        </div>
        <!-- 表格区域 -->
        <a-tabs style="width: 100%;" type="card">
          <a-tab-pane :key="1" tab="执行信息">
            <!-- 表格区域 -->
            <div class="content-right-table">
              <a-table
                :columns="columns"
                :data-source="dataSource"
                :transformCellText="({ text, column, record, index }) => text || '-'"
                :pagination="false"
                :scroll="{ y: 340 }"
              >
                <span slot="check" slot-scope="text, scope">
                  <img v-if="scope.img" style="width: 16px;height: 16px;" src="@/assets/icon_image.png" @click="queryImg(scope.img)">
                  <span v-if="text === 1">正常<span v-if="scope.standardWay && scope.resultNum">({{ scope.resultNum }})</span></span>
                  <span v-else-if="text === 2" style="color: #FAAD14;">劣化</span>
                  <span v-else-if="text === 3" style="color: #FF4D4F;">异常</span>
                  <span v-else>未检</span>
                </span>
                <span slot="feedbackMesFlag" slot-scope="text">
                  {{ text | feedbackMesFlag(text) }}
                </span>
              </a-table>
            </div>
          </a-tab-pane>
          <a-tab-pane :key="0" tab="审核信息">
            <template v-if="evaluateHistory.length > 0">
              <div class="paddingb16 flex-title">
                <div class="blueLine"></div>
                <div class="flex-title-name">工单评价</div>
              </div>
              <div class="flex-right-account-left-content-table">
                <div class="flex-right-account-left-content-table-item borderb1">
                  <div class="table-item-left borderr1">评价时间：</div>
                  <div class="table-item-right borderr1">{{ evaluateHistory[0].evaluateTime ? $moment(evaluateHistory[0].evaluateTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}</div>
                  <div class="table-item-left borderr1">评价人：</div>
                  <div class="table-item-right">{{ evaluateHistory[0].evaluateUserName || '-' }}</div>
                </div>
                <div class="flex-right-account-left-content-table-item borderb1">
                  <div class="table-item-left borderr1">执行及时性：</div>
                  <div class="table-item-right borderr1">{{ evaluateHistory[0].timely || '-' }}</div>
                  <div class="table-item-left borderr1">执行准确率：</div>
                  <div class="table-item-right">{{ evaluateHistory[0].accuracy || '-' }}</div>
                </div>
                <div class="flex-right-account-left-content-table-item borderb1">
                  <div class="table-item-left borderr1">作业规范性：</div>
                  <div class="table-item-right borderr1">{{ evaluateHistory[0].standard || '-' }}</div>
                  <div class="table-item-left borderr1">评价说明：</div>
                  <div class="table-item-right">{{ evaluateHistory[0].remarks || '-' }}</div>
                </div>
                <div class="flex-right-account-left-content-table-item">
                  <div class="table-item-left borderr1">说明照片：</div>
                  <div class="table-item-right borderr1">
                    <span v-if="evaluateHistory[0].remarksImg" style="color: #1890FF;cursor: pointer;" @click="queryImg(evaluateHistory[0].remarksImg)">查看</span>
                    <span v-else>-</span>
                    </div>
                  <div class="table-item-left borderr1">&nbsp;</div>
                  <div class="table-item-right"></div>
                </div>
              </div>
            </template>
            <template v-if="fillHistory.length > 0">
              <div class="paddingb16 flex-title">
                <div class="blueLine"></div>
                <div class="flex-title-name">工单审核</div>
              </div>
              <div class="flex-right-account-left-content-table">
                <div class="flex-right-account-left-content-table-item borderb1">
                  <div style="text-align: left;" class="table-item-left borderr1">标题</div>
                  <div style="text-align: left;" class="table-item-left borderr1">审核时间</div>
                  <div style="text-align: left;" class="table-item-left borderr1">审核人</div>
                  <div style="text-align: left;" class="table-item-left borderr1">审核结果</div>
                  <div style="text-align: left;" class="table-item-left">审核说明</div>
                </div>
                <div v-for="(item, index) in fillHistory" :key="index" class="flex-right-account-left-content-table-item borderb1">
                  <div style="text-align: left;" class="table-item-left borderr1">{{ item.link }}</div>
                  <div style="text-align: left;background: #FFFFFF;color: #666666;" class="table-item-left borderr1">{{ item.createTime ? $moment(item.createTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-'  }}</div>
                  <div style="text-align: left;background: #FFFFFF;color: #666666;" class="table-item-left borderr1">{{ item.userName || '-' }}</div>
                  <div style="text-align: left;background: #FFFFFF;color: #666666;" class="table-item-left borderr1">{{ item.pass || '-' }}</div>
                  <div style="text-align: left;background: #FFFFFF;color: #666666;" class="table-item-left">{{ item.resultsDescribe || '-' }}</div>
                </div>
              </div>
            </template>
            <template v-if="evaluateHistory.length == 0 && fillHistory.length == 0">
              <div style="width: 100%;height: 200px;display: flex;align-items: center;justify-content: center;">--- 暂无数据 ---</div>
            </template>
          </a-tab-pane>
        </a-tabs>
      </div>
    </jywsDialog>
    <imgDialog title="查看图片" bottomName="关闭" :visible="imgVisble" :imgSrc="imgSrc" :itemSrcIndex="itemSrcIndex" :itemSrc="itemSrc" @selecedImgClick="selecedImgClick" @updataWin="close"></imgDialog>
  </div>
</template>

<script>
import jywsDialog from '@/components/jywsDialog.vue'
import imgDialog from '@/components/imgDialog.vue'
import * as util from '@/core/util'
import moment from 'moment'
export default {
  name: 'missionDetails',
  props: {
    // 所用组件
    id: {
      type: String,
      default: ''
    },
    tenantId: {
      type: String,
      default: ''
    },
    mode: {
      type: Number,
      default: 1
    },
    visible: {
			type: Boolean,
			default: false,
		},
  },
  components: {
		jywsDialog,
    imgDialog
	},
  filters: {
    type(text) {
			if (text == 'day') {
        return '日点检'
      } else if (text == 'specialty') {
        return '周点检'
      } else if (text == 'precision') {
        return '定期点检'
      } else if (text == 'planned') {
        return '计划点检'
      } else if (text == 'B1') {
        return '一级保养'
      } else if (text == 'B2') {
        return '二级保养'
      } else if (text == 'B3') {
        return '日常保养'
      } else if (text == 'project') {
        return '安全巡检'
      } else {
        return '-'
      }
		},
    feedbackMesFlag(text) {
      if (text == 'feedBack') {
        return '隐患反馈'
      } else if (text == 'feedBackEnd') {
        return '隐患反馈已处置'
      } else if (text == 'selfDeal') {
        return '自行处置'
      } else if (text == 'alreadyDeal') {
        return '报修'
      } else if (text == 'no') {
        return '未处理'
      } else {
        return '-'
      }
    }
  },
  data() {
    return {
      infoList: {
        assessUserName: '',
        objectName: '',
        startUserName: '',
        type: ''
      },
      imgVisble: false,
      imgSrc: [],
      itemSrcIndex: 0,
      itemSrc: '',
      columns: [
				{
					title: '执行单元',
					dataIndex: 'element',
					key: 'element',
          ellipsis: true,
					width: 200,
				},
				{
					title: '执行项目',
					dataIndex: 'project',
					key: 'project',
          ellipsis: true,
					width: 200,
				},
				{
					title: '执行内容',
					dataIndex: 'content',
					key: 'content',
          ellipsis: true,
					width: 200,
				},
				{
					title: '执行结果',
					dataIndex: 'check',
					key: 'check',
          scopedSlots: { customRender: 'check' },
				},
				{
					title: '隐患处置',
					dataIndex: 'feedbackMesFlag',
					key: 'feedbackMesFlag',
					width: 150,
					scopedSlots: { customRender: 'feedbackMesFlag' },
				}
			],
      dataSource: [],
      fillHistory: [],
      evaluateHistory: []
    }
  },
  created() {
    this.queryForTaskIdDetail()
    this.getAteEvaluateDetailsApp()
  },
  mounted() {},
  methods: {
    // 执行信息
    queryForTaskIdDetail() {
      let dataset = {
        id: this.id,
				mode: this.mode // 1任务 0计划
      }
      util.postform('ZNequipment/queryForTaskIdDetail', dataset).then((res) => {
        let data = res.data.vos
        if (data.length > 0) {
          data.forEach((element) => {
            element.key = element.c_id
          })
        }
        this.infoList = {
          assessUserName: res.data.assessUserName || '',
          objectName: res.data.objectName || '',
          startUserName: res.data.startUserName || '',
          type: res.data.type || ''
        }
        this.dataSource = data
      })
    },
    // 评价、审核 信息
    getAteEvaluateDetailsApp() {
      const dataset = {
        repairId: this.id,
        tenantId: this.tenantId
      }
      util.postform('workOrderProcessAssociated/getAteEvaluateDetailsApp', dataset).then(res => {
        console.log(res)
        if (res.data.listAD.length > 0) {
          res.data.listAD.forEach(ele => {
            ele.link = ele.move === 1 ? '环节一' : ele.move === 2 ? '环节二' : ele.move === 3 ? '环节三' : ele.move === 4 ? '环节四' : ele.move === 5 ? '环节五' : ''
            ele.time = moment(ele.createTime, 'YYYY-MM-DD').format('YYYY-MM-DD')
            ele.pass = ele.results ? '通过' : '不通过'
          })
        }
        this.fillHistory = res.data.listAD
        this.evaluateHistory = res.data.listEDOA
      }).catch(err => {
        console.error(err)
        this.$message.error(err.message)
      })
    },
    queryImg(img) {
      debugger
			this.imgVisble = true
			this.imgSrc = (img && img.split(',')) || []
			this.itemSrcIndex = 0
			this.itemSrc = (img && img.split(',')[0]) || ''
		},
    selecedImgClick(key) {
			this.itemSrcIndex = key
			this.itemSrc = this.imgSrc[key]
		},
		close() {
			this.imgVisble = false
		},
    closeModal() {
      this.$emit('closeModal')
    },
    updataWin() {
      this.$emit('closeModal')
    }
  },
}
</script>

<style scoped lang="less">
.missionDetails-content {
  max-height: 582px;
  overflow: auto;
}
.flex-right-account-left-content-table {
	border: 1px solid #E8E8E8;
	margin-bottom: 16px;
}
.flex-right-account-left-content-table-item {
	min-height: 40px;
	display: flex;
}
.table-item-left {
	flex: 1;
	height: 100%;
	padding: 10px 12px;
	text-align: right;
	background: #FAFAFA;
}
.table-item-right {
	flex: 2;
	height: 100%;
	padding: 10px 12px;
	text-align: left;
	color: #666666;
  overflow: hidden; //溢出隐藏
  text-overflow:ellipsis; //超出显示省略号
  white-space: nowrap;//禁止自动换行
}
.flex-right-account-right {
	width: 202px;
	flex-shrink: 0;
}
.border1 {
	border: 1px solid #E8E8E8;
}
.borderr1 {
	border-right: 1px solid #E8E8E8;
}
.borderb1 {
	border-bottom: 1px solid #E8E8E8;
}
.blueLine {
  width: 3px;
  height: 16px;
  background: #1890FF;
}
.flex-title {
  display: flex;
  align-items: center
}
.flex-title-name {
  margin-left: 10px;
}
.paddingb16 {
  padding-bottom: 16px;
}
</style>
