<!--
 * @Author: zwb 1172031304@qq.com
 * @Date: 2022-05-27 10:13:04
 * @LastEditors: zwb 1172031304@qq.com
 * @LastEditTime: 2022-05-27 15:38:32
 * @FilePath: /jyws_vue/src/components/equipmentDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="equipmentDetail">
    <jyws-dialog :visible="visible" :amplifier="amplifier" :title="title" :width="width" :bottom-name="bottomName" @updataClose="updataWin" @updataWin="updataWin" @amplifierClick="amplifierClick">
      <a-spin :spinning="spinning">
      <div class="equipmentDetail-body">
        <div class="equipmentDetail-body-left">
          <a-carousel>
            <template v-if="equipmentItem.c_img">
              <div v-for="(imgs, indexs) in equipmentItem.c_img.split(',')" :key="indexs" style="width: 210px;height: 160px;">
                <img :src="imgs" style="width: 100%;height: 100%;">
              </div>
            </template>
            <template v-else>
              <div style="width: 210px;height: 160px;">
                <img src="http://v15.jingyiweishi.com/361f938c1ba9cebb97e6bcf92bf7a482.png" style="width: 100%;height: 100%;">
              </div>
            </template>
          </a-carousel>
        </div>
        <div class="equipmentDetail-body-right">
          <div class="equipmentDetail-body-item paddingb10">
            <div class="equipmentDetail-one-flex">
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">所属</div>
                <div>{{ equipmentItem.depName || '-'}}</div>
              </div>
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">资产编码</div>
                <div>{{ equipmentItem.c_assets_code || '-'}}</div>
              </div>
            </div>
            <div class="equipmentDetail-one-flex">
              <div  class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">简称</div>
                <div>{{ equipmentItem.c_simple_name || '-'}}</div>
              </div>
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">全称</div>
                <div>{{ equipmentItem.c_name || '-'}}</div>
              </div>
            </div>
            <div class="equipmentDetail-one-flex">
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">型号规格</div>
                <div>{{ equipmentItem.c_standard || '-'}}</div>
              </div>
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">投运日期</div>
                <div>{{ equipmentItem.c_start_time | '-'}}</div>
              </div>
            </div>
            <div class="equipmentDetail-one-flex">
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">状态</div>
                <div>{{ equipmentItem.c_status | statusType(equipmentItem.c_status)}}</div>
              </div>
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">启用</div>
                <div>{{ equipmentItem.flag == 'true' ? '是' : '否'}}</div>
              </div>
            </div>
          </div>
          <div class="equipmentDetail-body-item paddingb10">
            <div class="equipmentDetail-one-flex paddingt10">
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">特种设备</div>
                <div>{{ equipmentItem.c_special == '1' ? '是' : '否' }}</div>
              </div>
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">特种设备证书号</div>
                <div>{{ equipmentItem.c_credentials_code || '-' }}</div>
              </div>
            </div>
            <div class="equipmentDetail-one-flex">
              <div  class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">上次检验时间</div>
                <div>{{ equipmentItem.c_last_check_time | timeTransition(equipmentItem.c_last_check_time) }}</div>
              </div>
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">下次检验时间</div>
                <div>{{ equipmentItem.c_next_check_time | timeTransition(equipmentItem.c_next_check_time) }}</div>
              </div>
            </div>
            <div class="equipmentDetail-one-flex">
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">检验周期</div>
                <div>{{ equipmentItem.c_cycle || '0' }} {{ equipmentItem.c_unit == 'day' ? '天' : equipmentItem.c_unit == 'week' ? '周' : '月' }}</div>
              </div>
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">设备类型</div>
                <div>{{ equipmentItem.c_equpment_style || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="paddingb10">
            <div class="equipmentDetail-one-flex paddingt10">
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">品牌</div>
                <div>{{ equipmentItem.c_brand || '-' }}</div>
              </div>
            </div>
            <div class="equipmentDetail-one-flex">
              <div  class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">厂商信息</div>
                <div>{{ equipmentItem.c_enterprise || '-' }}</div>
              </div>
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">出厂日期</div>
                <div>{{ equipmentItem.c_produce_time || '-' }}</div>
              </div>
            </div>
            <div class="equipmentDetail-one-flex">
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">原值</div>
                <div>{{ equipmentItem.c_orginal_value || '-' }}</div>
              </div>
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">出厂编码</div>
                <div>{{ equipmentItem.c_produce_code || '-' }}</div>
              </div>
            </div>
            <div class="equipmentDetail-one-flex">
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">折旧率</div>
                <div>{{ equipmentItem.c_depreciation_rate * 100 || '-' }}</div>
              </div>
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">折旧年限</div>
                <div>{{ equipmentItem.c_depreciable_life || '-' }}</div>
              </div>
            </div>
            <div class="equipmentDetail-one-flex">
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">安装位置</div>
                <div>{{ equipmentItem.c_position || '-' }}</div>
              </div>
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">SN号</div>
                <div>{{ equipmentItem.c_sn || '-' }}</div>
              </div>
            </div>
            <div class="equipmentDetail-one-flex">
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">备注</div>
                <div>{{ equipmentItem.c_remark || '-' }}</div>
              </div>
            </div>
            <div class="equipmentDetail-one-flex">
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">Id</div>
                <div>{{ equipmentItem.c_id || '-' }}</div>
              </div>
              <div class="equipmentDetail-one-flex-item">
                <div class="equipmentDetail-one-flex-item-left">复制ID</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </a-spin>
    </jyws-dialog>
  </div>
</template>
<script>
import jywsDialog from '@/components/jywsDialog.vue'
import * as util from '@/core/util'
import { dateFormat7 } from '@/core/date'
export default {
  name: 'EquipmentDetail',
  props: {
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '设备详情'
    },
    visible: {
      type: Boolean,
      default: true
    },
    bottomName: {
      type: String,
      default: '关闭'
    }
  },
  components: {
    jywsDialog
  },
  filters: {
    statusType(text) {
      if (text === 1) {
        return '正投运'
      } else if (text === 2) {
        return '已闲置'
      } else if (text === 3) {
        return '即将报废'
      } else if (text === 4) {
        return '已报废'
      } else if (text === 5) {
        return '已封存'
      } else if (text === 6) {
        return '已转让'
      }
    },
    timeTransition(text) {
      if (text) {
        return dateFormat7(text)
      } else {
        return '-'
      }
    }
  },
  data() {
    return {
      spinning: false,
      amplifier: true,
      width: '960px',
      equipmentImgList: [
        'http://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/photo/cfd88a98c78f46f58d231756c4d2e6c5.jpg',
        'http://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/photo/5d6702e242bc466db89a0a176ff01d40.jpg',
        'http://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/photo/038a98f7c8f3401cb2f6ed03de9a600a.jpg',
        ],
      equipmentItem: {}
    }
  },
  created() {
    this.query()
  },
  mounted() {},
  methods: {
    //查询备件信息
    query() {
      let dataset = {
          id: this.id                     //查询条件---货位编码
      };
      this.spinning = true
      util.post('equipment/queryEquInfoForId', dataset).then((reg) => {
        this.equipmentItem = reg
        this.spinning = false
      })
    },
    updataClose() {
      this.$emit('cancel')
    },
    updataWin() {
      this.$emit('updataWin')
    },
    fullscreenClick() {
      this.$emit('fullscreenClick')
    },
    amplifierClick() {
      if (this.width == '960px') {
        this.width = '100%'
      } else {
        this.width = '960px'
      }
    }
  },
}
</script>

<style scoped lang="less">
.equipmentDetail-body {
  height: 600px;
  display: flex;
}
.equipmentDetail-body-left {
  width: 30%;
  padding-right: 24px;
}
.equipmentDetail-body-right {
  flex: 3;
  border-left: 1px dashed #e8e8e8;
  .equipmentDetail-body-item {
    border-bottom: 1px dashed #e8e8e8;
  }
  .equipmentDetail-one-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 19px;
    .equipmentDetail-one-flex-item {
      display: flex;
      width: 100%;
      .equipmentDetail-one-flex-item-left {
        text-align: right;
        width: 120px;
        padding-right: 20px;
        color: #333333;
        font-weight: 500;
      }
    }
  }
  .paddingt10 {
    padding-top: 10px;
  }
  .paddingb10 {
    padding-bottom: 10px;
  }
}
</style>
