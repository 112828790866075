<!--
 * @Author: your name
 * @Date: 2022-04-27 16:45:02
 * @LastEditTime: 2022-04-28 11:19:43
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/components/importDialogPlus.vue
-->
<template>
	<div class="importDialogPlus">
		<jyws-dialog
			:visible="visible"
			:title="title"
			:width="width"
			:roger="roger"
			:bottom-name="bottomName"
			@updataClose="updataClose"
			@updataWin="updataWin"
		>
			<a-steps :current="current">
				<a-step
					v-for="item in steps"
					:key="item.title"
					:title="item.title"
				/>
			</a-steps>
			<template v-if="current == 0">
				<div class="importDialogPlus-come" @click="windowOpen">
					<div class="marginB12">
						<span class="importDialogPlus-come-step">步骤1：</span
						><span>下载Excel模板</span>
					</div>
					<div><a-button type="primary">下载Excel模板</a-button></div>
				</div>
				<div class="importDialogPlus-come">
					<div class="marginB12">
						<span class="importDialogPlus-come-step">步骤2：</span
						><span>在已经下载的Excel模板中录入数据</span>
					</div>
					<div>
						<a-icon
							type="form"
							style="font-size: 48px; color: rgb(143, 143, 143)"
						/>
					</div>
				</div>
				<div class="importDialogPlus-come">
					<div class="marginB12">
						<span class="importDialogPlus-come-step">步骤3：</span
						><span>导入已经录入数据的Excel</span>
					</div>
					<a-upload-dragger
						name="file"
						:fileList="excelList"
						:remove="onRemove"
						:before-upload="beforeUpload"
					>
						<p class="ant-upload-drag-icon">
							<a-icon type="inbox" />
						</p>
						<p class="ant-upload-text">
							单击或拖动模板文件到该区域
						</p>
						<p class="ant-upload-hint">点击"下载Excel模板"下载</p>
					</a-upload-dragger>
				</div>
			</template>
			<template v-if="current == 1">
				<a-table
					:columns="columns"
					:loading='loading'
					:data-source="dataSource"
					:pagination="false"
					:scroll="{ y: 500 }"
				>
					<span slot="time" slot-scope="time">
						{{ $moment(time).format('YYYY-MM-DD') }}
					</span>
					<span slot="cycle" slot-scope="text, record">
						{{ record.cycle + record.cycleUnit }}
					</span>
					<span slot="executiveName" slot-scope="text, record">
						<selectPeo
							:inputName="record.executiveName"
							placeholder="必选"
							@onSelect="onSelectPeo(record, arguments)"
							:radioFlag="true"
						></selectPeo>
					</span>
					<span slot="ccName" slot-scope="text, record">
						<selectPeo
							:inputName="record.ccName"
							@onSelect="onSelectPeocc(record, arguments)"
							placeholder="非必选"
							:radioFlag="true"
						></selectPeo>
					</span>
				</a-table>
				<!-- <div class="importDialogPlus-come" @click="windowOpen">
          <a-result
            status="success"
            title="导入完成"
          >
          </a-result>
        </div> -->
			</template>
			<template v-if="current == 2">
				<div class="importDialogPlus-come" @click="windowOpen">
					<a-result status="success" title="导入完成"> </a-result>
				</div>
			</template>
		</jyws-dialog>
	</div>
</template>
<script>
import jywsDialog from '@/components/jywsDialog.vue'
import selectPeo from '@/components/selectPeo.vue'

export default {
	name: 'importDialogPlus',
	props: {
		title: {
			type: String,
			default: '导入',
		},
		visible: {
			type: Boolean,
			default: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String,
			default: '1100px',
		},
		roger: {
			type: String,
			default: '取消',
		},
		bottomName: {
			type: String,
			default: '确定',
		},
		url: {
			type: String,
			default: '',
		},
		current: {
			type: Number,
			default: 0,
		},
		columns: {
			type: Array,
			default: () => {
				return [
					{
						title: '所属设备',
						dataIndex: 'objectName',
						key: 'objectName',
					},
					{
						title: '年检项目',
						dataIndex: 'project',
						key: 'project',
					},
					{
						title: '年检内容',
						dataIndex: 'content',
						key: 'content',
					},
					{
						title: '年检周期',
						dataIndex: 'cycle',
						key: 'cycle',
						scopedSlots: { customRender: 'cycle' },
					},
					{
						title: '证书类型',
						dataIndex: 'credentialsType',
						key: 'credentialsType',
					},
					{
						title: '有效期限',
						dataIndex: 'periodTime',
						key: 'periodTime',
						scopedSlots: { customRender: 'time' },
					},
					{
						title: '提前提醒天数',
						dataIndex: 'executiveDays',
						key: 'executiveDays',
					},
					{
						title: '证书号',
						dataIndex: 'credentialsCode',
						key: 'credentialsCode',
					},
					{
						title: '执行人',
						dataIndex: 'executiveName',
						key: 'executiveName',
						scopedSlots: { customRender: 'executiveName' },
					},
					{
						title: '抄送人',
						dataIndex: 'ccName',
						key: 'ccName',
						scopedSlots: { customRender: 'ccName' },
					},
				]
			},
		},
		dataSource: {
			type: Array,
			default: () => {
				return []
			},
		},
	},
	components: {
		jywsDialog,
		selectPeo,
	},
	data() {
		return {
			steps: [
				{
					title: '导入检修计划',
				},
				{
					title: '设置执行人/组',
				},
				{
					title: '批量导入完成',
				},
			],
			excelList: []
		}
	},
	created() {},
	mounted() {},
	methods: {
		windowOpen() {
			window.open(this.url, '_black')
		},
		onRemove(file) {
			this.excelList.some((item, i) => {
				if (item.name == file.name) {
					this.excelList.splice(i, 1)
					return true //当内部return true时跳出整个循环
				}
			})
		},
		// 选择人员
		onSelectPeo(val, data) {
			let peoData = data[0].obj[0]
			val.executiveName = peoData.c_name
			val.executive = peoData.c_id
			val.execType  = 0
		},
		// 选择人员
		onSelectPeocc(val, data) {
			console.log(val)
			console.log(data)
			let peoData = data[0].obj[0]
			val.ccName = peoData.c_name
			val.cc = peoData.c_id
		},
		deleteXls(excelList, file) {
			const index = excelList.indexOf(file)
			const newFileList = excelList.slice()
			newFileList.splice(index, 1)
			return {
				excelList: newFileList,
			}
		},
		beforeUpload(file) {
			if (this.excelList.length < 1) {
				this.excelList = [file]
				return false
			} else if (this.excelList.length == 1) {
				this.excelList = [file]
				return false
			}
		},
		updataClose() {
			this.excelList = []
			this.$emit('cancel')
		},
		updataWin() {
			if (this.current == 0) {
				this.$emit('submit', this.excelList)
			} else if (this.current == 1) {
				this.excelList = []
				this.$emit('submit', this.excelList)
			} else if (this.current == 2) {
				this.excelList = []
				this.$emit('cancel')
			}
		},
	},
}
</script>

<style scoped lang="less">
.importDialogPlus-come {
	padding: 30px 40px;
	background-color: #f5f5f5;
	border: 1px solid #e8e8e8;
	margin-top: 20px;
	text-align: center;
}
.importDialogPlus-come-step {
	color: rgba(0, 0, 0, 0.85);
	font-weight: bold;
}
.marginB12 {
	margin-bottom: 10px;
}
</style>
