<template>
	<div class="outputManagementWorks">
		<div class="outputManagementWorks-content">
			<div class="content-right">
				<!-- 查询区域 -->
				<div class="content-right-form">
					<conditionQuery
						:headData="headData"
						@onTimeChange="onTimeChange"
						@exportExcel="exportExcel"
						@onHandleChange="onHandleChange"
						@onInputChange="onInputChange"
						@onSelectClick="getoutputManagementWorks"
					/>
				</div>
				<!-- 功能按钮区域 -->
				<div class="content-right-btnGroup">
					<a-button-group>
						<a-button @click="onbtnGroup" name="openWorks">下发任务</a-button>
						<a-button @click="delBtn" name="delWorks">删除工单</a-button>
					</a-button-group>
				</div>
				<!-- 表格区域 -->
				<div class="content-right-table">
					<a-table
						:loading="loading"
						:columns="columns"
						:row-selection="rowSelection"
						:data-source="dataSource"
						:pagination="false"
						:scroll="{ y: 380 }"
					>
						<span slot="time" slot-scope="time">
							{{ time ? $moment(time).format('YYYY-MM-DD HH:mm') : '-' }}
						</span>
						<span slot="dateTime" slot-scope="dateTime">
							{{ dateTime ? $moment(dateTime).format('YYYY-MM-DD') : '-' }}
						</span>
						<span slot="remark" slot-scope="remark">
							{{ remark ? remark : '/' }}
						</span>
						<span slot="executiveName" slot-scope="text, record">
							{{ record.execType === 0 ? record.executiveName : record.nowRecipientsName }}
						</span>
						<span slot="state" slot-scope="text, record">
							<a-badge v-if="record.state === 3" status="success" text="已完成" />
							<a-badge v-else-if="record.state === 0" status="error" text="未下发" />
							<a-badge v-else-if="record.state === 2" status="processing" text="已开始" />
							<a-badge v-else-if="record.state === 1" status="warning" text="未开始" />
						</span>
						<span slot="ck" slot-scope="text, record">
							<a @click="openinfooutputManagementWorks(record)" name="infooutputManagementWorks">查看 </a>
						</span>
						<span slot="action" slot-scope="text, record">
							<a @click="printWorkInfo(record)" name="printWorkInfo">打印生产单 </a>
						</span>
					</a-table>
					<a-pagination
						@change="pageonChange"
						@showSizeChange="showSizeChange"
						size="small"
						:total="total"
						:defaultCurrent="searchForm.pageIndex"
						:defaultPageSize="searchForm.pageSize"
						show-size-changer
						show-quick-jumper
						:show-total="total => `总共 ${total} 条`"
					/>
				</div>
			</div>
		</div>
		<!-- 弹窗区域 -->
		<jywsDialog
			v-if="visible"
			:visible="visible"
			:title="modalTitle"
			:width="visibleWidth"
			:roger="jywsDialogBottomRoger"
			:bottom-name="jywsDialogBottomName"
			@updataClose="closeModal"
			:typePrimary="typePrimary"
			@updataWin="updataWin"
		>
			<a-spin :spinning="spinning">
				<!-- 新增订单 -->
				<div
					class="newTransfer-modal"
					v-if="visibleType === 'addoutputManagementWorks' || visibleType === 'editoutputManagementWorks'"
				>
					<div class="addPsd-head">
						<div class="addPsd-head-left">排产数默认与订单需求数一致，可以依据实际情况进行调整</div>
						<div class="addPsd-head-left">
							<a-col :span="12" class="col-flex">
								<div class="col-label">备注</div>
								<div class="col-value">
									<a-input
										style="width: 300px"
										v-model="addForm.product"
										placeholder="请填写备注信息（如需）"
									/>
								</div>
							</a-col>
						</div>
					</div>
					<div class="addPsd-table">
						<a-table
							class="borderTable"
							:columns="addPsdcolumns"
							:data-source="addPsddataSource"
							:pagination="false"
							:scroll="{ y: 380 }"
						></a-table>
					</div>
				</div>
				<!-- 详情界面 -->
				<div v-else-if="visibleType === 'infooutputManagementWorks'">
					<div
						v-for="(item, index) in infodataSource"
						:key="index"
						:class="item.showBodyFlag ? 'info-content-one content-border' : 'info-content-one'"
					>
						<div
							:class="item.showBodyFlag ? 'info-content-head' : 'info-content-head content-border'"
							@click="changeShowBodyFlag(item)"
						>
							<div class="info-content-head-left">
								<div class="head-circle flex">{{ index + 1 }}</div>
								<div class="head-title">
									{{ item.productId }} | {{ item.productName }} | {{ item.productSpecifications }}
								</div>
							</div>
							<div class="info-content-head-left">
								<div class="head-nums">排产数：{{ item.productionScheduleNum }}</div>
								<div :class="item.showBodyFlag ? 'head-img' : 'head-img2'"></div>
							</div>
						</div>
						<div v-if="item.showBodyFlag" class="info-content-body">
							<a-descriptions :column="2">
								<a-descriptions-item label="排产数">
									{{ item.productionScheduleNum }}
								</a-descriptions-item>
								<a-descriptions-item label="状态">
									{{ showStateText(item.state) }}
								</a-descriptions-item>
								<a-descriptions-item label="开始生产时间">
									{{ item.startTime ? $moment(item.startTime).format('YYYY-MM-DD HH:mm') : '-' }}
								</a-descriptions-item>
								<a-descriptions-item label="结束生产时间">
									{{ item.endTime ? $moment(item.endTime).format('YYYY-MM-DD HH:mm') : '-' }}
								</a-descriptions-item>
							</a-descriptions>
							<a-table :columns="newLoadInfocolumns" :data-source="item.children" :pagination="false">
								<span slot="time" slot-scope="time">
									{{ time ? $moment(time).format('YYYY-MM-DD HH:mm') : '-' }}
								</span>
							</a-table>
						</div>
					</div>
				</div>
				<!-- 打印界面 -->
				<div v-else-if="visibleType === 'printWorkInfo'">
					<workerSinglesPrinting :id='printWorkInfoId'></workerSinglesPrinting>
				</div>
			</a-spin>
		</jywsDialog>
		<!-- 次级弹窗 -->
		<jywsDialog
			:visible="othervisible"
			:title="othermodalTitle"
			:width="othervisibleWidth"
			:roger="otherjywsDialogBottomRoger"
			:bottom-name="otherjywsDialogBottomName"
			@updataClose="othercloseModal"
			:typePrimary="othertypePrimary"
			@updataWin="otherupdataWin"
		>
			<a-spin :spinning="otherspinning">
				<div v-if="othervisibleType === 'aboutLoad'">
					<div class="outputManagementWorks-aboutLoad-head">
						<a-row :gutter="[32, 24]" justify="center" align="middle">
							<a-col :span="8" class="col-flex">
								<div class="col-label">产品编码</div>
								<div>
									<a-input v-model="addForm.qualifiedNum" placeholder="请输入产品编码" />
								</div>
							</a-col>
							<a-col :span="8" class="col-flex">
								<div class="col-label">产品名称</div>
								<div>
									<a-input v-model="addForm.qualifiedNum" placeholder="请输入产品名称" />
								</div>
							</a-col>
							<a-col :span="5" class="col-flex"> </a-col>
							<a-col :span="3" class="col-flex">
								<a-button class="marginl20" type="primary" icon="search">查询</a-button>
							</a-col>
						</a-row>
					</div>
					<div class="outputManagementWorks-aboutLoad-content">
						<a-table
							:columns="aboutLoadcolumns"
							:row-selection="aboutLoadrowSelection"
							:data-source="aboutLoaddataSource"
							:pagination="false"
						>
						</a-table>
					</div>
					<div class="outputManagementWorks-aboutLoad-bottom">
						<a-pagination
							@change="onChange"
							@showSizeChange="showSizeChange"
							size="small"
							:total="total"
							:defaultCurrent="pageIndex"
							:defaultPageSize="pageSize"
							show-size-changer
							show-quick-jumper
							:show-total="total => `总共 ${total} 条`"
						/>
					</div>
				</div>
			</a-spin>
		</jywsDialog>
		<importDataDialog
			:visible="importVisible"
			:title="importTitle"
			:url="importUrl"
			:current="current"
			@cancel="cancelClick"
			@submit="submitClick"
		/>
	</div>
</template>

<script>
import conditionQuery from '@/components/conditionQuery.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import importDataDialog from '@/components/importDataDialog.vue'
import workerSinglesPrinting from '@/components/workerSinglesPrinting.vue'
import { urlDataDeal } from '@/core/date'
import moment from 'moment'
import * as util from '@/core/util'
const leftTableColumns = [
	{
		dataIndex: 'description',
		title: '工艺名称',
		key: 'description',
	},
]
const rightTableColumns = [
	{
		dataIndex: 'title',
		title: '排序',
		key: 'title',
	},
	{
		dataIndex: 'description',
		title: '工艺步骤',
		key: 'description',
	},
	{
		dataIndex: 'cz',
		scopedSlots: { customRender: 'action' },
		width: 100,
		title: '操作',
		key: 'cz',
	},
]
export default {
	components: {
		conditionQuery,
		jywsDialog,
		workerSinglesPrinting,
		// uploadOss,
		importDataDialog,
	},
	data() {
		return {
			tenantId: '',
			printWorkInfoId: '',
			mockData: [
				{ title: '1', description: '下料', key: 0 },
				{ title: '2', description: '出货', key: 1 },
			],
			newLoadInfodataSource: [],
			newLoadInfocolumns: [
				{
					title: '工序名称',
					dataIndex: 'processName',
					key: 'processName',
				},
				{
					title: '生产人员',
					dataIndex: 'productionUserName',
					key: 'productionUserName',
				},
				{
					title: '协助人员',
					dataIndex: 'helpName',
					key: 'helpName',
				},
				{
					title: '生产数',
					dataIndex: 'productionNum',
					key: 'productionNum',
				},
				{
					title: '合格数',
					dataIndex: 'qualifiedNum',
					key: 'qualifiedNum',
				},
				{
					title: '不合格数',
					dataIndex: 'unqualifiedNum',
					key: 'unqualifiedNum',
				},
				{
					title: '开始时间',
					dataIndex: 'startTime',
					key: 'startTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '结束时间',
					dataIndex: 'endTime',
					key: 'endTime',
					scopedSlots: { customRender: 'time' },
				},
			],
			addPsddataSource: [],
			addPsdcolumns: [
				{
					title: '订单号',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '产品编号',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '产品名称',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '型号规格',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '产品类型',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '交付日期',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '订单数量',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '排产数',
					dataIndex: 'orderId',
					key: 'orderId',
				},
			],
			targetKeys: [],
			leftColumns: leftTableColumns,
			rightColumns: rightTableColumns,
			// 导入弹窗参数
			importVisible: false,
			importTitle: '批量导入',
			importUrl:
				'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/%E4%BA%A7%E9%87%8F%E7%AE%A1%E7%90%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
			importDataSource: [],
			current: 0,
			// 弹窗参数
			visible: false,
			visibleType: 'infooutputManagementWorks',
			modalTitle: '查看生产情况',
			visibleWidth: '960px',
			jywsDialogBottomRoger: '',
			jywsDialogBottomName: '关闭',
			typePrimary: '',
			spinning: false,
			infoList: {},
			// 新增接口传参
			addForm: {
				orderId: '',
				product: '',
				planNum: '',
				produceNum: '',
				qualifiedNum: '',
				startTime: '',
				finishTime: '',
				operUserId: '',
				operUserName: '',
			},
			editForm: {},
			infodataSource: [],
			// 表格表头
			columns: [
				{
					title: '工单号',
					dataIndex: 'workId',
					key: 'workId',
				},
				{
					title: '关联订单数',
					dataIndex: 'relationOrderNum',
					key: 'relationOrderNum',
				},
				{
					title: '关联产品数',
					dataIndex: 'relationProductNum',
					key: 'relationProductNum',
				},
				{
					title: '备注',
					dataIndex: 'remark',
					key: 'remark',
					scopedSlots: { customRender: 'remark' },
				},
				{
					title: '工单状态',
					dataIndex: 'state',
					key: 'state',
					scopedSlots: { customRender: 'state' },
				},
				{
					title: '下发时间',
					dataIndex: 'issueTime',
					key: 'issueTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '完成时间',
					dataIndex: 'finishTime',
					key: 'finishTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '生产情况',
					dataIndex: 'ck',
					key: 'ck',
					scopedSlots: { customRender: 'ck' },
				},
				{
					title: '操作',
					dataIndex: 'cz',
					key: 'cz',
					scopedSlots: { customRender: 'action' },
				},
			],
			dataSource: [],
			selectedRowKeys: [],
			selectedRows: [],
			headData: [
				{
					key: 1,
					name: '工单号',
					component: 'a-input',
					value: '',
					duplicate: 'workOrderId',
					placeholder: '请输入工单号',
				},
				{
					key: 2,
					name: '工单状态',
					component: 'a-select',
					value: undefined,
					placeholder: '请选择',
					provinceData: [
						{ name: '全部', value: '' },
						{ name: '未下发', value: '0' },
						{ name: '未开始', value: '1' },
						{ name: '已开始', value: '2' },
						{ name: '已完成', value: '3' },
					],
					duplicate: 'state',
				},
			],
			// 查询特种设备列表参数
			searchForm: {
				productName: '',
				workOrderId: '',
				startTime: '',
				endTime: '',
				pageIndex: 1,
				pageSize: 10,
				tenantId: '',
				state: '',
			},
			total: 10,
			oneTreeVisble: true,
			deviceTreeList: [],
			importName: '下一步',
			operUserId: '',
			operUserName: '',
			loading: false,
			aboutLoadselectedRowKeys: [],
			aboutLoadselectedRows: [],
			aboutLoaddataSource: [],
			aboutLoadcolumns: [
				{
					title: '产品编号',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '产品名称',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '型号规格',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '产品类型',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '产品单位',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '备注',
					dataIndex: 'orderId',
					key: 'orderId',
				},
			],
			othervisible: false,
			othermodalTitle: '新增工艺列表',
			othervisibleWidth: '540px',
			otherjywsDialogBottomRoger: '取消',
			othertypePrimary: 'primary',
			otherjywsDialogBottomName: '确认',
			otherspinning: false,
			othervisibleType: '',
			WorkmanshipName: '',
			showBodyFlag: true,
		}
	},
	created() {
		var str = this.$route.fullPath.split('=')
		this.tenantId = str[1].split('&')[0]
		this.operUserId = str[2].split('&')[0]
		this.operUserName = decodeURIComponent(str[3].split('&')[0])
	},
	computed: {
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys = selectedRowKeys
					this.selectedRows = selectedRows
				},
			}
		},

		aboutLoadrowSelection() {
			return {
				type: 'radio',
				selectedRowKeys: this.aboutLoadselectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.aboutLoadselectedRowKeys = selectedRowKeys
					this.aboutLoadselectedRows = selectedRows
				},
			}
		},
		nextReleaseTime() {
			let timeText = ''
			if (this.addForm.periodTime && this.addForm.executiveDays) {
				timeText = moment(this.addForm.periodTime)
					.subtract(this.addForm.executiveDays, 'days')
					.format('YYYY-MM-DD')
			}
			return timeText
		},
	},
	mounted() {
		this.getoutputManagementWorks()
	},
	methods: {
		changeShowBodyFlag(val) {
			val.showBodyFlag = !val.showBodyFlag
		},
		// 新增工艺列表
		addWorkmanship() {
			this.othermodalTitle = '新增工艺列表'
			this.othervisibleWidth = '960px'
			this.otherjywsDialogBottomRoger = '取消'
			this.othertypePrimary = 'primary'
			this.otherjywsDialogBottomName = '确认'
			this.othervisibleType = 'aboutLoad'
			this.othervisible = true
		},
		othercloseModal() {
			this.othervisible = false
		},
		otherupdataWin() {
			this.othervisible = false
		},
		// 查询区域 - 时间范围变动
		onTimeChange(val) {
			this.searchForm.startTime = val.dateString[0]
			this.searchForm.endTime = val.dateString[1]
		},
		// 选中设备树的回调
		treeSelect(val, data) {
			if (val.length !== 0) {
				this.searchForm.id = data.id
				if (data.type === 'dep') {
					this.searchForm.isDept = true
				} else {
					this.searchForm.isDept = false
				}
			} else {
				this.searchForm.id = ''
				this.searchForm.isDept = false
			}
			this.getoutputManagementWorks()
		},
		// 导出
		exportExcel() {
			this.searchForm.tenantId = this.tenantId
			let urldata = urlDataDeal(this.searchForm)
			let downUrl = util.getBaseURL() + 'product/exportProduct?' + urldata
			window.open(downUrl)
		},
		// 关闭弹窗
		closeModal() {
			this.othervisible = false
			this.visible = false
			this.addForm = {
				orderId: '',
				product: '',
				planNum: '',
				produceNum: '',
				qualifiedNum: '',
				startTime: '',
				finishTime: '',
				operUserId: '',
				operUserName: '',
			}
			this.selectedRowKeys = []
			this.getoutputManagementWorks()
		},
		// 弹窗确认按钮
		updataWin() {
			if (this.visibleType === 'addoutputManagementWorks') {
				this.addoutputManagementWorksBtn()
			} else if (this.visibleType === 'editoutputManagementWorks') {
				this.editoutputManagementWorksBtn()
			} else if (this.visibleType === 'changePlan') {
				this.changePlanBtn()
			} else if (this.visibleType === 'infooutputManagementWorks') {
				this.closeModal()
			} else if (this.visibleType === 'printWorkInfo') {
				this.closeModal()
			}
		},
		// 获取工单列表
		getoutputManagementWorks() {
			this.loading = true
			this.searchForm.tenantId = this.tenantId
			util.postform('productWorkOrder/getPageList', this.searchForm).then(res => {
				let temp = res.data.records
				temp.forEach(item => {
					item.key = item.id
				})
				this.loading = false
				this.dataSource = temp
				this.total = res.data.total
				this.selectedRowKeys = []
			})
		},
		// 翻页
		pageonChange(page) {
			this.searchForm.pageIndex = page
			this.getoutputManagementWorks()
		},
		// 页数size改变
		showSizeChange(key, size) {
			this.searchForm.pageSize = size
			this.getoutputManagementWorks()
		},
		// 新增产量管理
		addoutputManagementWorksBtn() {
			if (this.addForm.orderId === '') {
				this.$message.error('订单号不能为空！')
				return
			}
			if (this.addForm.product === '') {
				this.$message.error('产品名称不能为空！')
				return
			}
			if (this.addForm.planNum === '') {
				this.$message.error('计划数不能为空！')
				return
			}
			if (this.addForm.produceNum === '') {
				this.$message.error('生产数不能为空！')
				return
			}
			if (this.addForm.qualifiedNum === '') {
				this.$message.error('合格数不能为空！')
				return
			}
			if (this.addForm.startTime === '') {
				this.$message.error('开始时间不能为空！')
				return
			}
			if (this.addForm.finishTime === '') {
				this.$message.error('完成时间不能为空！')
				return
			}
			this.addForm.tenantId = this.tenantId
			this.addForm.operUserId = this.operUserId
			this.addForm.operUserName = this.operUserName
			util.postform('product/add', this.addForm)
				.then(res => {
					if (res.code == 200) {
						this.$message.success('新增成功！')
						this.closeModal()
					}
				})
				.catch(res => {
					this.$message.error(res.response.data.message)
				})
		},
		// 修改产量管理
		editoutputManagementWorksBtn() {
			if (this.addForm.orderId === '' || !this.addForm.orderId) {
				this.$message.error('订单号不能为空！')
				return
			}
			if (this.addForm.product === '' || !this.addForm.product) {
				this.$message.error('产品名称不能为空！')
				return
			}
			if (this.addForm.planNum === '' || !this.addForm.planNum) {
				this.$message.error('计划数不能为空！')
				return
			}
			if (this.addForm.produceNum === '' || !this.addForm.produceNum) {
				this.$message.error('生产数不能为空！')
				return
			}
			if (this.addForm.qualifiedNum === '' || !this.addForm.qualifiedNum) {
				this.$message.error('合格数不能为空！')
				return
			}
			if (this.addForm.startTime === '' || !this.addForm.startTime) {
				this.$message.error('开始时间不能为空！')
				return
			}
			if (this.addForm.finishTime === '' || !this.addForm.finishTime) {
				this.$message.error('完成时间不能为空！')
				return
			}
			let dataset = {
				tenantId: this.tenantId,
				operUserId: this.operUserId,
				operUserName: this.operUserName,
				orderId: this.addForm.orderId,
				product: this.addForm.product,
				planNum: this.addForm.planNum,
				produceNum: this.addForm.produceNum,
				qualifiedNum: this.addForm.qualifiedNum,
				startTime: this.addForm.startTime,
				finishTime: this.addForm.finishTime,
				id: this.addForm.id,
			}
			util.postform('product/update', dataset).then(res => {
				if (res.code == 200) {
					this.$message.success('修改成功！')
					this.closeModal()
				} else {
					this.$message.error('修改失败！')
				}
			})
		},
		// 变更下发计划
		changePlanBtn() {
			let dataset = {
				beforeDay: this.addForm.executiveDays,
				nextInspectTime: moment(this.addForm.periodTime)
					.subtract(this.addForm.executiveDays, 'days')
					.format('YYYY-MM-DD'),
				periodTime: this.addForm.periodTime,
				id: this.selectedRows[0].id,
			}
			util.postform('inspectPlan/changePlan', dataset).then(res => {
				if (res.code == 200) {
					this.$message.success('变更成功！')
					this.closeModal()
				}
			})
		},
		// 删除
		delBtn() {
			let that = this
			let flag = false 
			this.selectedRows.forEach(item=>{
				if(item.state !== 0){
					flag = true
				}
			})
			if(flag){
				this.$warning({
					title: '已经下发的工单无法直接删除！',
				})
				return
			}
			if (this.selectedRowKeys.length === 0) {
				this.$info({
					title: '提示',
					content: '请选择需要删除的记录！',
					onOk() {},
				})
				return
			}
			this.$confirm({
				title: '删除提示！',
				content: '删除后将不可恢复，是否删除选中工单？',
				onOk() {
					util.postform('productWorkOrder/delete', that.selectedRowKeys)
						.then(res => {
							if (res.code === 200) {
								that.$message.success('删除成功！')
								that.getoutputManagementWorks()
							}
						})
						.catch(res => {
							that.$message.error(res.response.data.message)
						})
				},
				onCancel() {},
			})
		},
		// 功能按钮操作
		onbtnGroup() {
			if (this.selectedRows.length !== 1) {
				this.$warning({
					title: '请选择一条工单记录进行下发操作！',
				})
				return
			}
			let that = this
			this.$confirm({
				title: '提示！',
				content: '是否确认下发选中工单？',
				onOk() {
					util.postform('/productWorkOrder/issue', that.selectedRowKeys)
						.then(res => {
							if (res.code === 200) {
								that.$message.success('下发成功！')
								that.getoutputManagementWorks()
							}
						})
						.catch(res => {
							that.$message.error(res.response.data.message)
						})
				},
				onCancel() {},
			})
		},
		// 选择执行人/组
		onSelectPeo(val) {
			if (val.obj.length === 0) {
				return
			} else {
				if (val.obj[0].groupId) {
					this.addForm.execType = 1
					this.addForm.nowRecipients = val.obj[0].groupId
					this.addForm.nowRecipientsName = val.obj[0].groupName
				} else {
					this.addForm.execType = 0
					this.addForm.executive = val.obj[0].c_id
					this.addForm.executiveName = val.obj[0].c_name
				}
			}
		},
		// 选择抄送人
		onSelectPeocc(val) {
			if (val.obj.length === 0) {
				return
			} else {
				this.addForm.cc = val.obj[0].c_id
				this.addForm.ccName = val.obj[0].c_name
			}
		},
		// 选择设备
		onSelectPeoEqus(val) {
			if (val.obj.length === 0) {
				return
			} else {
				this.addForm.objectId = val.obj[0].c_id
				this.addForm.objectName = val.obj[0].c_simple_name
				this.addForm.organizationId = val.obj[0].c_organization_id
				this.addForm.organizationName = val.obj[0].depName
			}
		},
		// 上传附件 -- 获取oss地址
		setFileList(val) {
			this.addForm.credentialsFile = val
		},
		setFileListName(val) {
			this.addForm.fileName = val
		},
		showModal(e) {
			let name = e.target.name

			this.visibleType = name
			console.log(this.visibleType)
			switch (name) {
				case 'addoutputManagementWorks':
					this.modalTitle = '新增'
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					this.visible = true
					break
				case 'editoutputManagementWorks':
					this.modalTitle = '修改'
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					this.visible = true
					break
				case 'infooutputManagementWorks':
					this.modalTitle = '详情'
					this.visibleWidth = '960px'
					this.typePrimary = ''
					this.jywsDialogBottomName = '关闭'
					this.jywsDialogBottomRoger = ''
					this.visible = true
					break
				case 'aboutLoad':
					this.modalTitle = '关联产品'
					this.visible = true
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					break
				case 'importExecl':
					this.importVisible = true
					break
				default:
					break
			}
		},
		cancelClick() {
			this.importVisible = false
		},
		submitClick(file) {
			const fd = new FormData()
			fd.append('tenantId', this.tenantId)
			fd.append('operUserName', this.operUserId)
			fd.append('file', file[0])
			util.postformData('product/importExcel', fd)
				.then(res => {
					if (res.code == 200) {
						this.importDataSource = res.data
						this.$message.success('导入成功')
						this.importVisible = false
					} else {
						this.$message.error(res.data.message)
					}
				})
				.catch(err => {
					this.$message.error(err.response.data.message)
				})
		},
		// 查看详情
		openinfooutputManagementWorks(val) {
			util.get('productWorkOrderSingle/info/?workOrderId=' + val.id).then(res => {
				res.data.forEach(item => {
					item.showBodyFlag = true
				})
				this.infodataSource = res.data
				this.infoList = val
				this.typePrimary = ''
				this.jywsDialogBottomName = '关闭'
				this.jywsDialogBottomRoger = ''
				this.modalTitle = '详情'
				this.visibleType = 'infooutputManagementWorks'
				this.visible = true
				this.visibleWidth = '960px'
			})

			console.log(val)
		},
		// 打印
		printWorkInfo(val) {
			console.log(val)
			this.printWorkInfoId = val.id
			this.typePrimary = ''
			this.jywsDialogBottomName = '关闭'
			this.jywsDialogBottomRoger = ''
			this.modalTitle = '详情'
			this.visibleType = 'printWorkInfo'
			this.visible = true
			this.visibleWidth = '960px'
		},
		showStateText(val) {
			if (val === 0) {
				return '未开始'
			} else if (val === 1) {
				return '已开始'
			} else if (val === 2) {
				return '已完成'
			}
		},
		// 下拉框选中
		onHandleChange(item) {
			if (item.duplicate == 'credentialsType') {
				this.searchForm.credentialsType = item.value
			} else if (item.duplicate == 'credentialsState') {
				this.searchForm.credentialsState = item.value
			} else if (item.duplicate == 'state') {
				this.searchForm.state = item.value
			}
		},
		// 输入框
		onInputChange(item) {
			if (item.duplicate == 'workOrderId') {
				this.searchForm.workOrderId = item.value
			} else if (item.duplicate == 'executor') {
				this.searchForm.executor = item.value
			}
		},
	},
}
</script>

<style scoped lang="less">
.outputManagementWorks {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	// display: flex;
	// justify-content: space-between;
	box-sizing: border-box;
	overflow: auto;
}
.info-title {
	display: flex;
	height: 40px;
	align-items: center;
	.info-title-icon {
		width: 4px;
		height: 14px;
		background: #1890ff;
	}
	.info-title-text {
		margin-left: 8px;
		font-size: 16px;
		color: #333333;
	}
}
.outputManagementWorks-title {
	width: 100%;
	height: 56px;
	background: #ffffff;
	border-radius: 4px 4px 0px 0px;
	color: #333333;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.infooutputManagementWorks-title {
	font-size: 24px;
	color: #333333;
	text-align: left;
}
.addPsd-head {
	display: flex;
	align-items: center;
	.addPsd-head-left {
		width: 50%;
		font-size: 14px;
		color: #333333;
	}
}
.addPsd-table {
	margin-top: 10px;
}
.infooutputManagementWorks-smtitle {
	display: flex;
	color: #999999;
	font-size: 12px;
	text-align: left;
	margin-top: 16px;
}
.infooutputManagementWorks-content {
	margin-top: 24px;
}
.info-content-body {
	padding: 20px;
}
.content-border {
	border: 1px solid #91d5ff;
}
.info-content-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	width: 100%;
	background: #e6f7ff;
	padding-left: 16px;
	padding-right: 16px;
	.head-circle {
		width: 16px;
		height: 16px;
		background: #1890ff;
		border-radius: 50%;
		color: #ffffff;
		font-size: 12px;
		margin-top: 1px;
	}
	.head-title {
		font-size: 16px;
		color: #333333;
	}
	.head-nums {
		font-size: 14px;
		color: #1890ff;
	}
	.head-img {
		width: 16px;
		height: 16px;
		background: url('../../assets/icon_折叠@3x.png') no-repeat;
		background-size: 100% 100%;
	}
	.head-img2 {
		width: 16px;
		height: 16px;
		background: url('../../assets/icon_展开@3x.png') no-repeat;
		background-size: 100% 100%;
	}
	.info-content-head-left {
		display: flex;
		align-items: center;
	}
}
.mg-l-5 {
	margin-left: 5px;
}
.mg-l-50 {
	margin-left: 50px;
}
.outputManagementWorks-aboutLoad-transferTitle {
	display: flex;
	.transferTitle-left {
		margin-left: 15px;
		width: calc(46% - 15px);
	}
	.transferTitle-name {
		font-size: 16px;
		color: #333333;
		letter-spacing: 0.32px;
		margin-top: 24px;
		margin-bottom: 16px;
	}
}
.aboutLoadInfo-line {
	width: 100%;
	height: 1px;
	background: #e8e8e8;
}
.newTransfer-modal {
	/deep/ .ant-transfer-list {
		border: none !important;
		padding-top: 0 !important;
	}
	/deep/ .ant-transfer-list-header {
		display: none;
	}
}
.aboutLoadInfo-btn {
	margin-top: 16px;
	display: flex;
	.aboutLoadInfo-btn-text {
		font-size: 14px;
		color: #b2b2b2;
		margin-left: 12px;
		padding-top: 8px;
	}
}
.aboutLoadInfo-table {
	margin-top: 12px;
}
.title-icon {
	width: 4px;
	height: 16px;
	background: #1890ff;
	display: inline-block;
	margin-right: 12px;
}
.col-value {
	width: 200px;
}
.outputManagementWorks-aboutLoad-head {
	padding-bottom: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.outputManagementWorks-content {
	background: #ffffff;
	width: 100%;
	height: 100%;
	display: flex;
}
.flush {
	font-size: 14px;
	color: #1890ff;
}
.content-right-btnGroup {
	height: 56px;
	width: 100%;
	line-height: 56px;
	margin-top: 10px;
}
.content-right-form {
	// width: 100%;
	background: #ffffff;
	padding: 0px 21px 0px 17px;
	.form-search {
		display: flex;
		align-items: center;
		width: 420px;
	}
}
.form-label {
	font-size: 14px;
	color: #333333;
	width: 70px;
	text-align: right;
	margin-right: 16px;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.content-left {
	width: 220px;
	height: calc(100% - 30px);
	background: #ffffff;
	border: 1px solid #e8e8e8;
	margin-left: 12px;
	overflow: auto;
	.content-left-title {
		height: 48px;
		width: 100%;
		padding: 0 16px 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #333333;
		font-size: 16px;
		border-bottom: 1px solid #e8e8e8;
	}
	.content-left-btnGroup {
		height: 48px;
		width: 100%;
		border-bottom: 1px solid #e8e8e8;
	}
}
.content-right {
	height: calc(100% - 30px);
	width: calc(100% - 60px);
	margin-left: 27px;
}
.content-right-message {
	margin-top: 20px;
}
</style>
