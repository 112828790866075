<template>
	<div class="outputManagementTabs">
    <div class="outputManagementTabs-title">
      <a-tabs :default-active-key="activeKeys" @change="callback">
			<a-tab-pane :key="1" tab="订单">
			</a-tab-pane>
			<a-tab-pane :key="2" tab="排产" force-render>
			</a-tab-pane>
      <a-tab-pane :key="3" tab="工单" force-render>
			</a-tab-pane>
		</a-tabs>
    </div>
    <div class="outputManagementTabs-content">
				<outputManagementOrders v-if="activeKeys === 1"></outputManagementOrders>
				<outputManagementPSD v-if='activeKeys === 2'></outputManagementPSD>
				<outputManagementWorks v-if='activeKeys === 3'></outputManagementWorks>
    </div>
	</div>
</template>

<script>
import outputManagementOrders from '@/views/outputManagementOrders/outputManagementOrders.vue'
import outputManagementPSD from '@/views/outputManagementPSD/outputManagementPSD.vue'
import outputManagementWorks from '@/views/outputManagementWorks/outputManagementWorks.vue'
export default {
	components: {
		outputManagementPSD,
    outputManagementOrders,
		outputManagementWorks
	},
	data() {
		return {
      activeKeys: 1
    }
	},
	created() {},
	mounted() {},
	methods: {
		callback(val) {
      this.activeKeys = val
    },
	},
}
</script>

<style scoped>
.outputManagementTabs {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	padding: 20px;
	box-sizing: border-box;
	overflow: auto;
}
.outputManagementTabs-content {
  background-color: #fff;
  height: calc(100% - 80px);
}
.outputManagementTabs-title {
  background-color: #fff;
}
.outputManagementTabs-title >>> .ant-tabs-ink-bar {
  width: 30px !important;
  left:15px !important
}
</style>
