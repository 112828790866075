<!--
 * @Author: your name
 * @Date: 2021-09-18 10:43:19
 * @LastEditTime: 2021-10-30 13:35:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /jingyiweishi_web/src/pages/productionManagement/workersTable.vue
-->
<template>
  <div>
    <div style="text-align: right;">
      <a-button v-print="printObj" type="primary">打印</a-button>
    </div>
    <div id="printTest" class="workerSinglesPrinting">
      <div class="workerSinglesPrinting-title">生产工单</div>
      <!-- <div class="workerSinglesPrinting-odd">生产单号  46651233268987</div> -->
      <div class="workerSinglesPrinting-table">
        <div class="flex">
          <div class="workerSinglesPrinting-name border-r">工单号</div>
          <div class="workerSinglesPrinting-value">{{ objectItem.workId }}</div>
        </div>
        <div class="flex">
          <div class="workerSinglesPrinting-name border-t">下发日期</div>
          <div class="workerSinglesPrinting-minvalue border-t border-l">{{ objectItem.workTime | getDate(objectItem.workTime) }}</div>
          <div class="workerSinglesPrinting-name border-t border-l">关联订单</div>
          <div class="workerSinglesPrinting-minvalue border-t border-l">{{ objectItem.orderNum }}</div>
          <div class="workerSinglesPrinting-name border-t border-l">关联产品</div>
          <div class="workerSinglesPrinting-minvalue border-t border-l">{{ objectItem.prodNum }}</div>
        </div>
        <div class="flex">
          <div class="workerSinglesPrinting-name border-t border-r">备注</div>
          <div class="workerSinglesPrinting-value border-t">{{ objectItem.remark }}</div>
        </div>
        <div class="flex">
          <div class="workerSinglesPrinting-QRremark border-t">
            <!-- 1.打印生产单
            2.登录生产管家app
            3.扫描生产单上的二维码
            4.查看生产工单列表（包括物料清单、产品工艺清单）
            5.进入生产管理，设置每一项工艺的生产人员
            6.开始生产时，勾选一项工艺路线，点击开始工序
            7.当一项工艺路线的工序结束后，勾选此条工艺路线点击完成工序
            8.所有工序完成后，结束生产单
            9.填写实际生产工序中的合格与不合格数进行提交
            10.进入web平台手动更改订单状态：已完成 -->
            <div style="margin-top:10px" v-for="(routeitem, routeIndex) in processRoute" :key="routeIndex">
              工艺路线{{routeIndex+1}}：<span v-for="(routeitem2, routeIndex2) in routeitem.processSteps" :key="routeIndex2">{{routeitem2}} <span v-if="routeIndex2 !== routeitem.processSteps.length -1">---></span></span>
            </div>
            <!-- <a-steps v-for="(routeitem, routeIndex) in processRoute" :key="routeIndex"  size="small" style="margin-top: 20px;width: 100%;">
              <a-step v-for="(routeitem2, routeIndex2) in routeitem.processSteps" status='finish' :key="routeIndex2" :title="routeitem2" />
            </a-steps> -->
          </div>
          <div class="workerSinglesPrinting-QRcode border-t border-l">
            <!-- <img :src="objectItem.qrCode" style="width: 194px;height: 194px;flex-shrink: 0;"> -->
            <img :src="objectItem.qrCode" style="width: 100%;height: 100%;flex-shrink: 0;">
          </div>
        </div>
        <div class="flex">
          <div class="workerSinglesPrinting-message border-t">产品信息</div>
        </div>
        <div v-for="(item, index) in objectItem.prodList" :key="index">
          <div class="flex">
            <div class="workerSinglesPrinting-name border-t">产品名称</div>
            <div class="workerSinglesPrinting-centrevalue border-t border-l">{{ item.name }}</div>
            <div class="workerSinglesPrinting-name border-t border-l">生产数量</div>
            <div class="workerSinglesPrinting-centrevalue border-t border-l">{{ item.productionNum }}</div>
          </div>
          <div class="flex">
            <div class="workerSinglesPrinting-name border-t">产品编号</div>
            <div class="workerSinglesPrinting-centrevalue border-t border-l">{{ item.serialNumber }}</div>
            <div class="workerSinglesPrinting-name border-t border-l">产品类型</div>
            <div class="workerSinglesPrinting-centrevalue border-t border-l">{{ item.type }}</div>
          </div>
          <div class="flex">
            <div class="workerSinglesPrinting-name border-t">产品型号</div>
            <div class="workerSinglesPrinting-centrevalue border-t border-l">{{ item.specifications }}</div>
            <div class="workerSinglesPrinting-name border-t border-l">产品工艺</div>
            <div class="workerSinglesPrinting-centrevalue border-t border-l">{{ item.craftName }}</div>
          </div>
          <div class="flex">
            <div class="workerSinglesPrinting-name border-t border-r">备注</div>
            <div class="workerSinglesPrinting-value border-t">{{ item.remark }}</div>
          </div>
          <!-- <div class="flex">
            <div class="workerSinglesPrinting-name border-t">物料清单（BOM）</div>
          </div>
          <div class="flex">
            <div class="workerSinglesPrinting-name border-t">序号</div>
            <div style="flex: 7" class="workerSinglesPrinting-minvalue2 border-t border-l">物料名称</div>
            <div style="flex: 7" class="workerSinglesPrinting-minvalue2 border-t border-l">物料号</div>
            <div style="flex: 7" class="workerSinglesPrinting-minvalue2 border-t border-l">物料规格</div>
            <div style="flex: 7" class="workerSinglesPrinting-minvalue2 border-t border-l">数量</div>
            <div style="flex: 7" class="workerSinglesPrinting-minvalue2 border-t border-l">单位</div>
          </div>
          <div v-for="(item2, index2) in item.materialList" :key="index2" class="flex">
            <div class="workerSinglesPrinting-name border-t">{{ index2 + 1 }}</div>
            <div style="flex: 7" class="workerSinglesPrinting-minvalue2 border-t border-l">{{ item2.name }}</div>
            <div style="flex: 7" class="workerSinglesPrinting-minvalue2 border-t border-l">{{ item2.model }}</div>
            <div style="flex: 7" class="workerSinglesPrinting-minvalue2 border-t border-l">{{ item2.specification }}</div>
            <div style="flex: 7" class="workerSinglesPrinting-minvalue2 border-t border-l">{{ item2.number }}</div>
            <div style="flex: 7" class="workerSinglesPrinting-minvalue2 border-t border-l">{{ item2.unit }}</div>
          </div> -->
          <div class="flex">
            <div class="workerSinglesPrinting-name border-t">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as util from '@/core/util'
import moment from 'moment'
export default {
  name: 'WorkerSinglesPrinting',
  filters: {
    getDate(date) {
      return moment(date).format('YYYY-MM-DD')
    }
  },
  props: {
    id: { // id
      type: String,
      default: ''
    }
  },
  data() {
    return {
      objectItem: {},
      printObj: {
        id: 'printTest',
        popTitle: '精益卫士TPM助手'
      },
      processRoute: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    unique(arr) {
      debugger
      return arr.filter(function(item, index, arr) {
        // 当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
        return arr.indexOf(item, 0) === index
      })
    },
    load() {
      util.get(`/productWorkOrder/info/${this.id}`).then(res => {
        if (res.code === 200) {
          this.objectItem = res.data
          // let list = []
          // res.data.productCraftList.forEach(element => {
          //   list.push(JSON.stringify({ productId: element.productId, productName: element.productName, child: [] }))
          // })
          // console.log(this.unique(list))
          // 设置一个对象放id 一个数组放处理后的数据
          // const a = {}
          // const b = []
          //  深拷贝源数据
          const arrs = JSON.parse(JSON.stringify(res.data.prodList))
          // 循环遍历原始数据
          // arrs.forEach((item) => {
          //   // 判断item.id 是否存在在 a 中 无则直接push进b
          //   if (!a[item.productId]) {
          //     // 直接push b
          //     b.push({ productId: item.productId, productName: item.productName, child: [] })
          //     // 将 对象塞进 a中
          //     a[item.productId] = item
          //   }
          // })
          // res.data.productCraftList.forEach(ele2 => {
          //   b.forEach(ele3 => {
          //     if (ele2.productId === ele3.productId) {
          //       ele3.child.push(ele2)
          //     }
          //   })
          // })
          this.processRoute = arrs
        }
      }).catch(err => {
        console.log(err.response)
        this.$message.error(err.message)
      })
    }
  }
}
</script>

<style scoped>
.workerSinglesPrinting {
  background-color: #FFFFFF;
  padding: 35px 32px;
}
.workerSinglesPrinting-title {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 16px;
}
.workerSinglesPrinting-odd {
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 7px;
}
.workerSinglesPrinting-table {
  font-size: 12px;
  color: #000000;
  font-weight: 400;
  border: 2px solid #000000;
  padding: 0;
  margin: 0;
}
.flex {
  display: flex;
}
.workerSinglesPrinting-name {
  flex: 3;
  padding: 6px 0px;
  text-align: center;
}
.workerSinglesPrinting-value {
  text-align: center;
  flex: 21;
  padding: 6px 0px;
  flex-shrink: 0;
}
.workerSinglesPrinting-minvalue {
  text-align: center;
  flex: 5;
  padding: 6px 0px;
}
.workerSinglesPrinting-minvalue2 {
  text-align: center;
  flex: 4.2;
  padding: 6px 0px;
}
.workerSinglesPrinting-centrevalue {
  text-align: center;
  flex: 9;
  padding: 6px 0px;
}
.workerSinglesPrinting-QRcode {
  text-align: center;
  flex: 6;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.QRcode-img {
  width: 194px;
  height: 194px;
  flex-shrink: 0;
}
.workerSinglesPrinting-QRremark {
  font-size: 10px;
  text-align: center;
  flex: 18;
  padding: 15px 0px;
  /* white-space: pre-wrap; */
  /* display: flex; */
  /* flex-wrap: wrap; */
}
.workerSinglesPrinting-QRremark >>> .el-step.is-simple .el-step__title {
  color: #303133;
  font-weight: 700;
}
.workerSinglesPrinting-QRremark >>> .el-step.is-simple .el-step__icon {
  border-color: #303133 !important;
}
.workerSinglesPrinting-message {
  text-align: center;
  flex: 24;
  padding: 26px 0px;
}
.border-t {
  border-top: 2px solid #000000;
}
.border-r {
  border-right: 2px solid #000000;
}
.border-b {
  border-bottom: 2px solid #000000;
}
.border-l {
  border-left: 2px solid #000000;
}
/* // 打印媒体查询 */
/* @media print {
  @page{
      size:  auto;
      margin: 3mm 3mm 0px;
	}
} */
</style>
