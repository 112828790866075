<template>
	<div class="oplList">
		<div class="oplList-title">
			<span class="title-icon"> </span>
			<span>成交客户管理</span>
		</div>
		<div class="oplList-content">
			<div class="content-right">
				<!-- 查询区域 -->
				<div class="content-right-form">
					<conditionQuery
						:headData="headData"
						@onHandleChange="onHandleChange"
						@onInputChange="onInputChange"
						@onSelectClick="getPageList"
					/>
				</div>
				<!-- 功能按钮区域 -->
				<div class="content-right-btnGroup">
					<a-button-group>
						<a-button @click="customerUpdate" name="addOplType">修改客户状态</a-button>
						<a-button @click="associatedTenantClick">关联租户</a-button>
						<!-- <a-button @click="addOplType" name="addOplType">新增租户</a-button>
						<a-button @click="delOplListBtn">修改租户</a-button>
						<a-button @click="delOplListBtn">停用租户</a-button> -->
						<!-- <a-button @click="showModal" name="addOplList">权限配置</a-button> -->
					</a-button-group>
				</div>
				<!-- 表格区域 -->
				<div class="content-right-table">
					<a-table
						:columns="columns"
						:transformCellText="({ text, column, record, index }) => text || '-'"
						:row-selection="rowSelection"
						:pagination="false"
						:data-source="dataSource"
					>
						<span slot="state" slot-scope="text">
							<a-badge
								v-if="text == '1'"
								color="red"
								text="报备"
							/>
							<a-badge
								v-if="text == '2'"
								color="green"
								text="续存"
							/>
						</span>
						<span slot="flag" slot-scope="text">
							<a-badge
								v-if="text"
								color="green"
								text="启用"
							/>
							<a-badge
								v-else
								color="red"
								text="停用"
							/>
						</span>
						<span slot="leaseBeginTime" slot-scope="scope">
							{{ scope.leaseBeginTime ? $moment(scope.leaseBeginTime).format('YYYY-MM-DD') + ' 至 ' + $moment(scope.leaseEndTime).format('YYYY-MM-DD') : '-' }}
						</span>
						<span slot="time" slot-scope="time">
							{{ $moment(time).format('YYYY-MM-DD HH:mm') }}
						</span>
						<span slot="action" slot-scope="scope">
							<a @click="openinfoOplList(scope)" name="infoOplList">查看</a>
						</span>
					</a-table>
					<a-pagination
						size="small"
						:total="total"
						:defaultCurrent="pageIndex"
						:defaultPageSize="pageSize"
						show-size-changer
						show-quick-jumper
						:show-total="total => `总共 ${total} 条`"
					/>
				</div>
			</div>
		</div>
		<!-- 弹窗区域 -->
		<jywsDialog
			:visible="visible"
			:title="modalTitle"
			:width="visibleWidth"
			:roger="jywsDialogBottomRoger"
			:typePrimary="typePrimary"
			:bottom-name="jywsDialogBottomName"
			@updataClose="closeModal"
			@updataWin="updataWin"
		>
			<a-spin :spinning="spinning">
				<div class="modal-body">
					<!-- 关联租户 -->
					<div v-if="visibleType === 'association'">
						<!-- 查询区域 -->
						<div class="content-right-form">
							<conditionQuery
								:headData="tenantHeadData"
								@onInputChange="onInputChange"
								@onSelectClick="queryTenantList"
							/>
						</div>
						<!-- 功能按钮区域 -->
						<div class="content-right-table">
							<a-table
								:columns="tenantColumns"
								:transformCellText="({ text, column, record, index }) => text || '-'"
								:row-selection="rowSelection2"
								:pagination="false"
								:data-source="tenantDataSource"
							>
								<span slot="flag" slot-scope="text">
									<a-badge
										v-if="text"
										color="green"
										text="启用"
									/>
									<a-badge
										v-else
										color="red"
										text="停用"
									/>
								</span>
							</a-table>
							<a-pagination
								size="small"
								@change="pageonChange"
								@showSizeChange="showSizeChange"
								:total="tenantTotal"
								:defaultCurrent="tenantPageIndex"
								:defaultPageSize="tenantPageSize"
								show-size-changer
								show-quick-jumper
								:show-total="total => `总共 ${tenantTotal} 条`"
							/>
						</div>
					</div>
					<!-- 新增/修改opl类型弹窗 -->
					<div v-if="visibleType === 'addOplType' || visibleType === 'editOplType'">
						<a-descriptions :column="2" bordered size="small">
							<a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">归属分销商：</div>
								</template>
								<a-input
									@click="newshowModal"
									name="selectBoss"
									style="width: 100%"
									placeholder="请输入"
							/></a-descriptions-item>
							<a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">分销商电话：</div>
								</template>
								<a-input style="width: 100%" placeholder="请输入"
							/></a-descriptions-item>
							<a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">直接推荐人：</div>
								</template>
								<a-input style="width: 100%" placeholder="选择分销商后自动写入"
							/></a-descriptions-item>
							<a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">间接推荐人：</div>
								</template>
								<a-input style="width: 100%" placeholder="选择分销商后自动写入"
							/></a-descriptions-item>
							<a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">企业名称：</div>
								</template>
								<a-input style="width: 100%" placeholder="请输入"
							/></a-descriptions-item>
							<a-descriptions-item>
								<template v-slot:label>
									<div>联系部门：</div>
								</template>
								<a-input style="width: 100%" placeholder="请输入联系部门"
							/></a-descriptions-item>
							<a-descriptions-item>
								<template v-slot:label>
									<div>联系人：</div>
								</template>
								<a-input style="width: 100%" placeholder="限50字符以内"
							/></a-descriptions-item>
							<a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">联系电话：</div>
								</template>
								<a-input style="width: 100%" placeholder="请输入联系电话"
							/></a-descriptions-item>
							<a-descriptions-item>
								<template v-slot:label>
									<div>客户地址：</div>
								</template>
								<a-input style="width: 100%" placeholder="限50字符以内"
							/></a-descriptions-item>
							<a-descriptions-item>
								<template v-slot:label>
									<div>从报备客户选择：</div>
								</template>
								<a-button type="primary" size="small" name="newselectBoss" @click="newshowModal">
									选择
								</a-button></a-descriptions-item
							>
						</a-descriptions>
						<a-descriptions class="mar-t16" :column="2" bordered size="small">
							<a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">管理员账号：</div>
								</template>
								<a-input style="width: 100%" placeholder="请输入" /></a-descriptions-item
							><a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">登录密码：</div>
								</template>
								<a-input
									style="width: 100%"
									placeholder="密码长度至少8位，密码含有数字和字母" /></a-descriptions-item
							><a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">启用日期：</div>
								</template>
								<a-date-picker
									style="width: 100%"
									@change="onChange"
									placeholder="请选择启用日期" /></a-descriptions-item
							><a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">停用日期：</div>
								</template>
								<a-date-picker
									style="width: 100%"
									@change="onChange"
									placeholder="请选择停用日期" /></a-descriptions-item
							><a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">租用状态：</div>
								</template>
								<a-switch
									checked-children="是"
									un-checked-children="否"
									default-checked /></a-descriptions-item
							><a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">用户数：</div>
								</template>
								<a-input style="width: 100%" placeholder="请输入"
							/></a-descriptions-item>
						</a-descriptions>
						<a-descriptions class="mar-t16" :column="2" bordered size="small">
							<a-descriptions-item>
								<template v-slot:label>
									<div>企业性质：</div>
								</template>
								<a-input style="width: 100%" placeholder="请输入" /></a-descriptions-item
							><a-descriptions-item>
								<template v-slot:label>
									<div>归属平台：</div>
								</template>
								<a-input
									style="width: 100%"
									placeholder="密码长度至少8位，密码含有数字和字母" /></a-descriptions-item
							><a-descriptions-item>
								<template v-slot:label>
									<div>所属行业：</div>
								</template>
								<a-input style="width: 100%" placeholder="请输入" /></a-descriptions-item
							><a-descriptions-item>
								<template v-slot:label>
									<div>联系邮箱：</div>
								</template>
								<a-input style="width: 100%" placeholder="请输入" /></a-descriptions-item
							><a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">短信通知：</div>
								</template>
								<a-switch checked-children="是" un-checked-children="否" default-checked
							/></a-descriptions-item>
							<a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">短信剩余：</div>
								</template>
								<a-input style="width: 50%" placeholder="请输入" /><a-button
									style="margin-left: 10px"
									type="primary"
									size="small"
									>修改</a-button
								></a-descriptions-item
							>
							<a-descriptions-item>
								<template v-slot:label>
									<div class="des-must">邮件通知：</div>
								</template>
								<a-switch checked-children="是" un-checked-children="否" default-checked
							/></a-descriptions-item>
							<a-descriptions-item>
								<template v-slot:label>
									<div>备注：</div>
								</template>
								<a-input style="width: 100%" placeholder="请输入"
							/></a-descriptions-item>
						</a-descriptions>
					</div>
					<!-- 新增opl课程列表弹窗 -->
					<div v-else-if="visibleType === 'addOplList'">
						<div class="choosePlan">
							<div class="choosePlan-top">
								<div class="choosePlan-top-left">
									<span>策略模板</span>
									<span style="font-size: 14px; color: #9e9e9e; margin-left: 18px"
										>将菜单权限赫应用权限配置成模板，便于下次重复使用</span
									>
								</div>
								<div>
									<a-button-group>
										<a-button>添加</a-button>
										<a-button>应用</a-button>
										<a-button>删除</a-button>
									</a-button-group>
								</div>
							</div>
							<div class="choosePlan-bottom">
								<a-checkbox @change="onChange"> 1.0数字化系统 </a-checkbox>
								<a-checkbox @change="onChange"> 2.0智能机 </a-checkbox>
								<a-checkbox @change="onChange"> 2.0智能机+物联 </a-checkbox>
								<a-checkbox @change="onChange"> 3.0企业经营 </a-checkbox>
							</div>
						</div>
						<div style="margin-top: 12px">
							<a-tabs type="card">
								<a-tab-pane key="1" tab="菜单权限">
									<div class="modal-tree">
										<a-tree
											checkable
											:tree-data="newtreeData"
											showLine
											:default-expanded-keys="['0-0-0', '0-0-1']"
											:default-selected-keys="['0-0-0', '0-0-1']"
											:default-checked-keys="['0-0-0', '0-0-1']"
											@select="onSelect"
											@check="onCheck"
										>
										</a-tree>
									</div>
								</a-tab-pane>
								<a-tab-pane key="2" tab="应用权限">
                  <div class="modal-app">
                    <div class="modal-app-box">
                      <a-checkbox @change="onChange"> 任务中心 </a-checkbox>
                      <a-input placeholder="请输入" style="width: 86px;margin-top: 8px;" />
                    </div>
                    <div class="modal-app-box">
                      <a-checkbox @change="onChange"> 任务中心 </a-checkbox>
                      <a-input placeholder="请输入" style="width: 86px;margin-top: 8px;" />
                    </div>
                    <div class="modal-app-box">
                      <a-checkbox @change="onChange"> 任务中心 </a-checkbox>
                      <a-input placeholder="请输入" style="width: 86px;margin-top: 8px;" />
                    </div>
                    <div class="modal-app-box">
                      <a-checkbox @change="onChange"> 任务中心 </a-checkbox>
                      <a-input placeholder="请输入" style="width: 86px;margin-top: 8px;" />
                    </div>
                    <div class="modal-app-box">
                      <a-checkbox @change="onChange"> 任务中心 </a-checkbox>
                      <a-input placeholder="请输入" style="width: 86px;margin-top: 8px;" />
                    </div>
                    <div class="modal-app-box">
                      <a-checkbox @change="onChange"> 任务中心 </a-checkbox>
                      <a-input placeholder="请输入" style="width: 86px;margin-top: 8px;" />
                    </div>
                  </div>
                </a-tab-pane>
							</a-tabs>
						</div>
						<!-- 富文本区域 -->
					</div>
					<!-- 关联课程弹窗 -->
					<div v-else-if="visibleType === 'editOplListType'">
						<a-row :gutter="[32, 24]" justify="center" align="middle">
							<a-col :span="24" class="flex">
								<div class="col-label must">opl类型</div>
								<div class="col-value">
									<a-tree-select
										v-model="addoplForm.oplTypeId"
										style="width: 100%"
										:dropdown-style="{
											maxHeight: '400px',
											overflow: 'auto',
										}"
										placeholder="请选择类型"
										:tree-data="treeData"
										:replaceFields="replaceFields"
										@change="oplTypeChange"
									></a-tree-select>
								</div>
							</a-col>
						</a-row>
					</div>
					<!-- opl课程详情 -->
					<div v-else-if="visibleType === 'infoOplList'">
						<div class="infoOplList-title">
							{{ this.infoOplList.title }}
						</div>
						<div class="infoOplList-smtitle">
							<div class="mg-l-5">创建人：{{ this.infoOplList.createUserName }}</div>
							<div class="mg-l-50">
								创建时间：{{ $moment(this.infoOplList.createTime).format('YYYY-MM-DD HH:mm') }}
							</div>
							<div class="mg-l-50">
								附件：<a :href="this.infoOplList.accessoryUrl" target="_blank"
									>{{ this.infoOplList.accessoryName }}（点击下载）</a
								>
							</div>
						</div>
						<div class="infoOplList-content" v-html="this.infoOplList.content">
							<!-- {{ `${this.infoOplList.content}` }} -->
						</div>
					</div>
				</div>
			</a-spin>
		</jywsDialog>
		<jywsDialog
			:visible="newvisible"
			:title="newmodalTitle"
			:width="newvisibleWidth"
			:roger="newjywsDialogBottomRoger"
			:typePrimary="newtypePrimary"
			:bottom-name="newjywsDialogBottomName"
			@updataClose="newcloseModal"
			@updataWin="newupdataWin"
		>
			<div class="modal-body">
				<div v-if="newvisibleType === 'selectBoss'">
					<div class="newcontent-right-form">
						<conditionQuery
							:headData="newheadData"
							@onTimeChange="onTimeChange"
							@onInputChange="onInputChange"
							@onSelectClick="getOplList"
						/>
					</div>
					<div class="modal-title-text">
						{{ modalTitleText }}
					</div>
					<a-table
						:columns="xzfxscolumns"
						:row-selection="newrowSelection"
						:pagination="false"
						:data-source="xzfxsdataSource"
					>
					</a-table>
				</div>
			</div>
		</jywsDialog>
	</div>
</template>

<script>
import jywsDialog from '@/components/jywsDialog.vue'
import * as util from '@/core/util'
import conditionQuery from '@/components/conditionQuery.vue'
import '@wangeditor/editor/dist/css/style.css'
// import currency from '@/core/currency'
const newtreeData = [
	{
		title: 'parent 1',
		key: '0-0',
		children: [
			{
				title: 'parent 1-0',
				key: '0-0-0',
				disabled: true,
				children: [
					{ title: 'leaf', key: '0-0-0-0', disableCheckbox: true },
					{ title: 'leaf', key: '0-0-0-1' },
				],
			},
			{
				title: 'parent 1-1',
				key: '0-0-1',
				children: [{ key: '0-0-1-0', slots: { title: 'title0010' } }],
			},
		],
	},
]
export default {
	components: {
		conditionQuery,
		jywsDialog,
	},
	data() {
		return {
			newtreeData,
			selectedRows: [],
			selectedRowKeys: [],
			modalTitleText: '',
			xzfxscolumns: [
				{
					title: '分销商昵称',
					dataIndex: 'title',
					key: 'title',
				},
				{
					title: '微信号',
					dataIndex: 'title',
					key: 'title',
				},
				{
					title: '分销商电话',
					dataIndex: 'title',
					key: 'title',
				},
				{
					title: '直接推荐人',
					dataIndex: 'title',
					key: 'title',
				},
				{
					title: '间接推荐人',
					dataIndex: 'title',
					key: 'title',
				},
			],
			xzfxsdataSource: [],
			newheadData: [
				{
					key: 1,
					name: '分销商昵称',
					component: 'a-input',
					value: '',
					duplicate: 'title',
					placeholder: '请输入',
				},
				{
					key: 1,
					name: '分销商电话',
					component: 'a-input',
					value: '',
					duplicate: 'title',
					placeholder: '请输入',
				},
			],
			tenantId: '',
			// 新增opl课程参数
			addoplForm: {
				oplTypeId: undefined,
				oplTypeName: '',
				title: '',
				accessoryUrl: '',
				accessoryName: '',
				content: '',
				tenantId: '',
			},
			// 查询opl课程列表参数
			searchForm: {
				title: '',
				startTime: '',
				endTime: '',
			},
			visible: false,
			newvisible: false,
			visibleType: '',
			visibleWidth: '520px',
			newvisibleWidth: '960px',
			typePrimary: 'primary',
			newtypePrimary: 'primary',
			modalTitle: '',
			newmodalTitle: '',
			oplTypeName: '',
			spinning: false,
			// 树组件 键值对
			replaceFields: {
				children: 'children',
				title: 'oplName',
				key: 'oplId',
				value: 'oplId',
			},
			treeData: [],
			oplId: '',
			selectTreeData: {},
			jywsDialogBottomRoger: '取消',
			newjywsDialogBottomRoger: '取消',
			newjywsDialogBottomName: '确认',
			jywsDialogBottomName: '确认',
			editor: null,
			html: '<p></p>',
			toolbarConfig: {},
			editorConfig: {
				placeholder: '请输入内容...',
				// 所有的菜单配置，都要在 MENU_CONF 属性下
				MENU_CONF: {},
			},
			mode: 'default', // or 'simple'
			infoOplList: {
				title: '',
				createUserName: '',
				accessoryName: '',
				createTime: '',
				content: '',
				accessoryUrl: '',
			},
			userId: '',
			userName: '',

			pageIndex: 1,
			pageSize: 10,
			total: 10,
			// rowSelection,
			headData: [
				{
					key: 1,
					name: '企业名称',
					component: 'a-input',
					value: '',
					duplicate: 'name',
					placeholder: '请输入',
				},
				// {
				// 	key: 2,
				// 	name: '管理员账号',
				// 	component: 'a-input',
				// 	value: '',
				// 	duplicate: 'title',
				// 	placeholder: '请输入',
				// },
				{
					key: 2,
					name: '客户状态',
					component: 'a-select',
					value: undefined,
					duplicate: 'state',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: '',
						},
						{
							name: '报备',
							value: 1,
						},
						{
							name: '续存',
							value: 2,
						}
					],
				},
				{
					key: 3,
					name: '租用状态',
					component: 'a-select',
					value: undefined,
					duplicate: 'flag',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: '',
						},
						{
							name: '启用',
							value: 1,
						},
						{
							name: '停用',
							value: 0,
						}
					],
				},
				{
					key: 4,
					name: '联系人',
					component: 'a-input',
					value: '',
					duplicate: 'contacter',
					placeholder: '请输入',
				},
				{
					key: 5,
					name: '联系电话',
					component: 'a-input',
					value: '',
					duplicate: 'contactPhone',
					placeholder: '请输入',
				},
				{
					key: 6,
					name: '归属分销商',
					component: 'a-input',
					value: '',
					duplicate: 'discountName',
					placeholder: '请输入',
				},
			],
			columns: [
				{
					title: '企业名称',
					dataIndex: 'name',
					key: 'name',
				},
				// {
				// 	title: '管理员账号',
				// 	dataIndex: 'oplTypeName',
				// 	key: 'oplTypeName',
				// },
				{
					title: '客户状态',
					dataIndex: 'state',
					key: 'state',
					scopedSlots: { customRender: 'state' },
				},
				{
					title: '租用状态',
					dataIndex: 'flag',
					key: 'flag',
					scopedSlots: { customRender: 'flag' },
				},
				{
					title: '租用时段',
					scopedSlots: { customRender: 'leaseBeginTime' },
				},
				// {
				// 	title: '用户数',
				// 	dataIndex: 'createTime',
				// 	key: 'createTime',
				// 	scopedSlots: { customRender: 'time' },
				// },
				{
					title: '备注',
					dataIndex: 'remark',
					key: 'remark',
				},
				{
					title: '联系部门',
					dataIndex: 'contactDep',
					key: 'contactDep',
				},
				{
					title: '联系人',
					dataIndex: 'contacter',
					key: 'contacter',
				},
				{
					title: '联系电话',
					dataIndex: 'contactPhone',
					key: 'contactPhone',
				},
				{
					title: '归属分销商',
					dataIndex: 'discountName',
					key: 'discountName',
				},
				{
					title: '分销商电话',
					dataIndex: 'phone',
					key: 'phone',
				}
			],
			dataSource: [],
			state: '',
			discountCode: '',
			shareDiscountCode: '',
			name: '',
			contactPhone: '',
			contacter: '',
			discountName: '',
			tenantHeadData: [
				{
					key: 1,
					name: '企业名称',
					component: 'a-input',
					value: '',
					duplicate: 'tenantName',
					placeholder: '请输入',
				}
			],
			tenantPageIndex: 1,
			tenantPageSize: 10,
			tenantTotal: 10,
			tenantName: '',
			tenantColumns: [
				{
					title: '企业名称',
					dataIndex: 'name',
					key: 'name',
				},
				// {
				// 	title: '管理员账号',
				// 	dataIndex: 'oplTypeName',
				// 	key: 'oplTypeName',
				// },
				{
					title: '人员规模',
					dataIndex: 'memberNum',
					key: 'memberNum',
				},
				{
					title: '联系人',
					dataIndex: 'linkman',
					key: 'linkman',
				},
				{
					title: '联系电话',
					dataIndex: 'telephone',
					key: 'telephone',
				},
				{
					title: '启用日期',
					dataIndex: 'startTime',
					key: 'startTime',
				},
				{
					title: '停用日期',
					dataIndex: 'endTime',
					key: 'endTime',
				},
				{
					title: '状态',
					dataIndex: 'contactor',
					key: 'contactor',
					scopedSlots: { customRender: 'flag' },
				}
			],
			tenantDataSource: [],
			selectedRows2: [],
			selectedRowKeys2: [],
		}
	},
	created() {
		// var str = this.$route.fullPath.split('=')
		// this.tenantId = str[1].split('&')[0]
		// this.userId = str[2].split('&')[0]
		// this.userName = decodeURIComponent(str[3].split('&')[0])
		// this.addoplForm.tenantId = str[1].split('&')[0]
		this.editorConfig.MENU_CONF['uploadImage'] = {
			server: '/api/upload-image',
			fieldName: 'custom-field-name',
			// 继续写其他配置...

			//【注意】不需要修改的不用写，wangEditor 会去 merge 当前其他配置
		}
	},
	computed: {
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys = selectedRowKeys
					this.selectedRows = selectedRows
				},
			}
		},
		rowSelection2() {
			return {
				selectedRowKeys: this.selectedRowKeys2,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys2 = selectedRowKeys
					this.selectedRows2 = selectedRows
				},
			}
		},
		newrowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys = selectedRowKeys
					this.selectedRows = selectedRows
				},
			}
		},
	},
	mounted() {
		this.getPageList()
	},
	beforeDestroy() {
		const editor = this.editor
		if (editor == null) return
		editor.destroy() // 组件销毁时，及时销毁编辑器
	},
	methods: {
		// 成交客户管理列表
		getPageList() {
			let dataset = {
				state: this.state,
				flag: this.flag,
				discountCode: this.discountCode,
				shareDiscountCode: this.shareDiscountCode,
				name: this.name,
				contactPhone: this.contactPhone,
				contacter: this.contacter,
				discountName: this.discountName,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize,
			}
			util.postform('customer/getPageList', dataset).then(res => {
				console.log(res)
				res.data.records.forEach(item => {
					item.key = item.id
				})
				this.dataSource = res.data.records
				this.total = res.data.total
			})
		},
		customerUpdate() {
			let that = this
			if (this.selectedRows.length != 1) {
				return this.$warning({
						title: '请选择一条记录进行修改！',
					})
			}
			this.$confirm({
				title: '修改提示！',
        content:'此操作将永久修改客户状态, 是否继续?',
				onOk() {
					let dataset = {
						id: that.selectedRows[0].id,
						state: 2,
					}
					util.postform('customer/update', dataset).then(res => {
						if (res.code === 200) {
							that.$message.success('修改成功！')
							that.selectedRows = []
							that.selectedRowKeys = []
							that.getPageList()
						}
					})
				},
				onCancel() {},
			})
		},
		// 关联租户列表
		queryTenantList() {
			let dataset = {
				name: this.tenantName,
				pageIndex: this.tenantPageIndex,
				pageSize: this.tenantPageSize
			}
			util.postform('ZNtenant/queryTenantList', dataset).then(res => {
				console.log(res)
				res.data.records.forEach(item => {
					item.key = item.id
				})
				this.tenantDataSource = res.data.records
				this.tenantTotal = res.data.total
			})
		},
		associatedTenantClick() {
			if (this.selectedRowKeys.length != 1) {
				return this.$warning({
						title: '请选择一条记录进行关联租户！',
					})
			}
			this.showModal({target: { name: 'association' }})
		},
		// 翻页
		pageonChange(page) {
			this.tenantPageIndex = page
			this.queryTenantList()
		},
		// 页数size改变
		showSizeChange(key, size) {
			this.tenantPageSize = size
			this.queryTenantList()
		},
		// 查询区域 - 输入框变动
		onInputChange(item) {
			if (item.duplicate == 'name') {
				this.name = item.value
			} else if (item.duplicate == 'contacter') {
				this.contacter = item.value
			} else if (item.duplicate == 'contactPhone') {
				this.contactPhone = item.value
			} else if (item.duplicate == 'discountName') {
				this.discountName = item.value
			} else if (item.duplicate == 'tenantName') {
				this.tenantName = item.value
			}
		},
		// 下拉框选中
		onHandleChange(item) {
			if (item.duplicate == 'state') {
				this.state = item.value
			} else if (item.duplicate == 'flag') {
				this.flag = item.value
			}
		},
		// 开启弹窗
		showModal(e) {
			let name = e.target.name
			this.jywsDialogBottomName = '确认'
			this.jywsDialogBottomRoger = '取消'
			this.typePrimary = 'primary'
			switch (name) {
				case 'association':
					this.modalTitle = '关联租户'
					this.visibleType = 'association'
					this.visible = true
					this.visibleWidth = '960px'
					this.queryTenantList()
					break
				case 'addOplType':
					this.modalTitle = '处理申请'
					this.visibleType = 'addOplType'
					this.visible = true
					this.visibleWidth = '960px'
					break
				case 'editOplType':
					this.modalTitle = '修 改'
					this.visibleType = 'editOplType'
					this.visible = true
					this.visibleWidth = '520px'
					break
				case 'editOplListType':
					this.modalTitle = '关联类型'
					this.visibleType = 'editOplListType'
					this.visible = true
					this.visibleWidth = '520px'
					break
				case 'addOplList':
					this.modalTitle = '权限配置'
					this.visibleType = 'addOplList'
					this.visible = true
					this.visibleWidth = '960px'
					break
				case 'editOplList':
					this.modalTitle = '修 改'
					this.visibleType = 'editOplList'
					this.visible = true
					this.visibleWidth = '960px'
					break
				default:
					break
			}
		},
		updataWin() {
			if (this.visibleType === 'association') {
				if (this.selectedRowKeys2.length != 1) {
					return this.$warning({
						title: '请选择一条记录进行关联租户！',
					})
				}
				this.customerUpdate2()
			} else if (this.visibleType === 'addOplType') {
				this.addOplTypeBtn()
			} else if (this.visibleType === 'editOplType') {
				this.editOplTypeBtn()
			} else if (this.visibleType === 'addOplList') {
				// this.addoplForm.content = this.editor.getText()
				this.addoplForm.content = this.html
				this.addOplListBtn()
			} else if (this.visibleType === 'editOplList') {
				this.editOplListBtn()
			} else if (this.visibleType === 'infoOplList') {
				this.closeModal()
			} else if (this.visibleType === 'editOplListType') {
				this.editOplListTypeBtn()
			}
		},
		customerUpdate2() {
			let that = this
			let dataset = {
				id: that.selectedRows[0].id,
				tenId: that.selectedRows2[0].id,
			}
			this.spinning = true
			util.postform('customer/update', dataset).then(res => {
				if (res.code === 200) {
					that.$message.success('关联成功！')
					this.spinning = false
					this.closeModal()
					that.getPageList()
				}
			})
		},
		onCreated(editor) {
			this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
		},
		onSelect(selectedKeys, info) {
			console.log('selected', selectedKeys, info)
		},
		onCheck(checkedKeys, info) {
			console.log('onCheck', checkedKeys, info)
		},
		// 选择opl类型
		oplTypeChange(val, obj) {
			this.addoplForm.oplTypeId = val
			this.addoplForm.oplTypeName = obj[0]
		},
		// 递归给树增加icon图标
		addIconSlot(val) {
			val.title = val.oplName
			val.key = val.oplId
			if (val.parentOpl === '0') {
				val.slots = { icon: 'home' }
			} else {
				val.slots = { icon: 'hdd' }
			}
			if (val.children && val.children.length !== 0) {
				val.children.forEach(item => {
					this.addIconSlot(item)
				})
			}
		},
		// 上传附件 -- 获取oss地址
		setFileList(val) {
			this.addoplForm.accessoryUrl = val
		},
		setFileListName(val) {
			this.addoplForm.accessoryName = val
		},
		// 查询区域 - 时间范围变动
		onTimeChange(val) {
			this.searchForm.startTime = val.dateString[0]
			this.searchForm.endTime = val.dateString[1]
		},
		// 获取opl类型树结构
		getOplTypeTree() {
			util.get('oplType/getTree/' + this.tenantId).then(res => {
				if (res.data) {
					res.data.forEach(item => {
						this.addIconSlot(item)
					})
				}
				this.treeData = res.data || []
			})
		},
		// 选中树的回调
		treeSelect(val, data) {
			this.selectTreeData = data
			this.oplId = val.length !== 0 ? val[0] : ''
			this.getOplList()
		},
		onChange(val) {
			console.log(val)
		},
		newshowModal(e) {
			let name = e.target.name
			this.newjywsDialogBottomName = '确认'
			this.newjywsDialogBottomRoger = '取消'
			this.newtypePrimary = 'primary'
			switch (name) {
				case 'selectBoss':
					this.newmodalTitle = '选择分销商'
					this.newvisibleType = 'selectBoss'
					this.newvisible = true
					this.newvisibleWidth = '960px'
					this.xzfxscolumns = [
						{
							title: '分销商昵称',
							dataIndex: 'title',
							key: 'title',
						},
						{
							title: '微信号',
							dataIndex: 'title',
							key: 'title',
						},
						{
							title: '分销商电话',
							dataIndex: 'title',
							key: 'title',
						},
						{
							title: '直接推荐人',
							dataIndex: 'title',
							key: 'title',
						},
						{
							title: '间接推荐人',
							dataIndex: 'title',
							key: 'title',
						},
					]
					this.newheadData = [
						{
							key: 1,
							name: '分销商昵称',
							component: 'a-input',
							value: '',
							duplicate: 'title',
							placeholder: '请输入',
						},
						{
							key: 1,
							name: '分销商电话',
							component: 'a-input',
							value: '',
							duplicate: 'title',
							placeholder: '请输入',
						},
					]
					this.modalTitleText = '提示：请勾选分销商，勾选后将自动带入，仅支持单选。'
					break
				case 'newselectBoss':
					this.newmodalTitle = '选择报备客户'
					this.newvisibleType = 'selectBoss'
					this.newvisible = true
					this.newvisibleWidth = '1000px'
					this.xzfxscolumns = [
						{
							title: '企业名称',
							dataIndex: 'title',
							key: 'title',
						},
						{
							title: '联系部门',
							dataIndex: 'title',
							key: 'title',
						},
						{
							title: '联系人',
							dataIndex: 'title',
							key: 'title',
						},
						{
							title: '联系电话',
							dataIndex: 'title',
							key: 'title',
						},
						{
							title: '归属分销商',
							dataIndex: 'title',
							key: 'title',
						},
						{
							title: '分销商电话',
							dataIndex: 'title',
							key: 'title',
						},
					]
					this.newheadData = [
						{
							key: 1,
							name: '企业名称',
							component: 'a-input',
							value: '',
							duplicate: 'title',
							placeholder: '请输入',
						},
						{
							key: 2,
							name: '联系人',
							component: 'a-input',
							value: '',
							duplicate: 'title',
							placeholder: '请输入',
						},
						{
							key: 3,
							name: '归属分销商',
							component: 'a-input',
							value: '',
							duplicate: 'title',
							placeholder: '请输入',
						},
					]
					this.modalTitleText = '提示：请勾选成交企业信息，勾选后将自动带入企业信息，仅支持单选。'
					break
			}
		},
		newcloseModal() {
			this.newvisible = false
		},
		newupdataWin() {
			this.newvisible = false
		},
		// 关闭弹窗
		closeModal() {
			this.visible = false
			if (this.addoplForm.accessoryName) {
				this.$refs.uploadOss.clearFileList()
			}
			this.oplTypeName = ''
			this.addoplForm = {
				oplTypeId: undefined,
				oplTypeName: '',
				title: '',
				accessoryUrl: '',
				accessoryName: '',
				content: '',
				tenantId: this.tenantId,
			}
			this.html = '<p></p>'
			this.selectedRowKeys = []
			this.selectedRows = []
		},
		// 打开弹窗按钮
		addOplType(e) {
			let name = e.target.name
			// 修改opl类型
			if (name === 'editOplType') {
				if (this.oplId === '') {
					this.$warning({
						title: '请选择一个类型进行修改操作！',
					})
					return
				}
				this.oplTypeName = this.selectTreeData.oplName
			}
			if (name === 'editOplList') {
				if (this.selectedRows.length !== 1) {
					this.$warning({
						title: '请选择一条记录进行修改操作！',
					})
					return
				}
				this.addoplForm = this.selectedRows[0]
				// this.html = `<p>${this.selectedRows[0].content}</p>`
				this.html = `<p>${this.selectedRows[0].content}</p>`
			}
			if (name === 'editOplListType') {
				if (this.selectedRows.length === 0) {
					this.$warning({
						title: '请选择至少一条记录进行关联类型！',
					})
					return
				}
			}
			this.showModal(e)
		},
		// 查看课程详情弹窗
		openinfoOplList(scope) {
			this.modalTitle = '详 情'
			this.jywsDialogBottomName = '关闭'
			this.jywsDialogBottomRoger = ''
			this.typePrimary = ''
			this.visibleType = 'infoOplList'
			this.visibleWidth = '960px'
			this.visible = true
			this.infoOplList = scope
			console.log(scope)
		},
		// 新增类型弹窗 确认
		addOplTypeBtn() {
			if (this.oplTypeName === '') {
				this.$message.error('请输入类型名称！')
				return
			}
			let dataset = {
				name: this.oplTypeName,
				tenantId: this.tenantId,
			}
			if (this.oplId !== '') {
				dataset.pid = this.oplId
			}
			this.spinning = true
			util.postform('oplType/add', dataset).then(res => {
				console.log(res)
				if (res.code === 200) {
					this.$message.success('新增成功！')
					this.spinning = false
					this.closeModal()
					this.getOplTypeTree()
				}
			})
		},
		// 修改类型弹窗 确认
		editOplTypeBtn() {
			let dataset = {
				name: this.oplTypeName,
				id: this.selectTreeData.oplId,
				tenantId: this.tenantId,
			}
			this.spinning = true
			util.postform('oplType/update', dataset).then(res => {
				if (res.code === 200) {
					this.$message.success('修改成功！')
					this.spinning = false
					this.closeModal()
					this.getOplTypeTree()
				}
			})
		},
		// 删除opl类型接口
		delOplType() {
			let that = this
			if (!this.selectTreeData.oplId || this.selectTreeData.oplId === '') {
				this.$info({
					title: '提示',
					content: '请选择需要删除的类型！',
					onOk() {},
				})
				return
			}
			this.$confirm({
				title: '是否确认要删除此类型?',
				onOk() {
					util.postform('oplType/delete/' + that.selectTreeData.oplId).then(res => {
						if (res.code === 200) {
							that.$message.success('删除成功！')
							that.oplId = ''
							that.closeModal()
							that.getOplTypeTree()
							that.getOplList()
						}
					})
				},
				onCancel() {},
			})
		},
		// 新增opl课程接口
		addOplListBtn() {
			if (this.addoplForm.title === '') {
				this.$message.error('标题不能为空！')
				return
			}
			if (this.addoplForm.accessoryUrl === '') {
				this.$message.error('请选择附件上传！')
				return
			}
			this.addoplForm.createUserName = this.userName
			util.postform('oplCourse/add', this.addoplForm).then(res => {
				if (res.code === 200) {
					this.$message.success('新增成功！')
					this.closeModal()
					this.getOplList()
				}
			})
		},
		// 修改opl课程接口
		editOplListBtn() {
			if (this.addoplForm.title === '') {
				this.$message.error('标题不能为空！')
				return
			}
			if (this.addoplForm.accessoryUrl === '') {
				this.$message.error('请选择附件上传！')
				return
			}
			this.addoplForm.createUserName = this.userName
			util.postform('oplCourse/update', this.addoplForm).then(res => {
				if (res.code === 200) {
					this.$message.success('修改成功！')
					this.closeModal()
					this.getOplList()
				}
			})
		},
		// 删除opl课程接口
		delOplListBtn() {
			let that = this
			if (this.selectedRows.length === 0) {
				this.$info({
					title: '提示',
					content: '请选择需要删除的课程记录！',
					onOk() {},
				})
				return
			}
			this.$confirm({
				title: '是否确认要删除此课程?',
				onOk() {
					util.postform('oplCourse/removeBetch', that.selectedRowKeys).then(res => {
						if (res.code === 200) {
							that.$message.success('删除成功！')
							that.closeModal()
							that.getOplList()
						}
					})
				},
				onCancel() {},
			})
		},
		// 下载附件
		downFile() {
			if (this.selectedRows.length !== 1) {
				this.$warning({
					title: '请选择一条记录进行附件下载！',
				})
				return
			}
			if (!this.selectedRows[0].accessoryUrl) {
				this.$warning({
					title: '该记录暂无附件！',
				})
				return
			}
			window.open(this.selectedRows[0].accessoryUrl)
		},
		// 关联类型
		editOplListTypeBtn() {
			let ids = []
			this.selectedRows.forEach(item => {
				ids.push(item.id)
			})
			let dataset = {
				cids: ids,
				typeId: this.addoplForm.oplTypeId,
				typeName: this.addoplForm.oplTypeName,
			}
			util.postform('oplCourse/updateType', dataset).then(res => {
				if (res.code === 200) {
					this.$message.success('修改成功！')
					this.getOplList()
					this.closeModal()
				}
			})
		},
	},
}
</script>

<style scoped lang="less">
.oplList {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	// display: flex;
	// justify-content: space-between;
	padding: 20px;
	box-sizing: border-box;
	overflow: auto;
}
.oplList-title {
	width: 100%;
	height: 56px;
	background: #ffffff;
	border-radius: 4px 4px 0px 0px;
	color: #333333;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.des-must::before {
	content: '*';
	color: #ff4d4f;
}
.choosePlan {
	height: 96px;
	background-color: #e6f7ff;
	width: 100%;
}
.choosePlan-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 7px;
	box-sizing: border-box;
	border-bottom: 1px solid #ffffff;
}
.modal-app {
  width: 100%;
  display: flex;
  padding: 0px 40px 0 40px;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
  .modal-app-box {
    flex: 20%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px #E8E8E8 solid;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}
.choosePlan-bottom {
	display: flex;
	align-items: center;
	height: 50px;
	padding: 7px;
	box-sizing: border-box;
}
.modal-tree {
  display: flex;
  align-items: center;
  justify-content: center;
}
.choosePlan-top-left {
	font-size: 16px;
	color: #333333;
}
.infoOplList-title {
	font-size: 24px;
	color: #333333;
	text-align: left;
}
.modal-body {
	/deep/.ant-descriptions-item-label {
		width: 160px;
		text-align: right;
	}
	/deep/.ant-descriptions-item-content {
		width: 300px;
	}
}
.mar-t16 {
	margin-top: 16px;
}
.infoOplList-smtitle {
	display: flex;
	color: #999999;
	font-size: 12px;
	text-align: left;
	margin-top: 16px;
}
.infoOplList-content {
	margin-top: 24px;
}
.mg-l-5 {
	margin-left: 5px;
}
.mg-l-50 {
	margin-left: 50px;
}
.title-icon {
	width: 4px;
	height: 16px;
	background: #1890ff;
	display: inline-block;
	margin-right: 12px;
}
.col-value {
	width: 200px;
}
.oplList-content {
	background: #ffffff;
	width: 100%;
	height: calc(100% - 56px);
	display: flex;
	padding-top: 32px;
}
.flush {
	font-size: 14px;
	color: #1890ff;
}
.content-right-btnGroup {
	height: 56px;
	width: 100%;
	line-height: 56px;
	margin-top: 10px;
}
.content-right-form {
	// width: 100%;
	background: #ffffff;
	padding: 0px 21px 0px 17px;
	.form-search {
		display: flex;
		align-items: center;
		width: 420px;
	}
}
.modal-title-text {
	font-size: 14px;
	color: #b2b2b2;
	margin-top: 12px;
	margin-bottom: 12px;
}
.newcontent-right-form {
	background: #ffffff;
	padding: 0px 21px 0px 17px;
	.form-search {
		display: flex;
		align-items: center;
		width: 420px;
	}
	.conditionQuery {
		padding: 0 !important;
	}
}
.form-label {
	font-size: 14px;
	color: #333333;
	width: 70px;
	text-align: right;
	margin-right: 16px;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.content-left {
	width: 220px;
	height: calc(100% - 30px);
	background: #ffffff;
	border: 1px solid #e8e8e8;
	margin-left: 12px;
	.content-left-title {
		height: 48px;
		width: 100%;
		padding: 0 16px 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #333333;
		font-size: 16px;
		border-bottom: 1px solid #e8e8e8;
	}
	.content-left-btnGroup {
		height: 48px;
		width: 100%;
		border-bottom: 1px solid #e8e8e8;
	}
}
.content-right {
	height: calc(100% - 30px);
	width: 100%;
	margin-left: 27px;
}
</style>
