<template>
	<a-spin style="height: 100%" :spinning="spinning">
		<div class="specialEqus">
			<div class="specialEqus-title">
				<span class="title-icon"> </span>
				<span>{{ homeTitle }}</span>
			</div>
			<div class="specialEqus-content">
				<div class="content-left">
          <div :style="{ border: '1px solid #d9d9d9' }">
            <a-calendar :fullscreen="false" @select="selectChange" @panelChange="onPanelChange">
							<template slot="dateCellRender" slot-scope="value" class="events">
									<div style="width: 58%;border-bottom: 1px solid #F87500;position: relative;top: -14px;margin: auto;" v-for="(item, index) in getListData(value)" :key="index" />
							</template>
						</a-calendar>
          </div>
          <div class="content-left-turiel">
            <div class="marginb20">图例说明：</div>
            <div class="turiel-flex">
              <div class="turiel-flex-item marginb8">
                <div class="turiel-flex-item-left cambridgeBlue">12</div>
                <div>有计划</div>
              </div>
              <div class="turiel-flex-item marginb8">
                <div class="turiel-flex-item-left whiteBlue">12</div>
                <div>当前日期</div>
              </div>
              <div class="turiel-flex-item marginb8">
                <div class="turiel-flex-item-left whiteBlack">12</div>
                <div>无计划</div>
              </div>
              <div class="turiel-flex-item marginb8">
                <div class="turiel-flex-item-left blueWhite">12</div>
                <div>选中日期</div>
              </div>
              <div class="turiel-flex-item">
                <div class="turiel-flex-item-left cambridgeWhite">12</div>
                <div>非查询月份</div>
              </div>
            </div>
          </div>
				</div>
				<div class="content-right">
					<!-- 查询区域 -->
					<div class="content-right-form">
						<conditionQuery
							:headData="headData"
							:exportState="false"
              @onselectEqus="onselectEqus"
              @onSelectPeo="onSelectPeo"
							@onSelectClick="onSelectClick"
						/>
					</div>
					<!-- 功能按钮区域 -->
					<div class="content-right-btnGroup">
						<a-button-group>
							<a-button @click="onbtnGroup" name="add">新的计划</a-button>
							<a-button @click="onbtnGroup" name="amendmentExecutor">修改执行人</a-button>
							<a-button @click="onbtnGroup" name="repairText">修改检修内容</a-button>
							<a-button @click="onbtnGroup" name="updateDelivery">修改计划截止日期</a-button>
							<a-button name="deleteBtn" @click="delBtn">删除计划</a-button>
							<a-button name="exportExcel" @click="exportExcel">导出计划</a-button>
						</a-button-group>
					</div>
					<!-- 表格区域 -->
					<div class="content-right-table">
						<a-table
							:columns="columns"
							:loading="loading"
							:row-selection="rowSelection"
							:data-source="dataSource"
							:pagination="false"
							:scroll="{ y: 380 }"
						>
							<span slot="cycleDay2" slot-scope="text, record">
								{{ record | computingTime(record) }}
							</span>
							<span slot="cycleDay" slot-scope="text">
								{{ text | timeTransition(text) }}
							</span>
							<span slot="type" slot-scope="text">
								{{ text | type(text) }}
							</span>
							<span slot="cycle" slot-scope="text">
								{{ text || 0 }}天
							</span>
							<span slot="createTime" slot-scope="text">
								{{ text | timeTransition(text) }}
							</span>
						</a-table>
						<a-pagination
							@change="pageonChange"
							@showSizeChange="showSizeChange"
							size="small"
							:total="total"
							:defaultCurrent="searchForm.pageIndex"
							:defaultPageSize="searchForm.pageSize"
							show-size-changer
							show-quick-jumper
							:show-total="(total) => `总共 ${total} 条`"
						/>
					</div>
				</div>
			</div>
			<!-- 弹窗区域 -->
			<jywsDialog
				:visible="visible"
				:title="modalTitle"
				:width="visibleWidth"
				:roger="jywsDialogBottomRoger"
				:bottom-name="jywsDialogBottomName"
				@updataClose="closeModal"
				:typePrimary="typePrimary"
				@updataWin="updataWin"
			>
				<a-spin :spinning="spinning">
					<!-- 新的计划 -->
					<div v-if="visibleType === 'add'">
						<div class="mission-content">
              <a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label must">选择设备</div>
									<div class="col-value">
										<selectEqus :inputName="addForm.devicename" :radioFlag="false" @onSelect="addSelectEqus" ></selectEqus>
									</div>
								</a-col>
							</a-row>
							<a-row class="margint12">
								<a-col :span="12" class="col-flex">
									<div class="col-label must">计划类型</div>
									<div class="col-value">
										<a-radio-group :value="addForm.taskState" @change="taskStateChange" >
                       <a-radio-button value="1">一次性计划</a-radio-button>
                      <a-radio-button value="2">周期性计划</a-radio-button>
                    </a-radio-group>
									</div>
								</a-col>
                <a-col v-if="addForm.taskState == '2'" :span="12" class="col-flex">
									<div class="col-label must">任务周期</div>
									<div class="col-value">
										<a-input-number style="width: 100%" placeholder="请输入任务周期天数" v-model="addForm.cycle" :min="1" @change="inputChange($event, 'cycle')" />
									</div>
								</a-col>
							</a-row>
							<a-row class="margint12">
                <a-col :span="12" class="col-flex">
									<div class="col-label must">任务截止日期</div>
									<div class="col-value">
										<a-date-picker style="width: 100%" placeholder="请填写任务截止日期" :value="addForm.endDate" :disabled-date="disabledDate" @change="endDateTimeChange" />
									</div>
								</a-col>
								<a-col :span="12" class="col-flex">
									<div class="col-label must">任务提前提醒天数</div>
									<div class="col-value">
										<a-input-number style="width: 100%" placeholder="请输入任务周期天数" v-model="addForm.remindDay" :min="0" @change="inputChange($event, 'remindDay')" />
									</div>
								</a-col>
							</a-row>
							<a-row class="margint12">
                <a-col :span="12" class="col-flex">
									<div class="col-label must">执行人/工作组</div>
									<div class="col-value">
										<selectPeo :inputName="addForm.recipientName" :radioFlag="true" :showGroup="true" @onSelect="addSelectPeo($event, '1')" ></selectPeo>
									</div>
								</a-col>
								<a-col :span="12" class="col-flex">
									<div class="col-label">评价人</div>
									<div class="col-value">
										<selectPeo :inputName="addForm.appraiserName" :radioFlag="true" :showGroup="false" @onSelect="addSelectPeo($event, '2')" ></selectPeo>
									</div>
								</a-col>
							</a-row>
							<a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label must">检修内容</div>
									<div style="width: 650px;" class="col-value">
										<a-textarea v-model="addForm.repairText" placeholder="请输入检修内容" :rows="4" />
									</div>
								</a-col>
							</a-row>
              <a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label">添加图片</div>
									<div style="width: 650px;display: flex;" class="col-value">
                    <div class="picture-img" v-for="(item, index) in addForm.fileList" :key="index" >
											<img class="delete-img" src="@/assets/icon_close_yuan.png" @click="deleteImgClick(index)" />
                      <img style="width: 100%;height: 100%;" :src="item" />
                    </div>
										<uploadOss
                      @setFileList="setFileList"
                      accept='.png,.jpg,.jpeg,.ico'
                      :showList="true"
                    >
                      <div class="picture" v-if="addForm.fileList.length < 4">
                        <a-icon class="picture-icon" type="plus" />
                      </div>
                    </uploadOss>
									</div>
								</a-col>
							</a-row>
						</div>
					</div>
					<!-- 变更执行人 -->
					<div v-if="visibleType === 'amendmentExecutor'">
					<div style="margin-top: 0px;" class="mission-content">
							<a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label must">执行人/工作组</div>
									<div class="col-value">
										<selectPeo :inputName="addForm.recipientName" :radioFlag="true" :showGroup="true" @onSelect="addSelectPeo($event, '1')" ></selectPeo>
									</div>
								</a-col>
							</a-row>
							<a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label">评价人</div>
									<div class="col-value">
										<selectPeo :inputName="addForm.appraiserName" :radioFlag="true" :showGroup="false" @onSelect="addSelectPeo($event, '2')" ></selectPeo>
									</div>
								</a-col>
							</a-row>
					</div>
					</div>
					<!-- 修改执行项 -->
					<div v-if="visibleType === 'repairText'">
						<div class="mission-content">
              <a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label must">检修内容</div>
										<div style="width: 650px;" class="col-value">
											<a-textarea v-model="addForm.repairText"  placeholder="请输入检修内容" :rows="4" />
										</div>
								</a-col>
							</a-row>
						</div>
					</div>
					<!-- 修改下发日期 -->
					<div v-if="visibleType === 'updateDelivery'">
						<a-row class="margint12">
							<a-col class="col-flex">
								<div class="col-label must">计划截止日期</div>
								<div class="col-value">
									<a-date-picker style="width: 100%" :value="delayTime" :disabled-date="disabledDate" :allowClear="false" placeholder='请选择' @change="produceTimeChange2" />
								</div>
							</a-col>
						</a-row>
						<a-row class="margint12">
							<a-col class="col-flex">
								<div class="col-label must">提前下发天数</div>
								<div class="col-value">
									<a-input-number style="width: 100%" placeholder="请输入任务周期天数" v-model="addForm.remindDay" :min="0" @change="inputChange($event, 'remindDay')" />
								</div>
							</a-col>
						</a-row>
						<a-row class="margint12">
							<a-col class="col-flex">
								<div class="col-label must">计划下发日期</div>
								<div class="col-value">
									<a-input style="width: 100%" :value="calculationTime" disabled />
								</div>
							</a-col>
						</a-row>
					</div>
				</a-spin>
			</jywsDialog>
		</div>
	</a-spin>
</template>

<script>
import uploadOss from '@/components/uploadOss'
import conditionQuery from '@/components/conditionQuery.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import selectPeo from '@/components/selectPeo.vue'
import selectEqus from '@/components/selectEqus.vue'
import moment from 'moment'
import * as util from '@/core/util'
import { dateFormat7, exportFile } from '@/core/date'

export default {
  name: 'OverhaulPlan',
	components: {
    uploadOss,
		conditionQuery,
		jywsDialog,
		selectPeo,
		selectEqus,
	},
	filters: {
		type(text) {
			if (text == 'day') {
        return '日点检'
      } else if (text == 'specialty') {
        return '周点检'
      } else if (text == 'precision') {
        return '安全点检'
      } else if (text == 'planned') {
        return '计划点检'
      } else if (text == 'B1') {
        return '一级保养'
      } else if (text == 'B2') {
        return '二级保养'
      } else if (text == 'B3') {
        return '日常保养'
      } else if (text == 'project') {
        return '安全巡检'
      } else {
        return '-'
      }
		},
		unit(text) {
			if (text == 'day') {
        return '天'
      } else if (text == 'week') {
        return '周'
      } else if (text == 'month') {
        return '月'
      } else {
        return '-'
      }
		},
    timeTransition(text) {
      if (text) {
        return dateFormat7(text)
      } else {
        return '-'
      }
    },
		computingTime(text) {
      if (text.cycleDay) {
        // return dateFormat7(text)
				return moment(text.cycleDay).subtract (text.remindDay,"days").format('YYYY-MM-DD')
      } else {
        return '-'
      }
    },
		getRowString(data) {
			if (data) {//日点检:day,周点检:specialty,专项巡检:project,安全点检:precision
				if (data == "day") {
					return '天'
				} if (data == "specialty") {
					return '周'
				} if (data == "week") {
					return '周'
				} if (data == "month") {
					return '月'
				} if (data == "precision") {
					return '安全点检'
				} if (data == 0) {
					return '否'
				} if (data == 1) {
					return '是'
				} else {
					return '-'
				}
			} else {
				return '-'
			}
		}
  },
	data() {
		return {
			homeTitle: '',
			tenantId: '',
      userId: '',
			loading:false,
			// 表格表头
			columns: [
				{
					title: '设备简称',
					dataIndex: 'objectName',
					key: 'objectName',
				},
				{
					title: '所属组织',
					dataIndex: 'organizationName',
					key: 'organizationName',
				},
				{
					title: '检修内容',
					dataIndex: 'repairText',
					key: 'repairText',
				},
				{
					title: '计划下发日期',
					dataIndex: 'cycleDay2',
					key: 'cycleDay2',
					scopedSlots: { customRender: 'cycleDay2' },
				},
        {
					title: '计划截止日期',
					dataIndex: 'cycleDay',
					key: 'cycleDay',
					scopedSlots: { customRender: 'cycleDay' },
				},
				{
					title: '提前下发天数',
					dataIndex: 'remindDay',
					key: 'remindDay'
				},
				{
					title: '执行周期',
					dataIndex: 'cycle',
					key: 'cycle',
					scopedSlots: { customRender: 'cycle' },
				},
				{
					title: '执行人/组',
					dataIndex: 'recipientName',
					key: 'recipientName'
				},
        {
					title: '评价人',
					dataIndex: 'assessUserName',
					key: 'assessUserName'
				},
				{
					title: '创建日期',
					dataIndex: 'createTime',
					key: 'createTime',
					scopedSlots: { customRender: 'createTime' },
				}
			],
			dataSource: [],
			selectedRowKeys: [],
			selectedRows: [],
			headData: [
        {
					key: 2,
					name: '组织/设备',
					component: 'a-selectEqus',
					value: '',
          radioFlag: false,
					duplicate: 'objects',
					placeholder: '请选择',
				},
        {
					key: 3,
					name: '执行人/组',
					component: 'a-selectPeo',
					value: '',
          radioFlag: false,
          showGroup: true,
					duplicate: 'users',
					placeholder: '请选择',
				}
			],
			// 查询特种设备列表参数
			searchForm: {
        pageIndex: 1,
				pageSize: 10,
        mode: 1, // 1 月份 2 年份
        beginTime: new Date(),
        endTime: new Date(),
        // types: ['day', 'specialty', 'precision', 'planned'], // 点检类型
        orgs: [], // 组织
        objects: [], // 设备
        users: [] // 执行人
			},
			//判断是月份日历还是年份日历
			mode: 'month',
			planDate: [],
			total: 0,
			// 派任务接口传参
			addForm: {
        // 计划类型
        taskState: '2',
        // 任务周期
        cycle: '',
        // 任务截止日期
        endDate: '',
        // 提前下发天数
				remindDay: '',
				// 执行人/工作组
				recipientId: '',
				recipientName: '',
				recipientObj: {},
				// 评价人
				appraiserId: '',
				appraiserName: '',
				appraiserObj: {},
        // 检修内容
        repairText: '',
        // 上传图片
        fileList: [],
				// 选择设备
				equ: [],
				devicename: '',
				arr: '',
				newdataEqus: []
			},
			// 弹窗参数
			visible: false,
			visibleType: '',
			modalTitle: '',
			visibleWidth: '960px',
			jywsDialogBottomRoger: '取消',
			jywsDialogBottomName: '确认',
			typePrimary: 'primary',
			steps: 0,
			spinning: false,
			// 表格表头
			sonColumns: [
				{
					dataIndex: 'pitch', 
					key: 'pitch',
					width: '50px',
					slots: { title: 'pitchName' },
					scopedSlots: { customRender: 'pitch' }
        },
				{
					title: '资产编码',
					dataIndex: 'assetsCode',
					key: 'assetsCode'
				},
				{
					title: '设备简称',
					dataIndex: 'simpleName',
					key: 'simpleName',
				},
				{
					title: '设备型号',
					dataIndex: 'standard',
					key: 'standard',
				},
				{
					title: '所属组织',
					dataIndex: 'organizationName',
					key: 'organizationName',
				},
				{
					title: '作业标准数',
					dataIndex: 'cot',
					key: 'cot'
				}
			],
			sonLoading:false,
			selectPlanningDate: [],
			pageIndex: 1,
			pageSize: 10,
			sonTotal: 5,
			infoList: {},
			// 表格表头
			detailColumns: [
				{
					title: '点检单元',
					dataIndex: 'c_element',
					key: 'c_element'
				},
				{
					title: '点检项目',
					dataIndex: 'c_project',
					key: 'c_project',
				},
				{
					title: '点检内容',
					dataIndex: 'c_content',
					key: 'c_content',
				},
				{
					title: '点检方法',
					dataIndex: 'c_method',
					key: 'c_method',
				},
				{
					title: '点检标准',
					dataIndex: 'c_standard',
					key: 'c_standard'
				},
				{
					title: '数值记录',
					dataIndex: 'c_way',
					key: 'c_way'
				},
				{
					title: '最大值',
					dataIndex: 'c_max_num',
					key: 'c_max_num'
				},
				{
					title: '最小值',
					dataIndex: 'c_min_num',
					key: 'c_min_num'
				},
				{
					title: '必须拍照',
					dataIndex: 'c_photo',
					key: 'c_photo',
					scopedSlots: { customRender: 'c_photo' }
				}
			],
			detailLoading: false,
			innerColumns: [
				{
          title: '', 
					dataIndex: 'sonPitch', 
					key: 'sonPitch',
					width: '50px',
					scopedSlots: { customRender: 'sonPitch' }
        },
				{
					title: '点检单元',
					dataIndex: 'c_element',
					key: 'c_element'
				},
				{
					title: '点检项目',
					dataIndex: 'c_project',
					key: 'c_project',
				},
				{
					title: '点检内容',
					dataIndex: 'c_content',
					key: 'c_content',
				},
				{
					title: '数值记录',
					dataIndex: 'c_way',
					key: 'c_way',
					scopedSlots: { customRender: 'c_way' }
				},
				{
					title: '必须拍照',
					dataIndex: 'c_photo',
					key: 'c_photo',
					scopedSlots: { customRender: 'c_photo' }
				}
			],
			selectedRows3: [],
			selectedRowKeys3: [],
			strandardDataSource: [],
			// 弹窗参数
			visible2: false,
			visibleType2: '',
			modalTitle2: '',
			visibleWidth2: '960px',
			jywsDialogBottomRoger2: '取消',
			jywsDialogBottomName2: '确认',
			typePrimary2: 'primary',

			detailDataSource: [],
			selectedRows4: [],
			selectedRowKeys4: [],

			standard: {
				m_element: '',
				m_project: '',
				m_content: '',
				m_method: '',
				m_standard: '',
				m_way: false,
				m_photo: false,
				m_minNum: 0,
				m_maxNum: 0,
			},
			// 计划延期时间
			delayTime: '',
			// 提前下发天数
			advanceDays: ''
		}
	},
	created() {
		var str = decodeURIComponent(this.$route.fullPath).split('=')
    this.tenantId = str[1].split('&')[0]
		this.userId = str[2].split('&')[0]
		console.log(str[2].split('&')[0])
		this.homeTitle = '检修计划'
		this.initWorkCalendar()
		this.queryList()
	},
	computed: {
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys = selectedRowKeys
					this.selectedRows = selectedRows
				},
			}
		},
		rowSelection3() {
			return {
				selectedRowKeys: this.selectedRowKeys3,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys3 = selectedRowKeys
					this.selectedRows3 = selectedRows
				},
			}
		},
		rowSelection4() {
			return {
				selectedRowKeys: this.selectedRowKeys4,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys4 = selectedRowKeys
					this.selectedRows4 = selectedRows
				},
			}
		},
		nextReleaseTime() {
			let timeText = ''
			if (this.addForm.periodTime && this.addForm.executiveDays) {
				timeText = moment(this.addForm.periodTime)
					.subtract(this.addForm.executiveDays, 'days')
					.format('YYYY-MM-DD')
			}
			return timeText
		},
		calculationTime() {
			if (this.delayTime) {
				return moment(this.delayTime).subtract (this.addForm.remindDay || 0,"days").format('YYYY-MM-DD') || ''
			} else {
				return ''
			}
		}
	},
	mounted() {
	},
	methods: {
		// 日历点击事件
		selectChange(value) {
			if (this.mode === 'month') {
				this.searchForm.beginTime = value
				this.searchForm.endTime = value
				this.initWorkCalendar()
				this.queryList()
			}
		},
    onPanelChange(value, mode) {
      console.log(value, mode);
			this.searchForm.beginTime = value
			this.searchForm.endTime = value
			this.mode = mode
			this.initWorkCalendar()
			this.queryList()
    },
		// 显示日历计划数据
		getListData(value) {
      let listData;
			for (let i = 0; i < this.planDate.length; i++) {
				const time = this.planDate[i];
				if (time == moment(value).format('YYYYMMDD')) {
					return listData = [{ type: '有计划', time: time }]
				}
			}
      return listData || [];
    },
    // 组织/设备
    onselectEqus(item) {
      let valueNames = []
      item.checkData.forEach(ele => {
        valueNames.push(ele.c_simple_name)
      })
      if (item.duplicate == 'objects') {
				this.searchForm.objects = item.value
        this.headData.forEach(ele2 => {
          if (ele2.duplicate == 'objects') {
            ele2.value = valueNames && valueNames.toString()
          }
        })
			}
    },
    // 执行人/组
    onSelectPeo(item) {
      let valueNames = []
      item.checkData.forEach(ele => {
        valueNames.push(ele.c_name)
      })
      if (item.duplicate == 'users') {
				this.searchForm.users = item.value
        this.headData.forEach(ele2 => {
          if (ele2.duplicate == 'users') {
            ele2.value = valueNames && valueNames.toString()
          }
        })
			}
    },
		// 查询
		onSelectClick() {
			this.initWorkCalendar()
			this.queryList()
		},
		// 获取有计划日期
		initWorkCalendar() {
			let dataset = { ...this.searchForm }
			dataset.tenantId = this.tenantId
			dataset.beginTime = moment(this.searchForm.beginTime).startOf("month").format("YYYYMMDD")
			dataset.endTime = moment(this.searchForm.endTime).endOf("month").format("YYYYMMDD")
			util.postform('ZNRepair/initWorkCalendar', dataset).then((res) => {
					this.planDate = res.data || []
				}
			)
		},
		// 查询计划
		queryList() {
			this.loading = true
			let dataset = { ...this.searchForm }
			dataset.tenantId = this.tenantId
			if (this.mode == 'month') {
				dataset.beginTime = moment(this.searchForm.beginTime).format('YYYYMMDD')
				dataset.endTime = moment(this.searchForm.endTime).format('YYYYMMDD')
			} else {
				dataset.beginTime = moment(this.searchForm.beginTime).startOf("month").format("YYYYMMDD")
				dataset.endTime = moment(this.searchForm.endTime).endOf("month").format("YYYYMMDD")
			}
			util.postform('ZNRepair/queryRepairPlan', dataset).then((res) => {
					this.loading = false
					this.dataSource = res.data.repairPlans || []
					this.total = res.data.total
				}
			)
		},
		// 翻页
		pageonChange(page) {
			this.searchForm.pageIndex = page
			this.queryList()
		},
		// 页数size改变
		showSizeChange(key, size) {
			this.searchForm.pageSize = size
			this.queryList()
		},
		// 功能按钮操作
		onbtnGroup(e) {
			let name = e.target.name
			if (name === 'add') {
				this.steps = 0
			} else if (name === 'amendmentExecutor') {
				if (this.selectedRows.length === 0) {
					this.$info({
						title: '提示',
						content: '请选择需要修改的项！',
						onOk() {},
					});
					return
				}
			} else if (name === 'repairText') {
				if (this.selectedRows.length != 1) {
					this.$info({
						title: '提示',
						content: '请选择需要修改的一项！',
						onOk() {},
					});
					return
				}
			} else if (name == 'updateDelivery') {
				if (this.selectedRows.length == 0) {
					this.$info({
						title: '提示',
						content: '请选择一条计划进行操作！',
						onOk() {},
					});
					return
				}
				// if (this.selectedRows.length > 1) {
				// 	this.$info({
				// 		title: '提示',
				// 		content: '请选择单条计划进行延期！',
				// 		onOk() {},
				// 	});
				// 	return;
				// }
			}
			this.showModal(e)
		},
		showModal(e) {
			let name = e.target.name
			switch (name) {
				case 'add':
					this.modalTitle = '派任务'
					this.visible = true
					this.visibleWidth = '960px'
					this.visibleType = name
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确定'
					break
				case 'amendmentExecutor':
					this.modalTitle = '变更执行人'
					this.visible = true
					this.visibleWidth = '560px'
					this.visibleType = name
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确定'
					break
				case 'repairText':
					this.infoList = this.selectedRows[0]
					this.modalTitle = '修改检修内容'
					this.visible = true
					this.visibleType = name
					this.jywsDialogBottomRoger = '取消'
					this.jywsDialogBottomName = '确定'
					this.typePrimary = 'primary'
					this.visibleWidth = '960px'
					break
				case 'updateDelivery':
					this.delayTime = dateFormat7(this.selectedRows[0].cycleDay)
					this.addForm.remindDay = this.selectedRows[0].remindDay
					this.modalTitle = '修改计划截止日期'
					this.visible = true
					this.visibleType = name
					this.jywsDialogBottomRoger = '取消'
					this.jywsDialogBottomName = '确定'
					this.typePrimary = 'primary'
					this.visibleWidth = '560px'
					break
				default:
					break
			}
		},
    taskStateChange(e) {
      this.addForm.taskState = e.target.value
    },
    // 任务截止日期
		endDateTimeChange(date, dateString) {
      console.log(date, dateString);
			this.addForm.endDate = dateString;
    },
    // 上传图片
    setFileList(val) {
      this.addForm.fileList.push(val)
    },
		deleteImgClick(index) {
			this.addForm.fileList.splice(index, 1)
		},
		// 新增执行人/组
    addSelectPeo(item, type) {
			if (type === '1') {
				this.addForm.recipientId = item.key.toString()
				this.addForm.recipientObj = item.obj
				this.addForm.recipientName = item.obj[0].c_name
			} else if (type === '2') {
				this.addForm.appraiserId = item.key.toString()
				this.addForm.appraiserObj = item.obj
				this.addForm.appraiserName = item.obj[0].c_name
			}
    },
		// 不能选中今天之前的日期
		disabledDate(current) {
			// Can not select days before today and today
			return current < moment().subtract(1, 'day');
		},
		// 新增提前下发天数、周期
		inputChange(value, type) {
			if (type === 'remindDay') {
				this.addForm.remindDay = value
			} else if (type === 'cycle') {
        this.addForm.cycle = value
      }
		},
		// 新增选中设备
    addSelectEqus(item) {
      let valueNames = []
			let arr = []
      item.obj.forEach(ele => {
        valueNames.push(ele.c_simple_name)
				arr.push({ id: ele.c_id, assetsCode: ele.c_assets_code, simpleName: ele.c_simple_name, standard: ele.c_standard, organizationId: ele.c_organization_id, organizationName: ele.depName })
      })
			this.addForm.equ = item.key
			this.addForm.devicename = valueNames.toString()
			this.addForm.arr = arr
			this.addForm.newdataEqus = item.obj
    },
		// 弹窗确认按钮
		updataWin() {
			if (this.visibleType === 'add') {
				this.addSave()
			} else if (this.visibleType === 'amendmentExecutor') {
				this.updatePlanInfo('amendmentExecutor')
			} else if (this.visibleType === 'repairText') {
				this.updatePlanInfo('repairText')
			} else if (this.visibleType === 'updateDelivery') {
				this.updatePlanInfo('updateDelivery')
			}
		},
		// 新增派任务
		addSave() {
      if (this.addForm.equ.length == 0) { this.$message.error("请选择设备"); return; }
      if (this.addForm.taskState == '2') {
          if (this.addForm.cycle == "") { this.$message.error("请输入任务周期天数"); return; }
          if (this.addForm.cycle == "0") { this.$message.error("任务周期天数不能为0"); return; }
      }
      if (this.addForm.endDate == "") { this.$message.error("请填选择任务截止日期"); return; }
      if (this.addForm.remindDay === "") { this.$message.error("请填选择任务提前提醒天数"); return; }
      if (this.addForm.recipientId == "") { this.$message.error("请选择执行人/组"); return; }
      if (this.addForm.repairText == "") { this.$message.error("请输入维修内容"); return; }
      if (parseInt(this.addForm.cycle) != 0) {
          if (parseInt(this.addForm.remindDay) >= parseInt(this.addForm.cycle)) { this.$message.error("‘任务提前提醒天数’不能大于或等于‘循环周期’"); return; }
      }
      //判断任务提醒的日期不能在今天之前
      let aa = moment(this.addForm.endDate).subtract(this.addForm.remindDay, 'days');//下发任务的日期
      if (aa.isBefore(moment(moment().format("YYYYMMDD")))) { this.$message.error("任务提醒的日期不能在今天之前"); return; }
      let equIdArray = []
      this.addForm.newdataEqus.forEach(ele => {
        equIdArray.push({ id: ele.c_id, simpleName: ele.c_simple_name, organizationId: ele.c_organization_id, organizationName: ele.depName })

      })
			this.spinning = true
      var dataset = {
          plan: {
            tenantId: this.tenantId,
            sender: this.userId,
            recipients: this.addForm.recipientId,
            recipientName: this.addForm.recipientName,
            assessUserId: this.addForm.appraiserId,
            assessUserName: this.addForm.appraiserName,
            repairDeadline: moment(this.addForm.endDate).format("YYYYMMDD"),
            repairText: this.addForm.repairText,
            repairImg: this.addForm.fileList.toString(),
            remindDay: this.addForm.remindDay,
            cycle: this.addForm.taskState == '2' ? this.addForm.cycle : '0',
            cycleDay: moment(this.addForm.endDate).format("YYYYMMDD")
          },
          equipments: equIdArray
      }
			util.postform('ZNRepair/sendTask', dataset).then((res) => {
					this.spinning = false
          if (res.code == 200) {
              this.closeModal();
              this.initWorkCalendar()
              this.queryList()
              this.$message.success("添加成功");
          }
      })
    },
		sonCheckAllChange(e) {
			let date = this.selectPlanningDate;
			if (e.target.checked) {
				date.forEach(ele => {
					ele.pitch = true;
					ele.standardList.forEach(ele2 => {
						ele2.sonPitch = true;
					})
				})
			} else {
				date.forEach(ele => {
					ele.pitch = false;
					ele.standardList.forEach(ele2 => {
						ele2.sonPitch = false;
					})
				})
			}
			this.selectPlanningDate = date
			this.$forceUpdate()
		},
		sonOnCheckAllChange(e) {
			let date = this.selectPlanningDate
			if (e.target.checked == true) {
				date.forEach(ele => {
					if (ele.id == e.target.value) {
						ele.pitch = true;
						ele.indeterminate = false;
						ele.standardList.forEach(ele2 => {
							ele2.sonPitch = true;
						})
					}
				})
			} else {
				date.forEach(ele => {
					if (ele.id == e.target.value) {
						ele.pitch = false;
						ele.indeterminate = false
						ele.standardList.forEach(ele2 => {
							ele2.sonPitch = false;
						})
					}
				})
			}
			this.selectPlanningDate = date
			this.$forceUpdate()
		},
		sonOnAloneChange(e) {
			let date = this.selectPlanningDate;
			if (e.target.checked == true) {
				date.forEach(ele => {			
					ele.standardList.forEach(ele2 => {
						if (ele2.c_id == e.target.value) {
							ele2.sonPitch = true;
						}
					})
				})
			} else {
				date.forEach(ele => {
					ele.standardList.forEach(ele2 => {
						if (ele2.c_id == e.target.value) {
							ele2.sonPitch = false;
						}
					})
				})
			}
			this.sonIndeterminateCss(date);
		},
		sonIndeterminateCss(item) {
			let date = item
			for (let i = 0; i < date.length; i++) {
				const element = date[i];
				let count = 0
				for (let j = 0; j < element.standardList.length; j++) {
					const element2 = element.standardList[j];
					if (element2.sonPitch == true) {
						count += 1
					}
				}
				if (element.standardList.length == count) {
					element.indeterminate = false
					element.pitch = true
				} else if (count == 0) {
					element.indeterminate = false
					element.pitch = false
				} else {
					element.indeterminate = true
				}
			}
			this.selectPlanningDate = date
			this.$forceUpdate()
		},
		// 修改执行人、检修内容、下发日期
		updatePlanInfo(type) {
			let rowsId = []
			let OUTselectedRows = this.selectedRows
			OUTselectedRows.forEach(ele => {
				rowsId.push(ele.id)
			});
			let dataset = {  
				ids: rowsId,         //多个项id
				tenantId: this.tenantId
			};
			if (type == 'amendmentExecutor') {
				//必填字段是否为空
				if (this.addForm.recipientId == "") {
					this.$message.error('接收人不能为空')
					return;
				}
				dataset.recipients = this.addForm.recipientId;  //接收人id  
				dataset.recipientName = this.addForm.recipientName; 
				if (this.addForm.appraiserId != "") {
					dataset.assessUserId = this.addForm.appraiserId;  //评价人id
					dataset.assessUserName = this.addForm.assessUserName; 
				}
			} else if (type == 'repairText') {
				//必填字段是否为空
				if (this.addForm.repairText == "") {
					this.$message.error('检修内容不能为空')
					return;
				}
				dataset.repairText = this.addForm.repairText;  //检修内容 
			}  else if (type == 'updateDelivery') {
				//必填字段是否为空
				if (this.delayTime == '') {
					this.$message.error('请选择计划截止日期！')
					return;
				}
				if (this.addForm.remindDay == '') {
					this.$message.error('请输入提前下发天数！')
					return;
				}
				dataset.cycleDay = moment(this.delayTime).format('YYYYMMDD');  //下次计划日期
				dataset.remindDay = this.addForm.remindDay
			}
			this.spinning = true
			util.postform('ZNRepair/updatePlanInfo', dataset).then((reg) => {
				this.spinning = false
				if (reg.code === 200) {
					this.$message.success('修改成功')
					this.closeModal();
				} else {
					this.$message.error('网络链接错误')
				}
			})
		},
		// 派任务清空数据
		sendMissionClose() {
			this.visible = false
			this.addForm = {
        // 计划类型
        taskState: '2',
        // 任务周期
        cycle: '',
        // 任务截止日期
        endDate: '',
        // 提前下发天数
				remindDay: '',
				// 执行人/工作组
				recipientId: '',
				recipientName: '',
				recipientObj: {},
				// 评价人
				appraiserId: '',
				appraiserName: '',
				appraiserObj: {},
        // 检修内容
        repairText: '',
        // 上传图片
        fileList: [],
				// 选择设备
				equ: [],
				devicename: '',
				arr: '',
				newdataEqus: []
			},
			this.selectPlanningDate = []
			this.selectedRowKeys = []
			this.selectedRows = []
		},
		// 关闭弹窗
		closeModal() {
			if (this.visibleType === 'updateDelivery') {
				this.delayTime = ''
			}
			this.sendMissionClose()
			this.initWorkCalendar()
			this.queryList()
		},
		// 新增首次任务截止日期
		produceTimeChange2(date, dateString) {
      console.log(date, dateString);
			this.delayTime = dateString;
    },
		// 删除计划
		delBtn() {
			let that = this
			if (this.selectedRows.length === 0) {
				this.$info({
					title: '提示',
					content: '请选择需要删除的记录！',
					onOk() {},
				})
				return
			}
			let deleteArry = [];
      this.selectedRows.forEach((ele) => {
        deleteArry.push(ele && ele.id)
      })
			this.$confirm({
				title: '删除提示！',
        content:'是否删除选中计划，如果删除，执行人后续将不再收到这些计划的任务推送，请谨慎处理！',
				onOk() {
					let dataset = {
            ids: deleteArry
          };
					util.postform('ZNRepair/deletePlanInfo', dataset).then((res) => {
						if (res.code === 200) {
							that.$message.success('删除成功！')
							that.selectedRows = []
							that.selectedRowKeys = []
							that.initWorkCalendar()
							that.queryList()
						}
					})
				},
				onCancel() {},
			})
		},
		// 导出计划
    exportExcel() {
				let dataset = { ...this.searchForm }
				dataset.tenantId = this.tenantId
				dataset.pageIndex = 1
				dataset.pageSize = 999999999
				if (this.mode == 'month') {
					dataset.beginTime = moment(this.searchForm.beginTime).format('YYYYMMDD')
					dataset.endTime = moment(this.searchForm.endTime).format('YYYYMMDD')
				} else {
					dataset.beginTime = moment(this.searchForm.beginTime).startOf("month").format("YYYYMMDD")
					dataset.endTime = moment(this.searchForm.endTime).endOf("month").format("YYYYMMDD")
				}
        let downUrl = util.getBaseURL() + 'ZNEasy/excel/download/repairPlan'
				this.spinning = true
        exportFile(downUrl, dataset).then(res => {
					console.log(res)
					this.spinning = false
        })
    },
	},
}
</script>

<style scoped lang="less">
/deep/ .ant-spin-container {
	height: 100%;
}
.specialEqus {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	// display: flex;
	// justify-content: space-between;
	padding: 20px;
	box-sizing: border-box;
	overflow: auto;
}
.info-title {
	display: flex;
	height: 40px;
	align-items: center;
	.info-title-icon {
		width: 4px;
		height: 14px;
		background: #1890ff;
	}
	.info-title-text {
		margin-left: 8px;
		font-size: 16px;
		color: #333333;
	}
}
.specialEqus-title {
	width: 100%;
	height: 56px;
	background: #ffffff;
	border-radius: 4px 4px 0px 0px;
	color: #333333;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.infospecialEqus-title {
	font-size: 24px;
	color: #333333;
	text-align: left;
}
.infospecialEqus-smtitle {
	display: flex;
	color: #999999;
	font-size: 12px;
	text-align: left;
	margin-top: 16px;
}
.infospecialEqus-content {
	margin-top: 24px;
}
.mg-l-5 {
	margin-left: 5px;
}
.mg-l-50 {
	margin-left: 50px;
}
.title-icon {
	width: 4px;
	height: 16px;
	background: #1890ff;
	display: inline-block;
	margin-right: 12px;
}
.col-label {
	width: 130px;
	text-align: left;
}
.col-value {
	width: 240px;
}

.specialEqus-content {
	background: #ffffff;
	width: 100%;
	height: calc(100% - 56px);
	display: flex;
	padding-top: 32px;
}
.flush {
	font-size: 14px;
	color: #1890ff;
}
.content-right-btnGroup {
	height: 56px;
	width: 100%;
	line-height: 56px;
	margin-top: 10px;
}
.content-right-form {
	// width: 100%;
	background: #ffffff;
	padding: 0px 21px 0px 17px;
	.form-search {
		display: flex;
		align-items: center;
		width: 420px;
	}
}
.form-label {
	font-size: 14px;
	color: #333333;
	width: 70px;
	text-align: right;
	margin-right: 16px;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.content-left {
	width: 312px;
	height: calc(100% - 30px);
	background: #ffffff;
	margin-left: 12px;
	overflow: auto;
	.content-left-turiel {
    margin-top: 16px;
    padding: 14px 13px;
    height: 156px;
    background: #FAFAFA;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    .turiel-flex {
      display: flex;
      flex-wrap: wrap;
      .turiel-flex-item {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        width: 50%;
        .turiel-flex-item-left {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 39px;
          height: 24px;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 400;
					margin-right: 14px;
        }
      }
    }
  }
}
.content-right {
	height: calc(100% - 30px);
	width: calc(100% - 375px);
	margin-left: 27px;
}
.content-right-message {
	margin-top: 20px;
}
.marginb20 {
  margin-bottom: 20px;
}

.marginb8 {
  margin-bottom: 8px;
}

.cambridgeBlue {
	border-radius: 0px !important;
  background: #FFFFFF;
  color: #333333;
  border-bottom: 1px solid #F87500;
}
.whiteBlue {
  background: #FFFFFF;
  color: #333333;
  border: 1px solid #1890FF;
}
.blueWhite {
  background: #1890FF;
  color: #FFFFFF;
  border: 1px solid #1890FF;
}
.cambridgeWhite {
  background: #FFFFFF;
  color: #999999;
  border: 1px solid #FFFFFF;
}
.whiteBlack {
  background: #FFFFFF;
  color: #333333;
  border: 1px solid #FFFFFF;
}
.mission-content {
	width: 90%;
  margin: auto;
}
.margint12 {
	margin-top: 12px;
}
.successMessage {
	width: 60%;
	margin: 80px auto;
	text-align: center;
}
.successMessage-title {
	text-align: center;
	color: #272727;
	font-size: 20px;
	margin: 24px 0px 12px;
}
.successMessage-subTitle {
	text-align: center;
	color: #B2B2B2;
	font-size: 14px;
}
.info-table {
  border: 1px solid #f0f0f0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.info-table-flex {
	display: flex;
}
.info-table-flex-item {
	display: flex;
}
.info-table-flex-item-l {
	padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
  width: 220px;
  background-color: #fafafa;
}
.info-table-flex-item-r {
	padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
	color: rgba(0, 0, 0, 0.65);
  width: 235px;
}
.picture {
  width: 104px;
  height: 104px;
  border: 1px dashed #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.picture-img {
  width: 104px;
  height: 104px;
  border: 1px solid #d9d9d9;
  padding: 8px;
  margin: 0 8px 8px 0;
  position: relative;
}
.delete-img {
	width: 24px;
  height: 24px;
  position: absolute;
  top: -8px;
  right: -8px;
}
.picture-icon {
  font-size: 32px;
  color: #b2b2b2;
}
/deep/ .ant-upload-list {
  display: none;
}
</style>
