<!--
 * @Author: your name
 * @Date: 2022-04-22 14:10:47
 * @LastEditTime: 2022-12-15 10:21:00
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/detailComponent/maintenanceDetails.vue
-->
<template>
  <div class="maintenanceDetails">
    <jywsDialog
			:visible="visible"
			title="工单详情"
			width="960px"
			roger=""
			bottom-name="关闭"
			typePrimary="primary"
			@updataClose="closeModal"
			@updataWin="updataWin"
		>
      <div class="maintenanceDetails-content">
        <div class="flex-right-account-left-content-table">
          <div class="flex-right-account-left-content-table-item borderb1">
            <div class="table-item-left borderr1">工单号：</div>
            <div class="table-item-right borderr1">{{ serviceData.code || '-' }}</div>
            <div class="table-item-left borderr1">报修等级：</div>
            <div class="table-item-right">{{ serviceData.repairRank | repairRank(serviceData.repairRank) }}</div>
          </div>
          <div class="flex-right-account-left-content-table-item">
            <div class="table-item-left borderr1">设备简称：</div>
            <div class="table-item-right borderr1">{{ serviceData.objectName || '-' }}</div>
            <div class="table-item-left borderr1">工单状态：</div>
            <div class="table-item-right">{{ serviceData.state | state(serviceData.state) }}</div>
          </div>
        </div>
        <!-- 表格区域 -->
        <a-tabs style="width: 100%;" type="card">
        <a-tab-pane :key="0" tab="维修信息">
          <template>
            <div class="flex-right-account-left-content-table">
              <div class="flex-right-account-left-content-table-item borderb1">
                <div class="table-item-left borderr1">报修时间：</div>
                <div class="table-item-right borderr1">{{ serviceData.createTime || '-' }}</div>
                <div class="table-item-left borderr1">期待完成时间：</div>
                <div class="table-item-right">{{ serviceData.repairDeadline ? $moment(serviceData.repairDeadline, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}</div>
              </div>
              <div class="flex-right-account-left-content-table-item borderb1">
                <div class="table-item-left borderr1">报修人：</div>
                <div class="table-item-right borderr1">{{ serviceData.senderName || '-' }}</div>
                <div class="table-item-left borderr1">接收人：</div>
                <div class="table-item-right">{{  serviceData.recipientName || '-' }}</div>
              </div>
              <div class="flex-right-account-left-content-table-item borderb1">
                <div class="table-item-left borderr1">抄送人：</div>
                <div class="table-item-right borderr1">{{ serviceData.copyPeople || '-' }}</div>
                <div class="table-item-left borderr1">故障类型：</div>
                <div class="table-item-right">{{ serviceData.dealType || '-' }}</div>
              </div>
              <div class="flex-right-account-left-content-table-item">
                <div class="table-item-left borderr1">故障描述：</div>
                <div class="table-item-right borderr1">{{ serviceData.repairText || '-' }}</div>
                <div class="table-item-left borderr1">报修描述照片：</div>
                <div class="table-item-right">
                  <span v-if="serviceData.repairImg" style="color: #1890FF;cursor: pointer;" @click="queryImg(serviceData.repairImg)">查看</span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>
            <div class="flex-right-account-left-content-table">
              <div class="flex-right-account-left-content-table-item borderb1">
                <div class="table-item-left borderr1">签到时间：</div>
                <div class="table-item-right borderr1">{{ serviceData.signTime || '-' }}</div>
                <div class="table-item-left borderr1">维修人：</div>
                <div class="table-item-right">{{ serviceData.recipientName || '-' }}</div>
              </div>
              <div class="flex-right-account-left-content-table-item">
                <div class="table-item-left borderr1">原因分析：</div>
                <div class="table-item-right borderr1">{{ serviceData.dealReason || '-' }}</div>
                <div class="table-item-left borderr1">原因分析照片：</div>
                <div class="table-item-right">
                  <span v-if="serviceData.dealReasonImg" style="color: #1890FF;cursor: pointer;" @click="queryImg(serviceData.dealReasonImg)">查看</span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>
            <div class="flex-right-account-left-content-table">
              <div class="flex-right-account-left-content-table-item borderb1">
                <div class="table-item-left borderr1">完成时间：</div>
                <div class="table-item-right borderr1">{{ serviceData.endTime || '-' }}</div>
                <div class="table-item-left borderr1">提交人：</div>
                <div class="table-item-right">{{ serviceData.recipientName || '-' }}</div>
              </div>
              <div class="flex-right-account-left-content-table-item borderb1">
                <div class="table-item-left borderr1">维修对策：</div>
                <div class="table-item-right borderr1">{{ serviceData.dealText || '-' }}</div>
                <div class="table-item-left borderr1">维修对策照片：</div>
                <div class="table-item-right">
                  <span v-if="serviceData.dealTextImg" style="color: #1890FF;cursor: pointer;" @click="queryImg(serviceData.dealTextImg)">查看</span>
                  <span v-else>-</span>
                </div>
              </div>
              <div class="flex-right-account-left-content-table-item">
                <div class="table-item-left borderr1">永久预防措施：</div>
                <div class="table-item-right borderr1">{{ serviceData.forever || '-' }}</div>
                <div class="table-item-left borderr1">维修工时：</div>
                <div class="table-item-right">{{ serviceData.repairTime ? serviceData.repairTime + '分钟' : '0分钟' }}</div>
              </div>
            </div>
            <div class="flex-right-account-left-content-table">
              <div class="flex-right-account-left-content-table-item borderb1">
                <div class="table-item-left borderr1">协作人 (工时)：</div>
                <div class="table-item-right borderr1">{{ serviceData.helper || '-' }}</div>
                <div class="table-item-left borderr1">故障影响时长：</div>
                <div class="table-item-right">{{ serviceData.stopTime ? serviceData.stopTime + '分钟' : '0分钟' }}</div>
              </div>
              <div class="flex-right-account-left-content-table-item borderb1">
                <div class="table-item-left borderr1">维修暂停时长：</div>
                <div class="table-item-right borderr1">{{ serviceData.pauseTime ? serviceData.pauseTime + '分钟' : '0分钟' }}</div>
                <div class="table-item-left borderr1">等待备件时长：</div>
                <div class="table-item-right">{{ serviceData.waitPartTime ? serviceData.waitPartTime + '分钟' : '0分钟' }}</div>
              </div>
              <div class="flex-right-account-left-content-table-item">
                <div class="table-item-left borderr1">备注：</div>
                <div class="table-item-right borderr1">{{ serviceData.remark || '-' }}</div>
                <div class="table-item-left borderr1">备注照片：</div>
                <div class="table-item-right">
                  <span v-if="serviceData.remarksImg" style="color: #1890FF;cursor: pointer;" @click="queryImg(serviceData.remarksImg)">查看</span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>
          </template>
        </a-tab-pane>
        <a-tab-pane :key="1" tab="备件信息">
          <!-- 表格区域 -->
          <div class="content-right-table">
            <a-table
              :columns="columns"
              :data-source="dataSource"
              :transformCellText="({ text, column, record, index }) => text || '-'"
              :pagination="false"
              :scroll="{ y: 340 }"
            >
            </a-table>
          </div>
        </a-tab-pane>
        <a-tab-pane :key="2" tab="评价审核">
          <template v-if="evaluateHistory.length > 0">
            <div class="paddingb16 flex-title">
              <div class="blueLine"></div>
              <div class="flex-title-name">工单评价</div>
            </div>
            <div class="flex-right-account-left-content-table">
              <div class="flex-right-account-left-content-table-item borderb1">
                <div class="table-item-left borderr1">评价时间：</div>
                <div class="table-item-right borderr1">{{ evaluateHistory[0].evaluateTime || '-' }}</div>
                <div class="table-item-left borderr1">评价人：</div>
                <div class="table-item-right">{{ evaluateHistory[0].userName || '-' }}</div>
              </div>
              <template v-if="serviceData.repairType == 'repairApp' || serviceData.repairType == 'repairWei' || serviceData.repairType == 'repairPc'">
                <div class="flex-right-account-left-content-table-item borderb1">
                  <div class="table-item-left borderr1">维修满意度：</div>
                  <div class="table-item-right borderr1">{{ evaluateHistory[0].satisfaction || '-' }}</div>
                  <div class="table-item-left borderr1">该故障是否频发：</div>
                  <div class="table-item-right">{{ evaluateHistory[0].isFrequency ? '是' : '否' }}</div>
                </div>
                <div class="flex-right-account-left-content-table-item">
                  <div class="table-item-left borderr1">评价说明：</div>
                  <div class="table-item-right borderr1">{{ evaluateHistory[0].remarks || '-' }}</div>
                  <div class="table-item-left borderr1">说明照片：</div>
                  <div class="table-item-right">
                    <span v-if="evaluateHistory[0].remarksImg" style="color: #1890FF;cursor: pointer;" @click="queryImg(evaluateHistory[0].remarksImg)">查看</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="flex-right-account-left-content-table-item borderb1">
                  <div class="table-item-left borderr1">执行及时性：</div>
                  <div class="table-item-right borderr1">{{ evaluateHistory[0].timely || '-' }}</div>
                  <div class="table-item-left borderr1">执行准确率：</div>
                  <div class="table-item-right">{{ evaluateHistory[0].accuracy || '-' }}</div>
                </div>
                <div class="flex-right-account-left-content-table-item borderb1">
                  <div class="table-item-left borderr1">作业规范性：</div>
                  <div class="table-item-right borderr1">{{ evaluateHistory[0].standard || '-' }}</div>
                  <div class="table-item-left borderr1">评价说明：</div>
                  <div class="table-item-right">{{ evaluateHistory[0].remarks || '-' }}</div>
                </div>
                <div class="flex-right-account-left-content-table-item">
                  <div class="table-item-left borderr1">说明照片：</div>
                  <div class="table-item-right borderr1">
                    <span v-if="evaluateHistory[0].remarksImg" style="color: #1890FF;cursor: pointer;" @click="queryImg(evaluateHistory[0].remarksImg)">查看</span>
                    <span v-else>-</span>
                    </div>
                  <div class="table-item-left borderr1">&nbsp;</div>
                  <div class="table-item-right"></div>
                </div>
              </template>
            </div>
          </template>
          <template v-if="fillHistory.length > 0">
            <div class="paddingb16 flex-title">
              <div class="blueLine"></div>
              <div class="flex-title-name">工单审核</div>
            </div>
            <div class="flex-right-account-left-content-table">
              <div class="flex-right-account-left-content-table-item borderb1">
                <div style="text-align: left;" class="table-item-left borderr1">标题</div>
                <div style="text-align: left;" class="table-item-left borderr1">审核时间</div>
                <div style="text-align: left;" class="table-item-left borderr1">审核人</div>
                <div style="text-align: left;" class="table-item-left borderr1">审核结果</div>
                <div style="text-align: left;" class="table-item-left borderr1">审核说明</div>
                <div style="text-align: left;" class="table-item-left">其它</div>
              </div>
              <div v-for="(item, index) in fillHistory" :key="index" class="flex-right-account-left-content-table-item borderb1">
                <div style="text-align: left;" class="table-item-left borderr1">{{ item.move == 1 ? '环节一' : item.move == 2 ? '环节二' : item.move == 3 ? '环节三' : item.move == 4 ? '环节四' : item.move == 5 ? '环节五' : '-' }}</div>
                <div style="text-align: left;background: #FFFFFF;color: #666666;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" class="table-item-left borderr1">{{ item.createTime || '-'  }}</div>
                <div style="text-align: left;background: #FFFFFF;color: #666666;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" class="table-item-left borderr1">{{ item.userName || '-' }}</div>
                <div style="text-align: left;background: #FFFFFF;color: #666666;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" class="table-item-left borderr1">{{ item.results ? '通过' : '不通过' }}</div>
                <div style="text-align: left;background: #FFFFFF;color: #666666;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" class="table-item-left borderr1">{{ item.resultsDescribe || '-' }}</div>
                <div style="text-align: left;background: #FFFFFF;color: #666666;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" class="table-item-left">
                  <template v-if="item.approvalProcessSubsidiaryList.length > 0">
                    <span  v-for="(item2, index2) in item.approvalProcessSubsidiaryList" :key="index2">
                      <span v-if="item2.approvalType === 1 && item2.isQuality === 1">影响质量 ({{ item2.qualityNum }}件)</span>
                      <span v-if="item2.approvalType === 2 && item2.isQuality === 1">影响交期 ({{ item2.qualityNum }}天)</span>
                      <span v-if="item2.approvalType === 3 && item2.isQuality === 1">影响安全 ({{ item2.qualityNum === 1 ? '有休' : item2.qualityNum === 2 ? '无休' : '事件' }})</span>
                    </span>
                  </template>
                  <template v-else>
                    <span>-</span>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <template v-if="serviceData.signatureImg">
            <div class="paddingb16 flex-title">
              <div class="blueLine"></div>
              <div class="flex-title-name">签名确认</div>
            </div>
            <div class="flex-right-account-left-content-table">
              <div class="flex-right-account-left-content-table-item borderb1">
                <div style="text-align: left;" class="table-item-left borderr1">签名（1）</div>
                <div style="text-align: left;" class="table-item-left borderr1">签名（2）</div>
                <div style="text-align: left;" class="table-item-left borderr1">签名（3）</div>
                <div style="text-align: left;" class="table-item-left borderr1">签名（4）</div>
                <div style="text-align: left;" class="table-item-left ">签名（5）</div>
              </div>
              <div class="flex-right-account-left-content-table-item borderb1">
                <div v-for="(item2, index2) in serviceData.signatureImg" :key="index2" style="text-align: left;background: #FFFFFF;" class="table-item-left borderr1">
                  <span v-if="item2" style="color: #1890FF;cursor: pointer;" @click="queryImg(serviceData.signatureImg, index2)">查看</span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>
          </template>
          <template v-if="evaluateHistory.length == 0 && fillHistory.length == 0 && !serviceData.signatureImg">
            <div style="width: 100%;height: 200px;display: flex;align-items: center;justify-content: center;">--- 暂无数据 ---</div>
          </template>
        </a-tab-pane>
        </a-tabs>
      </div>
    </jywsDialog>
    <imgDialog title="查看图片" bottomName="关闭" :visible="imgVisble" :imgSrc="imgSrc" :itemSrcIndex="itemSrcIndex" :itemSrc="itemSrc" @selecedImgClick="selecedImgClick" @updataWin="close"></imgDialog>
  </div>
</template>

<script>
import jywsDialog from '@/components/jywsDialog.vue'
import imgDialog from '@/components/imgDialog.vue'
import * as util from '@/core/util'
export default {
  name: 'MaintenanceDetails',
  props: {
    // 所用组件
    id: {
      type: String,
      default: ''
    },
    tenantId: {
      type: String,
      default: ''
    },
    visible: {
			type: Boolean,
			default: false,
		},
  },
  components: {
		jywsDialog,
    imgDialog
	},
  filters: {
    repairRank(text) {
			if (text === 1) {
        return '停机故障维修'
      } else if (text === 2) {
        return '不停机维修'
      } else if (text === 3) {
        return '设备不良反馈'
      } else {
        return '-'
      }
		},
    state(text) {
      if (text == 'first') {
        return '待执行'
      } else if (text == 'dealing') {
        return '执行中'
      } else if (text == 'audit') {
        return '审核中'
      } else if (text == 'again') {
        return '审核不通过'
      } else if (text == 'evaluation') {
        return '评价中'
      } else if (text == 'evaluationnot') {
        return '评价不通过'
      } else if (text == 'end') {
        return '执行完成'
      } else if (text == 'refuse') {
        return '已拒绝'
      } else if (text == 'pause') {
        return '维修暂停'
      } else if (text == 'pausepart') {
        return '维修待备件'
      } else {
        return '-'
      }
    }
  },
  data() {
    return {
      serviceData: {},
      imgVisble: false,
      imgSrc: [],
      itemSrcIndex: 0,
      itemSrc: '',
      columns: [
				{
					title: '备件编码',
					dataIndex: 'code',
					key: 'code',
					width: 200,
				},
				{
					title: '备件名称',
					dataIndex: 'name',
					key: 'name',
					width: 200,
				},
				{
					title: '型号',
					dataIndex: 'version',
					key: 'version',
					width: 130,
				},
				{
					title: '规格',
					dataIndex: 'norms',
					key: 'norms',
					width: 130,
				},
				{
					title: '品牌',
					dataIndex: 'brand',
					key: 'brand',
					width: 130,
				},
				{
					title: '消耗数',
					dataIndex: 'num',
					key: 'num',
				}
			],
      dataSource: [],
      fillHistory: [],
      evaluateHistory: []
    }
  },
  created() {
    this.getAteEvaluateDetailsApp()
  },
  mounted() {},
  methods: {
    // 评价、审核 信息
    getAteEvaluateDetailsApp() {
      const dataset = {
        id: this.id,
        tenantId: this.tenantId
      }
      util.postform('ZNRepair/queryRepairInfo', dataset).then(res => {
        console.log(res)
        this.serviceData = res.data
        this.dataSource = res.data.znRepairPartList || []
        this.evaluateHistory = res.data.znWorkOrderEvaluateQueryVoList || []
        this.fillHistory = res.data.znApprovalProcessQueryVoList || []
      }).catch(err => {
        console.error(err)
        this.$message.error(err.message)
      })
    },
    queryImg(img, index) {
			this.imgVisble = true
			this.imgSrc = (img && img.split(',')) || []
			this.itemSrcIndex = index ? index : 0
			this.itemSrc = (img && img.split(',')[0]) || ''
		},
    selecedImgClick(key) {
			this.itemSrcIndex = key
			this.itemSrc = this.imgSrc[key]
		},
		close() {
			this.imgVisble = false
		},
    closeModal() {
      this.$emit('closeModal')
    },
    updataWin() {
      this.$emit('closeModal')
    }
  },
}
</script>

<style scoped lang="less">
.maintenanceDetails-content {
  max-height: 582px;
  overflow: auto;
}
.flex-right-account-left-content-table {
	border: 1px solid #E8E8E8;
	margin-bottom: 16px;
}
.flex-right-account-left-content-table-item {
	min-height: 40px;
	display: flex;
}
.table-item-left {
	flex: 1;
	height: 100%;
	padding: 10px 12px;
	text-align: right;
	background: #FAFAFA;
}
.table-item-right {
	flex: 2;
	height: 100%;
	padding: 10px 12px;
	text-align: left;
	color: #666666;
  overflow: hidden; //溢出隐藏
  text-overflow:ellipsis; //超出显示省略号
  white-space: nowrap;//禁止自动换行
}
.flex-right-account-right {
	width: 202px;
	flex-shrink: 0;
}
.border1 {
	border: 1px solid #E8E8E8;
}
.borderr1 {
	border-right: 1px solid #E8E8E8;
}
.borderb1 {
	border-bottom: 1px solid #E8E8E8;
}
.blueLine {
  width: 3px;
  height: 16px;
  background: #1890FF;
}
.flex-title {
  display: flex;
  align-items: center
}
.flex-title-name {
  margin-left: 10px;
}
.paddingb16 {
  padding-bottom: 16px;
}
</style>
