<!--
 * @Author: your name
 * @Date: 2022-04-27 16:45:02
 * @LastEditTime: 2022-12-01 17:59:41
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/components/importDialogPlus.vue
-->
<template>
	<div class="imgDialog">
		<jyws-dialog
			:visible="visible"
			:title="title"
			:width="width"
			:roger="roger"
			:bottom-name="bottomName"
			@updataClose="updataWin"
			@updataWin="updataWin"
		>
      <div class='img-module-content'>
        <div class='img-module-content-left'>
          <div v-for="(item, imgIndex) in imgSrc" :key="imgIndex" :class="itemSrcIndex == imgIndex ? 'img-module-content-left-imges2' : 'img-module-content-left-imges'" @click="selecedImgClick(imgIndex)">
            <img :src="item" style="display: block;max-width: 100%;height: 100%; margin: 0 auto;" />
          </div>
        </div>
        <div class='img-module-content-right'>
          <img :src="itemSrc" style="width: 100%;" />
        </div>
      </div>
		</jyws-dialog>
	</div>
</template>
<script>
import jywsDialog from '@/components/jywsDialog.vue'

export default {
	name: 'imgDialog',
	props: {
		imgSrc: {
			type: Array,
			default: () => {
        return []
      },
		},
		itemSrcIndex: {
			type: Number,
			default: 0,
		},
		itemSrc: {
			type: String,
			default: '',
		},
		visible: {
			type: Boolean,
			default: false,
		},
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '960px'
    },
    roger: {
      type: String,
      default: ''
    },
    bottomName: {
      type: String,
      default: ''
    }
	},
	components: {
		jywsDialog,
	},
	data() {
		return {

		}
	},
	created() {},
	mounted() {},
	methods: {
    updataWin() {
      this.$emit('updataWin')
    },
    selecedImgClick(index) {
      this.$emit('selecedImgClick', index)
    }
	},
}
</script>

<style scoped lang="less">
.img-module-content {
	display: flex;
	align-items: center; 
	height: 600px
}
.img-module-content-left {
	width: 180px;
	height: 100%;
	overflow: auto;
}
.img-module-content-left-imges {
	width: 140px; 
	height: 105px;
	background: #444444;
	border: 3px solid transparent;
	margin-bottom: 20px;
}
.img-module-content-left-imges2 {
	width: 140px; 
	height: 105px;
	background: #444444;
	border: 3px solid #91D5FF;
	margin-bottom: 20px;
}
.img-module-content-right {
	background: #444444;
	width: calc(100% - 180px);
	height: 100%;
	overflow: auto;
}
</style>
