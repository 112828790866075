<!--
 * @Author: your name
 * @Date: 2022-04-22 11:51:01
 * @LastEditTime: 2022-12-02 14:58:08
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/views/redPacketRuleConfiguration/redPacketRuleConfiguration.vue
-->
<template>
  <div class="redPacketRuleConfiguration">
    <div class="redPacketRuleConfiguration-content">
      <a-tabs :default-active-key="tabState" @change="callback">
        <a-tab-pane key="1" tab="红包规则配置" />
        <a-tab-pane key="2" tab="中奖记录" />
      </a-tabs>
      <div v-if="tabState == '1'" class="redPacketRuleConfiguration-content-item">
        <div class="redPacketRuleConfiguration-content-item-left">
          <div class="flex-justify">
            <div>
              <div>账户余额</div>
              <div class="money-num"><span class="money-symbol">￥</span>3275</div>
            </div>
            <div>
              <a-button type="primary" size="large" @click="payClick">立即充值</a-button>
              <div class="prompt-title" @click="tradingRecordClick">交易记录</div>
            </div>
          </div>
          <div class="prompt-activity">
            活动资金账户<span class="prompt-red">已发布的红包抽奖占用资金账户</span>
          </div>
          <div class="prompt-gary">活动占用资金：10000元</div>
          <div class="margint32">
            <div style="font-size: 14px;">剩余资金</div>
            <div style="font-size: 24px;margin-top: 6px;">￥ 600</div>
            <a-button style="margin-top: 8px;" type="primary" size="default" @click="shiftClick">转入到余额</a-button>
          </div>
          <div>
            <div>活动时间：2022-04-27 至 2022-05-27</div>
            <a-button style="margin-top: 6px;width: 100%;background:#FAAD14;border: 1px solid #FAAD14;" type="primary" size="large">活动状态：进行中</a-button>
            <div style="margin-top: 20px;text-align: center;color: #b2b2b2;" class="prompt-title" @click="endSubscriptionClick">结束本次活动</div>
          </div>
        </div>
        <div class="redPacketRuleConfiguration-content-item-right">
          <div class="message-item">
            <div class="message-title">基本信息</div>
            <div class="margint30">
              <div class="message-flex">
                <div class="message-flex-left">活动标题：</div>
                <div style="width: 100%;"><a-input style="width: 60%;" /></div>
              </div>
              <div class="message-flex">
                <div class="message-flex-left">活动时间：</div>
                <div style="width: 100%;"><a-range-picker style="width: 60%;" @change="onTimeChange" /></div>
              </div>
              <div class="message-flex">
                <div class="message-flex-left">抽奖触发方式：</div>
                <div style="width: 100%;"><a-checkbox-group :options="plainOptions" :default-value="['日点检', '隐患提报', '维修完成']" @change="onCheckboxChange" /></div>
              </div>
            </div>
          </div>
          <div class="message-item">
            <div class="message-title">奖项设置</div>
            <div class="margint30">
              <a-tabs :default-active-key="tabState2" @change="callback2">
                <a-tab-pane key="1" tab="一等奖" />
                <a-tab-pane key="2" tab="二等奖" />
                <a-tab-pane key="3" tab="三等奖" />
                <a-tab-pane key="4" tab="安慰奖" />
                <a-tab-pane key="5" tab="谢谢参与" />
              </a-tabs>
              <div class="margint8">
                <div class="message-flex">
                  <div class="message-flex-left">奖项介绍：</div>
                  <div style="width: 100%;"><a-input style="width: 60%;" /></div>
                </div>
                <div class="message-flex">
                  <div class="message-flex-left">奖项类型：</div>
                  <div style="width: 100%;"><a-radio-group :options="plainOptions2" :default-value="value2" @change="onRadioChange" /></div>
                </div>
                <div v-if="value2 == '现金红包'">
                  <div class="message-flex">
                    <div class="message-flex-left">奖项数量：</div>
                    <div style="width: 100%;"><a-input style="width: 60%;" /></div>
                  </div>
                  <div class="message-flex">
                    <div class="message-flex-left">红包金额：</div>
                    <div style="width: 100%;"><a-radio-group :options="plainOptions3" :default-value="value3" @change="onRadioChange2" /></div>
                  </div>
                  <div class="message-flex">
                    <div class="message-flex-left" />
                    <div style="width: 100%;">
                      <div v-if="value3 == '固定金额'">
                        单个金额：
                        <a-input-number
                          :default-value="1000"
                          :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                          @change="onNumberChange"
                        />
                      </div>
                      <div v-if="value3 == '随机金额'">
                        浮动范围：
                        <a-input-number
                          :default-value="1000"
                          :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                          @change="onNumberChange"
                        />
                        至
                        <a-input-number
                          :default-value="1000"
                          :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                          @change="onNumberChange"
                        />
                      </div>
                      <div style="margin-top: 12px">单个红包金额必须高于0.1元低于1000元，最多输入两位小数；<span style="color: #FF4D4F;">活动发布后单个金额将无法更改</span></div>
                    </div>
                  </div>
                </div>
                <div v-if="value2 == '实物礼品'">
                  <div class="message-flex">
                    <div class="message-flex-left">兑奖期限：</div>
                    <div style="width: 100%;"><a-range-picker style="width: 60%;" @change="onTimeChange2" /></div>
                  </div>
                  <div class="message-flex">
                    <div class="message-flex-left">兑奖方式：</div>
                    <div style="width: 100%;"><a-radio-group :options="plainOptions4" v-model="value4" @change="onRadioChange3" /></div>
                  </div>
                  <div class="message-flex">
                    <div class="message-flex-left">兑奖说明：</div>
                    <div style="width: 100%;"><a-input style="width: 60%;" placeholder="请对兑奖联系人、方式和地址进行简要说明" /></div>
                  </div>
                  <div class="message-flex">
                    <div class="message-flex-left">奖品图片：</div>
                    <div style="width: 100%;">
                      <uploadOss @setFileList="setFileList"
                                 ref="uploadOss"
                                 @setFileListName="setFileListName"
                                 :showList="true">
                        <a-button>
                          <a-icon type="upload" />点击上传
                        </a-button>
                      </uploadOss>
                      <span style="color: #B2B2B2;">图片像素建议为 319px*180px 格式为jpg\bmp\png</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="padding-bottom: 0px;" class="message-item">
            <div class="message-title">中奖率</div>
            <div class="margint30">
              <div class="message-flex">
                <div class="message-flex-left">中奖率：</div>
                <div style="width: 100%;">
                  <a-input-number
                    :default-value="100"
                    :min="0"
                    :max="100"
                    :formatter="value => `${value}%`"
                    :parser="value => value.replace('%', '')"
                    @change="onNumberChange2"
                  />
                </div>
              </div>
              <div class="message-flex">
                <div class="message-flex-left">抽奖方式：</div>
                <div style="width: 100%;"><a-radio-group :options="plainOptions5" v-model="value5" @change="onRadioChange4" /></div>
              </div>
            </div>
          </div>
          <div class="operating-button">
            <span class="operating-hint">记得及时保存，防止数据丢失哦！</span>
            <a-button class="marginlr12" type="primary" @click="saveClick">保存配置</a-button>
            <a-button type="default" @click="subscriptionClick">发布活动</a-button>
          </div>
        </div>
      </div>
      <div v-if="tabState == '2'">
        <div class="query-button">
          <conditionQuery :headData="headData" @onHandleChange="onHandleChange" @onInputChange="onInputChange" @onSelectClick="onSelectClick"/>
        </div>
        <div style="height: calc(100% - 32px);padding: 0px 20px;">
          <a-table :scroll="{ y: 400 }" :pagination="false" :loading="loading" :columns="columns" :data-source="dataSource"></a-table>
          <div class="pagination-style">
            <a-pagination size="small"
            :total="pageTotal"
            :defaultCurrent='pageIndex'
            :defaultPageSize='pageSize'
            show-size-changer
            show-quick-jumper
            :show-total="total => `总共 ${total} 条`"
            @change="thePager"
            @showSizeChange="showSizeChange" />
          </div>
        </div>
      </div>
    </div>
    <jyws-dialog :visible="visible" :title="title" :width="width" :bottom-name="bottomName" @updataClose="updataClose" @updataWin="updataWin">
      <template v-if="title == '账户充值'">
        <div v-if="czState == '1'" class="cz-item">
          <div class="marginb24">
            <div class="marginb16" style="width: 330px;">充值金额</div>
            <div style="width: 330px;"><a-input /></div>
          </div>
          <div class="marginb24">
            <div class="marginb16" style="width: 330px;">备注</div>
            <div style="width: 330px;"><a-input /></div>
          </div>
          <div class="marginb24">
            <div class="marginb16" style="width: 330px;">支付方式</div>
            <div style="width: 330px;">
              <div class="cz-channel">
                <img style="width: 120px;height: 50px;" src="@/assets/icon_zfb.png">
                <img style="width: 30px;height: 30px;position: absolute;right: 0;bottom: 0;" src="@/assets/icon_选中.png">
              </div>
            </div>
          </div>
        </div>
        <div v-if="czState == '2'" class="cz-item">
          <div class="cz-ewm">
            <div class="cz-ewm-qrcode">
              <img class="cz-ewm-qrcode" src="https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/project/web/znws/img/appDownload15.png">
              <img class="cz-ewm-qrcode-icon" src="@/assets/zfbicon.png">
            </div>
            <div class="cz-ewm-hint">
              <img class="cz-ewm-qrcode-san" src="@/assets/icon_scan.png">
              <div class="cz-ewm-hint-title">请打开手机微信扫描二维码支付</div>
            </div>
          </div>
        </div>
        <div v-if="czState == '3'" style="text-align: center;" class="cz-item">
            <img class="cz-ewm-qrcode-icon-succeed" src="@/assets/icon_chose.png">
            <div class="cz-succeed">充值成功</div>
            <div class="cz-succeed-subtitle">恭喜您，本次充值成功。充值金额：3000元</div>
            <a-button style="margin-top: 25px;" size="default">查看充值记录</a-button>
        </div>
      </template>
      <template v-if="title == '转入到余额'">
        <div class="cz-item">
          <div class="marginb24">
            <div style="width: 330px;color: #999999;">活动资金账户剩余：600元</div>
          </div>
          <div class="marginb24">
            <div class="marginb16" style="width: 330px;">转入金额</div>
            <div style="width: 330px;">
              <a-input-number
                style="width: 100%;"
                :default-value="1000"
                :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                @change="onNumberChange"
              />
            </div>
          </div>
          <div class="marginb24">
            <div class="marginb16" style="width: 330px;">备注</div>
            <div style="width: 330px;"><a-input /></div>
          </div>
        </div>
      </template>
      <template v-if="title == '交易记录'">
        <div>
          <a-table :scroll="{ y: 400 }" :pagination="false" :loading="loading" :columns="columns2" :data-source="dataSource"></a-table>
          <div class="pagination-style">
            <a-pagination size="small"
            :total="pageTotal"
            :defaultCurrent='pageIndex'
            :defaultPageSize='pageSize'
            show-size-changer
            show-quick-jumper
            :show-total="total => `总共 ${total} 条`"
            @change="thePager"
            @showSizeChange="showSizeChange" />
          </div>
        </div>
      </template>
    </jyws-dialog>
  </div>
</template>

<script>
import uploadOss from '@/components/uploadOss'
import conditionQuery from '@/components/conditionQuery.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import * as util from '@/core/util'
export default {
  name: 'RedPacketRuleConfiguration',
  components: {
    uploadOss, conditionQuery, jywsDialog
  },
  filters: {
  },
  data() {
    return {
      userId:'',
      tenantId: '',
      tabState: '1',
      plainOptions: ['日点检', '隐患提报', '维修完成'],
      tabState2: '1',
      plainOptions2: ['现金红包', '实物礼品'],
      value2: '现金红包',
      plainOptions3: ['固定金额', '随机金额'],
      value3: '固定金额',
      plainOptions4: [
        { label: '活动工厂内部发放', value: '活动工厂内部发放' },
        { label: '活动主办方邮寄发放', value: '活动主办方邮寄发放' }
      ],
      value4: '活动工厂内部发放',
      infoOplList: {
        accessoryName: '',
        accessoryUrl: '',
      },
      plainOptions5: [
        { label: '按时间均匀发放', value: '按时间均匀发放' },
        { label: '前期大量发放', value: '前期大量发放' }
      ],
      value5: '按时间均匀发放',
      headData: [
        { key: 1, duplicate: 'time', name: '投运日期', component: 'a-range-picker', value: [] },
        { key: 2, duplicate: 'awards', name: '奖项', component: 'a-select', placeholder: '请选择', value: '', provinceData: [
          { key: 1, value: '' , name: '全部' },
          { key: 2, value: '1' , name: '一等奖' },
          { key: 3, value: '2' , name: '二等奖' },
          { key: 4, value: '3' , name: '三等奖' },
          { key: 5, value: '4' , name: '安慰奖' }]
        },
        { key: 3, duplicate: 'type', name: '类型', component: 'a-select', placeholder: '请选择', value: '', provinceData: [
          { key: 1, value: '' , name: '全部' },
          { key: 2, value: '1' , name: '现金红包' },
          { key: 3, value: '2' , name: '实物礼品' }]
        },
        { key: 4, duplicate: 'name', name: '中奖人', component: 'a-input', placeholder: '请输入中奖人姓名', value: '' },
        { key: 5, duplicate: 'type', name: '发放状态', component: 'a-select', placeholder: '请选择', value: '', provinceData: [
          { key: 1, value: '' , name: '全部' },
          { key: 2, value: '1' , name: '已发放' },
          { key: 3, value: '2' , name: '未发放' }]
        }
      ],
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      columns: [
        {
          title: '中奖时间',
          dataIndex: 'componentName',
          key: 'componentName',
          ellipsis: true,
        },
        {
          title: '奖项',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '类型',
          dataIndex: 'cycle',
          key: 'cycle',
          ellipsis: true,
          scopedSlots: { customRender: 'cycle' }
        },
        {
          title: '奖项描述',
          dataIndex: 'updateTime',
          key: 'updateTime',
          ellipsis: true,
          scopedSlots: { customRender: 'updateTime' }
        },
        {
          title: '红包金额',
          dataIndex: 'schedule',
          key: 'schedule',
          ellipsis: true,
          scopedSlots: { customRender: 'schedule' }
        },
        {
          title: '中奖人',
          dataIndex: 'state',
          key: 'state',
          ellipsis: true,
          scopedSlots: { customRender: 'state' }
        },
        {
          title: '中奖人电话',
          dataIndex: 'componentExperiences',
          key: 'componentExperiences',
          ellipsis: true,
          scopedSlots: { customRender: 'componentExperiences' }
        },
        {
          title: '收件人',
          dataIndex: 'componentExperiences',
          key: 'componentExperiences',
          ellipsis: true,
          scopedSlots: { customRender: 'componentExperiences' }
        },
        {
          title: '收件人电话',
          dataIndex: 'componentExperiences',
          key: 'componentExperiences',
          ellipsis: true,
          scopedSlots: { customRender: 'componentExperiences' }
        },
        {
          title: '收件地址',
          dataIndex: 'componentExperiences',
          key: 'componentExperiences',
          ellipsis: true,
          scopedSlots: { customRender: 'componentExperiences' }
        },
        {
          title: '发放状态',
          dataIndex: 'componentExperiences',
          key: 'componentExperiences',
          ellipsis: true,
          scopedSlots: { customRender: 'componentExperiences' }
        }
      ],
      dataSource: [],
      columns2: [
        {
          title: '流水号',
          dataIndex: 'componentName',
          key: 'componentName',
          ellipsis: true,
        },
        {
          title: '时间',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '金额（元）',
          dataIndex: 'cycle',
          key: 'cycle',
          ellipsis: true,
          scopedSlots: { customRender: 'cycle' }
        },
        {
          title: '类型',
          dataIndex: 'updateTime',
          key: 'updateTime',
          ellipsis: true,
          scopedSlots: { customRender: 'updateTime' }
        },
        {
          title: '交易方式',
          dataIndex: 'schedule',
          key: 'schedule',
          ellipsis: true,
          scopedSlots: { customRender: 'schedule' }
        },
        {
          title: '备注',
          dataIndex: 'state',
          key: 'state',
          ellipsis: true,
          scopedSlots: { customRender: 'state' }
        },
      ],
      czState: '1',
      visible: false,
      title: '',
      width: '760px',
      bottomName: ''
    }
  },
  created() {
  },
  mounted() {},
  methods: {
    callback(key) {
      this.tabState = key
    },
    callback2(key) {
      this.tabState2 = key
    },
    onTimeChange(date, dateString) {
      console.log(date, dateString);
    },
    onCheckboxChange(checkedValues) {
      console.log('checked = ', checkedValues);
      console.log('value = ', this.value);
    },
    onRadioChange(e) {
      this.value2 = e.target.value
      console.log('radio1 checked', e.target.value);
    },
    onRadioChange2(e) {
      this.value3 = e.target.value
      console.log('radio1 checked', e.target.value);
    },
    onRadioChange3(e) {
      this.value4 = e.target.value
      console.log('radio1 checked', e.target.value);
    },
    onNumberChange(value) {
      console.log('changed', value);
    },
    onTimeChange2(date, dateString) {
      console.log(date, dateString);
    },
     // 上传附件 -- 获取oss地址
    setFileList(val) {
      this.addoplForm.accessoryUrl = val
    },
    setFileListName(val) {
      this.addoplForm.accessoryName = val
    },
    onNumberChange2(value) {
      console.log('changed', value);
    },
    onRadioChange4(e) {
      this.value5 = e.target.value
      console.log('radio1 checked', e.target.value);
    },
    onHandleChange(item) {
      if (item.duplicate == 'anystandard') {
        this.equipmentData.forEach(ele => {
          if (ele.duplicate == 'anystandard') {
            ele.value = item.value
          }
        })
      } else if (item.duplicate == 'status') {
        this.headData.forEach(ele => {
          if (ele.duplicate == 'status') {
            ele.value = item.value
          }
        })
      } else if (item.duplicate == 'trigger') {
        this.headData.forEach(ele => {
          if (ele.duplicate == 'trigger') {
            ele.value = item.value
          }
        })
      }
    },
    onInputChange(item) {
      if (item.duplicate == 'library') {
        this.associatedData[0].value = item.value
      } else if (item.duplicate == 'devicename') {
        this.equipmentData.forEach(ele => {
          if (ele.duplicate == 'devicename') {
            ele.value = item.value
          }
        })
      } else if (item.duplicate == 'component') {
        this.headData.forEach(ele => {
          if (ele.duplicate == 'component') {
            ele.value = item.value
          }
        })
      }
    },
    onSelectClick() {
      console.log('点击了')
    },
    updataClose() {
      this.visible = false
      this.title = ''
    },
    updataWin() {
      if (this.title == '账户充值') {
        if (this.czState == '1') {
          this.czState = '2'
          this.bottomName = ''
        }
      } else if (this.title == '转入到余额') {
        this.visible = false
        this.title = ''
        this.bottomName = ''
      } else if (this.title == '交易记录') {
        this.visible = false
        this.title = ''
        this.bottomName = ''
      }
    },
    payClick() {
      this.visible = true
      this.title = '账户充值'
      this.czState = '1'
      this.bottomName = '确定'
    },
    shiftClick() {
      this.visible = true
      this.title = '转入到余额'
      this.bottomName = '确定'
    },
    tradingRecordClick() {
      this.visible = true
      this.title = '交易记录'
      this.bottomName = '关闭'
    },
    saveClick() {
      this.$message.success('配置保存成功');
    },
    subscriptionClick() {
      let that = this
      this.$confirm({
        title: '确认发布活动吗？',
        content: '依据您本次发布的活动配置，将会从账户余额转入2000元到本次活动资金账户用于红包抽奖',
        onOk() {
          that.$success({
            title: '活动发布成功',
            // JSX support
            content: '已成功将2000元从账户余额转入活动资金账户',
            okText: '我知道了'
          });
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    },
    endSubscriptionClick() {
      this.$confirm({
        title: '确认结束活动吗？',
        content: '结束活动后将不再触发抽奖活动，确认立即结束？',
        onOk() {
          console.log('OK');
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    }
  },
}
</script>

<style scoped lang="less">
.redPacketRuleConfiguration {
  background-color: #f0f2f5;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  font-family: PingFang SC, PingFang SC-Regular;
}
.redPacketRuleConfiguration-content {
  background-color: #FFFFFF;
  width: 100%;
  position: relative;
}
.redPacketRuleConfiguration-content-item {
  padding: 0px 20px;
  display: flex;
}
.redPacketRuleConfiguration-content-item-left {
  width: 360px;
  height: 490px;
  border: 1px solid #e8e8e8;
  padding: 24px 19px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  flex-shrink: 0;
}
.flex-justify {
  display: flex;
  justify-content: space-between;
}
.money-symbol {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}
.money-num {
  font-size: 32px;
  font-weight: 500;
  color: #333333;
}
.prompt-title {
  font-size: 14px;
  font-weight: 400;
  color: #1890ff;
  margin-top: 16px;
  text-align: right;
  cursor: pointer;
}
.prompt-activity {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-top: 54px;
}
.prompt-red {
  font-size: 12px;
  font-weight: 400;
  color: #ff4d4f;
  margin-left: 13px;
}
.prompt-gary {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}
.margint32 {
  margin-top: 32px;
}
.redPacketRuleConfiguration-content-item-right {
  width: 100%;
  min-width: 800px;
  margin-left: 30px;
}
.message-title {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
}
.message-item {
  padding-bottom: 65px;
}
.margint30 {
  margin-top: 30px;
}
.message-flex {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 24px;
}
.message-flex-left {
  width: 150px;
  margin-right: 38px;
  flex-shrink: 0;
  text-align: right;
}
.margint8 {
  margin-top: 8px;
}
.operating-button {
  position: absolute;
  bottom: 35px;
  right: 27px;
}
.operating-hint {
  font-size: 12px;
  font-weight: 400;
  color: #b2b2b2;
}
.marginlr12 {
  margin: 0px 12px;
}
.query-button {
  padding: 0px 21px 0px 17px;
  width: 100%;
  background: #ffffff;
}
.pagination-style {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.cz-item {
  margin: auto;
  width: 330px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #333333;
}
.marginb16 {
  margin-bottom: 16px;
}
.marginb24 {
  margin-bottom: 24px;
}
.cz-channel {
  width: 150px;
  height: 68px;
  background: #ffffff;
  border: 1px solid #1890ff;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cz-ewm {
  width: 212px;
  height: 264px;
  padding: 14px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  text-align: center;
  margin: auto;
}
.cz-ewm-qrcode {
  width: 184px;
  height: 184px;
  position: relative;
}
.cz-ewm-qrcode-icon {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 43%;
  left: 43%;
}
.cz-ewm-qrcode-san {
  width: 36px;
  height: 36px;
  margin-right: 16px;
}
.cz-ewm-hint {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cz-ewm-hint-title {
  width: 104px;
  white-space: pre-line;
}
.cz-ewm-qrcode-icon-succeed {
  width: 72px;
  height: 72px;
}
.cz-succeed {
  margin-top: 30px;
  font-size: 24px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #333333;
}
.cz-succeed-subtitle {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #999999;
}
</style>
