<!--
 * @Author: your name
 * @Date: 2022-04-22 14:10:47
 * @LastEditTime: 2022-12-14 17:34:29
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/components/bookReferenceDetails.vue
-->
<template>
  <div class="bookReferenceDetails">
    <jywsDialog
			:visible="visible"
			title="工单详情"
			width="960px"
			roger=""
			bottom-name="关闭"
			typePrimary="primary"
			@updataClose="closeModal"
			@updataWin="updataWin"
		>
      <div class="bookReferenceDetails-content">
        <div class="flex-right-account-left-content-table">
          <div class="flex-right-account-left-content-table-item borderb1">
            <div class="table-item-left borderr1">基准书名称：</div>
            <div class="table-item-right borderr1">{{ item.benchmarkBookName || '-' }}</div>
            <div class="table-item-left borderr1">类型：</div>
            <div class="table-item-right">{{ item.benchmarkType | type(item.benchmarkType) }}</div>
          </div>
          <div class="flex-right-account-left-content-table-item">
            <div class="table-item-left borderr1">适用于：</div>
            <div style="flex: 5.35;" class="table-item-right borderr1">{{ item.objectsName || '-' }}</div>
          </div>
        </div>
        <!-- 表格区域 -->
        <div style="width: 100%;">
        <!-- 表格区域 -->
        <div class="content-right-table">
          <a-table
            :columns="columns"
            :data-source="dataSource"
            :transformCellText="({ text, column, record, index }) => text || '-'"
            :pagination="false"
            :scroll="{ y: 340 }"
          >
            <span slot="createTime" slot-scope="text">
							{{ text ? $moment(text, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}
						</span>
            <span slot="action" slot-scope="scope">
              <a :href="'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/pdf/' + scope.pdfName"
								target="_blank"
							>查看</a>
            </span>
          </a-table>
        </div>
        </div>
      </div>
    </jywsDialog>
    <imgDialog title="查看图片" bottomName="关闭" :visible="imgVisble" :imgSrc="imgSrc" :itemSrcIndex="itemSrcIndex" :itemSrc="itemSrc" @selecedImgClick="selecedImgClick" @updataWin="close"></imgDialog>
  </div>
</template>

<script>
import jywsDialog from '@/components/jywsDialog.vue'
import imgDialog from '@/components/imgDialog.vue'
import * as util from '@/core/util'
export default {
  name: 'BookReferenceDetails',
  props: {
    // 所用组件
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    tenantId: {
      type: String,
      default: ''
    },
    visible: {
			type: Boolean,
			default: false,
		},
  },
  components: {
		jywsDialog,
    imgDialog
	},
  filters: {
    type(text) {
			if (text == 'day') {
        return '日点检'
      } else if (text == 'specialty') {
        return '周点检'
      } else if (text == 'precision') {
        return '定期点检'
      } else if (text == 'planned') {
        return '计划点检'
      } else if (text == 'B1') {
        return '一级保养'
      } else if (text == 'B2') {
        return '二级保养'
      } else if (text == 'B3') {
        return '日常保养'
      } else if (text == 'project') {
        return '安全巡检'
      } else {
        return '-'
      }
		},
    feedbackMesFlag(text) {
      if (text == 'feedBack') {
        return '隐患反馈'
      } else if (text == 'feedBackEnd') {
        return '隐患反馈已处置'
      } else if (text == 'selfDeal') {
        return '自行处置'
      } else if (text == 'alreadyDeal') {
        return '报修'
      } else if (text == 'no') {
        return '未处理'
      } else {
        return '-'
      }
    }
  },
  data() {
    return {
      infoList: {
        assessUserName: '',
        objectName: '',
        startUserName: '',
        type: ''
      },
      imgVisble: false,
      imgSrc: [],
      itemSrcIndex: 0,
      itemSrc: '',
      columns: [
				{
					title: '历史版本',
					dataIndex: 'versionName',
					key: 'versionName',
					width: 200,
				},
				{
					title: '附件名称',
					dataIndex: 'pdfZname',
					key: 'pdfZname',
					ellipsis: true,
					width: 200,
				},
				{
					title: '上传时间',
					dataIndex: 'createTime',
					key: 'createTime',
					width: 200,
          scopedSlots: { customRender: 'createTime' },
				},
				{
					title: '操作人',
					dataIndex: 'cname',
					key: 'cname',
				},
				{
					title: '附件',
					key: 'cz',
					width: 150,
					scopedSlots: { customRender: 'action' },
				}
			],
      dataSource: [],
      fillHistory: [],
      evaluateHistory: []
    }
  },
  created() {
    this.queryForTaskIdDetail()
  },
  mounted() {},
  methods: {
    // 作业基准书信息
    queryForTaskIdDetail() {
      let dataset = {
        pageIndex: 1,
        pageSize: 99999,
        taskBenchmarkId: this.item.taskBenchmarkId,
        tenantId: this.tenantId
      }
      util.postform('taskBenchmark/getTaskBenchmarkHistoryPageList', dataset).then((res) => {
        let data = res.data.records
        if (data.length > 0) {
          data.forEach((element) => {
            element.key = element.c_id
          })
        }
        this.dataSource = data
      })
    },
    queryImg(img) {
      debugger
			this.imgVisble = true
			this.imgSrc = (img && img.split(',')) || []
			this.itemSrcIndex = 0
			this.itemSrc = (img && img.split(',')[0]) || ''
		},
    selecedImgClick(key) {
			this.itemSrcIndex = key
			this.itemSrc = this.imgSrc[key]
		},
		close() {
			this.imgVisble = false
		},
    closeModal() {
      this.$emit('closeModal')
    },
    updataWin() {
      this.$emit('closeModal')
    }
  },
}
</script>

<style scoped lang="less">
.bookReferenceDetails-content {
  max-height: 582px;
  overflow: auto;
}
.flex-right-account-left-content-table {
	border: 1px solid #E8E8E8;
	margin-bottom: 16px;
}
.flex-right-account-left-content-table-item {
	min-height: 40px;
	display: flex;
}
.table-item-left {
	flex: 1;
	height: 100%;
	padding: 10px 12px;
	text-align: right;
	background: #FAFAFA;
}
.table-item-right {
	flex: 2;
	height: 100%;
	padding: 10px 12px;
	text-align: left;
	color: #666666;
  overflow: hidden; //溢出隐藏
  text-overflow:ellipsis; //超出显示省略号
  white-space: nowrap;//禁止自动换行
}
.flex-right-account-right {
	width: 202px;
	flex-shrink: 0;
}
.border1 {
	border: 1px solid #E8E8E8;
}
.borderr1 {
	border-right: 1px solid #E8E8E8;
}
.borderb1 {
	border-bottom: 1px solid #E8E8E8;
}
.blueLine {
  width: 3px;
  height: 16px;
  background: #1890FF;
}
.flex-title {
  display: flex;
  align-items: center
}
.flex-title-name {
  margin-left: 10px;
}
.paddingb16 {
  padding-bottom: 16px;
}
</style>
