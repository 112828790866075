<!--
 * @Author: your name
 * @Date: 2022-04-22 16:00:48
 * @LastEditTime: 2022-12-09 11:05:05
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/components/unitsTree.vue
-->
<template>
  <div class="unitsTree">
    <!-- 树区域 -->
    <div class="unitsTree-tree">
      <a-tree :show-line="true"
              :show-icon='true'
              :replaceFields='replaceFields'
              :default-expanded-keys="['0-0-0', '0-0-1', '0-0-2']"
              :tree-data="treeData"
              @select="onSelect">
        <a-icon slot="smile"
                type="home" />
        <a-icon slot="meh" type="hdd" />
        <a-icon slot="organization" type="bank" />
        <template slot="custom" slot-scope="{ scopedSlots }">
          <a-icon slot="meh" :style="scopedSlots.selected ? 'color: #1890FF;' : ''" type="hdd" />
        </template>
        <template slot="house" slot-scope="{ scopedSlots }">
          <a-icon slot="organization" :style="scopedSlots.selected ? 'color: #1890FF;' : ''" type="home" />
        </template>
      </a-tree>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnitsTree',
  props: {
    replaceFields: {
      type: Object,
      default: () => {
        return {
          children: 'children',
          title: 'title',
          key: 'key',
        }
      },
      require: false,
    },
    treeData: {
      type: Array,
      default: () => {
        return []
      },
      require: false,
    },
  },
  data() {
    return {
      typeName: '',
      // treeData: [
      //   {
      //     title: '专家课程',
      //     key: '0-0',
      //     slots: {
      //       icon: 'smile',
      //     },
      //     children: [
      //       { title: 'leaf', key: '0-0-0', slots: { icon: 'meh' } },
      //       { title: 'leaf', key: '0-0-1', slots: { icon: 'meh' } },
      //     ],
      //   },
      // ],
    }
  },
  created() {},
  mounted() {},
  methods: {
    onSelect(val,e) {
      let data = e.node._props.dataRef
      this.$emit('treeSelect',val,data)
    },
  },
}
</script>

<style scoped lang="less">
.unitsTree {
  width: 100%;
  height: 100%;
}
.unitsTree-search {
  width: calc(100% - 30px);
  margin: 0 auto;
  margin-top: 8px;
}
.unitsTree-tree {
  height: calc(100% - 32px);
  text-align: left;
  margin-left: 10px;
  overflow: auto;
}
</style>
