<template>
	<div class="specialRecordTabs">
    <div class="specialRecordTabs-title">
      <a-tabs :default-active-key="activeKeys" @change="callback">
			<a-tab-pane :key="1" tab="特种设备检验记录">
			</a-tab-pane>
			<a-tab-pane :key="2" tab="特种证书复审记录" force-render>
			</a-tab-pane>
		</a-tabs>
    </div>
    <div class="specialRecordTabs-content">
				<specialEqusRecords v-if="activeKeys === 1"></specialEqusRecords>
				<specialPeosRecords v-if='activeKeys === 2'></specialPeosRecords>
    </div>
	</div>
</template>

<script>
import specialEqusRecords from '@/views/specialEqusRecords/specialEqusRecords.vue'
import specialPeosRecords from '@/views/specialPeosRecords/specialPeosRecords.vue'
export default {
	components: {
		specialEqusRecords,
    specialPeosRecords
	},
	data() {
		return {
      activeKeys: 1
    }
	},
	created() {},
	mounted() {},
	methods: {
		callback(val) {
      console.log(val);
      this.activeKeys = val
    },
	},
}
</script>

<style scoped>
.specialRecordTabs {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	padding: 20px;
	box-sizing: border-box;
	overflow: auto;
}
.specialRecordTabs-content {
  background-color: #fff;
  height: calc(100% - 80px);
}
.specialRecordTabs-title {
  background-color: #fff;
}
.specialRecordTabs-title >>> .ant-tabs-ink-bar {
  width: 100px !important;
  left:22px !important
}
</style>
