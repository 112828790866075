<!--
 * @Author: your name
 * @Date: 2022-04-22 14:10:47
 * @LastEditTime: 2022-12-09 10:53:53
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/components/conditionQuery.vue
-->
<template>
  <div class="viewingExecutionItems">
    <jywsDialog
			:visible="visible"
			title="查看执行项"
			width="960px"
			roger=""
			bottom-name="关闭"
			typePrimary="primary"
			@updataClose="closeModal"
			@updataWin="updataWin"
		>
      <div class="viewingExecutionItems-content">
				<div class="info-table">
					<div class="info-table-flex">
						<div class="info-table-flex-item">
							<div class="info-table-flex-item-l">设备信息</div>
							<div class="info-table-flex-item-r">{{ infoList.objectName }}</div>
						</div>
						<div class="info-table-flex-item">
							<div class="info-table-flex-item-l">计划类型</div>
							<div style="border-right: 0px;" class="info-table-flex-item-r">{{ infoList.type | type(infoList.type) }}</div>
						</div>
					</div>
					<div style="border-top: 1px solid #f0f0f0;" class="info-table-flex">
						<div class="info-table-flex-item">
							<div class="info-table-flex-item-l">计划开始日期</div>
							<div class="info-table-flex-item-r">{{ infoList.planIssuedTime }}</div>
						</div>
						<div class="info-table-flex-item">
							<div class="info-table-flex-item-l">任务周期</div>
							<div style="border-right: 0px;" class="info-table-flex-item-r">{{ infoList.cycle }} {{ infoList.unit | getRowString(infoList.c_unit) }}</div>
						</div>
					</div>
					<div style="border-top: 1px solid #f0f0f0;" class="info-table-flex">
						<div class="info-table-flex-item">
							<div class="info-table-flex-item-l">计划截止日期</div>
							<div class="info-table-flex-item-r">{{ infoList.planOverTime }}</div>
						</div>
						<div class="info-table-flex-item">
							<div class="info-table-flex-item-l">提前下发天数</div>
							<div style="border-right: 0px;" class="info-table-flex-item-r">{{ infoList.remindDay || '0' }}天</div>
						</div>
					</div>
					<div style="border-top: 1px solid #f0f0f0;" class="info-table-flex">
						<div class="info-table-flex-item">
							<div class="info-table-flex-item-l">执行人/工作组</div>
							<div class="info-table-flex-item-r">{{ infoList.userName }}</div>
						</div>
						<div class="info-table-flex-item">
							<div class="info-table-flex-item-l">评价人</div>
							<div style="border-right: 0px;" class="info-table-flex-item-r">{{ infoList.assessUserName || '-' }}</div>
						</div>
					</div>
				</div>
        <div class="content-right-btnGroup">
          &nbsp;
				</div>
				<!-- 表格区域 -->
				<div class="content-right-table">
					<a-table
						:columns="detailColumns"
						:loading="detailLoading"
						:data-source="strandardDataSource"
						:pagination="false"
						:scroll="{ y: 380 }"
					>
						<span slot="c_way" slot-scope="text">
							{{ text | isSF(text) }}
						</span>
						<span slot="c_max_num" slot-scope="text">
							{{ text ? text : 0 }}
						</span>
						<span slot="c_min_num" slot-scope="text">
							{{ text ? text : 0 }}
						</span>
						<span slot="c_photo" slot-scope="text">
							{{ text | getRowString(text) }}
						</span>
					</a-table>
				</div>
			</div>
    </jywsDialog>
  </div>
</template>

<script>
import jywsDialog from '@/components/jywsDialog.vue'
import * as util from '@/core/util'
import moment from 'moment'
import { dateFormat7, randomString } from '@/core/date'
export default {
  name: 'ViewingExecutionItems',
  props: {
    // 所用组件
    id: {
      type: String,
      default: ''
    },
    tenantId: {
      type: String,
      default: ''
    },
    infoList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    visible: {
			type: Boolean,
			default: false,
		},
  },
  components: {
		jywsDialog
	},
  filters: {
    type(text) {
			if (text == 'day') {
        return '日点检'
      } else if (text == 'specialty') {
        return '周点检'
      } else if (text == 'precision') {
        return '定期点检'
      } else if (text == 'planned') {
        return '计划点检'
      } else if (text == 'B1') {
        return '一级保养'
      } else if (text == 'B2') {
        return '二级保养'
      } else if (text == 'B3') {
        return '日常保养'
      } else if (text == 'project') {
        return '安全巡检'
      } else {
        return '-'
      }
		},
    getRowString(data) {
			//日点检:day,周点检:specialty,专项巡检:project,定期点检:precision
			if (data == "day") {
				return '天'
			} if (data == "specialty") {
				return '周'
			} if (data == "week") {
				return '周'
			} if (data == "month") {
				return '月'
			} if (data == "precision") {
				return '定期点检'
			} if (data == 0) {
				return '否'
			} if (data == 1) {
				return '是'
			} else {
				return '-'
			}
		},
		isSF(text) {
			if (text) {
        return text ? '是' : '否'
      } else {
        return '否'
      }
		},
    computingTime(text) {
      if (text.startTime) {
        // return dateFormat7(text)
				return moment(text.startTime).subtract (text.remindDay,"days").format('YYYY-MM-DD')
      } else {
        return '-'
      }
    },
    timeTransition(text) {
      if (text) {
        return dateFormat7(text)
      } else {
        return '-'
      }
    }
  },
  data() {
    return {
      // 表格表头
			detailColumns: [
				{
					title: '执行单元',
					dataIndex: 'c_element',
					key: 'c_element',
					ellipsis: true,
				},
				{
					title: '执行项目',
					dataIndex: 'c_project',
					key: 'c_project',
					ellipsis: true,
				},
				{
					title: '执行内容',
					dataIndex: 'c_content',
					key: 'c_content',
					ellipsis: true,
				},
				{
					title: '执行方法',
					dataIndex: 'c_method',
					key: 'c_method',
					ellipsis: true,
				},
				{
					title: '执行标准',
					dataIndex: 'c_standard',
					key: 'c_standard',
					ellipsis: true
				},
				{
					title: '数值记录',
					dataIndex: 'c_way',
					key: 'c_way',
					scopedSlots: { customRender: 'c_way' },
					ellipsis: true
				},
				{
					title: '最大值',
					dataIndex: 'c_max_num',
					key: 'c_max_num',
					scopedSlots: { customRender: 'c_max_num' },
					ellipsis: true,
				},
				{
					title: '最小值',
					dataIndex: 'c_min_num',
					key: 'c_min_num',
					scopedSlots: { customRender: 'c_min_num' },
					ellipsis: true,
				},
				{
					title: '必须拍照',
					dataIndex: 'c_photo',
					key: 'c_photo',
					scopedSlots: { customRender: 'c_photo' }
				}
			],
			detailLoading: false,
      strandardDataSource: [],
    }
  },
  created() {
    this.getDetailListData()
  },
  mounted() {},
  methods: {
    //获得点检列表详情数据
		getDetailListData() {
			this.detailDataSource = []
			this.detailLoading = true
			let dataset = {
				id: this.infoList.id,
				objectId: this.infoList.objectId,
				type: this.infoList.type
			};
			util.post('webCheck/queryCheckPlanDetailList', dataset).then((reg) => {
				let temp = []
				let dataSource = []
				let selectedRows = []
				let selectedRowKeys = []
				let strandardDataSource = [];
				temp = reg.dataset;
				temp.forEach((ele) => {
					let unit = ele.c_unit == 'day' ? '天' : ele.c_unit == 'week' ? '周' : ele.c_unit == 'week' ? '月' : '-';
					if (ele.isExist == 1) {
						selectedRowKeys.push(ele.c_id);
						selectedRows.push({
							key: ele.c_id || randomString(25), //key react 数据唯一值
							c_element: ele.c_element,       //点检单元
							c_project: ele.c_project,       //点检项目
							c_content: ele.c_content,       //点检内容
							c_method: ele.c_method,         //点检方法
							c_standard: ele.c_standard,     //点检标准
							c_way: ele.c_way,               //数值记录
							c_max_num: ele.c_max_num,       //最大值
							c_min_num: ele.c_min_num,       //最小值
							// lastDate: ele.lastDate,         //上次执行时间
							// c_cycle_day: ele.c_cycle_day,   //下次计划执行时间
							c_cycle: ele.c_cycle + unit,           //周期
							c_photo: ele.c_photo,           //必须拍照
							isExist: ele.isExist
						});

						strandardDataSource.push({
							key: ele.c_id || randomString(25), //key react 数据唯一值
							c_element: ele.c_element,       //点检单元
							c_project: ele.c_project,       //点检项目
							c_content: ele.c_content,       //点检内容
							c_method: ele.c_method,         //点检方法
							c_standard: ele.c_standard,     //点检标准
							c_way: ele.c_way,               //数值记录
							c_max_num: ele.c_max_num,       //最大值
							c_min_num: ele.c_min_num,       //最小值
							// lastDate: ele.lastDate,         //上次执行时间
							// c_cycle_day: ele.c_cycle_day,   //下次计划执行时间
							c_cycle: ele.c_cycle + unit,           //周期
							c_photo: ele.c_photo,           //必须拍照
							isExist: ele.isExist
						})
					}

					dataSource.push({
						key: ele.c_id || randomString(25), //key react 数据唯一值
						c_element: ele.c_element,       //点检单元
						c_project: ele.c_project,       //点检项目
						c_content: ele.c_content,       //点检内容
						c_method: ele.c_method,         //点检方法
						c_standard: ele.c_standard,     //点检标准
						c_way: ele.c_way,               //数值记录
						c_max_num: ele.c_max_num,       //最大值
						c_min_num: ele.c_min_num,       //最小值
						// lastDate: ele.lastDate,         //上次执行时间
						// c_cycle_day: ele.c_cycle_day,   //下次计划执行时间
						c_cycle: ele.c_cycle + unit,           //周期
						c_photo: ele.c_photo,           //必须拍照
						isExist: ele.isExist
						})
					})
					this.detailDataSource = dataSource
					this.detailLoading = false
					this.strandardDataSource = strandardDataSource
			})
		},
    closeModal() {
      this.$emit('closeModal')
    },
    updataWin() {
      this.$emit('closeModal')
    }
  },
}
</script>

<style scoped lang="less">
.viewingExecutionItems-content {
  max-height: 582px;
  overflow: auto;
}
.info-table {
  border: 1px solid #f0f0f0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.info-table-flex {
	display: flex;
}
.info-table-flex-item {
	display: flex;
}
.info-table-flex-item-l {
	padding: 10px 24px;
  border-right: 1px solid #f0f0f0;
  width: 220px;
  background-color: #fafafa;
}
.info-table-flex-item-r {
	padding: 10px 24px;
  border-right: 1px solid #f0f0f0;
	color: rgba(0, 0, 0, 0.65);
  width: 235px;
}
</style>
