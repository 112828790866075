<template>
  <div class="specialPeos">
    <div class="specialPeos-title">
      <span class="title-icon"> </span>
      <span>特种证书年检</span>
    </div>
    <div class="specialPeos-content">
      <div class="content-left">
        <!-- 左侧标题 -->
        <div class="content-left-title">
          <div>用户列表</div>
          <div class="flush">
            <a href="javascript:;"
               @click="reflush">
              <a-icon type="sync"
                      style="margin-right: 6px" />刷新
            </a>
          </div>
        </div>
        <!-- 左侧树区域 -->
        <div class="content-left-tree">
          <deviceTree v-if="oneTreeVisble"
                      :treeData="deviceTreeList"
                      @searchTreeBtn="searchTreeBtn"
                      searchType='peos'
                      @treeSelect="treeSelect"
                      @expandTree="expandTree"></deviceTree>
        </div>
      </div>
      <div class="content-right">
        <!-- 查询区域 -->
        <div class="content-right-form">
          <conditionQuery :headData="headData"
                          :exportState="true"
                          @exportExcel="exportExcel"
                          @onHandleChange="onHandleChange"
                          @onInputChange="onInputChange"
                          @onSelectClick="getspecialPeos" />
        </div>
        <div class="content-right-message">
          <a-alert message="下次检验日期等于证书有限期限减去提前提醒天数；当前时间达到下次检验日期时，系统会自动下发一条年检任务给执行人；年检任务完成后会自动更新年检项目证书有效期限和下次检验日期"
                   banner />
        </div>
        <!-- 功能按钮区域 -->
        <div class="content-right-btnGroup">
          <a-button-group>
            <a-button @click="onbtnGroup"
                      name="addspecialPeos">新增</a-button>
            <a-button @click="onbtnGroup"
                      name="editspecialPeos">
              修改
            </a-button>
            <a-button @click="delBtn"> 删除 </a-button>
            <a-button @click="onbtnGroup"
                      name="importExecl">
              批量导入
            </a-button>
            <a-button name="changePlan"
                      @click="onbtnGroup">
              变更计划
            </a-button>
          </a-button-group>
        </div>
        <!-- 表格区域 -->
        <div class="content-right-table">
          <a-table :columns="columns"
                   :loading="loading"
                   :row-selection="rowSelection"
                   :data-source="dataSource"
                   :pagination="false"
                   :scroll="{ y: 380 }">
            <span slot="time"
                  slot-scope="time">
              {{
								time
									? $moment(time).format('YYYY-MM-DD')
									: '-'
							}}
            </span>
            <span slot="cycle"
                  slot-scope="text, record">
              {{ record.cycle + record.cycleUnit }}
            </span>
            <span slot="executiveName"
                  slot-scope="text, record">
              {{
								record.execType === 0
									? record.executiveName
									: record.nowRecipientsName
							}}
            </span>
            <span slot="credentialsState"
                  slot-scope="text, record">
              <a-badge v-if="record.credentialsState === 0"
                       status="success"
                       text="正常" />
              <a-badge v-else-if="record.credentialsState === 2"
                       status="error"
                       text="超期" />
              <a-badge v-else-if="record.credentialsState === 1"
                       status="warning"
                       text="待检" />
            </span>
            <span slot="action"
                  slot-scope="scope">
              <a @click="openinfospecialPeos(scope)"
                 name="infospecialPeos">查看</a>
            </span>
          </a-table>
          <a-pagination @change="pageonChange"
                        @showSizeChange="showSizeChange"
                        size="small"
                        :total="searchForm.total"
                        :defaultCurrent="searchForm.pageIndex"
                        :defaultPageSize="searchForm.pageSize"
                        show-size-changer
                        show-quick-jumper
                        :show-total="(total) => `总共 ${total} 条`" />
        </div>
      </div>
    </div>
    <!-- 弹窗区域 -->
    <jywsDialog :visible="visible"
                :title="modalTitle"
                :width="visibleWidth"
                :roger="jywsDialogBottomRoger"
                :bottom-name="jywsDialogBottomName"
                @updataClose="closeModal"
                :typePrimary="typePrimary"
                @updataWin="updataWin">
      <a-spin :spinning="spinning">
        <!-- 新增特种证书检验 -->
        <div v-if="visibleType === 'addspecialPeos'">
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">年检项目</div>
              <div class="col-value">
                <a-input v-model="addForm.project"
                         placeholder="请输入年检项目" />
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label">年检内容</div>
              <div class="col-value">
                <a-input v-model="addForm.content"
                         placeholder="请对年检内容进行简要描述" />
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">所属员工</div>
              <div class="col-value">
                <selectPeo ref="selectPeo1"
                           :radioFlag="true"
                           @onSelect="onSelectPeoEqus"></selectPeo>
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">年检周期</div>
              <div class="col-value">
                <a-input-group compact>
                  <a-input v-model="addForm.cycle"
                           style="width: 150px"
                           placeholder="请输入" />
                  <a-select default-value="day"
                            v-model="addForm.cycleUnit">
                    <a-select-option value="天">
                      天
                    </a-select-option>
                    <a-select-option value="周">
                      周
                    </a-select-option>
                    <a-select-option value="月">
                      月
                    </a-select-option>
                  </a-select>
                </a-input-group>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">证书类型</div>
              <div class="col-value">
                <a-select placeholder="请选择"
                          v-model="addForm.credentialsType"
                          style="width: 200px">
                  <a-select-option value="安全证书">
                    安全证书
                  </a-select-option>
                  <a-select-option value="叉车证书">
                    叉车证书
                  </a-select-option>
                  <a-select-option value="电工证书">
                    电工证书
                  </a-select-option>
                  <a-select-option value="焊工证书">
                    焊工证书
                  </a-select-option>
                  <a-select-option value="其它证书">
                    其它证书
                  </a-select-option>
                </a-select>
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">有效期限</div>
              <div class="col-value">
                <a-date-picker style="width: 200px"
                               v-model="addForm.periodTime" />
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">提前提醒天数</div>
              <div class="col-value">
                <a-input v-model="addForm.executiveDays"
                         placeholder="请输入提前提醒天数" />
                <a-tooltip>
                  <template slot="title">
                    建议提前提醒天数大于年检所需天数，下次检验日期等于有效期限减去提前提醒天数
                  </template>
                  <a-icon type="info-circle"
                          style="
											color: #1890ff;
											margin-left: 10px;
										" />
                </a-tooltip>
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label">下次检验日期</div>
              <div class="col-value">
                <a-input disabled
                         v-model="nextReleaseTime" />
                <a-tooltip>
                  <template slot="title">
                    当下次检验日期小于当前时间时，会自动下发一条年检任务给执行人
                  </template>
                  <a-icon type="info-circle"
                          style="
											color: #1890ff;
											margin-left: 10px;
										" />
                </a-tooltip>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">执行人/组</div>
              <div class="col-value">
                <selectPeo ref="selectPeo2"
                           @onSelect="onSelectPeo"
                           :showGroup="true"
                           :radioFlag="true"></selectPeo>
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label">抄送人</div>
              <div class="col-value">
                <selectPeo ref="selectPeo3"
                           @onSelect="onSelectPeocc"
                           :radioFlag="true"></selectPeo>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label">证书号</div>
              <div class="col-value">
                <a-input v-model="addForm.credentialsCode"
                         placeholder="请输入证书号" />
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label">证书附件</div>
              <div class="col-value">
                <uploadOss @setFileList="setFileList"
                           @setFileListName="setFileListName"
                           :showList="true">
                  <a-button>
                    <a-icon type="upload" />上传附件
                  </a-button>
                </uploadOss>
              </div>
            </a-col>
          </a-row>
        </div>
        <!-- 修改特种证书检验 -->
        <div v-else-if="visibleType === 'editspecialPeos'">
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">年检项目</div>
              <div class="col-value">
                <a-input v-model="addForm.project"
                         placeholder="请输入年检项目" />
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label">年检内容</div>
              <div class="col-value">
                <a-input v-model="addForm.content"
                         placeholder="请对年检内容进行简要描述" />
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">所属人员</div>
              <div class="col-value">
                <selectPeo disabled
                           :inputName="addForm.userName"
                           :radioFlag="true"
                           @onSelect="onSelectPeoEqus"></selectPeo>
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">年检周期</div>
              <div class="col-value">
                <a-input-group compact>
                  <a-input v-model="addForm.cycle"
                           style="width: 150px"
                           placeholder="请输入" />
                  <a-select default-value="day"
                            v-model="addForm.cycleUnit">
                    <a-select-option value="天">
                      天
                    </a-select-option>
                    <a-select-option value="周">
                      周
                    </a-select-option>
                    <a-select-option value="月">
                      月
                    </a-select-option>
                  </a-select>
                </a-input-group>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">证书类型</div>
              <div class="col-value">
                <a-select placeholder="请选择"
                          v-model="addForm.credentialsType"
                          style="width: 200px">
                  <a-select-option value="安全证书">
                    安全证书
                  </a-select-option>
                  <a-select-option value="叉车证书">
                    叉车证书
                  </a-select-option>
                  <a-select-option value="电工证书">
                    电工证书
                  </a-select-option>
                  <a-select-option value="焊工证书">
                    焊工证书
                  </a-select-option>
                  <a-select-option value="其它证书">
                    其它证书
                  </a-select-option>
                </a-select>
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">执行人/组</div>
              <div class="col-value">
                <selectPeo :inputName="addForm.peoName"
                           @onSelect="onSelectPeo"
                           :showGroup="true"
                           :radioFlag="true"></selectPeo>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label">抄送人</div>
							<div class="col-value">
								<selectPeo
									:inputName="addForm.ccName"
									@onSelect="onSelectPeocc"
									:radioFlag="true"
								></selectPeo>
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label">证书号</div>
							<div class="col-value">
								<a-input
									v-model="addForm.credentialsCode"
									placeholder="请输入证书号"
								/>
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label">证书附件</div>
							<div class="col-value">
								<uploadOss
									@setFileList="setFileList"
									@setFileListName="setFileListName"
									:showList="true"
								>
									<a-button>
										<a-icon type="upload" />上传附件
									</a-button>
								</uploadOss>
								<p v-if="visibleType === 'editspecialEqus'">上次附件：<a :href="this.addForm.credentialsFile" target="_blank" >{{this.addForm.fileName}}</a></p>
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex">
						</a-col>
					</a-row>
        </div>
        <!-- 变更计划 -->
        <div v-else-if="visibleType === 'changePlan'">
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="24"
                   :push="2"
                   class="col-flex">
              <div class="col-label must">提前提醒天数</div>
              <div class="col-value">
                <a-input v-model="addForm.executiveDays"
                         placeholder="请输入提前提醒天数" />
                <a-tooltip>
                  <template slot="title">
                    建议提前提醒天数大于年检所需天数，下次检验日期等于有效期限减去提前提醒天数
                  </template>
                  <a-icon type="info-circle"
                          style="
											color: #1890ff;
											margin-left: 10px;
										" />
                </a-tooltip>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="24"
                   :push="2"
                   class="col-flex">
              <div class="col-label must">有效期限</div>
              <div class="col-value">
                <a-date-picker style="width: 200px"
                               v-model="addForm.periodTime" />
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="24"
                   :push="2"
                   class="col-flex">
              <div class="col-label must">下次检验日期</div>
              <div class="col-value">
                <a-input disabled
                         v-model="nextReleaseTime" />
                <a-tooltip>
                  <template slot="title">
                    当下次检验日期小于当前时间时，会自动下发一条年检任务给执行人
                  </template>
                  <a-icon type="info-circle"
                          style="
											color: #1890ff;
											margin-left: 10px;
										" />
                </a-tooltip>
              </div>
            </a-col>
          </a-row>
        </div>
        <!-- 详情界面 -->
        <div v-else-if="visibleType === 'infospecialPeos'">
          <div class="info-title">
            <div class="info-title-icon"></div>
            <div class="info-title-text">基本信息</div>
          </div>
          <div class="info-content">
            <a-descriptions>
              <a-descriptions-item label="年检项目">
                {{ this.infoList.project }}
              </a-descriptions-item>
              <a-descriptions-item label="年检内容">
                {{ this.infoList.content }}
              </a-descriptions-item>
              <a-descriptions-item label="年检周期">
                {{
									this.infoList.cycle +
									this.infoList.cycleUnit
								}}
              </a-descriptions-item>
              <a-descriptions-item label="所属员工">
                {{ this.infoList.userName }}
              </a-descriptions-item>
              <a-descriptions-item label="证书类型">
                {{ this.infoList.credentialsType }}
              </a-descriptions-item>
              <a-descriptions-item label="有效期限">
                {{
									$moment(this.infoList.periodTime).format(
										'YYYY-MM-DD'
									)
								}}
              </a-descriptions-item>
              <a-descriptions-item label="下次检验日期">
                {{
									$moment(
										this.infoList.nextReleaseTime
									).format('YYYY-MM-DD')
								}}
              </a-descriptions-item>
              <a-descriptions-item label="证书状态">
                <a-badge v-if="this.infoList.credentialsState === 0"
                         status="success"
                         text="正常" />
                <a-badge v-else-if="
										this.infoList.credentialsState === 2
									"
                         status="error"
                         text="超期" />
                <a-badge v-else-if="
										this.infoList.credentialsState === 1
									"
                         status="warning"
                         text="待检" />
              </a-descriptions-item>
              <a-descriptions-item label="执行人/组">
                {{
									this.infoList.execType === 0
										? this.infoList.executiveName
										: this.infoList.nowRecipientsName
								}}
              </a-descriptions-item>
              <a-descriptions-item label="抄送人">
                {{ this.infoList.ccName }}
              </a-descriptions-item>
              <a-descriptions-item label="证书号">
                {{ this.infoList.credentialsCode }}
              </a-descriptions-item>
              <a-descriptions-item label="证书附件">
                <a :href="this.infoList.credentialsFile"
                   target="_blank">{{ this.infoList.fileName }}</a>
              </a-descriptions-item>
            </a-descriptions>
          </div>
          <div class="info-title">
            <div class="info-title-icon"></div>
            <div class="info-title-text">年检记录</div>
          </div>
          <div>
            <a-table :columns="infocolumns"
                     :data-source="infodataSource"
                     :pagination="false">
              <span slot="time"
                    slot-scope="time">
                {{
									time
										? $moment(time).format(
												'YYYY-MM-DD'	  )
										: '-'
								}}
              </span>
              <span slot="executiveName"
                    slot-scope="text, record">
                {{
									record.execType === 0
										? record.executiveName
										: record.nowRecipientsName
								}}
              </span>
              <span slot="fileName" slot-scope="text, record">
								<a :href="record.file" target="_blank">{{record.fileName}}</a>
							</span>
              <span slot="result"
                    slot-scope="text, record">
                <span v-if="record.result === 0"
                      style="color:#2FC25B">合格</span>
                <span v-else-if='record.result === 1'
                      style="color:red">不合格</span>
                <span v-else>-</span>
              </span>
              <span slot="execState"
                    slot-scope="text, record">
                <a-badge v-if="record.execState === 2"
                         status="success"
                         text="已完成" />
                <a-badge v-else-if="record.execState === 0"
                         status="error"
                         text="未开始" />
                <a-badge v-else-if="record.execState === 1"
                         status="warning"
                         text="执行中" />
              </span>
            </a-table>
          </div>
        </div>
      </a-spin>
    </jywsDialog>
    <importDialogPlus :visible="importVisible"
                      :title="importTitle"
                      :columns='importcolumns'
                      :dataSource="importDataSource"
                      :url="importUrl"
                      :bottomName="importName"
                      :current="current"
                      :loading='importLoading'
                      @cancel="cancelClick"
                      @submit="submitClick" />
  </div>
</template>

<script>
import conditionQuery from '@/components/conditionQuery.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import selectPeo from '@/components/selectPeo.vue'
// import selectEqus from '@/components/selectEqus.vue'
import uploadOss from '@/components/uploadOss'
import deviceTree from '@/components/deviceTree.vue'
import importDialogPlus from '@/components/importDialogPlus.vue'
import { urlDataDeal } from '@/core/date'
import moment from 'moment'
import * as util from '@/core/util'

export default {
  components: {
    conditionQuery,
    jywsDialog,
    selectPeo,
    uploadOss,
    // selectEqus,
    deviceTree,
    importDialogPlus,
  },
  data() {
    return {
      tenantId: '',
      // 导入弹窗参数
      importVisible: false,
      importTitle: '批量导入',
      importLoading:false,
      importUrl:
        'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/%E6%A3%80%E9%AA%8C%E8%AF%81%E4%B9%A6%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
      importDataSource: [],
      importcolumns: [
        {
          title: '所属员工',
          dataIndex: 'userName',
          key: 'userName',
        },
        {
          title: '年检项目',
          dataIndex: 'project',
          key: 'project',
        },
        {
          title: '年检内容',
          dataIndex: 'content',
          key: 'content',
        },
        {
          title: '年检周期',
          dataIndex: 'cycle',
          key: 'cycle',
          scopedSlots: { customRender: 'cycle' },
        },
        {
          title: '证书类型',
          dataIndex: 'credentialsType',
          key: 'credentialsType',
        },
        {
          title: '有效期限',
          dataIndex: 'periodTime',
          key: 'periodTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '提前提醒天数',
          dataIndex: 'executiveDays',
          key: 'executiveDays',
        },
        {
          title: '证书号',
          dataIndex: 'credentialsCode',
          key: 'credentialsCode',
        },
        {
          title: '执行人',
          dataIndex: 'executiveName',
          key: 'executiveName',
          scopedSlots: { customRender: 'executiveName' },
        },
        {
          title: '抄送人',
          dataIndex: 'ccName',
          key: 'ccName',
          scopedSlots: { customRender: 'ccName' },
        },
      ],
      current: 0,
      // 弹窗参数
      visible: false,
      visibleType: '',
      modalTitle: '',
      visibleWidth: '960px',
      jywsDialogBottomRoger: '取消',
      jywsDialogBottomName: '确认',
      typePrimary: 'primary',
      spinning: false,
      infoList: {},
      // 新增接口传参
      addForm: {
        project: '',
        content: '',
        cycle: '',
        cycleUnit: '天',
        credentialsType: undefined,
        periodTime: '',
        executiveDays: '',
        nextReleaseTime: '',
        credentialsCode: '',
        credentialsFile: '',
        fileName: '',
        executive: '',
        executiveName: '',
        nowRecipients: '',
        nowRecipientsName: '',
        execType: 0,
        cc: '',
        ccName: '',
        userId: '',
        userName: '',
        type: 1,
        peoName: '',
        organizationId: '',
        organizationName: '',
      },
      editForm: {},
      infodataSource: [],
      // 详情记录表头
      infocolumns: [
        {
          title: '年检项目',
          dataIndex: 'project',
          key: 'project',
        },
        {
          title: '完成时间',
          dataIndex: 'finishTime',
          key: 'finishTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '有效期限',
          dataIndex: 'periodTime',
          key: 'periodTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '执行人/组',
          dataIndex: 'executiveName',
          key: 'executiveName',
          scopedSlots: { customRender: 'executiveName' },
        },
        {
          title: '任务状态',
          dataIndex: 'execState',
          key: 'execState',
          scopedSlots: { customRender: 'execState' },
        },
        {
          title: '检验结果',
          dataIndex: 'result',
          key: 'result',
          scopedSlots: { customRender: 'result' },
        },
        {
          title: '证书号',
          dataIndex: 'credentialsCode',
          key: 'credentialsCode',
        },
        {
          title: '证书附件',
          dataIndex: 'fileName',
          key: 'fileName',
          scopedSlots: { customRender: 'fileName' }
        },
      ],
      // 表格表头
      columns: [
        {
          title: '年检项目',
          dataIndex: 'project',
          key: 'project',
        },
        {
          title: '证书类型',
          dataIndex: 'credentialsType',
          key: 'credentialsType',
        },
        {
          title: '所属员工',
          dataIndex: 'userName',
          key: 'userName',
        },
        {
          title: '有效期限',
          dataIndex: 'periodTime',
          key: 'periodTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '年检周期',
          dataIndex: 'cycle',
          key: 'cycle',
          scopedSlots: { customRender: 'cycle' },
        },
        {
          title: '执行人/组',
          dataIndex: 'executiveName',
          key: 'executiveName',
          scopedSlots: { customRender: 'executiveName' },
        },
        {
          title: '下次检验日期',
          dataIndex: 'nextReleaseTime',
          key: 'nextReleaseTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '证书状态',
          dataIndex: 'credentialsState',
          key: 'credentialsState',
          scopedSlots: { customRender: 'credentialsState' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          key: 'cz',
        },
      ],
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      headData: [
        {
          key: 2,
          name: '年检项目',
          component: 'a-input',
          value: '',
          duplicate: 'project',
          placeholder: '请输入年检项目',
        },
        {
          key: 1,
          name: '证书类型',
          component: 'a-select',
          value: undefined,
          duplicate: 'credentialsType',
          placeholder: '请选择',
          provinceData: [
            {
              name: '安全证书',
              value: '安全证书',
            },
            {
              name: '叉车证书',
              value: '叉车证书',
            },
            {
              name: '电工证书',
              value: '电工证书',
            },
            {
              name: '焊工证书',
              value: '焊工证书',
            },
            {
              name: '其它证书',
              value: '其它证书',
            },
          ],
        },
        {
          key: 2,
          name: '执行人/组',
          component: 'a-input',
          value: '',
          duplicate: 'executor',
          placeholder: '请输入执行人/组',
        },
        {
          key: 1,
          name: '证书状态',
          component: 'a-select',
          value: undefined,
          duplicate: 'credentialsState',
          placeholder: '请选择',
          provinceData: [
            {
              name: '待检',
              value: 1,
            },
            {
              name: '正常',
              value: 0,
            },
            {
              name: '超期',
              value: 2,
            },
          ],
        },
      ],
      // 查询特种证书列表参数
      searchForm: {
        project: '',
        credentialsState: '',
        credentialsType: '',
        executor: '',
        pageIndex: 1,
        pageSize: 10,
        total: 10,
        type: 1,
        id: '',
        isDept: false,
        tenantId: '',
        objectName:''
      },
      oneTreeVisble: true,
      deviceTreeList: [],
      importName: '下一步',
      loading: false,
      userName:''
    }
  },
  created() {
    var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
    this.userName = decodeURIComponent(str[3].split('&')[0])
    this.searchForm.tenantId = str[1].split('&')[0]
    this.initTree()
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
      }
    },
    nextReleaseTime() {
      let timeText = ''
      if (this.addForm.periodTime && this.addForm.executiveDays) {
        timeText = moment(this.addForm.periodTime)
          .subtract(this.addForm.executiveDays, 'days')
          .format('YYYY-MM-DD')
      }
      return timeText
    },
  },
  mounted() {
    this.getspecialPeos()
  },
  methods: {
    // 展开证书树
    expandTree(item) {
      this.dynamicLoadingTree(item)
    },
    // 刷新
    reflush() {
      this.initTree()
    },
    searchTreeBtn(val){
			console.log(val);
			if(val.length === 0){
				this.searchForm.id = ''
				this.searchForm.objectName = ''
			}else {
				this.searchForm.id = val[0].c_id
				this.searchForm.objectName = val[0].c_name
			}
		},
    // 选中证书树的回调
    treeSelect(val, data) {
      if (val.length !== 0) {
        this.searchForm.id = data.id
        if (data.type === 'dep') {
          this.searchForm.isDept = true
        } else {
          this.searchForm.isDept = false
          this.searchForm.objectName = data.detailName
        }
      } else {
        this.searchForm.id = ''
        this.searchForm.objectName = ''
        this.searchForm.isDept = false
      }
      this.getspecialPeos()
    },
    // 导出
    exportExcel() {
      let dataset = {
        type: 1,
        tenantId: this.tenantId,
        project: this.searchForm.project,
        credentialsState: this.searchForm.credentialsState,
        credentialsType: this.searchForm.credentialsType,
        executor: this.searchForm.executor,
      }
      let urldata = urlDataDeal(dataset)
      let downUrl = util.getBaseURL() + 'inspectPlan/planExport?' + urldata
      window.open(downUrl)
    },
    // 证书树主级
    initTree() {
      let dataset = {
        tenantId: this.tenantId,
        id: '',
      }
      this.oneTreeVisble = false
      util.postform('departmentNew/queryLazyTrees', dataset).then((res) => {
        this.oneTreeVisble = true
        let data = res.data.pubTreeInfos
        data.forEach((element) => {
          element.key = element.id
          element.title = element.name
          if (element.isDep < 1 && element.isEqu < 1) {
            element.isLeaf = true
          } else {
            element.isLeaf = false
          }
          if (element.type === 'dep') {
            element.scopedSlots = { icon: 'smile' }
          } else {
            element.scopedSlots = { icon: 'custom' }
          }
        })
        this.deviceTreeList = data
      })
    },
    // 证书树子集
    dynamicLoadingTree(item) {
      let dataset = {
        id: item.id,
        // modeType: 4,
        tenantId: this.tenantId,
        special: '1',
        type: 'user',
      }
      util
        .post('pubTree/dynamicLoadingTree', dataset)
        .then((res) => {
          let data = res.dataset
          data.forEach((element) => {
            element.key = element.c_id
            element.title = element.name
            if (element.type === 'user') {
              element.isLeaf = true
            }
            if (element.type === 'dep') {
              element.scopedSlots = { icon: 'smile' }
            } else {
              element.scopedSlots = {
                icon: 'custom',
                selected: element.componentFlag > 0 ? true : false,
              }
            }
          })
          item.children = data
          this.deviceTreeList = [...this.deviceTreeList]
          this.$forceUpdate()
        })
        .catch((err) => {
          this.$message.error(err.response.data.message)
        })
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false
      
      if (this.visibleType === 'addspecialPeos') {
        this.$refs.selectPeo1.clearName()
        this.$refs.selectPeo2.clearName()
        this.$refs.selectPeo3.clearName()
      }
      this.addForm = {
        project: '',
        content: '',
        cycle: '',
        cycleUnit: '天',
        credentialsType: undefined,
        periodTime: '',
        executiveDays: '',
        nextReleaseTime: '',
        credentialsCode: '',
        credentialsFile: '',
        fileName: '',
        executive: '',
        executiveName: '',
        nowRecipients: '',
        nowRecipientsName: '',
        execType: 0,
        cc: '',
        ccName: '',
        objectId: '',
        objectName: '',
        type: 1,
        peoName: '',
      }
      this.selectedRowKeys = []
      this.selectedRows = []
      this.getspecialPeos()
    },
    // 弹窗确认按钮
    updataWin() {
      console.log(this.visibleType)
      if (this.visibleType === 'addspecialPeos') {
        this.addspecialPeosBtn()
      } else if (this.visibleType === 'editspecialPeos') {
        this.editspecialPeosBtn()
      } else if (this.visibleType === 'changePlan') {
        this.changePlanBtn()
      } else if (this.visibleType === 'infospecialPeos') {
        this.closeModal()
      }
    },
    // 获取特种证书列表
    getspecialPeos() {
      this.loading = true
      this.searchForm.tenantId = this.tenantId
      util.postform('inspectPlan/getPageList', this.searchForm).then((res) => {
        let temp = res.data.records
        temp.forEach((item) => {
          item.key = item.id
        })
        this.loading = false
        this.dataSource = temp
        this.total = res.data.total
      })
    },
    // 翻页
    pageonChange(page) {
      this.searchForm.pageIndex = page
      this.getspecialPeos()
    },
    // 页数size改变
    showSizeChange(key, size) {
      this.searchForm.pageSize = size
      this.getspecialPeos()
    },
    // 新增特种证书检验
    addspecialPeosBtn() {
      if(this.addForm.project ===''){
				this.$message.error('年检项目不能为空！')
				return
			}
			if(this.addForm.userId ===''){
				this.$message.error('所属员工不能为空！')
				return
			}
			if(this.addForm.cycle ===''){
				this.$message.error('年检周期不能为空！')
				return
			}
			if(this.addForm.credentialsType ==='' || !this.addForm.credentialsType){
				this.$message.error('证书类型不能为空！')
				return
			}
			if(this.addForm.periodTime ===''){
				this.$message.error('有效期限不能为空！')
				return
			}
			if(this.addForm.executiveDays ===''){
				this.$message.error('提前提醒天数不能为空！')
				return
			}
			if(this.addForm.nowRecipients ==='' && this.addForm.executive ==='') {
				this.$message.error('执行人/组不能为空！')
				return
			}
      this.addForm.tenantId = this.tenantId
      this.addForm.creater = this.userName
      this.addForm.nextReleaseTime = moment(this.addForm.periodTime)
        .subtract(this.addForm.executiveDays, 'days')
        .format('YYYY-MM-DD')
      util.postform('inspectPlan/add', this.addForm).then((res) => {
        if (res.code == 200) {
          this.$message.success('新增成功！')
          this.closeModal()
        }
      })
    },
    // 修改特种证书检验
    editspecialPeosBtn() {
      if(this.addForm.project ===''){
				this.$message.error('年检项目不能为空！')
				return
			}
			if(this.addForm.userId ===''){
				this.$message.error('所属员工不能为空！')
				return
			}
			if(this.addForm.cycle ===''){
				this.$message.error('年检周期不能为空！')
				return
			}
			if(this.addForm.credentialsType ==='' || !this.addForm.credentialsType){
				this.$message.error('证书类型不能为空！')
				return
			}
			if(this.addForm.periodTime ===''){
				this.$message.error('有效期限不能为空！')
				return
			}
			if(this.addForm.executiveDays ===''){
				this.$message.error('提前提醒天数不能为空！')
				return
			}
			if(this.addForm.nowRecipients ==='' && this.addForm.executive ==='') {
				this.$message.error('执行人/组不能为空！')
				return
			}
      let dataset = {
        tenantId: this.tenantId,
        project: this.addForm.project,
        content: this.addForm.content,
        cycle: this.addForm.cycle,
        cycleUnit: this.addForm.cycleUnit,
        ccName: this.addForm.ccName,
        cc: this.addForm.cc,
        credentialsType: this.addForm.credentialsType,
        userId: this.addForm.userId,
        userName: this.addForm.userName,
        id: this.addForm.id,
        execType: this.addForm.execType,
        nowRecipients: this.addForm.nowRecipients,
        nowRecipientsName: this.addForm.nowRecipientsName,
        executive: this.addForm.executive,
        executiveName: this.addForm.executiveName,
        creater:this.userName
      }
      util.postform('inspectPlan/update', dataset).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.$message.success('修改成功！')
          this.closeModal()
        }
      })
    },
    // 变更下发计划
    changePlanBtn() {
      if(this.addForm.periodTime ===''){
				this.$message.error('有效期限不能为空！')
				return
			}
			if(this.addForm.executiveDays ===''){
				this.$message.error('提前提醒天数不能为空！')
				return
			}
      let dataset = {
        beforeDay: this.addForm.executiveDays,
        nextInspectTime: moment(this.addForm.periodTime)
          .subtract(this.addForm.executiveDays, 'days')
          .format('YYYY-MM-DD'),
        periodTime: this.addForm.periodTime,
				creater:this.userName,
        id: this.selectedRows[0].id,
      }
      util.postform('inspectPlan/changePlan', dataset).then((res) => {
        if (res.code == 200) {
          this.$message.success('变更成功！')
          this.closeModal()
        }
      })
    },
    // 删除
    delBtn() {
      let that = this
      if (this.selectedRowKeys.length === 0) {
        this.$info({
          title: '提示',
          content: '请选择需要删除的记录！',
          onOk() {},
        })
        return
      }
      this.$confirm({
        title: '删除提示！',
        content:'删除后将不可恢复，是否删除选中记录？',
        onOk() {
          util
            .postform('inspectPlan/removeBetch', that.selectedRowKeys)
            .then((res) => {
              if (res.code === 200) {
                that.$message.success('删除成功！')
                that.selectedRowKeys = []
                that.getspecialPeos()
              }
            })
        },
        onCancel() {},
      })
    },
    // 功能按钮操作
    onbtnGroup(e) {
      let name = e.target.name
      if (name === 'editspecialPeos') {
        if (this.selectedRows.length !== 1) {
          this.$warning({
            title: '请选择一条记录进行修改操作！',
          })
          return
        }
        let temp = this.selectedRows[0]
        this.addForm = temp
        if (temp.groupId) {
          this.addForm.peoName = temp.groupName
        } else {
          this.addForm.peoName = temp.executiveName
        }
      } else if (name == 'changePlan') {
        if (this.selectedRows.length !== 1) {
          this.$warning({
            title: '请选择一条记录进行修改操作！',
          })
          return
        }
      } else if (name == 'importExecl') {
				if (this.searchForm.id === '' || this.searchForm.isDept) {
					this.$warning({
						title: '请选中人员进行批量导入！',
					})
					return
				}
			}
      this.showModal(e)
    },
    // 选择执行人/组
    onSelectPeo(val) {
      if (val.obj.length === 0) {
        return
      } else {
        if (val.obj[0].id) {
          this.addForm.execType = 1
          this.addForm.nowRecipients = val.obj[0].id
          this.addForm.nowRecipientsName = val.obj[0].groupName
        } else {
          this.addForm.execType = 0
          this.addForm.executive = val.obj[0].c_id
          this.addForm.executiveName = val.obj[0].c_name
        }
      }
    },
    // 选择抄送人
    onSelectPeocc(val) {
      if (val.obj.length === 0) {
        return
      } else {
        this.addForm.cc = val.obj[0].c_id
        this.addForm.ccName = val.obj[0].c_name
      }
    },
    // 选择证书
    onSelectPeoEqus(val) {
      console.log(val)
      if (val.obj.length === 0) {
        return
      } else {
        this.addForm.userId = val.obj[0].c_id
        this.addForm.userName = val.obj[0].c_simple_name
        this.addForm.organizationId = val.obj[0].c_organization_id
        this.addForm.organizationName = val.obj[0].c_organization_name
      }
    },
    // 上传附件 -- 获取oss地址
    setFileList(val) {
      this.addForm.credentialsFile = val
    },
    setFileListName(val) {
      this.addForm.fileName = val
    },
    showModal(e) {
      let name = e.target.name
      this.visibleType = name
      this.jywsDialogBottomRoger = '取消'
      this.typePrimary = 'primary'
      this.jywsDialogBottomName = '确认'
      switch (name) {
        case 'addspecialPeos':
          this.modalTitle = '新增'
          this.visible = true
          this.visibleWidth = '960px'
          break
        case 'editspecialPeos':
          this.modalTitle = '修改'
          this.visible = true
          this.visibleWidth = '960px'
          break
        case 'infospecialPeos':
          this.modalTitle = '详情'
          this.visible = true
          this.visibleWidth = '960px'
          break
        case 'changePlan':
          this.modalTitle = '变更下发计划'
          this.visible = true
          this.visibleWidth = '560px'
          break
        case 'importExecl':
          this.importVisible = true
          break
        default:
          break
      }
    },
    cancelClick() {
      this.importVisible = false
      this.importTitle = ''
      this.current = 0
    },
    submitClick(file) {
      if (this.current == 0) {
        const fd = new FormData()
        fd.append('tenantId', this.tenantId)
        fd.append('file', file[0])
        fd.append('type', 1)
        this.importLoading = true
        util
          .postformData('inspectPlan/importEqu', fd)
          .then((res) => {
            if (res.code == 200) {
              res.data.forEach(item=>{
								item.userId = this.searchForm.id
								item.userName = this.searchForm.objectName
							})
              this.importDataSource = res.data
            } else {
              this.$message.error(res.data.message)
            }
            this.importLoading = false
          })
          .catch((err) => {
            this.$message.error(err.response.data.message)
            return
          })
        // fd.append('creater', this.userId)
      } else if (this.current == 1) {
        let flag = true
				this.importDataSource.forEach(item=>{
					if(item.executiveName === '' || !item.executiveName){
						flag = false
					}
				})
				if(!flag){
					this.$message.error('执行人不能为空！')
					return
				}
        util
          .postform('inspectPlan/saveBetchAPI', this.importDataSource)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success('导入成功')
            }
          })
        this.importName = '确认'
      }
      this.current++
    },
    // 查看详情
    openinfospecialPeos(val) {
      util.postform('inspect/getInspectByPlanIdList/' + val.id).then((res) => {
        this.infodataSource = res.data
        this.infoList = val
        this.typePrimary = ''
        this.jywsDialogBottomName = '关闭'
        this.jywsDialogBottomRoger = ''
        this.modalTitle = '详情'
        this.visibleType = 'infospecialPeos'
        this.visible = true
        this.visibleWidth = '960px'
      })

      console.log(val)
    },

    // 下拉框选中
    onHandleChange(item) {
      if (item.duplicate == 'credentialsType') {
        this.searchForm.credentialsType = item.value
      } else if (item.duplicate == 'credentialsState') {
        this.searchForm.credentialsState = item.value
      }
    },
    // 输入框
    onInputChange(item) {
      if (item.duplicate == 'project') {
        this.searchForm.project = item.value
      } else if (item.duplicate == 'executor') {
        this.searchForm.executor = item.value
      }
    },
  },
}
</script>

<style scoped lang="less">
.specialPeos {
  background-color: #f0f2f5;
  width: 100%;
  height: 100%;
  // display: flex;
  // justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}
.info-title {
  display: flex;
  height: 40px;
  align-items: center;
  .info-title-icon {
    width: 4px;
    height: 14px;
    background: #1890ff;
  }
  .info-title-text {
    margin-left: 8px;
    font-size: 16px;
    color: #333333;
  }
}
.specialPeos-title {
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  color: #333333;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.infospecialPeos-title {
  font-size: 24px;
  color: #333333;
  text-align: left;
}
.infospecialPeos-smtitle {
  display: flex;
  color: #999999;
  font-size: 12px;
  text-align: left;
  margin-top: 16px;
}
.infospecialPeos-content {
  margin-top: 24px;
}
.mg-l-5 {
  margin-left: 5px;
}
.mg-l-50 {
  margin-left: 50px;
}
.title-icon {
  width: 4px;
  height: 16px;
  background: #1890ff;
  display: inline-block;
  margin-right: 12px;
}
.col-value {
  width: 200px;
}
.specialPeos-content {
  background: #ffffff;
  width: 100%;
  height: calc(100% - 56px);
  display: flex;
  padding-top: 32px;
}
.flush {
  font-size: 14px;
  color: #1890ff;
}
.content-right-btnGroup {
  height: 56px;
  width: 100%;
  line-height: 56px;
  margin-top: 10px;
}
.content-right-form {
  // width: 100%;
  background: #ffffff;
  padding: 0px 21px 0px 17px;
  .form-search {
    display: flex;
    align-items: center;
    width: 420px;
  }
}
.form-label {
  font-size: 14px;
  color: #333333;
  width: 70px;
  text-align: right;
  margin-right: 16px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-left {
  width: 220px;
  height: calc(100% - 30px);
  background: #ffffff;
  border: 1px solid #e8e8e8;
  margin-left: 12px;
  overflow: auto;
  .content-left-title {
    height: 48px;
    width: 100%;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-left-btnGroup {
    height: 48px;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
  }
}
.content-right {
  height: calc(100% - 30px);
  width: calc(100% - 283px);
  margin-left: 27px;
}
.content-right-message {
  margin-top: 20px;
}
</style>
