<template>
	<div class="specialEqus">
		<div class="specialEqus-title">
			<span class="title-icon"> </span>
			<span>设备台账</span>
		</div>
		<a-spin style="height: calc(100% - 56px)" :spinning="spinning">
			<div class="specialEqus-content">
				<div class="content-left">
					<!-- 左侧标题 -->
					<div class="content-left-title">
						<div>组织结构</div>
						<div class="flush">
							<a href="javascript:;" @click="reflush">
								<a-icon type="sync" style="margin-right: 6px" />刷新
							</a>
						</div>
					</div>
					<!-- 左侧树区域 -->
					<div class="content-left-tree">
						<unitsTree v-if="oneTreeVisble" :treeData='deviceTreeList' @treeSelect='treeSelect'></unitsTree>
					</div>
				</div>
				<div class="content-right">
					<!-- 查询区域 -->
					<div class="content-right-form">
						<conditionQuery
							:headData="headData"
							:exportState="true"
							@onHandleChange="onHandleChange"
							@onTimeChange="onTimeChange"
							@onInputChange="onInputChange"
							@exportExcel="exportExcel"
							@onSelectClick="getEqusList"
						/>
					</div>
					<!-- 功能按钮区域 -->
					<div class="content-right-btnGroup">

					</div>
					<!-- 表格区域 -->
					<div class="content-right-table">
						<a-table
							:columns="columns"
							:loading="loading"
							:row-selection="rowSelection"
							:data-source="dataSource"
							:transformCellText="({ text, column, record, index }) => text || '-'"
							:pagination="false"
							:scroll="{ x: 1300, y: 380 }"
						>
							<span slot="time" slot-scope="time">
								{{
									time
										? $moment(time).format('YYYY-MM-DD')
										: '-'
								}}
							</span>
							<span slot="special" slot-scope="text">
								<a-badge
									v-if="text === '1'"
									color="green"
									text="是"
								/>
								<a-badge
									v-else
									color="#B2B2B2"
									text="否"
								/>
							</span>
							<span slot="flag" slot-scope="text">
								<a-badge
									v-if="text === 'true'"
									color="green"
									text="是"
								/>
								<a-badge
									v-else
									color="red"
									text="否"
								/>
							</span>
							<span slot="status" slot-scope="text">
								<a-badge
									v-if="text === 1"
									color="green"
									text="正投运"
								/>
								<a-badge
									v-else-if="text === 2"
									color="orange"
									text="已闲置"
								/>
								<a-badge
									v-else-if="text === 3"
									color="orange"
									text="即将报废"
								/>
								<a-badge
									v-else-if="text === 4"
									color="red"
									text="已报废"
								/>
								<a-badge
									v-else-if="text === 5"
									color="orange"
									text="已封存"
								/>
								<a-badge
									v-else-if="text === 6"
									color="red"
									text="已转让"
								/>
							</span>
							<span slot="gatewayStatus" slot-scope="text">
								<a-badge
									v-if="text === 1"
									color="green"
									text="已接入"
								/>
								<a-badge
									v-else
									color="red"
									text="未接入"
								/>
							</span>
							<span slot="action" slot-scope="scope">
								<a
									@click="openinfospecialEqus(scope)"
									name="infospecialEqus"
									>查看</a
								>
							</span>
						</a-table>
						<a-pagination
							:pageSizeOptions="['10', '50', '100', '200', '1000']"
							@change="pageonChange"
							@showSizeChange="showSizeChange"
							size="small"
							:total="searchForm.total"
							:defaultCurrent="searchForm.pageIndex"
							:defaultPageSize="searchForm.pageSize"
							show-size-changer
							show-quick-jumper
							:show-total="(total) => `总共 ${total} 条`"
						/>
					</div>
				</div>
			</div>
		</a-spin>
		<!-- 导出 -->
		<jywsDialog
			:visible="excelVisble"
			title="导出纪录"
			width="960px"
			roger="取消"
			bottom-name="确定"
			typePrimary="primary"
			@updataClose="closeModelExcel"
			@updataWin="equipExport"
		>
			<a-select
				mode="multiple"
				:default-value="['id', 'simpleName', 'standard', 'brand', 'assetsCode', 'organizationName', 'equpmentStyle', 'name', 'startTime', 'enterprise', 'orginalValue', 'produceTime', 'produceCode', 'status']"
				style="width: 100%"
				placeholder="请选择导出项"
				@change="handleChangeSelect"
			>
				<a-select-option key="id">id</a-select-option>
				<a-select-option key="simpleName">设备简称</a-select-option>
				<a-select-option key="standard">型号规格</a-select-option>
				<a-select-option key="brand">品牌</a-select-option>
				<a-select-option key="assetsCode">资产编码</a-select-option>
				<a-select-option key="organizationName">组织名称</a-select-option>
				<a-select-option key="equpmentStyle">设备类型</a-select-option>
				<a-select-option key="name">设备名称</a-select-option>
				<a-select-option key="startTime">投运时间</a-select-option>
				<a-select-option key="enterprise">生产商</a-select-option>
				<a-select-option key="orginalValue">原值</a-select-option>
				<a-select-option key="produceTime">出厂时间</a-select-option>
				<a-select-option key="produceCode">出厂编码</a-select-option>
				<a-select-option key="status">状态</a-select-option>
			</a-select>
    </jywsDialog>
		<!-- 详情弹窗 -->
		<equipmentOverview v-if="detailVisible" :id="detailId" :tenantId="tenantId" :visible="detailVisible" @updataClose="closeModal"></equipmentOverview>
	</div>
</template>

<script>
import conditionQuery from '@/components/conditionQuery.vue'
import unitsTree from '@/components/unitsTree.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import equipmentOverview from '@/components/equipmentOverview.vue'
import moment from 'moment'
import * as util from '@/core/util'
import { exportFile } from '@/core/date'
export default {
	name: 'EquipmentBookAccount',
	components: {
		conditionQuery,
		unitsTree,
		jywsDialog,
		equipmentOverview
	},
	data() {
		return {
			tenantId: '',
			// 弹窗参数
			detailId: '',
			detailVisible: false,
			spinning: false,
			// 导出
			excelVisble: false,
			// 表格表头
			columns: [
				{
					title: '设备简称',
					dataIndex: 'simpleName',
					key: 'simpleName',
					width: 150,
					fixed: 'left'
				},
				{
					title: '型号规格',
					dataIndex: 'standard',
					key: 'standard',
					width: 150,
					fixed: 'left'
				},
				{
					title: '所属组织',
					dataIndex: 'organizationName',
					key: 'organizationName',
					width: 150,
				},
				{
					title: '资产编码',
					dataIndex: 'assetsCode',
					key: 'assetsCode',
					width: 180,
				},
				{
					title: '品牌',
					dataIndex: 'brand',
					key: 'brand',
          width: 150
				},
				{
					title: '设备类型',
					dataIndex: 'equpmentStyle',
					key: 'equpmentStyle',
					width: 150
				},
				{
					title: '投运日期',
					dataIndex: 'startTime',
					key: 'startTime',
					width: 150,
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '特种设备',
					dataIndex: 'special',
					key: 'special',
					width: 150,
					scopedSlots: { customRender: 'special' },
				},
				{
					title: '启用状态',
					dataIndex: 'flag',
					key: 'flag',
					width: 150,
					scopedSlots: { customRender: 'flag' },
				},
				{
					title: '投运状态',
					dataIndex: 'status',
					key: 'status',
					width: 150,
					scopedSlots: { customRender: 'status' },
				},
				{
					title: '物联网',
					dataIndex: 'gatewayStatus',
					key: 'gatewayStatus',
					width: 150,
					scopedSlots: { customRender: 'gatewayStatus' },
				},
				{
					title: '备注',
					dataIndex: 'remark',
					key: 'remark',
					width: 150,
				},
				{
					title: '安装位置',
					dataIndex: 'position',
					key: 'position',
					width: 150,
				},
				{
					title: 'SN号',
					dataIndex: 'sn',
					key: 'sn',
					width: 150,
				},
				{
					title: '操作',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
					fixed: 'right',
					width: 120,
				},
			],
			dataSource: [],
			selectedRowKeys: [],
			selectedRows: [],
			headData: [
				{
					key: 1,
					name: '设备信息',
					component: 'a-input',
					value: '',
					duplicate: 'message',
					placeholder: '资产编码/简称/型号规格',
				},
        {
					key: 2,
					name: '设备类型',
					component: 'a-input',
					value: '',
					duplicate: 'type',
					placeholder: '请输入设备类型',
				},
        {
					key: 3,
					name: '投运日期',
					component: 'a-range-picker',
					value: [],
					duplicate: 'time',
					placeholder: '',
				},
				{
					key: 4,
					name: '特种设备',
					component: 'a-select',
					value: undefined,
					duplicate: 'special',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: '',
						},
						{
							name: '是',
							value: 1,
						},
						{
							name: '否',
							value: 0,
						}
					],
				},
        {
					key: 5,
					name: '启用',
					component: 'a-select',
					value: undefined,
					duplicate: 'flag',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: '',
						},
						{
							name: '是',
							value: 'true',
						},
						{
							name: '否',
							value: 'false',
						}
					],
				},
        {
					key: 6,
					name: '安装位置',
					component: 'a-input',
					value: '',
					duplicate: 'location',
					placeholder: '请输入安装位置',
				},
			],
			// 查询特种设备列表参数
			searchForm: {
				pageIndex: 1,
				pageSize: 10,
				searchValue: '', // 设备信息
				treeValue: [], // 组织
				beginTime: '',
				endTime: '',
				special: '',
				flag: '',
				position: '',
				total: 10,
				tenantId: '',
			},
			// 导出数据
			listIds: [{ name: 'id', key: 'id' },
			{ name: '设备简称', key: 'simpleName' },
			{ name: '型号规格', key: 'standard' },
			{ name: '品牌', key: 'brand' },
			{ name: '资产编码', key: 'assetsCode' },
			{ name: '组织名称', key: 'organizationName' },
			{ name: '设备类型', key: 'equpmentStyle' },
			{ name: '设备名称', key: 'name' },
			{ name: '投运时间', key: 'startTime' },
			{ name: '生产商', key: 'enterprise' },
			{ name: '原值', key: 'orginalValue' },
			{ name: '折旧值', key: 'depreciationRate' },
			{ name: '折旧年限', key: 'depreciableLife' },
			{ name: '备注', key: 'remark' },
			{ name: '出厂时间', key: 'produceTime' },
			{ name: '出厂编码', key: 'produceCode' },
			{ name: '状态', key: 'status' },],
			nameIds: ['id', '设备简称', '型号规格', '品牌', '资产编码', '所在组织', '设备类型', '设备名称', '投运时间', '生产商', '原值', '出厂时间', '出厂编码', '状态',],
			keyIds: ['id', 'simpleName', 'standard', 'brand', 'assetsCode', 'organizationName', 'equpmentStyle', 'name', 'startTime', 'enterprise', 'orginalValue', 'produceTime', 'produceCode', 'status',],
			oneTreeVisble: true,
			deviceTreeList: [],
			importName: '下一步',
			loading:false,
			tableKey: 1,
			accountItem: {}, //资产台帐数据
			imgVisble: false,
			imgSrc: [],
			itemSrcIndex: 0,
			itemSrc: ''
		}
	},
	created() {
		var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
		this.searchForm.tenantId = str[1].split('&')[0]
		this.initTree()
		this.getEqusList()
	},
	computed: {
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys = selectedRowKeys
					this.selectedRows = selectedRows
				},
			}
		},
	},
	mounted() {
	},
	methods: {
		// 刷新
		reflush() {
			this.searchForm = {
				pageIndex: 1,
				pageSize: 10,
				searchValue: '', // 设备信息
				treeValue: [], // 组织
				beginTime: '',
				endTime: '',
				special: '',
				flag: '',
				position: '',
				total: 10,
				tenantId: '',
			}
			this.initTree()
			this.getEqusList()
		},
		searchTreeBtn(val){
			console.log(val);
			if(val.length === 0){
				this.searchForm.id = ''
				this.searchForm.objectName = ''
			}else {
				this.searchForm.id = val[0].c_id
				this.searchForm.objectName = val[0].c_name
			}
		},
		// 选中设备树的回调
		treeSelect(val, data) {
			if (val.length > 0) {
				this.searchForm.treeValue = this.treeMapTree([data])
			} else {
				this.searchForm.treeValue = []
			}
			this.getEqusList()
		},
		// 选中设备树递归
    treeMapTree(data) {
			let arr = []
      data.forEach(items => {    //遍历树 拼入相应的disabled
        arr.push(items.id)
        if (items.children && items.children.length > 0) {
          const temp = this.treeMapTree(items.children)
					if (temp.length) {
						arr.push(...temp)
					}
        }
      })
			return arr
    },
		// 组织树
		initTree() {
			let dataset = {
				tenantId: this.tenantId,
				id: '',
			}
			this.oneTreeVisble = false
			util.postform('departmentNew/queryTree', dataset).then(
				(res) => {
					this.oneTreeVisble = true
					let data = res.data
          data.forEach(element => {
              element.key = element.id;
              element.title = element.name;
              element.value = element.code;
              element.children = element.departmentList || []
              if (element.children && element.children.length > 0) {
                element.scopedSlots = { icon: 'house', selected: element.children.length > 0 ? true : false }
                this.mapTree(element.children)
              } else {
                element.scopedSlots = { icon: 'house' }
              }
          });
          this.deviceTreeList = data
				}
			)
		},
    // 部件树递归
    mapTree(data) {
      data.forEach(items => {    //遍历树 拼入相应的disabled
        items.key = items.id;
        items.title = items.name;
        items.value = items.code;
        items.children = items.departmentList || []
        if (items.children && items.children.length > 0) {
          items.scopedSlots = { icon: 'house', selected: items.children.length > 0 ? true : false }
          this.mapTree(items.children)
        } else {
          items.scopedSlots = { icon: 'house' }
        }
      })
    },
		// 获取设备列表
    getEqusList() {
      let dataset = {
        pageIndex: this.searchForm.pageIndex,
        pageSize: this.searchForm.pageSize,
        tenantId: this.tenantId,
        name: this.searchForm.searchValue,
				standard: this.searchForm.standard,
        orgs: this.searchForm.treeValue,
				beginTime: this.searchForm.beginTime ? moment(this.searchForm.beginTime).format('YYYYMMDD') : '',
				endTime: this.searchForm.endTime ? moment(this.searchForm.endTime).format('YYYYMMDD') : '',
        special: this.searchForm.special, // 有无特种设备
				flag: this.searchForm.flag,
				position: this.searchForm.position
      }
      util.postform('ZNequipment/queryEquipmentList ', dataset).then((res) => {
        let data = res.data.records
        if (data.length > 0) {
          data.forEach((element) => {
            element.key = element.c_id
          })
        }
        this.dataSource = data
        this.searchForm.total = res.data.total
      })
    },
		// 导出
		equipExport() {
			this.closeModelExcel()
			let map = {}
			this.keyIds.forEach((item, index) => {
				map[item] = this.nameIds[index]
			})
			let dataset = {
				tenantId: this.tenantId,
				equpmentStyle: this.searchForm.standard,
				startTime: this.searchForm.beginTime ? moment(this.searchForm.beginTime).format('YYYYMMDD') : '',
				endTime: this.searchForm.endTime ? moment(this.searchForm.endTime).format('YYYYMMDD') : '',
				simpleName: this.searchForm.searchValue,
				flag: this.searchForm.flag,
				special: this.searchForm.special,
				map: map,
				organizationId: this.searchForm.treeValue
			}
			let downUrl = util.getBaseURL() + 'equipment/excelExport'
			this.spinning = true
			exportFile(downUrl, dataset).then(res => {
				console.log(res)
				this.spinning = false
      })
		},
		// 打开导出弹窗
		exportExcel() {
			this.excelVisble = true
		},
		//关闭导出弹窗
		closeModelExcel() {
			this.excelVisble = false
		},
		// 导出选中项
		handleChangeSelect(e) {
			let namelist = []
			let keylist = []
			this.listIds.forEach(ele => {
				e.forEach(ele2 => {
					if (ele.key == ele2) {
						namelist.push(ele.name)
						keylist.push(ele.key)
					}
				})
			});
			this.nameIds = namelist
			this.keyIds = keylist
			console.log(this.state.nameIds, this.state.keyIds)
		},
		// 翻页
		pageonChange(page) {
			this.searchForm.pageIndex = page
			this.getEqusList()
		},
		// 页数size改变
		showSizeChange(key, size) {
			this.searchForm.pageSize = size
			this.getEqusList()
		},
		showModal(e) {
			let name = e.target.name
			this.visibleType = name
			this.jywsDialogBottomRoger = '取消'
			this.typePrimary = 'primary'
			this.jywsDialogBottomName = '确认'
			switch (name) {
				case 'addspecialEqus':
					this.modalTitle = '新增'
					this.visible = true
					this.visibleWidth = '960px'
					break
				default:
					break
			}
		},
		// 日期选择框
		onTimeChange(val) {
			this.searchForm.beginTime = val.dateString[0]
      this.searchForm.endTime = val.dateString[1]
		},
		// 下拉框选中
		onHandleChange(item) {
			if (item.duplicate == 'special') {
				this.searchForm.special = item.value
			} else if (item.duplicate == 'flag') {
				this.searchForm.flag = item.value
			}
		},
		// 输入框
		onInputChange(item) {
			if (item.duplicate == 'message') {
				this.searchForm.searchValue = item.value
			} else if (item.duplicate == 'type') {
				this.searchForm.standard = item.value
			} else if (item.duplicate == 'location') {
				this.searchForm.position = item.value
			}
		},
		// 查看详情
		openinfospecialEqus(val) {
			console.log(val)
			this.detailId = val.id
			this.detailVisible = true
		},
		closeModal() {
			this.detailVisible = false
		}
	},
}
</script>

<style scoped lang="less">
/deep/ .ant-spin-container {
	height: 100%;
}
.specialEqus {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	// display: flex;
	// justify-content: space-between;
	padding: 20px;
	box-sizing: border-box;
	overflow: auto;
}
.specialEqus-title {
	width: 100%;
	height: 56px;
	background: #ffffff;
	border-radius: 4px 4px 0px 0px;
	color: #333333;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.title-icon {
	width: 4px;
	height: 16px;
	background: #1890ff;
	display: inline-block;
	margin-right: 12px;
}
.col-value {
	width: 200px;
}

.specialEqus-content {
	background: #ffffff;
	width: 100%;
	height: 100%;
	display: flex;
	padding-top: 32px;
}
.flush {
	font-size: 14px;
	color: #1890ff;
}
.content-right-btnGroup {
	height: 56px;
	width: 100%;
	line-height: 56px;
	margin-top: 10px;
}
.content-right-form {
	// width: 100%;
	background: #ffffff;
	padding: 0px 21px 0px 17px;
	.form-search {
		display: flex;
		align-items: center;
		width: 420px;
	}
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.content-left {
	width: 220px;
	height: calc(100% - 30px);
	background: #ffffff;
	border: 1px solid #e8e8e8;
	margin-left: 12px;
	overflow: auto;
	.content-left-title {
		height: 48px;
		width: 100%;
		padding: 0 16px 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #333333;
		font-size: 16px;
		border-bottom: 1px solid #e8e8e8;
	}
	.content-left-btnGroup {
		height: 48px;
		width: 100%;
		border-bottom: 1px solid #e8e8e8;
	}
}
.content-right {
	height: calc(100% - 30px);
	width: calc(100% - 283px);
	margin-left: 27px;
}
.content-right-message {
	margin-top: 20px;
}
</style>
