<template>
  <div class="selectEqus">
    <a-input v-model="inputName"
             placeholder="请选择"
             @click="showDialog"
             allow-clear
             @change="InputonChange" />
    <jywsDialog :visible="visible"
                :title="modalTitle"
                @updataClose="closeModal"
                @updataWin="updataWin"
                width="960px"
                :roger="jywsDialogBottomRoger"
                :bottom-name="jywsDialogBottomName">
      <div class="selectEqus-modal">
        <div>
          <div class="selectEqus-search">
            <a-row>
              <a-col :span="10" class="col-flex">
                <span class="marginR15">组织</span>
                <a-tree-select
                  v-model="treeValue"
                  style="width: 75%"
                  :tree-data="treeData"
                  :replaceFields='replaceFields'
                  tree-checkable
                  :show-checked-strategy="SHOW_PARENT"
                  placeholder="默认选择全部设备"
                />
              </a-col>
              <a-col :span="6" class="col-flex">
                <span class="marginR15">特种设备</span>
                <a-select v-model="specialEquipment" style="width: 40%">
                  <a-select-option value="">
                    全部
                  </a-select-option>
                  <a-select-option :value="1">
                    是
                  </a-select-option>
                  <a-select-option :value="0">
                    否
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="8" class="col-flex">
                <a-input-search :placeholder="placeholder"
                v-model="searchValue"
                enter-button="搜索"
                @search="onSearch" />
              </a-col>
            </a-row>
            <a-row v-if="generalState != '通用'" style="margin-top: 10px;">
              <a-col :span="10" class="col-flex">
                <span class="marginR15">类型</span>
                <a-select v-model="taskState" placeholder="请选择计划类型" style="width: 75%">
                  <a-select-option value="">
                    全部
                  </a-select-option>
                  <template v-if="generalState == '点检'">
                    <a-select-option value="day">
                      日点检
                    </a-select-option>
                    <a-select-option value="specialty">
                      周点检
                    </a-select-option>
                    <a-select-option value="precision">
                      定期点检
                    </a-select-option>
                    <a-select-option value="planned">
                      计划点检
                    </a-select-option>
                  </template>
                  <template v-if="generalState == '保养'">
                    <a-select-option value="B1">
                      一级保养
                    </a-select-option>
                    <a-select-option value="B2">
                      二级保养
                    </a-select-option>
                    <a-select-option value="B3">
                      日常保养
                    </a-select-option>
                  </template>
                </a-select>
              </a-col>
              <a-col :span="6" class="col-flex">
                <template>
                  <span class="marginR15">有无标准</span>
                  <a-select v-model="isNorm" style="width: 40%">
                    <a-select-option :value="1">
                      是
                    </a-select-option>
                    <a-select-option :value="0">
                      否
                    </a-select-option>
                  </a-select>
                </template>
              </a-col>
              <a-col :span="8" class="col-flex">
              </a-col>
            </a-row>
          </div>
          <div class="selectEqus-table">
            <a-table class="borderTable"
                     :columns="mode === 1 ? columns : columns2"
                     :scroll="{ y: 500 }"
                     :row-selection="rowSelection"
                     :data-source="dataSource"
                     :pagination="false">
              <span slot="time"
                    slot-scope="time">
                {{ $moment(time).format('YYYY-MM-DD HH:mm') }}
              </span>
              <span slot="c_special"
                    slot-scope="text, record">
                {{ record.c_special === '1' ? '是' : '否' }}
              </span>
              <span slot="isNorm"
                    slot-scope="text, record">
                {{ record.isNorm === '1' ? '是' : '否' }}
              </span>
            </a-table>
            <a-pagination :pageSizeOptions="['100', '1000', '2500', '5000', '10000']"
                          @change="onChange"
                          @showSizeChange="showSizeChange"
                          size="small"
                          :total="total"
                          :defaultCurrent="pageIndex"
                          :defaultPageSize="pageSize"
                          show-size-changer
                          show-quick-jumper
                          :show-total="(total) => `总共 ${total} 条`" />
          </div>
        </div>
      </div>
    </jywsDialog>
  </div>
</template>

<script>
import jywsDialog from '@/components/jywsDialog.vue'
import * as util from '@/core/util'
import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
  name: 'selectEqus',
  components: {
    jywsDialog,
  },
  props: {
    modalTitle: {
      type: String,
      default: '组织/设备'
    },
    generalState: {
      type: String,
      default: '通用'
    },
    mode: {
      type: Number,
      default: 1 //1.设备 0.区域
    },
    replaceFields: {
      type: Object,
      default: () => {
				return {
          children:'departmentList',
          title:'name',
          key:'id',
          value: 'id'
        }
      }
    },
    radioFlag: {
      type: Boolean,
      default: false,
    },
    inputName: {
      type: String,
      default: '',
    },
    special: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      // 组织
      SHOW_PARENT: SHOW_PARENT,
      treeValue: [],
      treeData: [],
      // 是否特种设备
      specialEquipment: '',
      // 点检类型
      taskState: '',
      // 有无标准
      isNorm: 1,
      tenantId: '',
      visible: false,
      jywsDialogBottomRoger: '取消',
      jywsDialogBottomName: '确认',
      pageIndex: 1,
      pageSize: 100,
      total: 100,
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      activeKey: '1',
      searchValue: '',
      placeholder: this.mode ? '请输入设备简称/型号/资产编码' : '请输入区域简称/编码',
      // 表格表头
      columns: [
        {
          title: '所属组织',
          dataIndex: 'depName',
          key: 'depName',
          width: 150,
          ellipsis: true,
        },
        {
          title: '设备简称',
          dataIndex: 'c_simple_name',
          key: 'c_simple_name',
          width: 150,
          ellipsis: true,
        },
        {
          title: '型号',
          dataIndex: 'c_standard',
          key: 'c_standard',
          width: 150,
          ellipsis: true,
        },
        {
          title: '特种设备',
          dataIndex: 'c_special',
          key: 'c_special',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'c_special' },
        },
        {
          title: '资产编码',
          dataIndex: 'c_assets_code',
          key: 'c_assets_code',
          width: 150,
          ellipsis: true,
        },
      ],
      columns2: [
        {
          title: '所属组织',
          dataIndex: 'depName',
          key: 'depName',
          width: 150,
          ellipsis: true,
        },
        {
          title: '简称',
          dataIndex: 'c_simple_name',
          key: 'c_simple_name',
          width: 150,
          ellipsis: true,
        },
        {
          title: '资产编码',
          dataIndex: 'c_assets_code',
          key: 'c_assets_code',
          width: 150,
          ellipsis: true,
        },
        {
          title: '有无标准',
          dataIndex: 'isNorm',
          key: 'isNorm',
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: 'isNorm' },
        },
      ]
    }
  },
  created() {
    var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
  },
  mounted() {
    this.search()
    this.getEqusList()
  },
  computed: {
    rowSelection() {
      return {
        type: this.radioFlag ? 'radio' : 'checkbox',
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    onSearch() {
      this.getEqusList()
    },
    // 翻页
    onChange(page) {
      this.pageIndex = page
      this.getEqusList()
    },
    // 页数size改变
    showSizeChange(key, size) {
      console.log(key)
      this.pageSize = size
      this.getEqusList()
    },
    // 获取组织列表
    search() {
      let dataset = {
        tenantId: this.tenantId
      }
      util.postform('departmentNew/queryTree', dataset).then((res) => {
        if(res.code == 200) {
            this.treeData = res.data
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 获取设备列表
    getEqusList() {
      let dataset = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        tenantId: this.tenantId,
        orgs: this.treeValue,
        name: this.searchValue,
        things: '', // 有无物联
        special: this.specialEquipment, // 有无特种设备
        type: this.taskState, // 点检类型
        mode: this.mode
      }
      if (this.taskState) {
        dataset.isNorm = this.isNorm // 有无标准
      }
      util.postform('ZNequipment/queryEquPubList', dataset).then((res) => {
        let data = res.data.records
        if (data.length > 0) {
          data.forEach((element) => {
            element.key = element.c_id
          })
        }
        this.dataSource = data
        this.total = res.data.total
      })
    },
    showDialog() {
      this.visible = true
    },
    closeModal() {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.searchValue = ''
      this.visible = false
    },
    clearName() {
      this.inputName = ''
    },
    // 清空选中
    InputonChange(val) {
      if (val) {
        this.selectedRowKeys = []
        this.selectedRows = []
        this.$emit('onSelect', {
          key: this.selectedRowKeys,
          obj: this.selectedRows,
        })
      }
    },
    updataWin() {
      if (this.selectedRows.length !== 0) {
        this.inputName = this.selectedRows[0].c_simple_name
        this.$emit('onSelect', {
          key: this.selectedRowKeys,
          obj: this.selectedRows,
        })
      }
      this.closeModal()
    },
  },
}
</script>

<style scoped lang="less">
.selectEqus-search {
  width: 100%;
  text-align: right;
}
.selectEqus-table {
  margin-top: 20px;
}
.borderTable {
  margin-bottom: 20px;
}
.selectEqus-modal {
  .ant-pagination {
    margin-top: 0px !important;
  }
}
.marginR15 {
  margin-right: 15px;
}
</style>
