<!--
 * @Author: your name
 * @Date: 2022-04-22 14:10:47
 * @LastEditTime: 2022-12-14 15:14:40
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/detailComponent/dangerDetails.vue
-->
<template>
  <div class="dangerDetails">
    <jywsDialog
			:visible="visible"
			title="隐患处置详情"
			width="960px"
			roger=""
			bottom-name="关闭"
			typePrimary="primary"
			@updataClose="closeModal"
			@updataWin="updataWin"
		>
      <div class="dangerDetails-content">
        <div class="flex-right-account-left-content-table">
          <div class="flex-right-account-left-content-table-item borderb1">
            <div class="table-item-left borderr1">设备简称：</div>
            <div class="table-item-right borderr1">{{ serviceData.objectName || '-' }}</div>
            <div class="table-item-left borderr1">所属组织：</div>
            <div class="table-item-right">{{ serviceData.organizationName || '-' }}</div>
          </div>
          <div class="flex-right-account-left-content-table-item borderb1">
            <div class="table-item-left borderr1">所属类型：</div>
            <div class="table-item-right borderr1">{{ serviceData.type | type(serviceData.type) }}</div>
            <div class="table-item-left borderr1">执行单元：</div>
            <div class="table-item-right">{{ serviceData.element || '-' }}</div>
          </div>
          <div class="flex-right-account-left-content-table-item borderb1">
            <div class="table-item-left borderr1">执行项目：</div>
            <div class="table-item-right borderr1">{{ serviceData.project || '-' }}</div>
            <div class="table-item-left borderr1">执行内容：</div>
            <div class="table-item-right">{{ serviceData.content || '-' }}</div>
          </div>
          <div class="flex-right-account-left-content-table-item">
            <div class="table-item-left borderr1">执行方法：</div>
            <div class="table-item-right borderr1">{{ serviceData.method || '-' }}</div>
            <div class="table-item-left borderr1">执行标准：</div>
            <div class="table-item-right">{{ serviceData.standard || '-' }}</div>
          </div>
        </div>
        <div class="paddingb16 flex-title">
          <div class="blueLine"></div>
          <div class="flex-title-name">发现隐患</div>
        </div>
        <div class="flex-right-account-left-content-table">
          <div class="flex-right-account-left-content-table-item borderb1">
            <div class="table-item-left borderr1">发现时间：</div>
            <div class="table-item-right borderr1">{{ serviceData.createTime ? $moment(serviceData.createTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}</div>
            <div class="table-item-left borderr1">发现人：</div>
            <div class="table-item-right">{{ serviceData.foundUserName || '-' }}</div>
          </div>
          <div class="flex-right-account-left-content-table-item">
            <div class="table-item-left borderr1">隐患描述：</div>
            <div style="display: flex;align-items: center;" class="table-item-right borderr1">
              {{ serviceData.describe || '-' }}
              <img v-if="serviceData.describeImg" style="width: 17px;height: 17px;" src="@/assets/icon_image.png" @click="queryImg(serviceData.describeImg)">
            </div>
            <div class="table-item-left borderr1">处置方式：</div>
            <div class="table-item-right">
              {{ serviceData.feedbackMesFlag == 'feedBack' ? '隐患反馈' : serviceData.feedbackMesFlag == 'feedBackEnd' ? '隐患反馈已处置': serviceData.feedbackMesFlag == 'selfDeal' ? '自行处置' : '-' }}
            </div>
          </div>
          </div>
          <div class="paddingb16 flex-title">
          <div class="blueLine"></div>
          <div class="flex-title-name">处置隐患</div>
          </div>
          <div v-if="serviceData.feedbackMesFlag == 'selfDeal'" class="flex-right-account-left-content-table">
            <div class="flex-right-account-left-content-table-item borderb1">
              <div class="table-item-left borderr1">处置时间：</div>
              <div class="table-item-right borderr1">{{ serviceData.disposalTime ? $moment(serviceData.disposalTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}</div>
              <div class="table-item-left borderr1">处置人：</div>
              <div class="table-item-right">{{ serviceData.disposalUserName || '-' }}</div>
            </div>
            <div class="flex-right-account-left-content-table-item">
              <div class="table-item-left borderr1">处置结果描述：</div>
              <div class="table-item-right borderr1">{{ serviceData.disposalDescribe || '-' }}</div>
              <div class="table-item-left borderr1">处置照片：</div>
              <div class="table-item-right">
                <span v-if="serviceData.disposalImg" style="color: #1890FF;cursor: pointer;" @click="queryImg(serviceData.disposalImg)">查看</span>
                <span v-else>-</span>
              </div>
            </div>
          </div>
          <div v-else-if="serviceData.feedbackMesFlag == 'feedBack' || serviceData.feedbackMesFlag == 'feedBackEnd'" class="flex-right-account-left-content-table">
          <div class="flex-right-account-left-content-table-item borderb1">
            <div class="table-item-left borderr1">处置时间：</div>
            <div class="table-item-right borderr1">{{ serviceData.disposalTime ? $moment(serviceData.disposalTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}</div>
            <div class="table-item-left borderr1">处置人：</div>
            <div class="table-item-right">{{ serviceData.disposalUserName || '-' }}</div>
          </div>
          <div class="flex-right-account-left-content-table-item">
            <div class="table-item-left borderr1">处置结果描述：</div>
            <div class="table-item-right borderr1">
              <span v-if="serviceData.code">
                工单号：
                <span style="color: #1890FF;" >{{ serviceData.code }}</span>
              </span>
              <span v-else>{{ serviceData.feedbackMesFlag == 'feedBackEnd' ? '工单拒绝' : '未处置' }}</span>
            </div>
            <div class="table-item-left borderr1">处置照片：</div>
            <div class="table-item-right">
              <span v-if="serviceData.disposalImg" style="color: #1890FF;cursor: pointer;" @click="queryImg(serviceData.disposalImg)">查看</span>
              <span v-else>-</span>
            </div>
          </div>
        </div>
      </div>
    </jywsDialog>
    <imgDialog title="查看图片" bottomName="关闭" :visible="imgVisble" :imgSrc="imgSrc" :itemSrcIndex="itemSrcIndex" :itemSrc="itemSrc" @selecedImgClick="selecedImgClick" @updataWin="close"></imgDialog>
  </div>
</template>

<script>
import jywsDialog from '@/components/jywsDialog.vue'
import imgDialog from '@/components/imgDialog.vue'
import * as util from '@/core/util'
export default {
  name: 'DangerDetails',
  props: {
    // 所用组件
    id: {
      type: String,
      default: ''
    },
    tenantId: {
      type: String,
      default: ''
    },
    visible: {
			type: Boolean,
			default: false,
		},
  },
  components: {
		jywsDialog,
    imgDialog
	},
  filters: {
    type(text) {
			if (text == 'day') {
        return '日点检'
      } else if (text == 'specialty') {
        return '周点检'
      } else if (text == 'precision') {
        return '定期点检'
      } else if (text == 'planned') {
        return '计划点检'
      } else if (text == 'B1') {
        return '一级保养'
      } else if (text == 'B2') {
        return '二级保养'
      } else if (text == 'B3') {
        return '日常保养'
      } else if (text == 'project') {
        return '安全巡检'
      } else {
        return '-'
      }
    },
    repairRank(text) {
			if (text === 1) {
        return '停机故障维修'
      } else if (text === 2) {
        return '不停机维修'
      } else if (text === 3) {
        return '设备不良反馈'
      } else {
        return '-'
      }
		},
    state(text) {
      if (text == 'first') {
        return '待执行'
      } else if (text == 'dealing') {
        return '执行中'
      } else if (text == 'audit') {
        return '审核中'
      } else if (text == 'again') {
        return '审核不通过'
      } else if (text == 'evaluation') {
        return '评价中'
      } else if (text == 'evaluationnot') {
        return '评价不通过'
      } else if (text == 'end') {
        return '执行完成'
      } else if (text == 'refuse') {
        return '已拒绝'
      } else if (text == 'pause') {
        return '维修暂停'
      } else if (text == 'pausepart') {
        return '维修待备件'
      } else {
        return '-'
      }
    }
  },
  data() {
    return {
      serviceData: {},
      imgVisble: false,
      imgSrc: [],
      itemSrcIndex: 0,
      itemSrc: '',
      columns: [
				{
					title: '备件编码',
					dataIndex: 'code',
					key: 'code',
					width: 200,
				},
				{
					title: '备件名称',
					dataIndex: 'name',
					key: 'name',
					width: 200,
				},
				{
					title: '型号',
					dataIndex: 'version',
					key: 'version',
					width: 130,
				},
				{
					title: '规格',
					dataIndex: 'norms',
					key: 'norms',
					width: 130,
				},
				{
					title: '品牌',
					dataIndex: 'brand',
					key: 'brand',
					width: 130,
				},
				{
					title: '消耗数',
					dataIndex: 'num',
					key: 'num',
				}
			],
      dataSource: [],
      fillHistory: [],
      evaluateHistory: []
    }
  },
  created() {
    this.queryForHiddenRroubleIdInfo()
  },
  mounted() {},
  methods: {
    // 隐患处置详情
    queryForHiddenRroubleIdInfo() {
      const dataset = {
        id: this.id,
        tenantId: this.tenantId
      }
      util.postform('ZNequipment/queryForHiddenRroubleIdInfo', dataset).then(res => {
        console.log(res)
        this.serviceData = res.data
      }).catch(err => {
        console.error(err)
        this.$message.error(err.message)
      })
    },
    queryImg(img, index) {
			this.imgVisble = true
			this.imgSrc = (img && img.split(',')) || []
			this.itemSrcIndex = index ? index : 0
			this.itemSrc = (img && img.split(',')[0]) || ''
		},
    selecedImgClick(key) {
			this.itemSrcIndex = key
			this.itemSrc = this.imgSrc[key]
		},
		close() {
			this.imgVisble = false
		},
    closeModal() {
      this.$emit('closeModal')
    },
    updataWin() {
      this.$emit('closeModal')
    }
  },
}
</script>

<style scoped lang="less">
.dangerDetails-content {
  max-height: 582px;
  overflow: auto;
}
.flex-right-account-left-content-table {
	border: 1px solid #E8E8E8;
	margin-bottom: 16px;
}
.flex-right-account-left-content-table-item {
	min-height: 40px;
	display: flex;
}
.table-item-left {
	flex: 1;
	height: 100%;
	padding: 10px 12px;
	text-align: right;
	background: #FAFAFA;
}
.table-item-right {
	flex: 2;
	height: 100%;
	padding: 10px 12px;
	text-align: left;
	color: #666666;
  overflow: hidden; //溢出隐藏
  text-overflow:ellipsis; //超出显示省略号
  white-space: nowrap;//禁止自动换行
}
.flex-right-account-right {
	width: 202px;
	flex-shrink: 0;
}
.border1 {
	border: 1px solid #E8E8E8;
}
.borderr1 {
	border-right: 1px solid #E8E8E8;
}
.borderb1 {
	border-bottom: 1px solid #E8E8E8;
}
.blueLine {
  width: 3px;
  height: 16px;
  background: #1890FF;
}
.flex-title {
  display: flex;
  align-items: center
}
.flex-title-name {
  margin-left: 10px;
}
.paddingb16 {
  padding-bottom: 16px;
}
</style>
