<template>
	<a-spin style="height: 100%" :spinning="spinning">
		<div class="specialEqus">
			<div class="specialEqus-title">
				<span class="title-icon"> </span>
				<span>{{ homeTitle }}</span>
			</div>
			<div class="specialEqus-content">
				<div class="content-left">
					<div :style="{ border: '1px solid #d9d9d9' }">
							<a-calendar :fullscreen="false" @select="selectChange" @panelChange="onPanelChange">
							<template slot="dateCellRender" slot-scope="value" class="events">
									<div style="width: 58%;border-bottom: 1px solid #F87500;position: relative;top: -14px;margin: auto;" v-for="(item, index) in getListData(value)" :key="index" />
							</template>
						</a-calendar>
						</div>
						<div class="content-left-turiel">
						<div class="marginb20">图例说明：</div>
						<div class="turiel-flex">
							<div class="turiel-flex-item marginb8">
								<div class="turiel-flex-item-left cambridgeBlue">12</div>
								<div>有计划</div>
							</div>
							<div class="turiel-flex-item marginb8">
								<div class="turiel-flex-item-left whiteBlue">12</div>
								<div>当前日期</div>
							</div>
							<div class="turiel-flex-item marginb8">
								<div class="turiel-flex-item-left whiteBlack">12</div>
								<div>无计划</div>
							</div>
							<div class="turiel-flex-item marginb8">
								<div class="turiel-flex-item-left blueWhite">12</div>
								<div>选中日期</div>
							</div>
							<div class="turiel-flex-item">
								<div class="turiel-flex-item-left cambridgeWhite">12</div>
								<div>非查询月份</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content-right">
					<!-- 查询区域 -->
					<div class="content-right-form">
						<conditionQuery
							:headData="headData"
							:exportState="false"
							@checkboxOnChange="checkboxOnChange"
							@onselectEqus="onselectEqus"
							@onSelectPeo="onSelectPeo"
							@onSelectClick="onSelectClick"
						/>
					</div>
					<!-- 功能按钮区域 -->
					<div class="content-right-btnGroup">
						<a-button-group>
							<a-button @click="onbtnGroup" name="add">新的计划</a-button>
							<a-button @click="onbtnGroup" name="amendmentExecutor">修改执行人</a-button>
							<a-button @click="onbtnGroup" name="infoDetails">修改执行项</a-button>
							<a-button @click="onbtnGroup" name="updateDelivery">修改计划截止日期</a-button>
							<a-button name="deleteBtn" @click="delBtn">删除计划</a-button>
							<a-button name="exportExcel" @click="exportExcel">导出计划</a-button>
						</a-button-group>
					</div>
					<!-- 表格区域 -->
					<div class="content-right-table">
						<a-table
							:columns="columns"
							:loading="loading"
							:row-selection="rowSelection"
							:data-source="dataSource"
							:pagination="false"
							:scroll="{ y: 380 }"
						>
							<span slot="nextTime2" slot-scope="text, record">
								{{ record | computingTime(record) }}
							</span>
							<span slot="startTime" slot-scope="text">
								{{ text | timeTransition(text) }}
							</span>
							<span slot="type" slot-scope="text">
								{{ text | type(text) }}
							</span>
							<span slot="cycle" slot-scope="text, record">
								{{ text }}{{ record.unit | unit(record.unit) }}
							</span>
							<span slot="createTime" slot-scope="text">
								{{ text | timeTransition(text) }}
							</span>
							<span slot="action" slot-scope="scope">
								<span style="color: #1890FF;">
									{{ scope.items }}
									|
									<a @click="openDetails(scope)">详情</a>
								</span>
							</span>
						</a-table>
						<a-pagination
							@change="pageonChange"
							@showSizeChange="showSizeChange"
							size="small"
							:total="total"
							:defaultCurrent="searchForm.pageIndex"
							:defaultPageSize="searchForm.pageSize"
							show-size-changer
							show-quick-jumper
							:show-total="(total) => `总共 ${total} 条`"
						/>
					</div>
				</div>
			</div>
			<!-- 弹窗区域 -->
			<jywsDialog
				:visible="visible"
				:title="modalTitle"
				:width="visibleWidth"
				:roger="jywsDialogBottomRoger"
				:bottom-name="jywsDialogBottomName"
				@updataClose="closeModal"
				:typePrimary="typePrimary"
				@updataWin="updataWin"
			>
				<a-spin :spinning="spinning">
					<!-- 新的计划 -->
					<div v-if="visibleType === 'add'" style="width: 80%;margin: auto;">
					<a-steps progress-dot :current="steps">
						<a-step title="步骤1" description="选择设备、执行人和下发日期" />
						<a-step title="步骤2" description="为已选设备选择计划项" />
						<a-step title="步骤3" description="派发成功" />
					</a-steps>
					<template v-if="steps === 0">
						<div class="mission-content">
							<a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label must">执行人/工作组</div>
									<div class="col-value">
										<selectPeo :inputName="addForm.recipientName" :radioFlag="true" :showGroup="true" @onSelect="addSelectPeo($event, '1')" ></selectPeo>
									</div>
								</a-col>
							</a-row>
							<a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label">评价人</div>
									<div class="col-value">
										<selectPeo :inputName="addForm.appraiserName" :radioFlag="true" :showGroup="false" @onSelect="addSelectPeo($event, '2')" ></selectPeo>
									</div>
								</a-col>
							</a-row>
							<a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label must">首次任务截止日期</div>
									<div class="col-value">
										<a-date-picker style="width: 100%" :value="addForm.firstpushTime" :disabled-date="disabledDate" placeholder='请选择' @change="produceTimeChange" />
									</div>
								</a-col>
							</a-row>
							<a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label must">计划类型</div>
									<div class="col-value">
										<a-select style="width: 100%" :default-value="addForm.selectType" @change="selectTypeChange($event, 'selectType')">
											<a-select-option v-for="(item2, index2) in optionsType" :key="index2" :value="item2.value">{{ item2.label }}</a-select-option>
										</a-select>
									</div>
								</a-col>
							</a-row>
							<a-row v-if="addForm.selectType == 'planned' || addForm.selectType == 'precision' || addForm.selectType == 'B1' || addForm.selectType == 'B2' || addForm.selectType == 'project'" class="margint12">
								<a-col class="col-flex">
									<div class="col-label must">提前下发天数</div>
									<div class="col-value">
										<a-input-number style="width: 100%" v-model="addForm.remindDay" :min="0" @change="inputChange($event, 'remindDay')" />
									</div>
								</a-col>
							</a-row>
							<a-row v-if="addForm.selectType == 'planned' || addForm.selectType == 'precision' || addForm.selectType == 'B1' || addForm.selectType == 'B2'  || addForm.selectType == 'project'" class="margint12">
								<a-col class="col-flex">
									<div class="col-label">首次任务下发日期</div>
									<div class="col-value">
										<a-input style="width: 100%" disabled v-model="addForm.firstIssueDate" />
									</div>
								</a-col>
							</a-row>
							<a-row v-if="addForm.selectType == 'planned' || addForm.selectType == 'precision' || addForm.selectType == 'B1' || addForm.selectType == 'B2'  || addForm.selectType == 'project'" class="margint12">
								<a-col class="col-flex">
									<div class="col-label must">周期</div>
									<div class="col-value">
										<a-input-number style="width: 80%" v-model="addForm.m_cycle" :min="0" @change="inputChange($event, 'm_cycle')" />
										<a-select style="width: 20%" v-model="addForm.m_unit">
											<a-select-option value="day">天</a-select-option>
											<a-select-option value="week">周</a-select-option>
											<a-select-option value="month">月</a-select-option>
										</a-select>
									</div>
								</a-col>
							</a-row>
							<a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label must">选择{{ modeSelect === 1 ? '设备' : '区域'}}</div>
									<div class="col-value">
										<selectEqus :inputName="addForm.devicename" :radioFlag="false" :mode="modeSelect" :modalTitle="modeSelect ? '组织/设备' : '组织/区域'" :generalState="homeTitle == '点检计划' ? '点检' : homeTitle == '保养计划' ? '保养' : '通用'" @onSelect="addSelectEqus" ></selectEqus>
									</div>
								</a-col>
							</a-row>
							<div class="margint12" style="color: #B2B2B2">
									<span>已选{{ modeSelect === 1 ? '设备' : '区域'}}:</span><span style="white-space: normal;">{{ addForm.devicename }}</span>
							</div>
						</div>
					</template>
					<template v-if="steps === 1">
						<!-- 表格区域 -->
						<div style="margin-top: 18px;position: relative;width: 913px;left: -91px;" class="content-right-table">
							<a-table
								:columns="sonColumns"
								:loading="sonLoading"
								:data-source="selectPlanningDate"
								:pagination="false"
								:scroll="{ y: 380 }"
							>
								<span slot="pitchName">
									<a-checkbox :defaultChecked="true" @change="sonCheckAllChange" />
								</span>
								<span slot="pitch" slot-scope="text, record">
									<a-checkbox :checked="text" :value="record.id" @change="sonOnCheckAllChange" />
								</span>
								<span slot="startTime" slot-scope="text">
									{{ text | timeTransition(text) }}
								</span>
								<span slot="type" slot-scope="text">
									{{ text | type(text) }}
								</span>
								<span slot="cycle" slot-scope="text, record">
									{{ text }}{{ record.unit | unit(record.unit) }}
								</span>
								<span slot="createTime" slot-scope="text">
									{{ text | timeTransition(text) }}
								</span>
								<span slot="action" slot-scope="scope">
									<span style="color: #1890FF;">
										{{ scope.items }}
										|
										<a @click="openDetails(scope)" name="infoDetails">查看</a>
									</span>
								</span>
								<a-table
									slot="expandedRowRender"
									slot-scope="record"
									:columns="innerColumns"
									:data-source="record.standardList"
									:pagination="false"
								>
								<span slot="sonPitch" slot-scope="text, record">
									<a-checkbox :checked="text" :value="record.c_id" @change="sonOnAloneChange" />
								</span>
								<span slot="c_way" slot-scope="text">
									{{ text | isSF(text) }}
								</span>
								<span slot="c_photo" slot-scope="text">
									{{ text | getRowString(text) }}
								</span>
								</a-table>
							</a-table>
						</div>
					</template>
					<template v-if="steps === 2">
						<div class="successMessage">
							<a-icon style="font-size: 72px;color: #2FC25B;" type="check-circle" theme="filled" />
              <div class="successMessage-title">计划点检任务制作成功</div>
              <div class="successMessage-subTitle">计划点检任务制作成功，在首次任务下发的日期，执行人APP会收到任务通知消息；<br />后续任务下发的日期均是上次任务下发日期+作业周期。</div>
            </div>
					</template>
					</div>
					<!-- 变更执行人 -->
					<div v-if="visibleType === 'amendmentExecutor'">
					<div style="margin-top: 0px;" class="mission-content">
							<a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label must">执行人/工作组</div>
									<div class="col-value">
										<selectPeo :inputName="addForm.recipientName" :radioFlag="true" :showGroup="true" @onSelect="addSelectPeo($event, '1')" ></selectPeo>
									</div>
								</a-col>
							</a-row>
							<a-row class="margint12">
								<a-col class="col-flex">
									<div class="col-label">评价人</div>
									<div class="col-value">
										<selectPeo :inputName="addForm.appraiserName" :radioFlag="true" :showGroup="false" @onSelect="addSelectPeo($event, '2')" ></selectPeo>
									</div>
								</a-col>
							</a-row>
					</div>
					</div>
					<!-- 修改执行项 -->
					<div v-if="visibleType === 'infoDetails'">
						<div class="info-table">
							<div class="info-table-flex">
								<div class="info-table-flex-item">
									<div class="info-table-flex-item-l">设备信息</div>
									<div class="info-table-flex-item-r">{{ infoList.objectName }}</div>
								</div>
								<div class="info-table-flex-item">
									<div class="info-table-flex-item-l">计划类型</div>
									<div style="border-right: 0px;" class="info-table-flex-item-r">{{ infoList.type | type(infoList.type) }}</div>
								</div>
							</div>
							<div style="border-top: 1px solid #f0f0f0;" class="info-table-flex">
								<div class="info-table-flex-item">
									<div class="info-table-flex-item-l">计划开始日期</div>
									<div class="info-table-flex-item-r">{{ infoList | computingTime(infoList) }}</div>
								</div>
								<div class="info-table-flex-item">
									<div class="info-table-flex-item-l">任务周期</div>
									<div style="border-right: 0px;" class="info-table-flex-item-r">{{ infoList.cycle }} {{ infoList.unit | getRowString(infoList.c_unit) }}</div>
								</div>
							</div>
							<div style="border-top: 1px solid #f0f0f0;" class="info-table-flex">
								<div class="info-table-flex-item">
									<div class="info-table-flex-item-l">计划截止日期</div>
									<div class="info-table-flex-item-r">{{ infoList.startTime | timeTransition(infoList.startTime) }}</div>
								</div>
								<div class="info-table-flex-item">
									<div class="info-table-flex-item-l">提前下发天数</div>
									<div style="border-right: 0px;" class="info-table-flex-item-r">{{ infoList.remindDay || '0' }}天</div>
								</div>
							</div>
							<div style="border-top: 1px solid #f0f0f0;" class="info-table-flex">
								<div class="info-table-flex-item">
									<div class="info-table-flex-item-l">执行人/工作组</div>
									<div class="info-table-flex-item-r">{{ infoList.startUserName }}</div>
								</div>
								<div class="info-table-flex-item">
									<div class="info-table-flex-item-l">评价人</div>
									<div style="border-right: 0px;" class="info-table-flex-item-r">{{ infoList.assessUserName || '-' }}</div>
								</div>
							</div>
						</div>
						<div class="content-right-btnGroup">
							<a-button-group>
								<a-button @click="onbtnGroup" name="addStrandOne">修改执行项</a-button>
								<a-button @click="onbtnGroup" name="addStandard">新增作业标准</a-button>
							</a-button-group>
						</div>
						<!-- 表格区域 -->
						<div class="content-right-table">
							<a-table
								:columns="detailColumns"
								:loading="detailLoading"
								:row-selection="rowSelection3"
								:data-source="strandardDataSource"
								:pagination="false"
								:scroll="{ y: 380 }"
							>
								<span slot="c_way" slot-scope="text">
									{{ text | isSF(text) }}
								</span>
								<span slot="c_max_num" slot-scope="text">
									{{ text ? text : 0 }}
								</span>
								<span slot="c_min_num" slot-scope="text">
									{{ text ? text : 0 }}
								</span>
								<span slot="c_photo" slot-scope="text">
									{{ text | getRowString(text) }}
								</span>
							</a-table>
						</div>
					</div>
					<!-- 修改计划截止日期 -->
					<div v-if="visibleType === 'updateDelivery'">
						<a-row class="margint12">
							<a-col class="col-flex">
								<div class="col-label must">计划截止日期</div>
								<div class="col-value">
									<a-date-picker style="width: 100%" :value="delayTime" :disabled-date="disabledDate" placeholder='请选择' @change="produceTimeChange2" />
								</div>
							</a-col>
						</a-row>
						<a-row class="margint12">
							<a-col class="col-flex">
								<div class="col-label must">提前下发天数</div>
								<div class="col-value">
									<a-input-number style="width: 100%" placeholder="请输入任务周期天数" v-model="addForm.remindDay" :min="0" @change="inputChange($event, 'remindDay')" />
								</div>
							</a-col>
						</a-row>
						<a-row class="margint12">
							<a-col class="col-flex">
								<div class="col-label must">计划下发日期</div>
								<div class="col-value">
									<a-input style="width: 100%" :value="calculationTime" disabled />
								</div>
							</a-col>
						</a-row>
					</div>
				</a-spin>
			</jywsDialog>
			<jywsDialog
				:visible="visible2"
				:title="modalTitle2"
				:width="visibleWidth2"
				:roger="jywsDialogBottomRoger2"
				:bottom-name="jywsDialogBottomName2"
				@updataClose="closeModal2"
				:typePrimary="typePrimary2"
				@updataWin="updataWin2"
			>
				<div v-if="visibleType2 === 'addStrandOne'">
					<p>请勾选计划任务需要的执行项，未勾选的不会包含在计划任务里面</p>
					<!-- 表格区域 -->
					<div class="content-right-table">
						<a-table
							:columns="detailColumns"
							:row-selection="rowSelection4"
							:data-source="detailDataSource"
							:pagination="false"
							:scroll="{ y: 380 }"
						>
							<span slot="c_way" slot-scope="text">
								{{ text | isSF(text) }}
							</span>
							<span slot="c_max_num" slot-scope="text">
								{{ text ? text : 0 }}
							</span>
							<span slot="c_min_num" slot-scope="text">
								{{ text ? text : 0 }}
							</span>
							<span slot="c_photo" slot-scope="text">
								{{ text | getRowString(text) }}
							</span>
						</a-table>
					</div>
				</div>
				<div v-if="visibleType2 === 'addStandard'">
					<div class="info-table">
						<div class="info-table-flex">
							<div class="info-table-flex-item">
								<div class="info-table-flex-item-l must">所选设备</div>
								<div style="width: 690px;border-right: 0px;" class="info-table-flex-item-r">{{ infoList.objectName }}</div>
							</div>
						</div>
						<div style="border-top: 1px solid #f0f0f0;" class="info-table-flex">
							<div class="info-table-flex-item">
								<div class="info-table-flex-item-l must">标准类型</div>
								<div class="info-table-flex-item-r">{{ infoList.type | type(infoList.type) }}</div>
							</div>
							<div class="info-table-flex-item">
								<div class="info-table-flex-item-l must">点检单元</div>
								<div style="border-right: 0px;" class="info-table-flex-item-r"><a-input v-model="standard.m_element" /></div>
							</div>
						</div>
						<div style="border-top: 1px solid #f0f0f0;" class="info-table-flex">
							<div class="info-table-flex-item">
								<div class="info-table-flex-item-l must">点检项目</div>
								<div class="info-table-flex-item-r"><a-input v-model="standard.m_project" /></div>
							</div>
							<div class="info-table-flex-item">
								<div class="info-table-flex-item-l must">点检内容</div>
								<div style="border-right: 0px;" class="info-table-flex-item-r"><a-input v-model="standard.m_content" /></div>
							</div>
						</div>
						<div style="border-top: 1px solid #f0f0f0;" class="info-table-flex">
							<div class="info-table-flex-item">
								<div class="info-table-flex-item-l">点检方法</div>
								<div class="info-table-flex-item-r"><a-input v-model="standard.m_method" /></div>
							</div>
							<div class="info-table-flex-item">
								<div class="info-table-flex-item-l must">点检标准</div>
								<div style="border-right: 0px;" class="info-table-flex-item-r"><a-input v-model="standard.m_standard" /></div>
							</div>
						</div>
						<div style="border-top: 1px solid #f0f0f0;" class="info-table-flex">
							<div class="info-table-flex-item">
								<div class="info-table-flex-item-l">数值记录</div>
								<div class="info-table-flex-item-r"><a-switch v-model="standard.m_way" @change="onChange" /></div>
							</div>
							<div class="info-table-flex-item">
								<div class="info-table-flex-item-l">是否拍照</div>
								<div style="border-right: 0px;" class="info-table-flex-item-r"><a-switch v-model="standard.m_photo" @change="onChange" /></div>
							</div>
						</div>
						<div v-if="standard.m_way" style="border-top: 1px solid #f0f0f0;" class="info-table-flex">
							<div class="info-table-flex-item">
								<div class="info-table-flex-item-l must">最小值</div>
								<div class="info-table-flex-item-r"><a-input-number style="width: 100%;" v-model="standard.m_minNum" /></div>
							</div>
							<div class="info-table-flex-item">
								<div class="info-table-flex-item-l must">最大值</div>
								<div style="border-right: 0px;" class="info-table-flex-item-r"><a-input-number style="width: 100%;" v-model="standard.m_maxNum" /></div>
							</div>
						</div>
					</div>
				</div>
			</jywsDialog>
		</div>
	</a-spin>
</template>

<script>
import conditionQuery from '@/components/conditionQuery.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import selectPeo from '@/components/selectPeo.vue'
import selectEqus from '@/components/selectEqus.vue'
import moment from 'moment'
import * as util from '@/core/util'
import { dateFormat7, randomString, exportFile } from '@/core/date'

export default {
  name: 'InspectionPlan',
	components: {
		conditionQuery,
		jywsDialog,
		selectPeo,
		selectEqus,
	},
	filters: {
		type(text) {
			if (text == 'day') {
        return '日点检'
      } else if (text == 'specialty') {
        return '周点检'
      } else if (text == 'precision') {
        return '定期点检'
      } else if (text == 'planned') {
        return '计划点检'
      } else if (text == 'B1') {
        return '一级保养'
      } else if (text == 'B2') {
        return '二级保养'
      } else if (text == 'B3') {
        return '日常保养'
      } else if (text == 'project') {
        return '安全巡检'
      } else {
        return '-'
      }
		},
		unit(text) {
			if (text == 'day') {
        return '天'
      } else if (text == 'week') {
        return '周'
      } else if (text == 'month') {
        return '月'
      } else {
        return '-'
      }
		},
    timeTransition(text) {
      if (text) {
        return dateFormat7(text)
      } else {
        return '-'
      }
    },
		isSF(text) {
			if (text) {
        return text ? '是' : '否'
      } else {
        return '否'
      }
		},
		getRowString(data) {
			//日点检:day,周点检:specialty,专项巡检:project,定期点检:precision
			if (data == "day") {
				return '天'
			} if (data == "specialty") {
				return '周'
			} if (data == "week") {
				return '周'
			} if (data == "month") {
				return '月'
			} if (data == "precision") {
				return '定期点检'
			} if (data == 0) {
				return '否'
			} if (data == 1) {
				return '是'
			} else {
				return '-'
			}
		},
		computingTime(text) {
      if (text.startTime) {
        // return dateFormat7(text)
				return moment(text.startTime).subtract (text.remindDay,"days").format('YYYY-MM-DD')
      } else {
        return '-'
      }
    }
  },
	data() {
		return {
			homeTitle: '',
			tenantId: '',
			loading:false,
			modeSelect: 1,
			// 当前页面的点检类型
			optionsType: [
        { label: '日点检', value: 'day' },
        { label: '周点检', value: 'specialty' },
        { label: '定期点检', value: 'precision' },
        { label: '计划点检', value: 'planned' }
      ],
			// 表格表头
			columns: [
				{
					title: '设备简称',
					dataIndex: 'objectName',
					key: 'objectName',
				},
				{
					title: '所属组织',
					dataIndex: 'organizationName',
					key: 'organizationName',
				},
				{
					title: '计划类型',
					dataIndex: 'type',
					key: 'type',
					scopedSlots: { customRender: 'type' },
				},
				{
					title: '计划下发日期',
					dataIndex: 'nextTime2',
					key: 'nextTime2',
					scopedSlots: { customRender: 'nextTime2' },
				},
        {
					title: '计划截止日期',
					dataIndex: 'startTime',
					key: 'startTime',
					scopedSlots: { customRender: 'startTime' },
				},
				{
					title: '提前下发天数',
					dataIndex: 'remindDay',
					key: 'remindDay'
				},
				{
					title: '执行周期',
					dataIndex: 'cycle',
					key: 'cycle',
					scopedSlots: { customRender: 'cycle' },
				},
				{
					title: '执行人/组',
					dataIndex: 'startUserName',
					key: 'startUserName'
				},
				{
					title: '创建日期',
					dataIndex: 'createTime',
					key: 'createTime',
					scopedSlots: { customRender: 'createTime' },
				},
				{
					title: '执行项',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
				},
			],
			dataSource: [],
			selectedRowKeys: [],
			selectedRows: [],
			headData: [
        {
					key: 1,
					name: '点检类型',
					component: 'a-checkbox',
					value: ['day', 'specialty', 'precision', 'planned'],
					options: [
            { label: '日点检', value: 'day' },
            { label: '周点检', value: 'specialty' },
            { label: '定期点检', value: 'precision' },
            { label: '计划点检', value: 'planned' }
          ],
					duplicate: 'types',
				},
        {
					key: 2,
					name: '组织/设备',
					component: 'a-selectEqus',
					value: '',
					mode: 1,
          radioFlag: false,
					duplicate: 'objects',
					placeholder: '请选择',
				},
        {
					key: 3,
					name: '执行人/组',
					component: 'a-selectPeo',
					value: '',
          radioFlag: false,
          showGroup: true,
					duplicate: 'users',
					placeholder: '请选择',
				}
			],
			// 查询特种设备列表参数
			searchForm: {
        pageIndex: 1,
				pageSize: 10,
        mode: 1, // 1 月份 2 年份
        beginTime: new Date(),
        endTime: new Date(),
        types: ['day', 'specialty', 'precision', 'planned'], // 点检类型
        orgs: [], // 组织
        objects: [], // 设备
        users: [] // 执行人
			},
			//判断是月份日历还是年份日历
			mode: 'month',
			planDate: [],
			total: 0,
			// 派任务接口传参
			addForm: {
				// 执行人/工作组
				recipientId: '',
				recipientName: '',
				recipientObj: {},
				// 评价人
				appraiserId: '',
				appraiserName: '',
				appraiserObj: {},
				// 首次推送日期
				firstpushTime: '',
				// 首次任务下发日期
				firstIssueDate: '',
				// 计划类型
				selectType: '',
				// 提前下发天数
				remindDay: '',
				// 周期
				m_cycle: '',
				m_unit: 'month',
				// 选择设备
				equ: [],
				devicename: '',
				arr: '',
				newdataEqus: []
			},
			// 弹窗参数
			visible: false,
			visibleType: '',
			modalTitle: '',
			visibleWidth: '960px',
			jywsDialogBottomRoger: '取消',
			jywsDialogBottomName: '确认',
			typePrimary: 'primary',
			steps: 0,
			spinning: false,
			// 表格表头
			sonColumns: [
				{
					dataIndex: 'pitch', 
					key: 'pitch',
					width: '50px',
					slots: { title: 'pitchName' },
					scopedSlots: { customRender: 'pitch' }
        },
				{
					title: '资产编码',
					dataIndex: 'assetsCode',
					key: 'assetsCode'
				},
				{
					title: '设备简称',
					dataIndex: 'simpleName',
					key: 'simpleName',
				},
				{
					title: '设备型号',
					dataIndex: 'standard',
					key: 'standard',
				},
				{
					title: '所属组织',
					dataIndex: 'organizationName',
					key: 'organizationName',
				},
				{
					title: '作业标准数',
					dataIndex: 'cot',
					key: 'cot'
				}
			],
			sonLoading:false,
			selectPlanningDate: [],
			pageIndex: 1,
			pageSize: 10,
			sonTotal: 5,
			infoList: {},
			// 表格表头
			detailColumns: [
				{
					title: '点检单元',
					dataIndex: 'c_element',
					key: 'c_element',
					ellipsis: true,
				},
				{
					title: '点检项目',
					dataIndex: 'c_project',
					key: 'c_project',
					ellipsis: true,
				},
				{
					title: '点检内容',
					dataIndex: 'c_content',
					key: 'c_content',
					ellipsis: true,
				},
				{
					title: '点检方法',
					dataIndex: 'c_method',
					key: 'c_method',
					ellipsis: true,
				},
				{
					title: '点检标准',
					dataIndex: 'c_standard',
					key: 'c_standard',
					ellipsis: true
				},
				{
					title: '数值记录',
					dataIndex: 'c_way',
					key: 'c_way',
					scopedSlots: { customRender: 'c_way' },
					ellipsis: true
				},
				{
					title: '最大值',
					dataIndex: 'c_max_num',
					key: 'c_max_num',
					scopedSlots: { customRender: 'c_max_num' },
					ellipsis: true,
				},
				{
					title: '最小值',
					dataIndex: 'c_min_num',
					key: 'c_min_num',
					scopedSlots: { customRender: 'c_min_num' },
					ellipsis: true,
				},
				{
					title: '必须拍照',
					dataIndex: 'c_photo',
					key: 'c_photo',
					scopedSlots: { customRender: 'c_photo' }
				}
			],
			detailLoading: false,
			innerColumns: [
				{
          title: '', 
					dataIndex: 'sonPitch', 
					key: 'sonPitch',
					width: '50px',
					ellipsis: true,
					scopedSlots: { customRender: 'sonPitch' }
        },
				{
					title: '点检单元',
					dataIndex: 'c_element',
					key: 'c_element',
					ellipsis: true
				},
				{
					title: '点检项目',
					dataIndex: 'c_project',
					key: 'c_project',
					ellipsis: true
				},
				{
					title: '点检内容',
					dataIndex: 'c_content',
					key: 'c_content',
					ellipsis: true
				},
				{
					title: '数值记录',
					dataIndex: 'c_way',
					key: 'c_way',
					ellipsis: true,
					scopedSlots: { customRender: 'c_way' }
				},
				{
					title: '必须拍照',
					dataIndex: 'c_photo',
					key: 'c_photo',
					ellipsis: true,
					scopedSlots: { customRender: 'c_photo' }
				}
			],
			selectedRows3: [],
			selectedRowKeys3: [],
			strandardDataSource: [],
			// 弹窗参数
			visible2: false,
			visibleType2: '',
			modalTitle2: '',
			visibleWidth2: '960px',
			jywsDialogBottomRoger2: '取消',
			jywsDialogBottomName2: '确认',
			typePrimary2: 'primary',

			detailDataSource: [],
			selectedRows4: [],
			selectedRowKeys4: [],

			standard: {
				m_element: '',
				m_project: '',
				m_content: '',
				m_method: '',
				m_standard: '',
				m_way: false,
				m_photo: false,
				m_minNum: 0,
				m_maxNum: 0,
			},
			// 计划延期时间
			delayTime: '',
		}
	},
	created() {
		var str = decodeURIComponent(this.$route.fullPath).split('=')
    this.tenantId = str[1].split('&')[0]
		this.searchForm.tenantId = str[1].split('&')[0]
		if (str[2].split('&')[0] == '点检') {
			this.homeTitle = '点检计划'
			this.optionsType = [
        { label: '日点检', value: 'day' },
        { label: '周点检', value: 'specialty' },
        { label: '定期点检', value: 'precision' },
        { label: '计划点检', value: 'planned' }
      ]
			this.headData[0].options = [
        { label: '日点检', value: 'day' },
        { label: '周点检', value: 'specialty' },
        { label: '定期点检', value: 'precision' },
        { label: '计划点检', value: 'planned' }
      ]
			this.headData[0].name = '点检类型'
			this.headData[0].value = ['day', 'specialty', 'precision', 'planned']
			this.searchForm.types = ['day', 'specialty', 'precision', 'planned']
			this.modeSelect = 1
		} else if (str[2].split('&')[0] == '保养') {
			this.homeTitle = '保养计划'
			this.optionsType = [
        { label: '一级保养', value: 'B1' },
        { label: '二级保养', value: 'B2' },
        { label: '日常保养', value: 'B3' }
      ]
			this.headData[0].options = [
        { label: '一级保养', value: 'B1' },
        { label: '二级保养', value: 'B2' },
        { label: '日常保养', value: 'B3' }
      ]
			this.headData[0].name = '保养类型'
			this.headData[0].value = ['B1', 'B2', 'B3']
			this.searchForm.types = ['B1', 'B2', 'B3']
			this.modeSelect = 1
		} else if (str[2].split('&')[0] == '巡检') {
			this.homeTitle = '安全巡检计划'
			this.optionsType = [
        { label: '安全巡检', value: 'project' }
      ]
			this.searchForm.types = ['project']
			this.columns[0].title = '区域简称'
			this.headData = [
        {
					key: 2,
					name: '组织/区域',
					component: 'a-selectEqus',
					value: '',
					mode: 0,
          radioFlag: false,
					duplicate: 'objects',
					placeholder: '请选择',
				},
        {
					key: 3,
					name: '执行人/组',
					component: 'a-selectPeo',
					value: '',
          radioFlag: false,
          showGroup: true,
					duplicate: 'users',
					placeholder: '请选择',
				}
			]
			this.modeSelect = 0
		}
		this.initWorkCalendar()
		this.queryList()
	},
	computed: {
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys = selectedRowKeys
					this.selectedRows = selectedRows
				},
			}
		},
		rowSelection3() {
			return {
				selectedRowKeys: this.selectedRowKeys3,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys3 = selectedRowKeys
					this.selectedRows3 = selectedRows
				},
			}
		},
		rowSelection4() {
			return {
				selectedRowKeys: this.selectedRowKeys4,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys4 = selectedRowKeys
					this.selectedRows4 = selectedRows
				},
			}
		},
		nextReleaseTime() {
			let timeText = ''
			if (this.addForm.periodTime && this.addForm.executiveDays) {
				timeText = moment(this.addForm.periodTime)
					.subtract(this.addForm.executiveDays, 'days')
					.format('YYYY-MM-DD')
			}
			return timeText
		},
		calculationTime() {
			if (this.delayTime) {
				return moment(this.delayTime).subtract (this.addForm.remindDay || 0,"days").format('YYYY-MM-DD') || ''
			} else {
				return ''
			}
		}
	},
	mounted() {
	},
	methods: {
		// 日历点击事件
		selectChange(value) {
			if (this.mode === 'month') {
				this.searchForm.beginTime = value
				this.searchForm.endTime = value
				this.initWorkCalendar()
				this.queryList()
			}
		},
    onPanelChange(value, mode) {
      console.log(value, mode);
			this.searchForm.beginTime = value
			this.searchForm.endTime = value
			this.mode = mode
			this.initWorkCalendar()
			this.queryList()
    },
		// 显示日历计划数据
		getListData(value) {
      let listData;
			for (let i = 0; i < this.planDate.length; i++) {
				const time = this.planDate[i];
				if (time == moment(value).format('YYYYMMDD')) {
					return listData = [{ type: '有计划', time: time }]
				}
			}
      return listData || [];
    },
		// 多选框
    checkboxOnChange(item) {
      if (item.duplicate == 'types') {
				this.searchForm.types = item.value
			}
    },
    // 组织/设备
    onselectEqus(item) {
      let valueNames = []
      item.checkData.forEach(ele => {
        valueNames.push(ele.c_simple_name)
      })
      if (item.duplicate == 'objects') {
				this.searchForm.objects = item.value
        this.headData.forEach(ele2 => {
          if (ele2.duplicate == 'objects') {
            ele2.value = valueNames && valueNames.toString()
          }
        })
			}
    },
    // 执行人/组
    onSelectPeo(item) {
      let valueNames = []
      item.checkData.forEach(ele => {
        valueNames.push(ele.c_name)
      })
      if (item.duplicate == 'users') {
				this.searchForm.users = item.value
        this.headData.forEach(ele2 => {
          if (ele2.duplicate == 'users') {
            ele2.value = valueNames && valueNames.toString()
          }
        })
			}
    },
		// 查询
		onSelectClick() {
			this.initWorkCalendar()
			this.queryList()
		},
		// 获取有计划日期
		initWorkCalendar() {
			let dataset = { ...this.searchForm }
			dataset.tenantId = this.tenantId
			dataset.beginTime = moment(this.searchForm.beginTime).startOf("month").format("YYYYMMDD")
			dataset.endTime = moment(this.searchForm.endTime).endOf("month").format("YYYYMMDD")
			if (dataset.types.length == 0) {
				if (this.homeTitle == '点检计划') {
					dataset.types = ['day', 'specialty', 'precision', 'planned']
				} else if (this.homeTitle == '保养计划') {
					dataset.types = ['B1', 'B2', 'B3']
				} else if (this.homeTitle == '安全巡检计划') {
					dataset.types = ['project']
				}
			}
			util.postform('ZNexamineTask/initWorkCalendar', dataset).then((res) => {
					this.planDate = res.data || []
				}
			)
		},
		// 查询计划
		queryList() {
			this.loading = true
			let dataset = { ...this.searchForm }
			dataset.tenantId = this.tenantId
			if (dataset.types.length == 0) {
				if (this.homeTitle == '点检计划') {
					dataset.types = ['day', 'specialty', 'precision', 'planned']
				} else if (this.homeTitle == '保养计划') {
					dataset.types = ['B1', 'B2', 'B3']
				} else if (this.homeTitle == '安全巡检计划') {
					dataset.types = ['project']
				}
			}
			if (this.mode == 'month') {
				dataset.beginTime = moment(this.searchForm.beginTime).format('YYYYMMDD')
				dataset.endTime = moment(this.searchForm.endTime).format('YYYYMMDD')
			} else {
				dataset.beginTime = moment(this.searchForm.beginTime).startOf("month").format("YYYYMMDD")
				dataset.endTime = moment(this.searchForm.endTime).endOf("month").format("YYYYMMDD")
			}
			util.postform('ZNexamineTask/queryList', dataset).then((res) => {
					this.loading = false
					this.dataSource = res.data.examinePlans || []
					this.total = res.data.total
				}
			)
		},
		// 翻页
		pageonChange(page) {
			this.searchForm.pageIndex = page
			this.queryList()
		},
		// 页数size改变
		showSizeChange(key, size) {
			this.searchForm.pageSize = size
			this.queryList()
		},
		// 功能按钮操作
		onbtnGroup(e) {
			let name = e.target.name
			if (name === 'add') {
				this.steps = 0
			} else if (name === 'amendmentExecutor') {
				if (this.selectedRows.length === 0) {
					this.$info({
						title: '提示',
						content: '请选择需要修改的项！',
						onOk() {},
					});
					return
				}
			} else if (name === 'infoDetails') {
				if (this.selectedRows.length != 1) {
					this.$info({
						title: '提示',
						content: '请选择需要修改的一项！',
						onOk() {},
					});
					return
				}
			} else if (name == 'updateDelivery') {
				if (this.selectedRows.length == 0) {
					this.$info({
						title: '提示',
						content: '请选择一条计划进行延期！',
						onOk() {},
					});
					return
				}
				// if (this.selectedRows.length > 1) {
				// 	this.$info({
				// 		title: '提示',
				// 		content: '请选择单条计划进行延期！',
				// 		onOk() {},
				// 	});
				// 	return;
				// }
				for (let i = 0; i < this.selectedRows.length; i++) {
					const element = this.selectedRows[i];
					if (element.type == 'day') {
						this.$info({
							title: '提示',
							content: '日点检不能进行延期！',
							onOk() {},
						});
						return
					} else if (element.type == 'specialty') {
						this.$info({
							title: '提示',
							content: '周点检不能进行延期！',
							onOk() {},
						});
						return
					} else if (element.type == 'B3') {
						this.$info({
							title: '提示',
							content: '日常保养不能进行延期！',
							onOk() {},
						});
						return
					}
				}
			}
			this.showModal(e)
		},
		showModal(e) {
			let name = e.target.name
			switch (name) {
				case 'add':
					this.modalTitle = '派任务'
					this.visible = true
					this.visibleWidth = '960px'
					this.visibleType = name
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '下一步'
					break
				case 'amendmentExecutor':
					this.modalTitle = '变更执行人'
					this.visible = true
					this.visibleWidth = '560px'
					this.visibleType = name
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确定'
					break
				case 'infospecialEqus':
					this.modalTitle = '详情'
					this.visible = true
					this.visibleType = name
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.visibleWidth = '960px'
					break
				case 'infoDetails':
					this.infoList = this.selectedRows[0]
					this.modalTitle = '修改执行项'
					this.visible = true
					this.visibleType = name
					this.jywsDialogBottomRoger = ''
					this.jywsDialogBottomName = '关闭'
					this.typePrimary = 'primary'
					this.visibleWidth = '960px'
					this.getDetailListData(this.selectedRows[0])
					break
				case 'updateDelivery':
					this.delayTime = dateFormat7(this.selectedRows[0].cycleDay)
					this.addForm.remindDay = this.selectedRows[0].remindDay
					this.modalTitle = '修改计划截止日期'
					this.visible = true
					this.visibleType = name
					this.jywsDialogBottomRoger = '取消'
					this.jywsDialogBottomName = '确定'
					this.typePrimary = 'primary'
					this.visibleWidth = '560px'
					break
				case 'addStrandOne':
					this.modalTitle2 = '修改执行项'
					this.visible2 = true
					this.visibleType2 = name
					this.jywsDialogBottomRoger2 = '取消'
					this.jywsDialogBottomName2 = '确定'
					this.typePrimary2 = 'primary'
					this.visibleWidth2 = '960px'
					break
				case 'addStandard':
					this.modalTitle2 = '新增作业标准'
					this.visible2 = true
					this.visibleType2 = name
					this.jywsDialogBottomRoger2 = '取消'
					this.jywsDialogBottomName2 = '保存'
					this.typePrimary2 = 'primary'
					this.visibleWidth2 = '960px'
					break
				default:
					break
			}
		},
		// 新增执行人/组
    addSelectPeo(item, type) {
			if (type === '1') {
				this.addForm.recipientId = item.key.toString()
				this.addForm.recipientObj = item.obj
				this.addForm.recipientName = item.obj[0].c_name
			} else if (type === '2') {
				this.addForm.appraiserId = item.key.toString()
				this.addForm.appraiserObj = item.obj
				this.addForm.appraiserName = item.obj[0].c_name
			}
    },
		// 不能选中今天之前的日期
		disabledDate(current) {
			// Can not select days before today and today
			return current < moment().subtract(1, 'day');
		},
		// 新增首次任务截止日期
		produceTimeChange(date, dateString) {
      console.log(date, dateString);
			this.addForm.firstpushTime = dateString;
			if (this.addForm.firstpushTime && this.addForm.remindDay) {
				this.addForm.firstIssueDate = moment(this.addForm.firstpushTime).subtract(this.addForm.remindDay, 'days').format('YYYY-MM-DD')
			} else {
				this.addForm.firstIssueDate = ''
			}
    },
		// 新增计划类型、周期
		selectTypeChange(value, type) {
      console.log(`selected ${value}`);
			if (type === 'selectType') {
				this.addForm.selectType = value
			} else if (type === 'm_unit') {
				this.addForm.m_unit = value
			}
    },
		// 新增提前下发天数、周期
		inputChange(value, type) {
			if (type === 'remindDay') {
				this.addForm.remindDay = value
				if (this.addForm.firstpushTime && this.addForm.remindDay) {
					this.addForm.firstIssueDate = moment(this.addForm.firstpushTime).subtract(this.addForm.remindDay, 'days').format('YYYY-MM-DD')
        } else {
          this.addForm.firstIssueDate = ''
        }
			}
		},
		// 新增选中设备
    addSelectEqus(item) {
      let valueNames = []
			let arr = []
      item.obj.forEach(ele => {
        valueNames.push(ele.c_simple_name)
				arr.push({ id: ele.c_id, assetsCode: ele.c_assets_code, simpleName: ele.c_simple_name, standard: ele.c_standard, organizationId: ele.c_organization_id, organizationName: ele.depName })
      })
			this.addForm.equ = item.key
			this.addForm.devicename = valueNames.toString()
			this.addForm.arr = arr
			this.addForm.newdataEqus = item.obj
    },
		// 弹窗确认按钮
		updataWin() {
			if (this.visibleType === 'add') {
				this.addSave()
			} else if (this.visibleType === 'amendmentExecutor') {
				this.personEditCon()
			} else if (this.visibleType === 'infoDetails') {
				this.closeModal()
			} else if (this.visibleType === 'infospecialEqus') {
				this.closeModal()
			} else if (this.visibleType === 'updateDelivery') {
				this.postponeSave()
			}
		},
		// 新增派任务
		addSave() {
			if (this.addForm.recipientId == '') {
				this.$message.error('请选择 "执行人" ！')
				return;
			}
			if (this.addForm.firstpushTime == '') {
				this.$message.error('请选择 "首次任务截止日期" ！')
				return;
			}
			if (this.addForm.selectType == '') {
				this.$message.error('请选择 "计划类型" ！')
				return;
			}
			if (this.addForm.selectType == 'planned' || this.addForm.selectType == 'precision' || this.addForm.selectType == 'B1' || this.addForm.selectType == 'B2' || this.addForm.selectType == 'project') {
				if (this.addForm.remindDay === '') {
					this.$message.error('请输入 "提前下发天数" ！')
					return;
				}
				let num = 0
				switch (this.addForm.m_unit) {
					case "day":
						num = Number(this.addForm.m_cycle)
						break;
					case "week":
						num = Number(this.addForm.m_cycle) * 7
						break;
					case "month":
						num = Number(this.addForm.m_cycle) * 30
						break;
					default:
						break;
				}
				if (Number(this.addForm.remindDay) > num) {
					this.$message.error('提前下发天数不能大于周期天数')
					return;
				}
			}
			if (this.addForm.equ.length == 0 && this.steps == 0) {
				this.$message.error('请选择 "设备" ！')
				return;
			}
			if (this.steps == 0) {
				this.sonLoading = true
				let dataset = {
					userId: this.addForm.recipientId,      //租户ID
					tenantId: this.tenantId,      //租户ID
					// arr: encodeURIComponent(JSON.stringify(this.addForm.arr)),
					arr: this.addForm.arr,
					type: this.addForm.selectType
				};
				util.postform('ZNequipment/queryNotIncludedPlan', dataset).then((reg) => {
					this.sonLoading = false
					let selectPlanning = reg.data;
					selectPlanning.forEach((element) => {
						element.key = element.id;
						element.pitch = true
						element.indeterminate = false
						element.standardList.forEach((sonElement) => {
							sonElement.key = sonElement.c_id;
							sonElement.sonPitch = true;
						})
					});
					let dataset = [];
					for (let i = 0; i < selectPlanning.length; i++) {
						const item = selectPlanning[i];
						if (item.standardList.length > 0) {
							dataset.push(item)
						}
					}
					if (reg.data) {
						const current = this.steps + 1;
						this.steps = current
						this.selectPlanningDate = dataset
						this.sonTotal = dataset.length
						this.jywsDialogBottomRoger = '上一步'
						this.jywsDialogBottomName = '下一步'
					} else {
						this.$message.error('网络链接错误')
					}
				})
			} else if (this.steps == 1) {
				if (this.selectPlanningDate.length == 0) {
					this.$message.error('暂无计划作业标准选中 ！')
					return;
				}
				let date = this.selectPlanningDate;
				console.log(date);
				let newdataset = []
				date.forEach(ele => {
					let standards = []
					ele.standardList.forEach(ele2 => {
						if (ele2.sonPitch) {
							standards.push({
								standardId: ele2.c_id,
								tenantId: this.tenantId,
							})
						}
					})
					newdataset.push({
						assessUserId: this.addForm.appraiserId,
						assessUserName: this.addForm.appraiserName,
						cycle: this.addForm.m_cycle,
						objectId: ele.id,
						objectName: ele.simpleName,
						organizationId: ele.organizationId,
						organizationName: ele.organizationName,
						startTime: this.addForm.firstpushTime.split('-').join(''),
						startUserId: this.addForm.recipientId,
						startUserName: this.addForm.recipientName,
						remindDay:  (this.addForm.selectType == 'planned' || this.addForm.selectType == 'precision' || this.addForm.selectType == 'B1' || this.addForm.selectType == 'B2' || this.addForm.selectType == 'project') ? this.addForm.remindDay : 0,
						tenantId: this.tenantId,
						type: this.addForm.selectType,
						unit: this.addForm.m_unit,
						examinePlanDetailParamLt: standards
					})
				})
				newdataset.forEach(item => {
					if (item.type == 'day') {
						item.cycle = '1'
						item.unit = 'day'
					} else if (item.type == 'specialty') {
						item.cycle = '1'
						item.unit = 'week'
					}
				})
				console.log(newdataset)
				let dataList = []
				for (let i = 0; i < newdataset.length; i++) {
					const element = newdataset[i];
					if (element.examinePlanDetailParamLt.length > 0) {
						dataList.push(element)
					}
				}
				if (dataList.length == 0) {
					this.$message.error('请选中计划作业标准 ！')
					return;
				}
				util.postform('task/addExaminePlan', dataList).then((reg) => {
					console.log(reg);
					if (reg.code == 200) {
						const current = this.steps + 1;
						this.steps = current
						this.addForm.equ = []
						this.jywsDialogBottomRoger = ''
						this.jywsDialogBottomName = '关闭'
					} else {
						this.$message.error('网络链接错误')
					}
				})
			} else {
				this.closeModal('1');
				this.initWorkCalendar()
				this.queryList()
			}
		},
		sonCheckAllChange(e) {
			let date = this.selectPlanningDate;
			if (e.target.checked) {
				date.forEach(ele => {
					ele.pitch = true;
					ele.standardList.forEach(ele2 => {
						ele2.sonPitch = true;
					})
				})
			} else {
				date.forEach(ele => {
					ele.pitch = false;
					ele.standardList.forEach(ele2 => {
						ele2.sonPitch = false;
					})
				})
			}
			this.selectPlanningDate = date
			this.$forceUpdate()
		},
		sonOnCheckAllChange(e) {
			let date = this.selectPlanningDate
			if (e.target.checked == true) {
				date.forEach(ele => {
					if (ele.id == e.target.value) {
						ele.pitch = true;
						ele.indeterminate = false;
						ele.standardList.forEach(ele2 => {
							ele2.sonPitch = true;
						})
					}
				})
			} else {
				date.forEach(ele => {
					if (ele.id == e.target.value) {
						ele.pitch = false;
						ele.indeterminate = false
						ele.standardList.forEach(ele2 => {
							ele2.sonPitch = false;
						})
					}
				})
			}
			this.selectPlanningDate = date
			this.$forceUpdate()
		},
		sonOnAloneChange(e) {
			let date = this.selectPlanningDate;
			if (e.target.checked == true) {
				date.forEach(ele => {			
					ele.standardList.forEach(ele2 => {
						if (ele2.c_id == e.target.value) {
							ele2.sonPitch = true;
						}
					})
				})
			} else {
				date.forEach(ele => {
					ele.standardList.forEach(ele2 => {
						if (ele2.c_id == e.target.value) {
							ele2.sonPitch = false;
						}
					})
				})
			}
			this.sonIndeterminateCss(date);
		},
		sonIndeterminateCss(item) {
			let date = item
			for (let i = 0; i < date.length; i++) {
				const element = date[i];
				let count = 0
				for (let j = 0; j < element.standardList.length; j++) {
					const element2 = element.standardList[j];
					if (element2.sonPitch == true) {
						count += 1
					}
				}
				if (element.standardList.length == count) {
					element.indeterminate = false
					element.pitch = true
				} else if (count == 0) {
					element.indeterminate = false
					element.pitch = false
				} else {
					element.indeterminate = true
				}
			}
			this.selectPlanningDate = date
			this.$forceUpdate()
		},
		// 修改执行人
		personEditCon() {
			//必填字段是否为空
			if (this.addForm.recipientId == "") {
				this.$message.error('接收人不能为空')
				return;
			}
			let rowsId = []
			let OUTselectedRows = this.selectedRows
			OUTselectedRows.forEach(ele => {
				rowsId.push(ele.id)
			});
			let dataset = {
				startUserId: this.addForm.recipientId,       //接收人id    
				ids: rowsId.toString(),         //多个项id
				startUserName: this.addForm.recipientName
			};
			if (this.addForm.appraiserId != "") {
				dataset.assessUserId = this.addForm.appraiserId;  //评价人id
				dataset.assessUserName = this.addForm.assessUserName; 
			}
			this.spinning = true
			util.post('webCheck/updateCheckPlanUser', dataset).then((reg) => {
				this.spinning = false
				if (reg.dataset === 'true') {
					this.$message.success('修改成功')
					this.closeModal('1');
				} else {
					this.$message.error('网络链接错误')
				}
			})
		},
		// 详情
		openDetails(val) {
			this.visibleType = 'infoDetails'
			this.infoList = val
			this.modalTitle = '修改执行项'
			this.visible = true
			this.visibleWidth = '960px'
			this.jywsDialogBottomRoger = ''
			this.jywsDialogBottomName = '关闭'
			this.getDetailListData(val)
		},
		//获得点检列表详情数据
		getDetailListData(record) {
			this.detailDataSource = []
			this.detailLoading = true
			let dataset = {
				id: record.id,
				objectId: record.objectId,
				type: record.type
			};
			util.post('webCheck/queryCheckPlanDetailList', dataset).then((reg) => {
				let temp = []
				let dataSource = []
				let selectedRows = []
				let selectedRowKeys = []
				let strandardDataSource = [];
				temp = reg.dataset;
				temp.forEach((ele) => {
					let unit = ele.c_unit == 'day' ? '天' : ele.c_unit == 'week' ? '周' : ele.c_unit == 'week' ? '月' : '-';
					if (ele.isExist == 1) {
						selectedRowKeys.push(ele.c_id);
						selectedRows.push({
							key: ele.c_id || randomString(25), //key react 数据唯一值
							c_element: ele.c_element,       //点检单元
							c_project: ele.c_project,       //点检项目
							c_content: ele.c_content,       //点检内容
							c_method: ele.c_method,         //点检方法
							c_standard: ele.c_standard,     //点检标准
							c_way: ele.c_way,               //数值记录
							c_max_num: ele.c_max_num,       //最大值
							c_min_num: ele.c_min_num,       //最小值
							// lastDate: ele.lastDate,         //上次执行时间
							// c_cycle_day: ele.c_cycle_day,   //下次计划执行时间
							c_cycle: ele.c_cycle + unit,           //周期
							c_photo: ele.c_photo,           //必须拍照
							isExist: ele.isExist
						});

						strandardDataSource.push({
							key: ele.c_id || randomString(25), //key react 数据唯一值
							c_element: ele.c_element,       //点检单元
							c_project: ele.c_project,       //点检项目
							c_content: ele.c_content,       //点检内容
							c_method: ele.c_method,         //点检方法
							c_standard: ele.c_standard,     //点检标准
							c_way: ele.c_way,               //数值记录
							c_max_num: ele.c_max_num,       //最大值
							c_min_num: ele.c_min_num,       //最小值
							// lastDate: ele.lastDate,         //上次执行时间
							// c_cycle_day: ele.c_cycle_day,   //下次计划执行时间
							c_cycle: ele.c_cycle + unit,           //周期
							c_photo: ele.c_photo,           //必须拍照
							isExist: ele.isExist
						})
					}

					dataSource.push({
						key: ele.c_id || randomString(25), //key react 数据唯一值
						c_element: ele.c_element,       //点检单元
						c_project: ele.c_project,       //点检项目
						c_content: ele.c_content,       //点检内容
						c_method: ele.c_method,         //点检方法
						c_standard: ele.c_standard,     //点检标准
						c_way: ele.c_way,               //数值记录
						c_max_num: ele.c_max_num,       //最大值
						c_min_num: ele.c_min_num,       //最小值
						// lastDate: ele.lastDate,         //上次执行时间
						// c_cycle_day: ele.c_cycle_day,   //下次计划执行时间
						c_cycle: ele.c_cycle + unit,           //周期
						c_photo: ele.c_photo,           //必须拍照
						isExist: ele.isExist
						})
					})
					this.detailDataSource = dataSource
					this.detailLoading = false
					this.selectedRows4 = selectedRows
					this.selectedRowKeys4 = selectedRowKeys
					this.strandardDataSource = strandardDataSource
			})
		},
		// 派任务清空数据
		sendMissionClose() {
			this.visible = false
			this.steps = 0
			this.addForm = {
				// 执行人/工作组
				recipientId: '',
				recipientName: '',
				recipientObj: {},
				// 评价人
				appraiserId: '',
				appraiserName: '',
				appraiserObj: {},
				// 首次推送日期
				firstpushTime: '',
				// 首次任务下发日期
				firstIssueDate: '',
				// 计划类型
				selectType: '',
				// 提前下发天数
				remindDay: '',
				// 周期
				m_cycle: '',
				m_unit: 'month',
				// 选择设备
				equ: [],
				devicename: '',
				arr: '',
				newdataEqus: []
			}
			this.selectPlanningDate = []
			this.selectedRowKeys = []
			this.selectedRows = []
		},
		// 关闭弹窗
		closeModal(type) {
			if(this.visibleType === 'add') {
				if (this.steps === 0) {
					this.sendMissionClose()
				} else if (this.steps === 1) {
					if (type === '2') {
						this.jywsDialogBottomRoger = '取消'
						this.steps--
					} else {
						this.sendMissionClose()
					}
				} else if (this.steps === 2) {
					this.sendMissionClose()
				}
			} else if (this.visibleType === 'amendmentExecutor') {
				this.sendMissionClose()
			} else if (this.visibleType === 'infoDetails') {
				this.visible = false
				this.detailDataSource = []
				this.detailLoading = false
				this.selectedRows4 = []
				this.selectedRowKeys4 = []
				this.strandardDataSource = []
			} else if (this.visibleType === 'updateDelivery') {
				this.delayTime = ''
				this.sendMissionClose()
			}  else if (this.visibleType === 'updateDelivery') {
				this.closeModal()
			}
			this.initWorkCalendar()
			this.queryList()
		},
		// 修改执行项
		setTasks() {		
			let selectedRows = this.selectedRows4;
			if (selectedRows.length == 0) {//标准不能为空
				this.$message.error('请选择标准！')
				return;
			}
			let listStandard = [];
			selectedRows.forEach((ele) => {
				listStandard.push({
					id: ele.key
				})
			})
			let dataset = {
				tenantId: this.tenantId,
				id: this.infoList.id,
				items: listStandard,
			};
			util.postform('ZNexamineTask/updatePlanInfo', dataset).then((reg) => {
				if (reg.code == 200) {
					this.$message.success('成功')
					this.detailDataSource = []
					this.selectedRows4 = []
					this.selectedRowKeys4 = []
					this.closeModal2();//关闭遮罩
					this.getDetailListData(this.infoList);//刷新表格
				} else {
					this.$message.error('失败')
				}
			})
		},
		addNewStrandBtn() {
			if (this.standard.m_element == '') {
				return this.$message.error('请输入点检单元！')
			} else if (this.standard.m_project == '') {
				return this.$message.error('请输入点检项目！')
			} else if (this.standard.m_content == '') {
				return this.$message.error('请输入点检内容！')
			} else if (this.standard.m_method == '') {
				return this.$message.error('请输入点检方法！')
			} else if (this.standard.m_standard == '') {
				return this.$message.error('请输入点检标准！')
			}
			if (this.standard.m_way) {
				if (this.standard.m_minNum == null) {
					return this.$message.error('请输入点检单元！')
				} else if (this.standard.m_maxNum == null) {
					return this.$message.error('请输入点检项目！')
				}
				if (this.standard.m_minNum >= this.standard.m_maxNum) {
					return this.$message.error('最小值不能大于等于最大值！')
				}
			}
			let dataset = {
				content: this.standard.m_content,
				element: this.standard.m_element,
				method: this.standard.m_method,
				photo: this.standard.m_photo ? 1 : 0,
				project: this.standard.m_project,
				standard: this.standard.m_standard,
				type: this.infoList.type,
				way: this.standard.m_way ? 1 : 0,
				tenantId: this.tenantId,
				objectId: this.infoList.objectId,
				objectName: this.infoList.objectName,
				planId: this.infoList.id,
			}
			if (this.standard.m_way) {
				dataset.minNum = this.standard.m_minNum
				dataset.maxNum = this.standard.m_maxNum
			}
			util.postform('task/addExaminePlanStandard', dataset).then((res) => {
				if (res.code == 200) {
					this.$message.success('新增成功！')
					this.detailDataSource = []
					this.selectedRows4 = []
					this.selectedRowKeys4 = []
					this.standard = {
						m_element: '',
						m_project: '',
						m_content: '',
						m_method: '',
						m_standard: '',
						m_way: false,
						m_photo: false,
						m_minNum: 0,
						m_maxNum: 0,
					}
					this.closeModal2();//关闭遮罩
					this.getDetailListData(this.infoList);//刷新表格
				}
			})
		},
		// 新增首次任务截止日期
		produceTimeChange2(date, dateString) {
      console.log(date, dateString);
			this.delayTime = dateString;
    },
		postponeSave() {
			let rows = this.selectedRows
			if (this.delayTime == '') {
				this.$message.error('请选择延期日期！')
				return;
			}
			let arr = []			
			for (let i = 0; i < rows.length; i++) {
				const ele = rows[i];
				arr.push({
					cycle: ele.cycle,
					oldTime: ele.startTime,
					planId: ele.id,
					startTime: moment(this.delayTime).format('YYYYMMDD'),
					tenantId: this.tenantId,
					unit: ele.unit,
					remindDay: this.addForm.remindDay
				})
			}
			let dataset = arr
			this.spinning = true
			util.postform('postpone/taskPostponeList', dataset).then((reg) => {
				this.spinning = false
				console.log(reg)
				this.closeModal()
				this.$message.success('延期成功')
			})
		},
		// 删除计划
		delBtn() {
			let that = this
			if (this.selectedRows.length === 0) {
				this.$info({
					title: '提示',
					content: '请选择需要删除的记录！',
					onOk() {},
				})
				return
			}
			let deleteArry = [];
      this.selectedRows.forEach((ele) => {
        deleteArry.push(ele && ele.id)
      })
			this.$confirm({
				title: '删除提示！',
        content:'是否删除选中计划，如果删除，执行人后续将不再收到这些计划的任务推送，请谨慎处理！',
				onOk() {
					let dataset = {
            ids: deleteArry
          };
					util.postform('ZNexamineTask/deltePlan', dataset).then((res) => {
						if (res.code === 200) {
							that.$message.success('删除成功！')
							that.selectedRows = []
							that.selectedRowKeys = []
							that.initWorkCalendar()
							that.queryList()
						}
					})
				},
				onCancel() {},
			})
		},
		// 导出计划
    exportExcel() {
				let dataset = { ...this.searchForm }
				dataset.tenantId = this.tenantId
				dataset.pageIndex = 1
				dataset.pageSize = 999999999
				if (this.mode == 'month') {
					dataset.beginTime = moment(this.searchForm.beginTime).format('YYYYMMDD')
					dataset.endTime = moment(this.searchForm.endTime).format('YYYYMMDD')
				} else {
					dataset.beginTime = moment(this.searchForm.beginTime).startOf("month").format("YYYYMMDD")
					dataset.endTime = moment(this.searchForm.endTime).endOf("month").format("YYYYMMDD")
				}
        let downUrl = util.getBaseURL() + 'ZNEasy/excel/download/plan'
				this.spinning = true
        exportFile(downUrl, dataset).then(res => {
					console.log(res)
					this.spinning = false
        })
    },
		// 弹窗确认按钮
		updataWin2() {
			if (this.visibleType2 === 'addStrandOne') {
				this.setTasks()
			} else if (this.visibleType2 === 'addStandard') {
				this.addNewStrandBtn()
			}
		},
		// 关闭弹窗
		closeModal2() {
			if(this.visibleType2 === 'addStrandOne') {
				this.visible2 = false
			} else if(this.visibleType2 === 'addStandard') {
				this.visible2 = false
				this.standard = {
					m_element: '',
					m_project: '',
					m_content: '',
					m_method: '',
					m_standard: '',
					m_way: false,
					m_photo: false,
					m_minNum: 0,
					m_maxNum: 0,
				}
			}
		}
	},
}
</script>

<style scoped lang="less">
/deep/ .ant-spin-container {
	height: 100%;
}
.specialEqus {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	// display: flex;
	// justify-content: space-between;
	padding: 20px;
	box-sizing: border-box;
	overflow: auto;
}
.info-title {
	display: flex;
	height: 40px;
	align-items: center;
	.info-title-icon {
		width: 4px;
		height: 14px;
		background: #1890ff;
	}
	.info-title-text {
		margin-left: 8px;
		font-size: 16px;
		color: #333333;
	}
}
.specialEqus-title {
	width: 100%;
	height: 56px;
	background: #ffffff;
	border-radius: 4px 4px 0px 0px;
	color: #333333;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.infospecialEqus-title {
	font-size: 24px;
	color: #333333;
	text-align: left;
}
.infospecialEqus-smtitle {
	display: flex;
	color: #999999;
	font-size: 12px;
	text-align: left;
	margin-top: 16px;
}
.infospecialEqus-content {
	margin-top: 24px;
}
.mg-l-5 {
	margin-left: 5px;
}
.mg-l-50 {
	margin-left: 50px;
}
.title-icon {
	width: 4px;
	height: 16px;
	background: #1890ff;
	display: inline-block;
	margin-right: 12px;
}
.col-label {
	width: 130px;
	text-align: left;
}
.col-value {
	width: 540px;
}

.specialEqus-content {
	background: #ffffff;
	width: 100%;
	height: calc(100% - 56px);
	display: flex;
	padding-top: 32px;
}
.flush {
	font-size: 14px;
	color: #1890ff;
}
.content-right-btnGroup {
	height: 56px;
	width: 100%;
	line-height: 56px;
	margin-top: 10px;
}
.content-right-form {
	// width: 100%;
	background: #ffffff;
	padding: 0px 21px 0px 17px;
	.form-search {
		display: flex;
		align-items: center;
		width: 420px;
	}
}
.form-label {
	font-size: 14px;
	color: #333333;
	width: 70px;
	text-align: right;
	margin-right: 16px;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.content-left {
	width: 312px;
	height: calc(100% - 30px);
	background: #ffffff;
	margin-left: 12px;
	overflow: auto;
	.content-left-turiel {
    margin-top: 16px;
    padding: 14px 13px;
    height: 156px;
    background: #FAFAFA;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    .turiel-flex {
      display: flex;
      flex-wrap: wrap;
      .turiel-flex-item {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        width: 50%;
        .turiel-flex-item-left {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 39px;
          height: 24px;
          border-radius: 2px;
          font-size: 14px;
          font-weight: 400;
					margin-right: 14px;
        }
      }
    }
  }
}
.content-right {
	height: calc(100% - 30px);
	width: calc(100% - 375px);
	margin-left: 27px;
}
.content-right-message {
	margin-top: 20px;
}
.marginb20 {
  margin-bottom: 20px;
}

.marginb8 {
  margin-bottom: 8px;
}

.cambridgeBlue {
	border-radius: 0px !important;
  background: #FFFFFF;
  color: #333333;
  border-bottom: 1px solid #F87500;
}
.whiteBlue {
  background: #FFFFFF;
  color: #333333;
  border: 1px solid #1890FF;
}
.blueWhite {
  background: #1890FF;
  color: #FFFFFF;
  border: 1px solid #1890FF;
}
.cambridgeWhite {
  background: #FFFFFF;
  color: #999999;
  border: 1px solid #FFFFFF;
}
.whiteBlack {
  background: #FFFFFF;
  color: #333333;
  border: 1px solid #FFFFFF;
}
.mission-content {
	width: 90%;
  margin: 36px auto 0px;
}
.margint12 {
	margin-top: 12px;
}
.successMessage {
	width: 60%;
	margin: 80px auto;
	text-align: center;
}
.successMessage-title {
	text-align: center;
	color: #272727;
	font-size: 20px;
	margin: 24px 0px 12px;
}
.successMessage-subTitle {
	text-align: center;
	color: #B2B2B2;
	font-size: 14px;
}
.info-table {
  border: 1px solid #f0f0f0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.info-table-flex {
	display: flex;
}
.info-table-flex-item {
	display: flex;
}
.info-table-flex-item-l {
	padding: 10px 24px;
  border-right: 1px solid #f0f0f0;
  width: 220px;
  background-color: #fafafa;
}
.info-table-flex-item-r {
	padding: 10px 24px;
  border-right: 1px solid #f0f0f0;
	color: rgba(0, 0, 0, 0.65);
  width: 235px;
}
</style>
