<template>
  <div class="oplList">
    <div class="oplList-title">
      <span class="title-icon"> </span>
      <span>技术支持申请</span>
    </div>
    <div class="oplList-content">
      <div class="content-right">
        <!-- 查询区域 -->
        <div class="content-right-form">
          <conditionQuery :headData="headData"
                          @onTimeChange="onTimeChange"
                          @onInputChange="onInputChange"
                          @onSelectClick="getOplList" />
        </div>
        <!-- 功能按钮区域 -->
        <div class="content-right-btnGroup">
          <a-button-group>
            <a-button @click="addOplType"
                      name="addOplType">处理</a-button>
            <a-button @click="delOplListBtn"> 删除 </a-button>
          </a-button-group>
        </div>
        <!-- 表格区域 -->
        <div class="content-right-table">
          <a-table :columns="columns"
                   :row-selection="rowSelection"
                   :pagination="false"
                   :data-source="dataSource">
            <span slot="time"
                  slot-scope="time">
              {{ time?$moment(time).format('YYYY-MM-DD'):'-' }}
            </span>
            <span slot="status"
                  slot-scope="scope">
                <a-badge v-if="scope === 0" status="error" text="待处理"></a-badge>
                <a-badge v-else status="default" text="已处理"></a-badge>
            </span>
          </a-table>
          <a-pagination size="small"
                        :total="total"
                        :defaultCurrent="pageIndex"
                        :defaultPageSize="pageSize"
                        show-size-changer
                        show-quick-jumper
                        :show-total="(total) => `总共 ${total} 条`" />
        </div>
      </div>
    </div>
    <!-- 弹窗区域 -->
    <jywsDialog :visible="visible"
                :title="modalTitle"
                :width="visibleWidth"
                :roger="jywsDialogBottomRoger"
                :typePrimary="typePrimary"
                :bottom-name="jywsDialogBottomName"
                @updataClose="closeModal"
                @updataWin="updataWin">
      <a-spin :spinning="spinning">
        <div class="modal-body">
          <!-- 新增/修改opl类型弹窗 -->
          <div v-if="
							visibleType === 'addOplType' ||
							visibleType === 'editOplType'
						"
               class="modal-body-box">
            <a-row :gutter="[32, 24]"
                   justify="center"
                   align="middle">
              <a-col :span="24"
                     class="flex">
                <div class="col-label must">技术员</div>
                <div class="col-value">
                  <a-input v-model="oplTypeName"
                           placeholder="请输入" />
                </div>
              </a-col>
            </a-row>
          </div>
          <!-- 新增opl课程列表弹窗 -->
          <div v-else-if="
							visibleType === 'addOplList' ||
							visibleType === 'editOplList'
						"
               class="modal-body-box">
            <a-row :gutter="[32, 24]"
                   justify="center"
                   align="middle">
              <a-col :span="12"
                     :push="1"
                     class="col-flex">
                <div class="col-label must">标题</div>
                <div class="col-value">
                  <a-input v-model="addoplForm.title"
                           placeholder="请输入标题" />
                </div>
              </a-col>
              <a-col :span="12"
                     :push="1"
                     class="col-flex">
                <div class="col-label">OPL类型</div>
                <div class="col-value">
                  <a-tree-select v-model="addoplForm.oplTypeId"
                                 style="width: 100%"
                                 :dropdown-style="{
											maxHeight: '400px',
											overflow: 'auto',
										}"
                                 placeholder="请选择类型"
                                 :tree-data="treeData"
                                 :replaceFields="replaceFields"
                                 @change="oplTypeChange"></a-tree-select>
                </div>
              </a-col>
            </a-row>
            <a-row :gutter="[32, 24]"
                   justify="center"
                   align="middle">
              <a-col :span="12"
                     :push="1"
                     class="col-flex">
                <div class="col-label must">附件</div>
                <div class="col-value">
                  <uploadOss @setFileList="setFileList"
                             ref="uploadOss"
                             @setFileListName="setFileListName"
                             :showList="true">
                    <a-button>
                      <a-icon type="upload" />上传附件
                    </a-button>
                  </uploadOss>
                  <p v-if="visibleType === 'editOplList'">上次附件：{{this.addoplForm.accessoryName}}</p>
                </div>
              </a-col>
            </a-row>
            <a-row :gutter="[32, 24]">
              <div style="border: 1px solid #ccc">
                <Toolbar style="border-bottom: 1px solid #ccc"
                         :editor="editor"
                         :defaultConfig="toolbarConfig"
                         :mode="mode" />
                <Editor style="height: 500px; overflow-y: hidden"
                        v-model="html"
                        :defaultConfig="editorConfig"
                        :mode="mode"
                        @onCreated="onCreated" />
              </div>
            </a-row>
            <!-- 富文本区域 -->
          </div>
          <!-- 关联课程弹窗 -->
          <div v-else-if="visibleType === 'editOplListType'">
            <a-row :gutter="[32, 24]"
                   justify="center"
                   align="middle">
              <a-col :span="24"
                     class="flex">
                <div class="col-label must">opl类型</div>
                <div class="col-value">
                  <a-tree-select v-model="addoplForm.oplTypeId"
                                 style="width: 100%"
                                 :dropdown-style="{
											maxHeight: '400px',
											overflow: 'auto',
										}"
                                 placeholder="请选择类型"
                                 :tree-data="treeData"
                                 :replaceFields="replaceFields"
                                 @change="oplTypeChange"></a-tree-select>
                </div>
              </a-col>
            </a-row>
          </div>
          <!-- opl课程详情 -->
          <div v-else-if="visibleType === 'infoOplList'">
            <div class="infoOplList-title">
              {{ this.infoOplList.title }}
            </div>
            <div class="infoOplList-smtitle">
              <div class="mg-l-5">
                创建人：{{ this.infoOplList.createUserName }}
              </div>
              <div class="mg-l-50">
                创建时间：{{
									$moment(this.infoOplList.createTime).format(
										'YYYY-MM-DD HH:mm'
									)
								}}
              </div>
              <div class="mg-l-50">
                附件：<a :href="this.infoOplList.accessoryUrl"
                   target="_blank">{{
										this.infoOplList.accessoryName
									}}（点击下载）</a>
              </div>
            </div>
            <div class="infoOplList-content"
                 v-html="this.infoOplList.content">
              <!-- {{ `${this.infoOplList.content}` }} -->
            </div>
          </div>
        </div>
      </a-spin>
    </jywsDialog>
  </div>
</template>

<script>
import uploadOss from '@/components/uploadOss'
import jywsDialog from '@/components/jywsDialog.vue'
import * as util from '@/core/util'
import conditionQuery from '@/components/conditionQuery.vue'
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
// import currency from '@/core/currency'
export default {
  components: {
    conditionQuery,
    jywsDialog,
    uploadOss,
    Editor,
    Toolbar,
  },
  data() {
    return {
      selectedRows: [],
      selectedRowKeys: [],
      // rowSelection,
      columns: [
        {
          title: '预约时间',
          dataIndex: 'subscribeTime',
          key: 'subscribeTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '客户名称',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '沟通内容',
          dataIndex: 'content',
          key: 'content',
        },
        {
          title: '申请提交时间',
          dataIndex: 'applyTime',
          key: 'applyTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '申请人',
          dataIndex: 'applicant',
          key: 'applicant',
        },
        {
          title: '手机号码',
          dataIndex: 'applicantPhone',
          key: 'applicantPhone',
        },
        {
          title: '处理状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '技术员',
          dataIndex: 'technician',
          key: 'technician',
        },
        {
          title: '处理时间',
          dataIndex: 'processingTime',
          key: 'processingTime',
          scopedSlots: { customRender: 'time' },
        },
      ],
      headData: [
        {
          key: 1,
          name: '客户名称',
          component: 'a-input',
          value: '',
          duplicate: 'name',
          placeholder: '请输入',
        },{
          key: 2,
          name: '申请时间',
          component: 'a-range-picker',
          value: [],
        },
        {
          key: 3,
          name: '申请人',
          component: 'a-input',
          value: '',
          duplicate: 'applicant',
          placeholder: '请输入',
        },{
          key: 4,
          name: '技术员',
          component: 'a-input',
          value: '',
          duplicate: 'technician',
          placeholder: '请输入',
        },
      ],
      tenantId: '',
      // 新增opl课程参数
      addoplForm: {
        oplTypeId: undefined,
        oplTypeName: '',
        title: '',
        accessoryUrl: '',
        accessoryName: '',
        content: '',
        tenantId: '',
      },
      // 查询opl课程列表参数
      searchForm: {
        title: '',
        startTime: '',
        endTime: '',
      },
      dataSource: [],
      visible: false,
      visibleType: '',
      visibleWidth: '520px',
      typePrimary: 'primary',
      modalTitle: '',
      oplTypeName: '',
      spinning: false,
      // 树组件 键值对
      replaceFields: {
        children: 'children',
        title: 'oplName',
        key: 'oplId',
        value: 'oplId',
      },
      treeData: [],
      oplId: '',
      selectTreeData: {},
      pageIndex: 1,
      pageSize: 10,
      total: 10,
      jywsDialogBottomRoger: '取消',
      jywsDialogBottomName: '确认',
      editor: null,
      html: '<p></p>',
      toolbarConfig: {},
      editorConfig: {
        placeholder: '请输入内容...',
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {},
      },
      mode: 'default', // or 'simple'
      infoOplList: {
        title: '',
        createUserName: '',
        accessoryName: '',
        createTime: '',
        content: '',
        accessoryUrl: '',
      },
      userId: '',
      userName: '',
    }
  },
  created() {
    // var str = this.$route.fullPath.split('=')
    // this.tenantId = str[1].split('&')[0]
    // this.userId = str[2].split('&')[0]
    // this.userName = decodeURIComponent(str[3].split('&')[0])
    // this.addoplForm.tenantId = str[1].split('&')[0]
    this.editorConfig.MENU_CONF['uploadImage'] = {
      server: '/api/upload-image',
      fieldName: 'custom-field-name',
      // 继续写其他配置...

      //【注意】不需要修改的不用写，wangEditor 会去 merge 当前其他配置
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
      }
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    // }, 1500)
    // this.getOplTypeTree()
    this.getOplList()
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    // 选择opl类型
    oplTypeChange(val, obj) {
      this.addoplForm.oplTypeId = val
      this.addoplForm.oplTypeName = obj[0]
    },
    // 递归给树增加icon图标
    addIconSlot(val) {
      val.title = val.oplName
      val.key = val.oplId
      if (val.parentOpl === '0') {
        val.slots = { icon: 'home' }
      } else {
        val.slots = { icon: 'hdd' }
      }
      if (val.children && val.children.length !== 0) {
        val.children.forEach((item) => {
          this.addIconSlot(item)
        })
      }
    },
    // 上传附件 -- 获取oss地址
    setFileList(val) {
      this.addoplForm.accessoryUrl = val
    },
    setFileListName(val) {
      this.addoplForm.accessoryName = val
    },
    // 查询区域 - 时间范围变动
    onTimeChange(val) {
      this.searchForm.startTime = val.dateString[0]
      this.searchForm.endTime = val.dateString[1]
    },
    // 查询区域 - 输入框变动
    onInputChange(item) {
      if (item.duplicate == 'name') {
        this.searchForm.name = item.value
      }else if (item.duplicate == 'applicant') {
        this.searchForm.applicant = item.value
      }else if (item.duplicate == 'technician') {
        this.searchForm.technician = item.value
      }
    },
    // 获取opl类型树结构
    getOplTypeTree() {
      util.get('oplType/getTree/' + this.tenantId).then((res) => {
        if (res.data) {
          res.data.forEach((item) => {
            this.addIconSlot(item)
          })
        }
        this.treeData = res.data || []
      })
    },
    // 获取opl课程分页列表
    getOplList() {
      let dataset = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        name: this.searchForm.name,
        applicant: this.searchForm.applicant,
        technician: this.searchForm.technician,
        startTime: this.searchForm.startTime,
        endTime: this.searchForm.endTime,
      }
      util.postform('technicalSupport/getPageList', dataset).then((res) => {
        console.log(res);
        res.data.records.forEach((item) => {
          item.key = item.id
        })
        this.dataSource = res.data.records
        this.total = res.data.total
      })
    },
    // 选中树的回调
    treeSelect(val, data) {
      this.selectTreeData = data
      this.oplId = val.length !== 0 ? val[0] : ''
      this.getOplList()
    },
    onChange(val) {
      console.log(val)
    },
    // 开启弹窗
    showModal(e) {
      let name = e.target.name
      this.jywsDialogBottomName = '确认'
      this.jywsDialogBottomRoger = '取消'
      this.typePrimary = 'primary'
      switch (name) {
        case 'addOplType':
          this.modalTitle = '处理申请'
          this.visibleType = 'addOplType'
          this.visible = true
          this.visibleWidth = '960px'
          break
        case 'editOplType':
          this.modalTitle = '修 改'
          this.visibleType = 'editOplType'
          this.visible = true
          this.visibleWidth = '520px'
          break
        case 'editOplListType':
          this.modalTitle = '关联类型'
          this.visibleType = 'editOplListType'
          this.visible = true
          this.visibleWidth = '520px'
          break
        case 'addOplList':
          this.modalTitle = '新 增'
          this.visibleType = 'addOplList'
          this.visible = true
          this.visibleWidth = '960px'
          break
        case 'editOplList':
          this.modalTitle = '修 改'
          this.visibleType = 'editOplList'
          this.visible = true
          this.visibleWidth = '960px'
          break
        default:
          break
      }
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false
      if (this.addoplForm.accessoryName) {
        this.$refs.uploadOss.clearFileList()
      }
      this.oplTypeName = ''
      this.addoplForm = {
        oplTypeId: undefined,
        oplTypeName: '',
        title: '',
        accessoryUrl: '',
        accessoryName: '',
        content: '',
        tenantId: this.tenantId,
      }
      this.html = '<p></p>'
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    // 打开弹窗按钮
    addOplType(e) {
      let name = e.target.name
      // 修改opl类型
      if (name === 'addOplType') {
        if (this.selectedRows.length !== 1) {
          this.$warning({
            title: '请选择一条记录进行处理操作！',
          })
          return
        }
      }
      if (name === 'editOplList') {
        if (this.selectedRows.length !== 1) {
          this.$warning({
            title: '请选择一条记录进行修改操作！',
          })
          return
        }
        this.addoplForm = this.selectedRows[0]
        // this.html = `<p>${this.selectedRows[0].content}</p>`
        this.html = `<p>${this.selectedRows[0].content}</p>`
      }
      if (name === 'editOplListType') {
        if (this.selectedRows.length === 0) {
          this.$warning({
            title: '请选择至少一条记录进行关联类型！',
          })
          return
        }
      }
      this.showModal(e)
    },
    // 查看课程详情弹窗
    openinfoOplList(scope) {
      this.modalTitle = '详 情'
      this.jywsDialogBottomName = '关闭'
      this.jywsDialogBottomRoger = ''
      this.typePrimary = ''
      this.visibleType = 'infoOplList'
      this.visibleWidth = '960px'
      this.visible = true
      this.infoOplList = scope
      console.log(scope)
    },
    updataWin() {
      if (this.visibleType === 'addOplType') {
        this.addOplTypeBtn()
      } else if (this.visibleType === 'editOplType') {
        this.editOplTypeBtn()
      } else if (this.visibleType === 'addOplList') {
        // this.addoplForm.content = this.editor.getText()
        this.addoplForm.content = this.html
        this.addOplListBtn()
      } else if (this.visibleType === 'editOplList') {
        this.editOplListBtn()
      } else if (this.visibleType === 'infoOplList') {
        this.closeModal()
      } else if (this.visibleType === 'editOplListType') {
        this.editOplListTypeBtn()
      }
    },
    // 新增类型弹窗 确认
    addOplTypeBtn() {
      if (this.oplTypeName === '') {
        this.$message.error('请输入技术员名称！')
        return
      }
      let dataset = {
        technician: this.oplTypeName,
        status: 1,
        id:this.selectedRowKeys[0]
      }
      
      this.spinning = true
      util.postform('/technicalSupport/processApplication', dataset).then((res) => {
        console.log(res)
        if (res.code === 200) {
          this.$message.success('操作成功！')
          this.spinning = false
          this.closeModal()
          this.getOplList()
        }
      })
    },
    // 修改类型弹窗 确认
    editOplTypeBtn() {
      let dataset = {
        name: this.oplTypeName,
        id: this.selectTreeData.oplId,
        tenantId: this.tenantId,
      }
      this.spinning = true
      util.postform('oplType/update', dataset).then((res) => {
        if (res.code === 200) {
          this.$message.success('修改成功！')
          this.spinning = false
          this.closeModal()
          this.getOplTypeTree()
        }
      })
    },
    // 删除opl类型接口
    delOplType() {
      let that = this
      if (!this.selectTreeData.oplId || this.selectTreeData.oplId === '') {
        this.$info({
          title: '提示',
          content: '请选择需要删除的类型！',
          onOk() {},
        })
        return
      }
      this.$confirm({
        title: '是否确认要删除此类型?',
        onOk() {
          util
            .postform('oplType/delete/' + that.selectTreeData.oplId)
            .then((res) => {
              if (res.code === 200) {
                that.$message.success('删除成功！')
                that.oplId = ''
                that.closeModal()
                that.getOplTypeTree()
                that.getOplList()
              }
            })
        },
        onCancel() {},
      })
    },
    // 新增opl课程接口
    addOplListBtn() {
      if (this.addoplForm.title === '') {
        this.$message.error('标题不能为空！')
        return
      }
      if (this.addoplForm.accessoryUrl === '') {
        this.$message.error('请选择附件上传！')
        return
      }
      this.addoplForm.createUserName = this.userName
      util.postform('oplCourse/add', this.addoplForm).then((res) => {
        if (res.code === 200) {
          this.$message.success('新增成功！')
          this.closeModal()
          this.getOplList()
        }
      })
    },
    // 修改opl课程接口
    editOplListBtn() {
      if (this.addoplForm.title === '') {
        this.$message.error('标题不能为空！')
        return
      }
      if (this.addoplForm.accessoryUrl === '') {
        this.$message.error('请选择附件上传！')
        return
      }
      this.addoplForm.createUserName = this.userName
      util.postform('oplCourse/update', this.addoplForm).then((res) => {
        if (res.code === 200) {
          this.$message.success('修改成功！')
          this.closeModal()
          this.getOplList()
        }
      })
    },
    // 删除opl课程接口
    delOplListBtn() {
      let that = this
      if (this.selectedRows.length === 0) {
        this.$info({
          title: '提示',
          content: '请选择需要删除的记录！',
          onOk() {},
        })
        return
      }
      this.$confirm({
        title: '是否确认要删除此记录?',
        onOk() {
          util
            .postform('technicalSupport/batchDelete', that.selectedRowKeys)
            .then((res) => {
              if (res.code === 200) {
                that.$message.success('删除成功！')
                that.closeModal()
                that.getOplList()
              }
            })
        },
        onCancel() {},
      })
    },
    // 下载附件
    downFile() {
      if (this.selectedRows.length !== 1) {
        this.$warning({
          title: '请选择一条记录进行附件下载！',
        })
        return
      }
      if (!this.selectedRows[0].accessoryUrl) {
        this.$warning({
          title: '该记录暂无附件！',
        })
        return
      }
      window.open(this.selectedRows[0].accessoryUrl)
    },
    // 关联类型
    editOplListTypeBtn() {
      let ids = []
      this.selectedRows.forEach((item) => {
        ids.push(item.id)
      })
      let dataset = {
        cids: ids,
        typeId: this.addoplForm.oplTypeId,
        typeName: this.addoplForm.oplTypeName,
      }
      util.postform('oplCourse/updateType', dataset).then((res) => {
        if (res.code === 200) {
          this.$message.success('修改成功！')
          this.getOplList()
          this.closeModal()
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.oplList {
  background-color: #f0f2f5;
  width: 100%;
  height: 100%;
  // display: flex;
  // justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}
.oplList-title {
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  color: #333333;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.infoOplList-title {
  font-size: 24px;
  color: #333333;
  text-align: left;
}
.infoOplList-smtitle {
  display: flex;
  color: #999999;
  font-size: 12px;
  text-align: left;
  margin-top: 16px;
}
.infoOplList-content {
  margin-top: 24px;
}
.mg-l-5 {
  margin-left: 5px;
}
.mg-l-50 {
  margin-left: 50px;
}
.title-icon {
  width: 4px;
  height: 16px;
  background: #1890ff;
  display: inline-block;
  margin-right: 12px;
}
.col-value {
  width: 200px;
}
.oplList-content {
  background: #ffffff;
  width: 100%;
  height: calc(100% - 56px);
  display: flex;
  padding-top: 32px;
}
.flush {
  font-size: 14px;
  color: #1890ff;
}
.content-right-btnGroup {
  height: 56px;
  width: 100%;
  line-height: 56px;
  margin-top: 10px;
}
.content-right-form {
  // width: 100%;
  background: #ffffff;
  padding: 0px 21px 0px 17px;
  .form-search {
    display: flex;
    align-items: center;
    width: 420px;
  }
}
.form-label {
  font-size: 14px;
  color: #333333;
  width: 70px;
  text-align: right;
  margin-right: 16px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-left {
  width: 220px;
  height: calc(100% - 30px);
  background: #ffffff;
  border: 1px solid #e8e8e8;
  margin-left: 12px;
  .content-left-title {
    height: 48px;
    width: 100%;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-left-btnGroup {
    height: 48px;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
  }
}
.content-right {
  height: calc(100% - 30px);
  width: 100%;
  margin-left: 27px;
}
</style>
