var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"oplList"},[_vm._m(0),_c('div',{staticClass:"oplList-content"},[_c('div',{staticClass:"content-right"},[_c('div',{staticClass:"content-right-form"},[_c('conditionQuery',{attrs:{"headData":_vm.headData},on:{"onTimeChange":_vm.onTimeChange,"onInputChange":_vm.onInputChange,"onSelectClick":_vm.getOplList}})],1),_c('div',{staticClass:"content-right-btnGroup"},[_c('a-button-group',[_c('a-button',{attrs:{"name":"addOplType"},on:{"click":_vm.addOplType}},[_vm._v("处理")]),_c('a-button',{on:{"click":_vm.delOplListBtn}},[_vm._v(" 删除 ")])],1)],1),_c('div',{staticClass:"content-right-table"},[_c('a-table',{attrs:{"columns":_vm.columns,"row-selection":_vm.rowSelection,"pagination":false,"data-source":_vm.dataSource},scopedSlots:_vm._u([{key:"time",fn:function(time){return _c('span',{},[_vm._v(" "+_vm._s(time?_vm.$moment(time).format('YYYY-MM-DD'):'-')+" ")])}},{key:"status",fn:function(scope){return _c('span',{},[(scope === 0)?_c('a-badge',{attrs:{"status":"error","text":"待处理"}}):_c('a-badge',{attrs:{"status":"default","text":"已处理"}})],1)}}])}),_c('a-pagination',{attrs:{"size":"small","total":_vm.total,"defaultCurrent":_vm.pageIndex,"defaultPageSize":_vm.pageSize,"show-size-changer":"","show-quick-jumper":"","show-total":function (total) { return ("总共 " + total + " 条"); }}})],1)])]),_c('jywsDialog',{attrs:{"visible":_vm.visible,"title":_vm.modalTitle,"width":_vm.visibleWidth,"roger":_vm.jywsDialogBottomRoger,"typePrimary":_vm.typePrimary,"bottom-name":_vm.jywsDialogBottomName},on:{"updataClose":_vm.closeModal,"updataWin":_vm.updataWin}},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-body"},[(
							_vm.visibleType === 'addOplType' ||
							_vm.visibleType === 'editOplType'
						)?_c('div',{staticClass:"modal-body-box"},[_c('a-row',{attrs:{"gutter":[32, 24],"justify":"center","align":"middle"}},[_c('a-col',{staticClass:"flex",attrs:{"span":24}},[_c('div',{staticClass:"col-label must"},[_vm._v("技术员")]),_c('div',{staticClass:"col-value"},[_c('a-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.oplTypeName),callback:function ($$v) {_vm.oplTypeName=$$v},expression:"oplTypeName"}})],1)])],1)],1):(
							_vm.visibleType === 'addOplList' ||
							_vm.visibleType === 'editOplList'
						)?_c('div',{staticClass:"modal-body-box"},[_c('a-row',{attrs:{"gutter":[32, 24],"justify":"center","align":"middle"}},[_c('a-col',{staticClass:"col-flex",attrs:{"span":12,"push":1}},[_c('div',{staticClass:"col-label must"},[_vm._v("标题")]),_c('div',{staticClass:"col-value"},[_c('a-input',{attrs:{"placeholder":"请输入标题"},model:{value:(_vm.addoplForm.title),callback:function ($$v) {_vm.$set(_vm.addoplForm, "title", $$v)},expression:"addoplForm.title"}})],1)]),_c('a-col',{staticClass:"col-flex",attrs:{"span":12,"push":1}},[_c('div',{staticClass:"col-label"},[_vm._v("OPL类型")]),_c('div',{staticClass:"col-value"},[_c('a-tree-select',{staticStyle:{"width":"100%"},attrs:{"dropdown-style":{
											maxHeight: '400px',
											overflow: 'auto',
										},"placeholder":"请选择类型","tree-data":_vm.treeData,"replaceFields":_vm.replaceFields},on:{"change":_vm.oplTypeChange},model:{value:(_vm.addoplForm.oplTypeId),callback:function ($$v) {_vm.$set(_vm.addoplForm, "oplTypeId", $$v)},expression:"addoplForm.oplTypeId"}})],1)])],1),_c('a-row',{attrs:{"gutter":[32, 24],"justify":"center","align":"middle"}},[_c('a-col',{staticClass:"col-flex",attrs:{"span":12,"push":1}},[_c('div',{staticClass:"col-label must"},[_vm._v("附件")]),_c('div',{staticClass:"col-value"},[_c('uploadOss',{ref:"uploadOss",attrs:{"showList":true},on:{"setFileList":_vm.setFileList,"setFileListName":_vm.setFileListName}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v("上传附件 ")],1)],1),(_vm.visibleType === 'editOplList')?_c('p',[_vm._v("上次附件："+_vm._s(this.addoplForm.accessoryName))]):_vm._e()],1)])],1),_c('a-row',{attrs:{"gutter":[32, 24]}},[_c('div',{staticStyle:{"border":"1px solid #ccc"}},[_c('Toolbar',{staticStyle:{"border-bottom":"1px solid #ccc"},attrs:{"editor":_vm.editor,"defaultConfig":_vm.toolbarConfig,"mode":_vm.mode}}),_c('Editor',{staticStyle:{"height":"500px","overflow-y":"hidden"},attrs:{"defaultConfig":_vm.editorConfig,"mode":_vm.mode},on:{"onCreated":_vm.onCreated},model:{value:(_vm.html),callback:function ($$v) {_vm.html=$$v},expression:"html"}})],1)])],1):(_vm.visibleType === 'editOplListType')?_c('div',[_c('a-row',{attrs:{"gutter":[32, 24],"justify":"center","align":"middle"}},[_c('a-col',{staticClass:"flex",attrs:{"span":24}},[_c('div',{staticClass:"col-label must"},[_vm._v("opl类型")]),_c('div',{staticClass:"col-value"},[_c('a-tree-select',{staticStyle:{"width":"100%"},attrs:{"dropdown-style":{
											maxHeight: '400px',
											overflow: 'auto',
										},"placeholder":"请选择类型","tree-data":_vm.treeData,"replaceFields":_vm.replaceFields},on:{"change":_vm.oplTypeChange},model:{value:(_vm.addoplForm.oplTypeId),callback:function ($$v) {_vm.$set(_vm.addoplForm, "oplTypeId", $$v)},expression:"addoplForm.oplTypeId"}})],1)])],1)],1):(_vm.visibleType === 'infoOplList')?_c('div',[_c('div',{staticClass:"infoOplList-title"},[_vm._v(" "+_vm._s(this.infoOplList.title)+" ")]),_c('div',{staticClass:"infoOplList-smtitle"},[_c('div',{staticClass:"mg-l-5"},[_vm._v(" 创建人："+_vm._s(this.infoOplList.createUserName)+" ")]),_c('div',{staticClass:"mg-l-50"},[_vm._v(" 创建时间："+_vm._s(_vm.$moment(this.infoOplList.createTime).format( 'YYYY-MM-DD HH:mm' ))+" ")]),_c('div',{staticClass:"mg-l-50"},[_vm._v(" 附件："),_c('a',{attrs:{"href":this.infoOplList.accessoryUrl,"target":"_blank"}},[_vm._v(_vm._s(this.infoOplList.accessoryName)+"（点击下载）")])])]),_c('div',{staticClass:"infoOplList-content",domProps:{"innerHTML":_vm._s(this.infoOplList.content)}})]):_vm._e()])])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"oplList-title"},[_c('span',{staticClass:"title-icon"}),_c('span',[_vm._v("技术支持申请")])])}]

export { render, staticRenderFns }