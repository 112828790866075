<template>
	<div class="outputManagement">
		<div class="outputManagement-title">
			<span class="title-icon"> </span>
			<span>生产记录</span>
		</div>
		<div class="outputManagement-content">
			<div class="content-right">
				<!-- 查询区域 -->
				<div class="content-right-form">
					<conditionQuery
						:headData="headData"
						:exportState="true"
            @onTimeChange="onTimeChange"
						@exportExcel="exportExcel"
						@onHandleChange="onHandleChange"
						@onInputChange="onInputChange"
						@onSelectClick="getoutputManagement"
					/>
				</div>
				<!-- 功能按钮区域 -->
				<div class="content-right-btnGroup">
					<a-button-group>
						<a-button @click="onbtnGroup" name="addoutputManagement"
							>新增</a-button
						>
						<a-button @click="onbtnGroup" name="editoutputManagement">
							修改
						</a-button>
						<a-button @click="delBtn"> 删除 </a-button>
						<a-button @click="onbtnGroup" name="importExecl">
							批量导入
						</a-button>
					</a-button-group>
				</div>
				<!-- 表格区域 -->
				<div class="content-right-table">
					<a-table
            :loading="loading"
						:columns="columns"
						:row-selection="rowSelection"
						:data-source="dataSource"
						:pagination="false"
						:scroll="{ y: 380 }"
					>
						<span slot="time" slot-scope="time">
							{{
								time
									? $moment(time).format('YYYY-MM-DD HH:mm')
									: '-'
							}}
						</span>
            <span slot="dateTime" slot-scope="dateTime">
							{{
								dateTime
									? $moment(dateTime).format('YYYY-MM-DD')
									: '-'
							}}
						</span>
						<span slot="cycle" slot-scope="text, record">
							{{ record.cycle + record.cycleUnit }}
						</span>
						<span slot="executiveName" slot-scope="text, record">
							{{
								record.execType === 0
									? record.executiveName
									: record.nowRecipientsName
							}}
						</span>
						<span slot="credentialsState" slot-scope="text, record">
							<a-badge
								v-if="record.credentialsState === 0"
								status="success"
								text="正常"
							/>
							<a-badge
								v-else-if="record.credentialsState === 2"
								status="error"
								text="超期"
							/>
							<a-badge
								v-else-if="record.credentialsState === 1"
								status="warning"
								text="待检"
							/>
						</span>
						<span slot="action" slot-scope="scope">
							<a
								@click="openinfooutputManagement(scope)"
								name="infooutputManagement"
								>查看</a
							>
						</span>
					</a-table>
					<a-pagination
						@change="pageonChange"
						@showSizeChange="showSizeChange"
						size="small"
						:total="total"
						:defaultCurrent="searchForm.pageIndex"
						:defaultPageSize="searchForm.pageSize"
						show-size-changer
						show-quick-jumper
						:show-total="(total) => `总共 ${total} 条`"
					/>
				</div>
			</div>
		</div>
		<!-- 弹窗区域 -->
		<jywsDialog
			:visible="visible"
			:title="modalTitle"
			:width="visibleWidth"
			:roger="jywsDialogBottomRoger"
			:bottom-name="jywsDialogBottomName"
			@updataClose="closeModal"
			:typePrimary="typePrimary"
			@updataWin="updataWin"
		>
			<a-spin :spinning="spinning">
				<!-- 新增产量管理 -->
				<div v-if="visibleType === 'addoutputManagement'">
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">订单号</div>
							<div class="col-value">
								<a-input
									v-model="addForm.orderId"
									placeholder="请输入订单号"
								/>
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">产品名称</div>
							<div class="col-value">
								<a-input
									v-model="addForm.product"
									placeholder="请输入产品名称"
								/>
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">计划数</div>
							<div class="col-value">
								<a-input
                  type='number'
									:min='0'
									onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
									v-model="addForm.planNum"
									placeholder="请输入计划数"
								/>
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">生产数</div>
							<div class="col-value">
								<a-input
                  type='number'
									:min='0'
									onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
									v-model="addForm.produceNum"
									placeholder="请输入生产数"
								/>
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">合格数</div>
							<div class="col-value">
								<a-input
                  type='number'
									:min='0'
									onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
									v-model="addForm.qualifiedNum"
									placeholder="请输入合格数"
								/>
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">开始时间</div>
							<div class="col-value">
								<a-date-picker
									style="width: 200px"
									v-model="addForm.startTime"
								/>
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">完成时间</div>
							<div class="col-value">
								<a-date-picker
									style="width: 200px"
									v-model="addForm.finishTime"
								/>
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex">
						</a-col>
					</a-row>
				</div>
				<!-- 修改产量管理 -->
				<div v-else-if="visibleType === 'editoutputManagement'">
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">订单号</div>
							<div class="col-value">
								<a-input
									v-model="addForm.orderId"
									placeholder="请输入订单号"
								/>
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">产品名称</div>
							<div class="col-value">
								<a-input
									v-model="addForm.product"
									placeholder="请输入产品名称"
								/>
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">计划数</div>
							<div class="col-value">
								<a-input
                  type='number'
									:min='0'
									onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
									v-model="addForm.planNum"
									placeholder="请输入计划数"
								/>
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">生产数</div>
							<div class="col-value">
								<a-input
                  type='number'
									:min='0'
									onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
									v-model="addForm.produceNum"
									placeholder="请输入生产数"
								/>
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">合格数</div>
							<div class="col-value">
								<a-input
                  type='number'
									:min='0'
									onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
									v-model="addForm.qualifiedNum"
									placeholder="请输入合格数"
								/>
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">开始时间</div>
							<div class="col-value">
								<a-date-picker
									style="width: 200px"
									v-model="addForm.startTime"
								/>
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">完成时间</div>
							<div class="col-value">
								<a-date-picker
									style="width: 200px"
									v-model="addForm.finishTime"
								/>
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex">
						</a-col>
					</a-row>
				</div>
				<!-- 变更计划 -->
				<div v-else-if="visibleType === 'changePlan'">
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="24" :push="2" class="col-flex">
							<div class="col-label must">提前提醒天数</div>
							<div class="col-value">
								<a-input
									v-model="addForm.executiveDays"
									placeholder="请输入提前提醒天数"
								/>
								<a-tooltip>
									<template slot="title">
										建议提前提醒天数大于年检所需天数，下次检验日期等于有效期限减去提前提醒天数
									</template>
									<a-icon
										type="info-circle"
										style="
											color: #1890ff;
											margin-left: 10px;
										"
									/>
								</a-tooltip>
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="24" :push="2" class="col-flex">
							<div class="col-label must">有效期限</div>
							<div class="col-value">
								<a-date-picker
									style="width: 200px"
									v-model="addForm.periodTime"
								/>
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="24" :push="2" class="col-flex">
							<div class="col-label must">下次检验日期</div>
							<div class="col-value">
								<a-input disabled v-model="nextReleaseTime" />
								<a-tooltip>
									<template slot="title">
										当下次检验日期小于当前时间时，会自动下发一条年检任务给执行人
									</template>
									<a-icon
										type="info-circle"
										style="
											color: #1890ff;
											margin-left: 10px;
										"
									/>
								</a-tooltip>
							</div>
						</a-col>
					</a-row>
				</div>
				<!-- 详情界面 -->
				<div v-else-if="visibleType === 'infooutputManagement'">
					<div class="info-content">
						<a-descriptions>
							<a-descriptions-item label="订单号">
								{{ this.infoList.orderId }}
							</a-descriptions-item>
							<a-descriptions-item label="产品名称" :span='2'>
								{{ this.infoList.product }}
							</a-descriptions-item>
							<a-descriptions-item label="计划数">
								{{
									this.infoList.planNum
								}}
							</a-descriptions-item>
							<a-descriptions-item label="生产数">
								{{ this.infoList.produceNum }}
							</a-descriptions-item>
							<a-descriptions-item label="合格数">
								{{ this.infoList.qualifiedNum }}
							</a-descriptions-item>
							<a-descriptions-item label="开始时间">
								{{
									$moment(this.infoList.startTime).format(
										'YYYY-MM-DD HH:mm'
									)
								}}
							</a-descriptions-item>
							<a-descriptions-item label="完成时间">
								{{
									$moment(
										this.infoList.finishTime
									).format('YYYY-MM-DD HH:mm')
								}}
							</a-descriptions-item>
						</a-descriptions>
					</div>
				</div>
			</a-spin>
		</jywsDialog>
    <importDataDialog 
    :visible="importVisible" 
    :title="importTitle" 
    :url="importUrl" 
    :current="current"
     @cancel="cancelClick" 
     @submit="submitClick"/>
	</div>
</template>

<script>
import conditionQuery from '@/components/conditionQuery.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import importDataDialog from '@/components/importDataDialog.vue'
import { urlDataDeal } from '@/core/date'
import moment from 'moment'
import * as util from '@/core/util'

export default {
	components: {
		conditionQuery,
		jywsDialog,
		// uploadOss,
		importDataDialog,
	},
	data() {
		return {
			tenantId: '',
			// 导入弹窗参数
			importVisible: false,
			importTitle: '批量导入',
			importUrl:
				'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/%E7%94%9F%E4%BA%A7%E8%AE%B0%E5%BD%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
			importDataSource: [],
			current: 0,
			// 弹窗参数
			visible: false,
			visibleType: '',
			modalTitle: '',
			visibleWidth: '960px',
			jywsDialogBottomRoger: '取消',
			jywsDialogBottomName: '确认',
			typePrimary: 'primary',
			spinning: false,
			infoList: {},
			// 新增接口传参
			addForm: {
				orderId: '',
				product: '',
				planNum: '',
				produceNum: '',
				qualifiedNum: '',
				startTime: '',
				finishTime: '',
        operUserId: '',
        operUserName: ''
			},
			editForm: {},
			infodataSource: [],
			// 详情记录表头
			infocolumns: [
				{
					title: '年检项目',
					dataIndex: 'project',
					key: 'project',
				},
				{
					title: '完成时间',
					dataIndex: 'finishTime',
					key: 'finishTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '有效期限',
					dataIndex: 'periodTime',
					key: 'periodTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '执行人/组',
					dataIndex: 'executiveName',
					key: 'executiveName',
					scopedSlots: { customRender: 'executiveName' },
				},
				{
					title: '任务状态',
					dataIndex: 'execState',
					key: 'execState',
					scopedSlots: { customRender: 'execState' },
				},
				{
					title: '检验结果',
					dataIndex: 'result',
					key: 'result',
					scopedSlots: { customRender: 'result' },
				},
				{
					title: '证书号',
					dataIndex: 'credentialsCode',
					key: 'credentialsCode',
				},
				{
					title: '证书附件',
					dataIndex: 'fileName',
					key: 'fileName',
				},
			],
			// 表格表头
			columns: [
				{
					title: '订单号',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '产品名称',
					dataIndex: 'product',
					key: 'product',
				},
				{
					title: '计划数',
					dataIndex: 'planNum',
					key: 'planNum',
				},
				{
					title: '生产数',
					dataIndex: 'produceNum',
					key: 'produceNum',
				},
				{
					title: '合格数',
					dataIndex: 'qualifiedNum',
					key: 'qualifiedNum',
				},
				{
					title: '开始时间',
					dataIndex: 'startTime',
					key: 'startTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '完成时间',
					dataIndex: 'finishTime',
					key: 'finishTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '操作者',
					dataIndex: 'operUserName',
					key: 'operUserName',
				},
        {
					title: '导入日期',
					dataIndex: 'createTime',
					key: 'createTime',
					scopedSlots: { customRender: 'dateTime' },
				},
				{
					title: '操作',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
				},
			],
			dataSource: [],
			selectedRowKeys: [],
			selectedRows: [],
			headData: [
        {
					key: 1,
					name: '开始时间',
					component: 'a-range-picker',
					value: [],
				},
				{
					key: 2,
					name: '产品名称',
					component: 'a-input',
					value: '',
					duplicate: 'productName',
					placeholder: '请输入产品名称',
				},
			],
			// 查询特种设备列表参数
			searchForm: {
				productName	: '',
				startTime:'',
        endTime:'',
				pageIndex: 1,
				pageSize: 10,
				tenantId: '',
			},
			total: 10,
			oneTreeVisble: true,
			deviceTreeList: [],
			importName: '下一步',
      operUserId:'',
      operUserName:'',
      loading:false
		}
	},
	created() {
		var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
    this.operUserId = str[2].split('&')[0]
    this.operUserName = decodeURIComponent(str[3].split('&')[0])
	},
	computed: {
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys = selectedRowKeys
					this.selectedRows = selectedRows
				},
			}
		},
		nextReleaseTime() {
			let timeText = ''
			if (this.addForm.periodTime && this.addForm.executiveDays) {
				timeText = moment(this.addForm.periodTime)
					.subtract(this.addForm.executiveDays, 'days')
					.format('YYYY-MM-DD')
			}
			return timeText
		},
	},
	mounted() {
		this.getoutputManagement()
	},
	methods: {
		// 展开设备树
		expandTree(item) {
			this.dynamicLoadingTree(item)
		},
		// 刷新
		reflush() {
			this.initTree()
		},
    // 查询区域 - 时间范围变动
		onTimeChange(val) {
			this.searchForm.startTime = val.dateString[0]
			this.searchForm.endTime = val.dateString[1]
		},
		// 选中设备树的回调
		treeSelect(val, data) {
			if (val.length !== 0) {
				this.searchForm.id = data.id
				if (data.type === 'dep') {
					this.searchForm.isDept = true
				} else {
					this.searchForm.isDept = false
				}
			} else {
				this.searchForm.id = ''
				this.searchForm.isDept = false
			}
			this.getoutputManagement()
		},
		// 导出
		exportExcel() {
      this.searchForm.tenantId = this.tenantId
			let urldata = urlDataDeal(this.searchForm)
			let downUrl =
				util.getBaseURL() + 'product/exportProduct?' + urldata
			window.open(downUrl)
		},
		// 设备树主级
		initTree() {
			let dataset = {
				tenantId: this.tenantId,
				id: '',
			}
			this.oneTreeVisble = false
			util.postform('departmentNew/queryLazyTrees', dataset).then(
				(res) => {
					this.oneTreeVisble = true
					let data = res.data.pubTreeInfos
					data.forEach((element) => {
						element.key = element.id
						element.title = element.name
						if (element.isDep < 1 && element.isEqu < 1) {
							element.isLeaf = true
						} else {
							element.isLeaf = false
						}
						if (element.type === 'dep') {
							element.scopedSlots = { icon: 'smile' }
						} else {
							element.scopedSlots = { icon: 'custom' }
						}
					})
					this.deviceTreeList = data
				}
			)
		},
		// 设备树子集
		dynamicLoadingTree(item) {
			let dataset = {
				id: item.id,
				// modeType: 4,
				tenantId: this.tenantId,
				special: '1',
				type: 'special',
			}
			util.post('pubTree/dynamicLoadingTree', dataset)
				.then((res) => {
					let data = res.dataset
					data.forEach((element) => {
						element.key = element.c_id
						element.title = element.name
						if (
							element.type == 'equ' ||
							(element.type == 'dep' &&
								element.isDep < 1 &&
								element.isEqu < 1)
						) {
							element.isLeaf = true
						} else {
							element.isLeaf = false
						}
						if (element.type === 'dep') {
							element.scopedSlots = { icon: 'smile' }
						} else {
							element.scopedSlots = {
								icon: 'custom',
								selected:
									element.componentFlag > 0 ? true : false,
							}
						}
					})
					item.children = data
					this.deviceTreeList = [...this.deviceTreeList]
					this.$forceUpdate()
				})
				.catch((err) => {
					this.$message.error(err.response.data.message)
				})
		},
		// 关闭弹窗
		closeModal() {
			this.visible = false
			this.addForm = {
				orderId: '',
				product: '',
				planNum: '',
				produceNum: '',
				qualifiedNum: '',
				startTime: '',
				finishTime: '',
        operUserId: '',
        operUserName: ''
			}
			this.selectedRowKeys = []
			this.getoutputManagement()
		},
		// 弹窗确认按钮
		updataWin() {
			if (this.visibleType === 'addoutputManagement') {
				this.addoutputManagementBtn()
			} else if (this.visibleType === 'editoutputManagement') {
				this.editoutputManagementBtn()
			} else if (this.visibleType === 'changePlan') {
				this.changePlanBtn()
			} else if (this.visibleType === 'infooutputManagement') {
				this.closeModal()
			}
		},
		// 获取特种设备列表
		getoutputManagement() {
      this.loading = true
			this.searchForm.tenantId = this.tenantId
			util.postform('product/getPageList', this.searchForm).then(
				(res) => {
					let temp = res.data.records
					temp.forEach((item) => {
						item.key = item.id
					})
          this.loading = false
					this.dataSource = temp
					this.total = res.data.total
				}
			)
		},
		// 翻页
		pageonChange(page) {
			this.searchForm.pageIndex = page
			this.getoutputManagement()
		},
		// 页数size改变
		showSizeChange(key, size) {
			this.searchForm.pageSize = size
			this.getoutputManagement()
		},
		// 新增产量管理
		addoutputManagementBtn() {
      if(this.addForm.orderId === ''){
        this.$message.error('订单号不能为空！')
        return
      }
      if(this.addForm.product === ''){
        this.$message.error('产品名称不能为空！')
        return
      }
      if(this.addForm.planNum === ''){
        this.$message.error('计划数不能为空！')
        return
      }
      if(this.addForm.produceNum === ''){
        this.$message.error('生产数不能为空！')
        return
      }
      if(this.addForm.qualifiedNum === ''){
        this.$message.error('合格数不能为空！')
        return
      }
      if(this.addForm.startTime === ''){
        this.$message.error('开始时间不能为空！')
        return
      }
      if(this.addForm.finishTime === ''){
        this.$message.error('完成时间不能为空！')
        return
      }
			this.addForm.tenantId = this.tenantId
			this.addForm.operUserId = this.operUserId
			this.addForm.operUserName = this.operUserName
			util.postform('product/add', this.addForm).then((res) => {
				if (res.code == 200) {
					this.$message.success('新增成功！')
					this.closeModal()
				}
			}).catch(res=>{
        this.$message.error(res.response.data.message)
      })
		},
		// 修改产量管理
		editoutputManagementBtn() {
      if(this.addForm.orderId === '' || !this.addForm.orderId){
        this.$message.error('订单号不能为空！')
        return
      }
      if(this.addForm.product === '' || !this.addForm.product){
        this.$message.error('产品名称不能为空！')
        return
      }
      if(this.addForm.planNum === '' || !this.addForm.planNum){
        this.$message.error('计划数不能为空！')
        return
      }
      if(this.addForm.produceNum === '' || !this.addForm.produceNum){
        this.$message.error('生产数不能为空！')
        return
      }
      if(this.addForm.qualifiedNum === '' || !this.addForm.qualifiedNum){
        this.$message.error('合格数不能为空！')
        return
      }
      if(this.addForm.startTime === '' || !this.addForm.startTime){
        this.$message.error('开始时间不能为空！')
        return
      }
      if(this.addForm.finishTime === '' || !this.addForm.finishTime){
        this.$message.error('完成时间不能为空！')
        return
      }
			let dataset = {
				tenantId: this.tenantId,
				operUserId: this.operUserId,
				operUserName: this.operUserName,
				orderId: this.addForm.orderId,
				product: this.addForm.product,
				planNum: this.addForm.planNum,
				produceNum: this.addForm.produceNum,
				qualifiedNum: this.addForm.qualifiedNum,
				startTime: this.addForm.startTime,
				finishTime: this.addForm.finishTime,
				id: this.addForm.id,
			}
			util.postform('product/update', dataset).then((res) => {
				if (res.code == 200) {
					this.$message.success('修改成功！')
					this.closeModal()
				}else {
          this.$message.error('修改失败！')
        }
			})
		},
		// 变更下发计划
		changePlanBtn() {
			let dataset = {
				beforeDay: this.addForm.executiveDays,
				nextInspectTime: moment(this.addForm.periodTime)
					.subtract(this.addForm.executiveDays, 'days')
					.format('YYYY-MM-DD'),
				periodTime: this.addForm.periodTime,
				id: this.selectedRows[0].id,
			}
			util.postform('inspectPlan/changePlan', dataset).then((res) => {
				if (res.code == 200) {
					this.$message.success('变更成功！')
					this.closeModal()
				}
			})
		},
		// 删除
		delBtn() {
			let that = this
			if (this.selectedRowKeys.length === 0) {
				this.$info({
					title: '提示',
					content: '请选择需要删除的记录！',
					onOk() {},
				})
				return
			}
			this.$confirm({
				title: '是否确认要删除此记录?',
				onOk() {
					util.postform(
						'product/removeBetch',
						that.selectedRowKeys
					).then((res) => {
						if (res.code === 200) {
							that.$message.success('删除成功！')
							that.getoutputManagement()
						}
					})
				},
				onCancel() {},
			})
		},
		// 功能按钮操作
		onbtnGroup(e) {
			let name = e.target.name
			if (name === 'editoutputManagement') {
				if (this.selectedRows.length !== 1) {
					this.$warning({
						title: '请选择一条记录进行修改操作！',
					})
					return
				}
				let temp = this.selectedRows[0]
				this.addForm = temp
			} else if (name == 'changePlan') {
				if (this.selectedRows.length !== 1) {
					this.$warning({
						title: '请选择一条记录进行修改操作！',
					})
					return
				}
			}
			this.showModal(e)
		},
		// 选择执行人/组
		onSelectPeo(val) {
			if (val.obj.length === 0) {
				return
			} else {
				if (val.obj[0].groupId) {
					this.addForm.execType = 1
					this.addForm.nowRecipients = val.obj[0].groupId
					this.addForm.nowRecipientsName = val.obj[0].groupName
				} else {
					this.addForm.execType = 0
					this.addForm.executive = val.obj[0].c_id
					this.addForm.executiveName = val.obj[0].c_name
				}
			}
		},
		// 选择抄送人
		onSelectPeocc(val) {
			if (val.obj.length === 0) {
				return
			} else {
				this.addForm.cc = val.obj[0].c_id
				this.addForm.ccName = val.obj[0].c_name
			}
		},
		// 选择设备
		onSelectPeoEqus(val) {
			if (val.obj.length === 0) {
				return
			} else {
				this.addForm.objectId = val.obj[0].c_id
				this.addForm.objectName = val.obj[0].c_simple_name
				this.addForm.organizationId = val.obj[0].c_organization_id
				this.addForm.organizationName = val.obj[0].depName
			}
		},
		// 上传附件 -- 获取oss地址
		setFileList(val) {
			this.addForm.credentialsFile = val
		},
		setFileListName(val) {
			this.addForm.fileName = val
		},
		showModal(e) {
			let name = e.target.name
			
			this.visibleType = name
			console.log(this.visibleType)
			switch (name) {
				case 'addoutputManagement':
					this.modalTitle = '新增'
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					this.visible = true
					break
				case 'editoutputManagement':
					this.modalTitle = '修改'
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					this.visible = true
					break
				case 'infooutputManagement':
					this.modalTitle = '详情'
					this.visibleWidth = '960px'
					this.typePrimary = ''
					this.jywsDialogBottomName = '关闭'
					this.jywsDialogBottomRoger = ''
					this.visible = true
					break
				case 'changePlan':
					this.modalTitle = '变更下发计划'
					this.visible = true
					this.visibleWidth = '560px'
					break
				case 'importExecl':
					this.importVisible = true
					break
				default:
					break
			}
		},
		cancelClick() {
			this.importVisible = false
		},
		submitClick(file) {
			const fd = new FormData()
			fd.append('tenantId', this.tenantId)
			fd.append('operUserName', this.operUserId)
			fd.append('file', file[0])
			util.postformData('product/importExcel', fd)
				.then((res) => {
					if (res.code == 200) {
						this.importDataSource = res.data
						this.$message.success('导入成功')
            this.importVisible = false
					} else {
						this.$message.error(res.data.message)
					}
				})
				.catch((err) => {
					this.$message.error(err.response.data.message)
				})
		},
		// 查看详情
		openinfooutputManagement(val) {
			util.postform('inspect/getInspectByPlanIdList/' + val.id).then(
				(res) => {
					this.infodataSource = res.data
					this.infoList = val
					this.typePrimary = ''
					this.jywsDialogBottomName = '关闭'
					this.jywsDialogBottomRoger = ''
					this.modalTitle = '详情'
					this.visibleType = 'infooutputManagement'
					this.visible = true
					this.visibleWidth = '960px'
				}
			)

			console.log(val)
		},

		// 下拉框选中
		onHandleChange(item) {
			if (item.duplicate == 'credentialsType') {
				this.searchForm.credentialsType = item.value
			} else if (item.duplicate == 'credentialsState') {
				this.searchForm.credentialsState = item.value
			}
		},
		// 输入框
		onInputChange(item) {
			if (item.duplicate == 'productName') {
				this.searchForm.productName = item.value
			} else if (item.duplicate == 'executor') {
				this.searchForm.executor = item.value
			}
		},
	},
}
</script>

<style scoped lang="less">
.outputManagement {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	// display: flex;
	// justify-content: space-between;
	padding: 20px;
	box-sizing: border-box;
	overflow: auto;
}
.info-title {
	display: flex;
	height: 40px;
	align-items: center;
	.info-title-icon {
		width: 4px;
		height: 14px;
		background: #1890ff;
	}
	.info-title-text {
		margin-left: 8px;
		font-size: 16px;
		color: #333333;
	}
}
.outputManagement-title {
	width: 100%;
	height: 56px;
	background: #ffffff;
	border-radius: 4px 4px 0px 0px;
	color: #333333;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.infooutputManagement-title {
	font-size: 24px;
	color: #333333;
	text-align: left;
}
.infooutputManagement-smtitle {
	display: flex;
	color: #999999;
	font-size: 12px;
	text-align: left;
	margin-top: 16px;
}
.infooutputManagement-content {
	margin-top: 24px;
}
.mg-l-5 {
	margin-left: 5px;
}
.mg-l-50 {
	margin-left: 50px;
}
.title-icon {
	width: 4px;
	height: 16px;
	background: #1890ff;
	display: inline-block;
	margin-right: 12px;
}
.col-value {
	width: 200px;
}
.outputManagement-content {
	background: #ffffff;
	width: 100%;
	height: calc(100% - 56px);
	display: flex;
	padding-top: 32px;
}
.flush {
	font-size: 14px;
	color: #1890ff;
}
.content-right-btnGroup {
	height: 56px;
	width: 100%;
	line-height: 56px;
	margin-top: 10px;
}
.content-right-form {
	// width: 100%;
	background: #ffffff;
	padding: 0px 21px 0px 17px;
	.form-search {
		display: flex;
		align-items: center;
		width: 420px;
	}
}
.form-label {
	font-size: 14px;
	color: #333333;
	width: 70px;
	text-align: right;
	margin-right: 16px;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.content-left {
	width: 220px;
	height: calc(100% - 30px);
	background: #ffffff;
	border: 1px solid #e8e8e8;
	margin-left: 12px;
	overflow: auto;
	.content-left-title {
		height: 48px;
		width: 100%;
		padding: 0 16px 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #333333;
		font-size: 16px;
		border-bottom: 1px solid #e8e8e8;
	}
	.content-left-btnGroup {
		height: 48px;
		width: 100%;
		border-bottom: 1px solid #e8e8e8;
	}
}
.content-right {
	height: calc(100% - 30px);
	width: calc(100% - 60px);
	margin-left: 27px;
}
.content-right-message {
	margin-top: 20px;
}
</style>
