<!--
 * @Author: your name
 * @Date: 2022-04-22 14:10:47
 * @LastEditTime: 2022-12-12 11:53:21
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/detailComponent/partsDetails.vue
-->
<template>
  <div class="partsDetails">
    <jywsDialog
			:visible="visible"
			title="工单详情"
			width="960px"
			roger=""
			bottom-name="关闭"
			typePrimary="primary"
			@updataClose="closeModal"
			@updataWin="updataWin"
		>
      <div class="partsDetails-content">
        <template>
          <div class="paddingb16 flex-title">
            <div class="blueLine"></div>
            <div class="flex-title-name">基础信息</div>
          </div>
          <div class="flex-right-account-left-content-table">
            <div class="flex-right-account-left-content-table-item borderb1">
              <div class="table-item-left borderr1">编码：</div>
              <div class="table-item-right borderr1">{{ serviceData.code || '-' }}</div>
              <div class="table-item-left borderr1">品牌：</div>
              <div class="table-item-right">{{ serviceData.brand || '-' }}</div>
            </div>
            <div class="flex-right-account-left-content-table-item borderb1">
              <div class="table-item-left borderr1">名称：</div>
              <div class="table-item-right borderr1">{{ serviceData.name || '-' }}</div>
              <div class="table-item-left borderr1">型号：</div>
              <div class="table-item-right">{{ serviceData.version || '-' }}</div>
            </div>
            <div class="flex-right-account-left-content-table-item borderb1">
              <div class="table-item-left borderr1">规格：</div>
              <div class="table-item-right borderr1">{{ serviceData.norms || '-' }}</div>
              <div class="table-item-left borderr1">在库数：</div>
              <div class="table-item-right">{{ serviceData.stockNum || '-' }}</div>
            </div>
            <div class="flex-right-account-left-content-table-item">
              <div class="table-item-left borderr1">照片：</div>
              <div class="table-item-right borderr1">
                <span v-if="serviceData.img" style="color: #1890FF;cursor: pointer;" @click="queryImg(serviceData.img)">查看</span>
                <span v-else>-</span>
                </div>
              <div class="table-item-left borderr1">备注：</div>
              <div class="table-item-right">{{ serviceData.remark || '-' }}</div>
            </div>
          </div>
        </template>
        <template>
          <div class="paddingb16 flex-title">
            <div class="blueLine"></div>
            <div class="flex-title-name">预警阀值</div>
          </div>
          <div class="flex-right-account-left-content-table">
            <div class="flex-right-account-left-content-table-item borderb1">
              <div class="table-item-left borderr1">库存上限：</div>
              <div class="table-item-right borderr1">{{ serviceData.upper || '-' }}</div>
              <div class="table-item-left borderr1">库存下限：</div>
              <div class="table-item-right">{{ serviceData.lower || '-' }}</div>
            </div>
            <div class="flex-right-account-left-content-table-item">
              <div class="table-item-left borderr1">存放期限：</div>
              <div class="table-item-right borderr1">{{ serviceData.depositary ? serviceData.depositary + '月' : '-' }}</div>
              <div class="table-item-left borderr1">库存负责人：</div>
              <div class="table-item-right">{{ serviceData.responsibleUser || '-' }}</div>
            </div>
          </div>
        </template>
        <template>
          <div class="paddingb16 flex-title">
            <div class="blueLine"></div>
            <div class="flex-title-name">存放位置</div>
          </div>
          <div class="flex-right-account-left-content-table">
            <div class="flex-right-account-left-content-table-item">
              <div style="text-align: left;" class="table-item-left borderr1">{{ serviceData.warehouseList.length > 0 ? serviceData.warehouseList.toString() : '-' }}</div>
            </div>
          </div>
        </template>
        <template>
          <div class="paddingb16 flex-title">
            <div class="blueLine"></div>
            <div class="flex-title-name">适用设备</div>
          </div>
          <div class="flex-right-account-left-content-table">
            <div class="flex-right-account-left-content-table-item">
              <div style="text-align: left;" class="table-item-left borderr1">{{ serviceData.objectList.length > 0 ? serviceData.objectList.toString() : '-' }}</div>
            </div>
          </div>
        </template>
      </div>
    </jywsDialog>
    <imgDialog title="查看图片" bottomName="关闭" :visible="imgVisble" :imgSrc="imgSrc" :itemSrcIndex="itemSrcIndex" :itemSrc="itemSrc" @selecedImgClick="selecedImgClick" @updataWin="close"></imgDialog>
  </div>
</template>

<script>
import jywsDialog from '@/components/jywsDialog.vue'
import imgDialog from '@/components/imgDialog.vue'
import * as util from '@/core/util'
export default {
  name: 'PartsDetails',
  props: {
    // 所用组件
    id: {
      type: String,
      default: ''
    },
    tenantId: {
      type: String,
      default: ''
    },
    visible: {
			type: Boolean,
			default: false,
		},
  },
  components: {
		jywsDialog,
    imgDialog
	},
  data() {
    return {
      serviceData: {},
      imgVisble: false,
      imgSrc: [],
      itemSrcIndex: 0,
      itemSrc: '',
    }
  },
  created() {
    this.queryForPartIdInfo()
  },
  mounted() {},
  methods: {
    // 备件信息
    queryForPartIdInfo() {
      const dataset = {
        id: this.id,
        tenantId: this.tenantId
      }
      util.postform('ZNequipment/queryForPartIdInfo', dataset).then(res => {
        console.log(res)
        this.serviceData = res.data
      }).catch(err => {
        console.error(err)
        this.$message.error(err.message)
      })
    },
    queryImg(img, index) {
			this.imgVisble = true
			this.imgSrc = (img && img.split(',')) || []
			this.itemSrcIndex = index ? index : 0
			this.itemSrc = (img && img.split(',')[0]) || ''
		},
    selecedImgClick(key) {
			this.itemSrcIndex = key
			this.itemSrc = this.imgSrc[key]
		},
		close() {
			this.imgVisble = false
		},
    closeModal() {
      this.$emit('closeModal')
    },
    updataWin() {
      this.$emit('closeModal')
    }
  },
}
</script>

<style scoped lang="less">
.partsDetails-content {
  max-height: 582px;
  overflow: auto;
}
.flex-right-account-left-content-table {
	border: 1px solid #E8E8E8;
	margin-bottom: 16px;
}
.flex-right-account-left-content-table-item {
	min-height: 40px;
	display: flex;
}
.table-item-left {
	flex: 1;
	height: 100%;
	padding: 10px 12px;
	text-align: right;
	background: #FAFAFA;
}
.table-item-right {
	flex: 2;
	height: 100%;
	padding: 10px 12px;
	text-align: left;
	color: #666666;
  overflow: hidden; //溢出隐藏
  text-overflow:ellipsis; //超出显示省略号
  white-space: nowrap;//禁止自动换行
}
.flex-right-account-right {
	width: 202px;
	flex-shrink: 0;
}
.border1 {
	border: 1px solid #E8E8E8;
}
.borderr1 {
	border-right: 1px solid #E8E8E8;
}
.borderb1 {
	border-bottom: 1px solid #E8E8E8;
}
.blueLine {
  width: 3px;
  height: 16px;
  background: #1890FF;
}
.flex-title {
  display: flex;
  align-items: center
}
.flex-title-name {
  margin-left: 10px;
}
.paddingb16 {
  padding-bottom: 16px;
}
</style>
