<!--
 * @Author: your name
 * @Date: 2022-04-22 11:51:01
 * @LastEditTime: 2022-08-01 11:59:09
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/views/numberOfDevices/numberOfDevices.vue
-->
<template>
  <div class="numberOfDevices">
    <div style="width: 220px;margin-right: 21px;">
      <div class="content-left">
          <!-- 左侧标题 -->
          <div class="content-left-title">
            <div>设备列表</div>
            <div class="flush" @click="oneTree">
              <a-icon type="sync"
                      style="margin-right:6px" />刷新
            </div>
          </div>
          <div class="content-left-tree ">
            <deviceTree v-if="oneTreeVisble" :treeData='deviceTreeList' @treeSelect='treeSelect' @expandTree="expandTree"></deviceTree>
          </div>
        </div>
    </div>
    <div style="width: 100%;height: 100%;overflow: auto;">
      <div class="numberOfDevices-title">
        <span class="title-icon">
        </span>
        <span>设备树维护</span>
      </div>
      <div class="query-button">
        <conditionQuery :headData="headData" @onHandleChange="onHandleChange" @onInputChange="onInputChange" @onSelectClick="onSelectClick"/>
      </div>
      <div class="numberOfDevices-content">
        <div class="content-left">
          <!-- 左侧标题 -->
          <div class="content-left-title">
            <div>设备树</div>
            <div class="flush" @click="twoTree">
              <a-icon type="sync"
                      style="margin-right:6px" />刷新
            </div>
          </div>
          <!-- 左侧树按钮区域 -->
          <div class="content-left-btnGroup flex">
            <a-button-group>
              <a-button @click="unitsTreeAdd">新增</a-button>
              <a-button @click="unitsTreeEdit">修改</a-button>
              <a-button @click="forIdDelete">删除</a-button>
            </a-button-group>
          </div>
          <div class="content-left-hint">提示：请先从左侧选择设备</div>
          <div class="content-left-tree2">
            <unitsTree v-if="twoTreeVisble" :treeData='this.unitsTreeData.treeData' @treeSelect='treeSelect2'></unitsTree>
          </div>
        </div>
        <div class="content-right">
          <div class="content-right-button">
            <a-button-group>
              <a-button @click="handleSizeChange('setUp')">
                触发规则设置
              </a-button>
              <a-button @click="handleSizeChange('apply')">
                适用于
              </a-button>
              <a-button @click="handleSizeChange('update')">
                修改
              </a-button>
              <a-button @click="deleteRule">
                删除
              </a-button>
              <a-button @click="handleSizeChange('equipmentImport')">
                按设备导入
              </a-button>
              <a-button @click="handleSizeChange('partsImport')">
                按部件导入
              </a-button>
              <a-button @click="handleSizeChange('progress')">
                进度初始化
              </a-button>
              <a-button @click="handleSizeChange('associated')">
                关联任务
              </a-button>
            </a-button-group>
          </div>
          <div style="height: calc(100% - 32px);">
            <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :scroll="{ y: 400 }" :pagination="false" :loading="loading" :columns="columns" :data-source="dataSource">
              <a slot="type" slot-scope="text">{{ text == 1 ? '按时间' : '按次数' }}</a>
              <a slot="cycle" slot-scope="text,record">{{text}}{{ record.type == 1 ? '天' : '次' }}</a>
              <a slot="updateTime" slot-scope="text">{{text || '-'}}</a>
              <a slot="schedule" slot-scope="text"><a-progress v-if="text" :percent="text" size="small" /><span v-else>-</span></a>
              <a slot="state" slot-scope="text"><a-badge :color="text == 1 ? '#2FC25B' : '#FAAD14'" :text="text == 1 ? '启用' : '停用'" /></a>
              <a slot="componentExperiences" slot-scope="text"><span :style="text.length > 0 ? 'color: #666666;' : 'color: #FAAD14;'">{{ text.length > 0 ? '预防维修' : '未关联' }}</span></a>
              <a v-if="record.componentExperiences.length > 0" slot="operation" slot-scope="text,record" @click="standardLibraryDetails(record)">详情</a>
              <a v-else slot="operation">-</a>
            </a-table>
            <div class="pagination-style">
            <a-pagination size="small"
            :total="pageTotal"
            :defaultCurrent='pageIndex'
            :defaultPageSize='pageSize'
            show-size-changer
            show-quick-jumper
            :show-total="total => `总共 ${total} 条`"
            @change="thePager"
            @showSizeChange="showSizeChange" />
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 设备树弹窗 -->
    <jyws-dialog :visible="unitsTreeVisble" :title="jywsDialogName" :width="jywsDialogWidth" :roger="jywsDialogBottomRoger" :bottom-name="jywsDialogBottomName" @updataClose="updataClose" @updataWin="updataWin">
      <template v-if="jywsDialogName == '新增' || jywsDialogName == '修改'">
        <div v-if="jywsDialogName == '新增'" class="flex marginb24">
          <div class="flex-leftName">所属设备<span style="color: red;">*</span></div>
          <div class="flex-rightName"><a-input style="width: 85%" disabled :value='relatedData.objectName' /></div>
        </div>
        <div class="flex marginb24">
          <div class="flex-leftName">所属部件</div>
          <div class="flex-rightName">
            <a-tree-select
              v-model="unitsTreeData.theirAssemblyId"
              :tree-data="unitsTreeData.treeData"
              show-search
              style="width: 85%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择部件"
              allow-clear
              tree-default-expand-all
              tree-node-filter-prop="title"
            >
            </a-tree-select>
          </div>
        </div>
        <div class="flex marginb24">
          <div class="flex-leftName">部件编号<span style="color: red;">*</span></div>
          <div class="flex-rightName"><a-input style="width: 85%" v-model='unitsTreeData.assemblyId' :disabled="jywsDialogName == '修改'" /></div>
        </div>
        <div class="flex marginb24">
          <div class="flex-leftName">部件名称<span style="color: red;">*</span></div>
          <div class="flex-rightName"><a-input style="width: 85%" v-model='unitsTreeData.assemblyName' /></div>
        </div>
        <div class="flex marginb24">
          <div class="flex-leftName">排序号</div>
          <div class="flex-rightName"><a-input-number style="width: 85%" v-model='unitsTreeData.sort' :precision="0" /></div>
        </div>
      </template>
      <template v-if="jywsDialogName == '新增触发规则' || jywsDialogName == '修改触发规则'">
        <div class="flex marginb24">
          <div class="flex-leftName">所属设备<span style="color: red;">*</span></div>
          <div class="flex-rightName"><a-input style="width: 85%" disabled :value='relatedData.objectName' /></div>
        </div>
        <div class="flex marginb24">
          <div class="flex-leftName">所属部件<span style="color: red;">*</span></div>
          <div class="flex-rightName">
            <a-tree-select
              v-model="unitsTreeData.updateAssemblyId"
              :tree-data="unitsTreeData.treeData"
              show-search
              disabled
              style="width: 85%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择部件"
              allow-clear
              tree-default-expand-all
              tree-node-filter-prop="title"
            >
            </a-tree-select>
          </div>
        </div>
        <div class="flex marginb24">
          <div class="flex-leftName">触发规则<span style="color: red;">*</span></div>
          <div class="flex-rightName"><a-radio-group :options="['按时间','按次数']" v-model="settingTableDate.type" /></div>
        </div>
        <div class="flex marginb24">
          <div class="flex-leftName">设定周期<span style="color: red;">*</span></div>
          <div class="flex-rightName">
            <a-input-number
              style="width: 85%"
              v-model="settingTableDate.num"
              :min="0"
              :precision="0"
              :formatter="value => `${value}${settingTableDate.type=='按时间'?'天':'次'}`"
              :parser="value => value.replace(`${settingTableDate.type=='按时间'?'天':'次'}`, '')"
            />
          </div>
        </div>
        <div class="flex marginb24">
          <div class="flex-leftName">状态<span style="color: red;">*</span></div>
          <div class="flex-rightName">
            <a-select v-model="settingTableDate.state" style="width: 85%">
              <a-select-option :value="1">
                启动
              </a-select-option>
              <a-select-option :value="0">
                禁用
              </a-select-option>
            </a-select>
          </div>
        </div>
      </template>
      <template v-if="jywsDialogName == '关联任务'">
        <div class="associatedTasks-head">
          <img class="icon-img" src="@/assets/icon_温馨提示.png" />请先在基础设置-工单推送处设置该设备的预防维修任务接收人后，再选择需要关联的预防维修任务
        </div>
        <div>
          <conditionQuery :headData="associatedData" @onInputChange="onInputChange" @onSelectClick="onSelectClick"/>
        </div>
        <div>
          <a-table :row-selection="{ selectedRowKeys: selectedRowKeys2, onChange: onSelectChange2, type: 'radio' }" :scroll="{ y: 450 }" :pagination="false" :loading="loading" :columns="columns2" :data-source="dataSource2">
            <a slot="type" slot-scope="text">{{ text == 1 ? '按时间' : '按次数' }}</a>
            <a slot="cycle" slot-scope="text,record">{{text}}{{ record.type == 1 ? '天' : '次' }}</a>
            <a slot="updateTime" slot-scope="text">{{text || '-'}}</a>
            <a slot="schedule" slot-scope="text"><a-progress v-if="text" :percent="text" size="small" /><span v-else>-</span></a>
            <a slot="state" slot-scope="text"><a-badge :color="text == 1 ? '#2FC25B' : '#FAAD14'" :text="text == 1 ? '启用' : '停用'" /></a>
            <a slot="componentExperiences" slot-scope="text"><span :style="text.length > 0 ? 'color: #666666;' : 'color: #FAAD14;'">{{ text.length > 0 ? '预防维修' : '未关联' }}</span></a>
            <a slot="operation" slot-scope="text,record" @click="standardLibraryDetails(record)">详情</a>
          </a-table>
          <div class="pagination-style">
            <a-pagination size="small"
            :total="pageTotal2"
            :defaultCurrent='pageIndex2'
            :defaultPageSize='pageSize2'
            show-size-changer
            show-quick-jumper
            :show-total="total => `总共 ${total} 条`"
            @change="thePager2"
            @showSizeChange="showSizeChange2" />
          </div>
        </div>
      </template>
      <template v-if="jywsDialogName == '批量新增（适用于）'">
        <div class="associatedTasks-head">
          <img class="icon-img" src="@/assets/icon_温馨提示.png" />将设备树适用于其它设备，如被适用的设备原本存在设备树，则会将原设备树进行覆盖，且原已制定的规则和关联的任务将不会存在。
        </div>
        <div>
          <conditionQuery :headData="equipmentData" @onInputChange="onInputChange" @onTreeSelectChange="onTreeSelectChange" @onHandleChange="onHandleChange" @onSelectClick="onSelectClick"/>
        </div>
        <div>
          <a-table :row-selection="{ selectedRowKeys: selectedRowKeys3, onChange: onSelectChange3 }" :scroll="{ y: 450 }" :pagination="false" :loading="loading" :columns="columns3" :data-source="dataSource3">
            <a slot="componentFlag" slot-scope="text"><a-badge :color="text == 1 ? '#2FC25B' : '#FAAD14'" :text="text == 1 ? '有' : '无'" /></a>
          </a-table>
          <div class="pagination-style">
            <a-pagination size="small"
            :total="pageTotal3"
            :defaultCurrent='pageIndex3'
            :defaultPageSize='pageSize3'
            show-size-changer
            show-quick-jumper
            :show-total="total => `总共 ${total} 条`"
            @change="thePager3"
            @showSizeChange="showSizeChange3" />
          </div>
        </div>
      </template>
    </jyws-dialog>
    <!-- 二级弹窗 -->
    <jyws-dialog :visible="unitsTreeVisble2" :title="jywsDialogName2" :width="jywsDialogWidth2" :bottom-name="jywsDialogBottomName2" @updataClose="updataClose2" @updataWin="updataClose2">
      <template v-if="jywsDialogName2 == '标准库详情'">
        <a-radio-group :value="radioHandleSize" @change="radioHandleSizeChange">
          <a-radio-button value="基本信息">
            基本信息
          </a-radio-button>
          <a-radio-button value="点检保养信息">
            点检保养信息
          </a-radio-button>
          <a-radio-button value="备件信息">
            备件信息
          </a-radio-button>
        </a-radio-group>
        <div v-if="radioHandleSize == '基本信息'">
          <div class="page-head-title">
            <div class="page-head-title-icon" />
            <div>作业库信息</div>
          </div>
          <div>
            <div class="information-flex">
              <div class="information-flex1">
                <div style="flex: 1;text-align: right;">标准库名称：</div>
                <div style="flex: 3;padding-left: 10px;">{{ libraryDetailsObject.standardExperienceName || '-' }}</div>
              </div>
              <div class="information-flex1">
                <div style="flex: 1;text-align: right;">标准库类型：</div>
                <div style="flex: 3;padding-left: 10px;">{{ libraryDetailsObject.experienceType == 1 ? '点检' : '保养' }}</div>
              </div>
            </div>
            <div class="information-flex">
              <div class="information-flex1">
                <div style="flex: 1;text-align: right;">作业人数：</div>
                <div style="flex: 3;padding-left: 10px;">{{ libraryDetailsObject.peopleNum || '0' }} 人</div>
              </div>
              <div class="information-flex1">
                <div style="flex: 1;text-align: right;">工时：</div>
                <div style="flex: 3;padding-left: 10px;">{{ libraryDetailsObject.workingHours || '0' }} 小时</div>
              </div>
            </div>
            <div class="information-flex">
              <div class="information-flex1">
                <div style="flex: 1;text-align: right;">工器具：</div>
                <div style="flex: 7;padding-left: 10px;">{{ libraryDetailsObject.appliances || '-' }}</div>
              </div>
            </div>
            <div class="information-flex">
              <div class="information-flex1">
                <div style="flex: 1;text-align: right;">安全告知：</div>
                <div style="flex: 7;padding-left: 10px;">{{ libraryDetailsObject.securityInform || '-' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="radioHandleSize != '基本信息'">
          <a-table :pagination="false" :columns="radioHandleSize == '点检保养信息' ? columns4 : columns5" :data-source="radioHandleSize == '点检保养信息' ? dataSource4 : dataSource5">
            <a slot="type" slot-scope="text">{{ text | getCtype(text) }}</a>
            <a slot="way" slot-scope="text"><a-badge :color="text == 1 ? '#2FC25B' : '#FAAD14'" :text="text == 1 ? '是' : '否'" /></a>
            <a slot="photo" slot-scope="text"><a-badge :color="text == 1 ? '#2FC25B' : '#FAAD14'" :text="text == 1 ? '是' : '否'" /></a>
          </a-table>
        </div>
      </template>
    </jyws-dialog>
    <importDataDialog :visible="importVisible" :title="importTitle" :url="importUrl" :current="current" @cancel="cancelClick" @submit="submitClick"/>
  </div>
</template>

<script>
import deviceTree from '@/components/deviceTree.vue'
import unitsTree from '@/components/unitsTree.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import conditionQuery from '@/components/conditionQuery.vue'
import importDataDialog from '@/components/importDataDialog.vue'
import * as util from '@/core/util'
export default {
  name: 'NumberOfDevices',
  components: {
    deviceTree, unitsTree, jywsDialog, conditionQuery, importDataDialog
  },
  filters: {
    getCtype(data) {
      if (data) {//日点检:day,周点检:specialty,专项巡检:project,定期点检:precision
        if (data == "day") {
          return '日点检'
        } if (data == "specialty") {
          return '周点检'
        } if (data == "planned") {
          return '计划点检'
        } if (data == "precision") {
          return '定期点检'
        } if (data == "B1") {
          return '一级保养'
        } if (data == "B2") {
          return '二级保养'
        } if (data == "B3") {
          return '日常保养'
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    }
  },
  data() {
    return {
      userId:'',
      tenantId: '',
      headData: [
        { key: 2, duplicate: 'component', name: '部件名称', component: 'a-input', placeholder: '请输入部件名称', value: '' },
        { key: 3, duplicate: 'status', name: '状态', component: 'a-select', placeholder: '请选择', value: '', provinceData: [
          { key: 1, value: '' , name: '全部' },
          { key: 2, value: '1' , name: '启用' },
          { key: 3, value: '0' , name: '停用' }]
        },
        { key: 4, duplicate: 'trigger', name: '触发规则', component: 'a-select', placeholder: '请选择', value: '', provinceData: [
          { key: 1, value: '' , name: '全部' },
          { key: 2, value: '1' , name: '按时间' },
          { key: 3, value: '2' , name: '按次数' }]
       },
      ],
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        {
          title: '部件名称',
          dataIndex: 'componentName',
          key: 'componentName',
          ellipsis: true,
        },
        {
          title: '触发规则',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '设定周期',
          dataIndex: 'cycle',
          key: 'cycle',
          ellipsis: true,
          scopedSlots: { customRender: 'cycle' }
        },
        {
          title: '初始化日期',
          dataIndex: 'updateTime',
          key: 'updateTime',
          ellipsis: true,
          scopedSlots: { customRender: 'updateTime' }
        },
        {
          title: '进度',
          dataIndex: 'schedule',
          key: 'schedule',
          ellipsis: true,
          scopedSlots: { customRender: 'schedule' }
        },
        {
          title: '状态',
          dataIndex: 'state',
          key: 'state',
          ellipsis: true,
          scopedSlots: { customRender: 'state' }
        },
        {
          title: '关联任务类型',
          dataIndex: 'componentExperiences',
          key: 'componentExperiences',
          ellipsis: true,
          scopedSlots: { customRender: 'componentExperiences' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
        }
      ],
      dataSource: [],
      deviceTreeList: [], // 设备树数据源
      relatedData: {
        objectId: '', // 设备id
        objectName: '', // 设备名称
        organizationId: '', // 组织id
        organizationName: '', // 组织名称
      }, // 设备树选中属性
      jywsDialogName: '', // 弹窗名称
      jywsDialogWidth: '560px', // 弹窗名称
      jywsDialogBottomRoger: '取消',
      jywsDialogBottomName: '确定', // 弹窗确定按钮
      unitsTreeVisble: false, // 部件树-新增、修改 弹窗
      jywsDialogName2: '', // 弹窗名称
      jywsDialogWidth2: '560px', // 弹窗名称
      jywsDialogBottomName2: '知道', // 弹窗确定按钮
      unitsTreeVisble2: false, // 部件树-新增、修改 弹窗
      unitsTreeData: {
        id: '',
        treeData: [],
        theirAssemblyId: undefined, // 部件Id
        assemblyId: '', // 部件Id
        assemblyName: '', // 部件名称
        updateAssemblyId: '', // 修改部件Id
        updateAssemblyName: '', // 修改部件名称
        sort: '', // 排序号
        ids: [] // 选中树下面的所以id包括自己
      }, // 设备树新增、修改属性
      settingTableDate: {
        id: '',
        type: '按时间',
        num: '',
        state: 1
      }, // 触发规则属性
      associatedData: [
        { key: 1, duplicate: 'library' , name: '标准库名称', component: 'a-input', placeholder: '请输入标准库名称', value: '' }
      ],
      pageIndex2: 1,
      pageSize2: 10,
      pageTotal2: 0,
      columns2: [
        {
          title: '标准库名称',
          dataIndex: 'standardExperienceName',
          key: 'standardExperienceName',
          ellipsis: true,
        },
        {
          title: '作业人数',
          dataIndex: 'peopleNum',
          key: 'peopleNum',
          ellipsis: true,
        },
        {
          title: '工时（小时）',
          dataIndex: 'workingHours',
          key: 'workingHours',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
        }
      ],
      dataSource2: [],
      standardExperienceName: '',
      selectedRowKeys2: [],
      selectedRows2: [],
      applyData: [
        { key: 1, duplicate: 'organization' , name: '组织', component: 'a-select' },
        { key: 2, duplicate: 'anystandard' , name: '有无标准', component: 'a-select' },
        { key: 3, duplicate: 'devicename' , name: '设备信息', component: 'a-input' }
      ],
      pageIndex3: 1,
      pageSize3: 10,
      pageTotal3: 0,
      columns3: [
        {
          title: '所属组织',
          dataIndex: 'organizationName',
          key: 'organizationName',
          ellipsis: true,
        },
        {
          title: '设备简称',
          dataIndex: 'simpleName',
          key: 'simpleName',
          ellipsis: true,
        },
        {
          title: '型号',
          dataIndex: 'standard',
          key: 'standard',
          ellipsis: true,
        },
        {
          title: '有无设备树',
          dataIndex: 'componentFlag',
          key: 'componentFlag',
          ellipsis: true,
          scopedSlots: { customRender: 'componentFlag' },
        },
        {
          title: '资产编码',
          dataIndex: 'assetsCode',
          key: 'assetsCode',
          ellipsis: true
        }
      ],
      dataSource3: [],
      selectedRowKeys3: [],
      selectedRows3: [],
      equipmentData: [
        { key: 1, duplicate: 'organization' , name: '组织', component: 'a-tree-select', placeholder: '默认选择全部组织', value: undefined, value2: [], treeData: [] },
        { key: 2, duplicate: 'anystandard' , name: '有无标准', component: 'a-select' , value: '', provinceData: [
          { key: 1, value: '' , name: '全部' },
          { key: 2, value: '1' , name: '是' },
          { key: 3, value: '0' , name: '否' }]
        },
        { key: 3, duplicate: 'devicename' , name: '设备信息', component: 'a-input', placeholder: '请输入设备信息', value: '' }
      ],
      radioHandleSize: '基本信息',
      detailsObjectId: '',
      libraryDetailsObject: {},
      columns4: [
        {
          title: '标准类型',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '点检保养单元',
          dataIndex: 'element',
          key: 'element',
          ellipsis: true,
        },
        {
          title: '点检保养项目',
          dataIndex: 'project',
          key: 'project',
          ellipsis: true,
        },
        {
          title: '点检保养内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true
        },
        {
          title: '数值',
          dataIndex: 'way',
          key: 'way',
          ellipsis: true,
          scopedSlots: { customRender: 'way' },
        },
        {
          title: '拍照',
          dataIndex: 'photo',
          key: 'photo',
          ellipsis: true,
          scopedSlots: { customRender: 'photo' },
        }
      ],
      columns5: [
        {
          title: '备件编码',
          dataIndex: 'code',
          key: 'code',
          ellipsis: true,
        },
        {
          title: '备件名称',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
        },
        {
          title: '品牌',
          dataIndex: 'brand',
          key: 'brand',
          ellipsis: true,
        },
        {
          title: '型号',
          dataIndex: 'version',
          key: 'version',
          ellipsis: true
        },
        {
          title: '规格',
          dataIndex: 'norms',
          key: 'norms',
          ellipsis: true
        },
        {
          title: '消耗数量',
          dataIndex: 'partNum',
          key: 'partNum',
          ellipsis: true
        }
      ],
      dataSource4: [],
      dataSource5: [],
      importVisible: false,
      importTitle: '',
      importUrl: '',
      current: 0,
      oneTreeVisble: true,
      twoTreeVisble: true
    }
  },
  created() {
    var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
    this.userId = this.GetQueryString('userId')
    this.initTree()
    this.queryList()
  },
  mounted() {},
  methods: {
    // 分解地址栏参数
    GetQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = this.$route.fullPath.substr(1).match(reg)
      if (r != null) return decodeURIComponent(r[2])
      return null
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows
    },
    onSelectChange2(selectedRowKeys, selectedRows) {
      this.selectedRowKeys2 = selectedRowKeys;
      this.selectedRows2 = selectedRows
    },
    onSelectChange3(selectedRowKeys, selectedRows) {
      this.selectedRowKeys3 = selectedRowKeys;
      this.selectedRows3 = selectedRows
    },
    handleSizeChange(type) {
      switch (type) {
        case "setUp":
          if (this.unitsTreeData.updateAssemblyId) {
            this.showModel('setUp')
          } else {
            this.$message.error('提示：请先从左侧设备树选择设备！');
          }
          break;
        case "update":
          if (this.selectedRows.length == 1) {
            this.settingTableDate.id = this.selectedRows[0].id
            this.settingTableDate.type = this.selectedRows[0].type == 1 ? '按时间' : '按次数'
            this.settingTableDate.num = this.selectedRows[0].cycle
            this.settingTableDate.state = this.selectedRows[0].state
            this.unitsTreeData.updateAssemblyId = this.selectedRows[0].code
            this.showModel('update')
          } else {
            this.$message.error('提示：请选择需要修改的单条触发规则项！');
          }
          break;
        case "apply":
          if (this.relatedData.objectId) {
            this.treeSearch()
            this.showModel('apply')
          } else {
            this.$message.error('提示：请先从设备列表选择设备！');
          }
          break;
        case "associated":
          if (this.relatedData.objectId && this.selectedRows.length == 1) {
            this.getStandardExperiencePageList()
            this.showModel('associated')
          } else {
            if (this.selectedRows.length == 0) {
              return this.$message.error('提示：请选择需要关联任务的单条触发规则项！');
            }
            if (!this.relatedData.objectId) {
              return this.$message.error('提示：请先从设备列表选择设备！');
            }
          }
          break;
        case "equipmentImport":
          this.showModel('equipmentImport')
          break;
        case "partsImport":
          if (this.relatedData.objectId) {
            this.treeSearch()
            this.showModel('partsImport')
          } else {
            this.$message.error('提示：请先从设备列表选择设备！');
          }
          break;
        case "progress":
          if (this.selectedRowKeys.length > 0) {
            this.initRule()
          } else {
            this.$message.error('提示：请选择需要初始化的触发规则！');
          }
          break;
        default:
          break;
      }
    },
    oneTree() {
      this.relatedData = {
        objectId: '', // 设备id
        objectName: '', // 设备名称
        organizationId: '', // 组织id
        organizationName: '' // 组织名称
      }
      this.unitsTreeData.updateAssemblyId = ''
      this.dataSource = []
      this.empty()
      this.initTree()
      this.queryForId()
      this.queryList()
    },
    twoTree() {
      this.unitsTreeData.updateAssemblyId = ''
      this.dataSource = []
      this.empty()
      this.queryForId()
      this.queryList()
    },
    radioHandleSizeChange(e) {
      this.radioHandleSize = e.target.value;
    },
    // 设备树主级
    initTree() {
      let dataset = {
        tenantId: this.tenantId,
        id: ''
      }
      this.oneTreeVisble = false
      util.postform('departmentNew/queryLazyTrees', dataset).then((res) => {
        this.oneTreeVisble = true
        console.log(res)
        let data = res.data.pubTreeInfos;
        data.forEach(element => {
          element.key = element.id
          element.title = element.name
          if (element.isDep < 1 && element.isEqu < 1) {
              element.isLeaf = true;
          } else {
              element.isLeaf = false;
          }
          if (element.type === 'dep') {
            element.scopedSlots = { icon: 'smile' }
          } else {
            element.scopedSlots = { icon: 'custom' }
          }
        });
        this.deviceTreeList = data
      })
    },
    // 设备树子集
    dynamicLoadingTree(item) {
      let dataset = {
        id: item.id,
        modeType: 4,
        tenantId: this.tenantId
      }
      util.postform('departmentNew/queryLazyTrees', dataset).then((res) => {
        console.log(res)
        let data = res.data.pubTreeInfos;
        data.forEach(element => {
            element.key = element.id;
            element.title = element.name;
            if (element.type == 'equ' || (element.type == 'dep' && element.isDep < 1 && element.isEqu < 1)) {
                element.isLeaf = true
            } else {
              element.isLeaf = false
            }
            if (element.type === 'dep') {
              element.scopedSlots = { icon: 'smile' }
            } else {

              element.scopedSlots = { icon: 'custom', selected: element.componentFlag > 0 ? true : false }
            }
        });
        item.children = data
        this.deviceTreeList = [...this.deviceTreeList]
        this.$forceUpdate()
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    // 部件树
    queryForId() {
      let dataset = {
        objectId: this.relatedData.objectId,
        tenantId: this.tenantId
      }
      this.twoTreeVisble = false
      util.postform('component/queryTree', dataset).then((res) => {
        this.twoTreeVisble = true
        console.log(res)
        let data = res.data;
        data.forEach(element => {
            element.key = element.id;
            element.title = element.name;
            element.value = element.code;
            if (element.components && element.components.length > 0) {
                element.children = element.components
                element.isLeaf = false
                element.scopedSlots = { icon: 'custom', selected: element.componentRules.length > 0 ? true : false }
                this.mapTree(element.components)
            } else {
              element.isLeaf = true
              element.scopedSlots = { icon: 'custom' }
            }
        });
        this.unitsTreeData.treeData = data
        this.unitsTreeData.treeData = [...this.unitsTreeData.treeData]
        console.log(this.unitsTreeData.treeData)
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    // 部件树递归
    mapTree(data) {
      data.forEach(items => {    //遍历树 拼入相应的disabled
        items.key = items.id;
        items.title = items.name;
        items.value = items.code;
        if (items.components && items.components.length > 0) {
            items.children = items.components
            items.isLeaf = false
            items.scopedSlots = { icon: 'custom', selected: items.componentRules.length > 0 ? true : false }
            this.mapTree(items.components)
        } else {
          items.isLeaf = true
          items.scopedSlots = { icon: 'custom', selected: items.componentRules.length > 0 ? true : false }
        }
      })
    },
    // 查询触发规则
    queryList() {
      this.loading = true
      let dataset = {
        objectId: this.relatedData.objectId,
        name: this.headData[0].value,
        flag: this.headData[1].value,
        type: this.headData[2].value,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        tenantId: this.tenantId
      }
      if (this.unitsTreeData.updateAssemblyId) {
        dataset.code = this.unitsTreeData.updateAssemblyId
      }
      util.postform('component/queryList', dataset).then((res) => {
        if (res.code === 200) {
          let list = []
          res.data.records.forEach(ele => {
            ele.componentRules.forEach(ele2 => {
              ele2.key = ele2.code
              list.push({ id: ele2.id, key: ele2.id, experienceId: ele.componentExperiences.length > 0 ? ele.componentExperiences[0].experienceId : '', code: ele.code, supCode: ele.supCode, componentName: ele2.componentName, type: ele2.type, cycle: ele2.cycle, createTime: ele2.createTime, schedule: ele2.schedule, state: ele2.state, componentExperiences: ele.componentExperiences })
            })
          })
          this.dataSource = list
          this.pageTotal = res.data.total
          this.loading = false
        }
      }).catch(err => {
        this.$message.error(err.message);
        this.loading = false
      })
    },
    // 新增部件
    forIdAdd() {
      if (!this.unitsTreeData.assemblyId) {
        return this.$message.error('请输入部件编号')
      } else if (!this.unitsTreeData.assemblyName) {
        return this.$message.error('请输入部件名称')
      }
      let dataset = {
        objectId: this.relatedData.objectId,
        organizationId: this.relatedData.organizationId,
        name: this.unitsTreeData.assemblyName,
        code: this.unitsTreeData.assemblyId,
        supCode: this.unitsTreeData.theirAssemblyId,
        flag: 1,
        sort: this.unitsTreeData.sort,
        creater: this.userId,
        tenantId: this.tenantId,
      }
      util.postform('component/add', dataset).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.updataClose()
          this.empty()
          this.queryForId()
          this.$message.success('添加成功')
        } else {
          this.$message.error('部件编号重复请更换部件编号')
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    // 修改部件
    forIdEdit() {
      if (!this.unitsTreeData.assemblyName) {
        return this.$message.error('请输入部件名称')
      }
      let dataset = {
        id: this.unitsTreeData.id,
        name: this.unitsTreeData.assemblyName,
        supCode: this.unitsTreeData.theirAssemblyId,
        sort: this.unitsTreeData.sort,
        tenantId: this.tenantId
      }
      util.postform('component/update', dataset).then((res) => {
        if (res.code == 200) {
          if (res.data == false) {
            return this.$message.error('请选择正确的归属部件')
          }
          this.updataClose()
          this.empty()
          this.queryForId()
          this.$message.success('修改成功')
        } else {
          this.$message.error('修改失败')
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    // 新增触发规则
    addRule() {
      if (!this.settingTableDate.num) {
        return this.$message.error('请输入设定周期')
      }
      let dataset = {
        objectId: this.relatedData.objectId,
        objectName: this.relatedData.objectName,
        code: this.unitsTreeData.updateAssemblyId,
        componentName: this.unitsTreeData.updateAssemblyName,
        type: this.settingTableDate.type == '按时间' ? 1 : 2,
        state: this.settingTableDate.state,
        cycle: this.settingTableDate.num,
        tenantId: this.tenantId
      }
      util.postform('component/addRule', dataset).then((res) => {
        if (res.code == 200) {
          if (res.data == false) {
            return this.$message.error('该部件下有触发规则，请删除后新增！')
          }
          this.updataClose()
          this.empty()
          this.queryList()
          this.$message.success('新增触发规则成功')
        } else {
          this.$message.error('新增触发规则失败')
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    // 修改触发规则
    updateRule() {
      let dataset = {
        id: this.settingTableDate.id,
        objectId: this.relatedData.objectId,
        objectName: this.relatedData.objectName,
        code: this.unitsTreeData.updateAssemblyId,
        componentName: this.unitsTreeData.updateAssemblyName,
        type: this.settingTableDate.type == '按时间' ? 1 : 2,
        state: this.settingTableDate.state,
        cycle: this.settingTableDate.num,
        tenantId: this.tenantId
      }
      util.postform('component/updateRule', dataset).then((res) => {
        if (res.code == 200) {
          this.updataClose()
          this.empty()
          this.queryList()
          this.$message.success('修改触发规则成功')
        } else {
          this.$message.error('修改触发规则失败')
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    // 删除触发规则
    deleteRule() {
      let that = this
      if (this.selectedRowKeys.length == 0) {
        return this.$message.error('请选择需要删除的触发规则！')
      }
      this.$confirm({
        title: '删除提示！',
        content: '删除后数据不可恢复，并且部件使用进度记录不会保存，确定删除选中的触发规则吗？',
        onOk() {
          let dataset = that.selectedRowKeys
          util.postform('component/deleteRule', dataset).then((res) => {
            if (res.code == 200) {
              that.updataClose()
              that.empty()
              that.queryList()
              that.$message.success('删除触发规则成功')
            } else {
              that.$message.error('删除触发规则失败')
            }
          }).catch(err => {
            that.$message.error(err.message);
          })
        },
        onCancel() {},
      });
    },
    // 删除部件
    forIdDelete() {
      let that = this
      if (this.unitsTreeData.ids.length == 0) {
        return this.$message.error('请选择需要删除的部件！')
      }
      this.$confirm({
        title: '删除提示！',
        content: '删除选中部件的同时会删除下级的子部件和部件关联的任务，是否删除选中部件？',
        onOk() {
          let dataset = that.unitsTreeData.ids
          util.postform('component/remove', dataset).then((res) => {
            if (res.code == 200) {
              that.unitsTreeData.ids = []
              that.updataClose()
              that.empty()
              that.queryForId()
              that.$message.success('删除成功')
            } else {
              that.$message.error('删除失败')
            }
          }).catch(err => {
            that.$message.error(err.message);
          })
        },
        onCancel() {},
      });
    },
    // 查询关联任务
    getStandardExperiencePageList() {
      let dataset = {
        tenantId: this.tenantId,
        pageIndex: this.pageIndex2,                      //页数
        pageSize: this.pageSize2,                     //每页 条数
        standardExperienceName: this.associatedData[0].value
      }
      util.postform('standardExperience/getStandardExperiencePageList', dataset).then((res) => {
        if (res.code == 200) {
          let data = res.data.records;
          let total = res.data.total
          data.forEach((element, index) => {
            element.selected = true;
            element.key = index;
            element.experienceId = element.id
          });
          this.dataSource2 = data;
          this.pageTotal2 = total;
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    // 部件关联任务
    addComponentExperience() {
      let dataset = {
        objectId: this.relatedData.objectId,
        experienceId: this.selectedRows2[0].id,
        code: this.selectedRows[0].code,
        flag: 1,
        creater: this.userId,
        tenantId: this.tenantId
      }
      util.postform('component/addComponentExperience', dataset).then((res) => {
        if (res.code == 200) {
          this.updataClose()
          this.empty()
          this.queryList()
          this.$message.success('关联成功')
        } else {
          this.$message.error('关联失败')
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    // 查询所属组织
    treeSearch() {
      var dataset = {
          dataset : this.tenantId
      };
      util.post("department/search",dataset).then((res)=>{
          let temp=res.dataset;
          temp.forEach(ele => {
            ele.key = ele.id;
            ele.title = ele.name;
            ele.value = ele.id;
          });
          this.equipmentData.forEach(ele => {
            if (ele.duplicate == 'organization') {
              ele.treeData = this.doFormateTreeFun(temp, '')
              ele.value = undefined
            }
          })
          this.$nextTick(() => {
            this.queryEquipmentList()
          })
      })
    },
    // 查询所属组织表格
    queryEquipmentList() {
      var dataset = {
          tenantId: this.tenantId,
          departments: this.equipmentData[0].value2,
          modeFlag: this.equipmentData[1].value,
          objectName: this.equipmentData[2].value,
          pageIndex: this.pageIndex3,                      //页数
          pageSize: this.pageSize3,                     //每页 条数
      };
      util.postform("departmentNew/queryEquipmentList",dataset).then((res)=>{
          let temp=res.data.records;
          temp.forEach(ele => {
            ele.key = ele.id;
          });
          this.dataSource3 = temp
          this.pageTotal3 = res.data.total
      })
    },
    doFormateTreeFun(data, pId){
      var tree = [];
      var temp;
      for (var i = 0; i < data.length; i++) {
          if (data[i].pId == pId) {
              var obj = data[i];
              temp = this.doFormateTreeFun(data, data[i].id);
              if (temp.length > 0) {
                  obj.children = temp;
              }
              tree.push(obj);
          }
      }
      console.log(tree);
      return tree;
    },
    // 部件关联任务
    batchApply() {
      let that = this
      let list = []
      if (this.selectedRowKeys3.length == 0) {
        return this.$message.error('请选择需要适用于设备！')
      }
      this.selectedRowKeys3.forEach(ele => {
        list.push({ tenantId: this.tenantId, objectId: ele, flag: 1 })
      })
      this.$confirm({
        title: '设备树适用提示？',
        content: '适用后原设备树、已制定的规则和关联的任务将不会存在！是否确定适用于所选设备的设备树？',
        onOk() {
         let dataset = {
            componentApplies: list,
            componentApply: {
              tenantId: that.tenantId,
              objectId: that.relatedData.objectId,
              flag: 1
            }
          }
          util.postform('component/apply', dataset).then((res) => {
            if (res.code == 200) {
              that.updataClose()
              that.empty()
              that.queryList()
              that.$message.success('适用于成功')
            } else {
              that.$message.error('适用于失败')
            }
          }).catch(err => {
            that.$message.error(err.message);
          }) 
        },
        onCancel() {},
      });
    },
    // 部件关联任务 详情
    queryStandardExperienceByObectId() {
         let dataset = {
            tenantId: this.tenantId,
            id: this.detailsObjectId
          }
          util.postform('standardExperience/queryStandardExperienceByObectId', dataset).then((res) => {
            if (res.code == 200) {
              this.dataSource4 = res.data.standards
              this.dataSource5 = res.data.parts
              this.libraryDetailsObject = res.data
            }
          }).catch(err => {
            this.$message.error(err.message);
          }) 
    },
    //部件全导入
    excelCompleteImportComponent(flie) {
      const fd = new FormData()
      fd.append('tenantId', this.tenantId)
      fd.append('excel', flie[0])
      fd.append('creater', this.userId)
      util.postformData('component/excel/excelCompleteImportComponent', fd).then((res) => {
         console.log(res)
         if (res.data.statusCode == 200) {
          this.$message.success('导入成功')
          this.current = 1
         } else {
          this.$message.error(res.data.message)
         }
      }).catch(err => {
        this.$message.error(err.message);
      }) 
    },
    //按部件导入
    excelImportComponent(flie) {
      const fd = new FormData()
      fd.append('tenantId', this.tenantId)
      fd.append('objectId', this.relatedData.objectId)
      fd.append('organizationId', this.relatedData.organizationId)
      fd.append('excel', flie[0])
      fd.append('creater', this.userId)
      util.postformData('component/excel/excelImportComponent', fd).then((res) => {
         console.log(res)
         if (res.data.statusCode == 200) {
          this.$message.success('导入成功')
          this.current = 1
         } else {
          this.$message.error(res.data.message)
         }
      }).catch(err => {
        this.$message.error(err.message);
      }) 
    },
    // 进度初始化
    initRule() {
      let that = this
      this.$confirm({
        title: '进度初始化提示？',
        content: '初始化后，关联的任务会按新的时间进行计时。确定要初始化进度吗？',
        onOk() {
         let dataset = that.selectedRowKeys
          util.postform('component/initRule', dataset).then((res) => {
            if (res.code == 200) {
              that.empty()
              that.queryList()
              that.$message.success('初始化成功')
            } else {
              that.$message.error('初始化失败')
            }
          }).catch(err => {
            that.$message.error(err.message);
          }) 
        },
        onCancel() {},
      });
    },
    thePager(page, pageSize) {
      this.pageIndex = page
      this.pageSize = pageSize
      this.queryList()
    },
    showSizeChange(page, pageSize) {
      this.pageIndex = page
      this.pageSize = pageSize
      this.queryList()
    },
    thePager2(page, pageSize) {
      this.pageIndex2 = page
      this.pageSize2 = pageSize
      this.getStandardExperiencePageList()
    },
    showSizeChange2(page, pageSize) {
      this.pageIndex2 = page
      this.pageSize2 = pageSize
      this.getStandardExperiencePageList()
    },
    thePager3(page, pageSize) {
      this.pageIndex3 = page
      this.pageSize3 = pageSize
      this.queryEquipmentList()
    },
    showSizeChange3(page, pageSize) {
      this.pageIndex3 = page
      this.pageSize3 = pageSize
      this.queryEquipmentList()
    },
    onTreeSelectChange(item) {
      if (item.duplicate == 'organization') {
        this.equipmentData.forEach(ele => {
          if (ele.duplicate == 'organization') {
            ele.value = item.value
            ele.value2 = (item.object && this.allChildNodes([item.object])) || []
          }
        })
      }
    },
    // 获取选择树节点下面的Id递归
    allChildNodes(node){
      // 1.创建全部节点的数组
      var allCN = [];
      // 2.递归获取全部节点
      var getAllChildNodes = function(node, allCN){
        // 获取nodes的子节点
        for (var i = 0; i < node.length; i++) {
            var child = node[i];
            // 判断是否为指定类型节点
            allCN.push(child.id);
            if (child.children && child.children.length > 0) {
              getAllChildNodes(child.children, allCN)
            }
        } 
      }
      getAllChildNodes(node, allCN);
      // 3.返回全部节点的数组
      return allCN;
    },
    onHandleChange(item) {
      if (item.duplicate == 'anystandard') {
        this.equipmentData.forEach(ele => {
          if (ele.duplicate == 'anystandard') {
            ele.value = item.value
          }
        })
      } else if (item.duplicate == 'status') {
        this.headData.forEach(ele => {
          if (ele.duplicate == 'status') {
            ele.value = item.value
          }
        })
      } else if (item.duplicate == 'trigger') {
        this.headData.forEach(ele => {
          if (ele.duplicate == 'trigger') {
            ele.value = item.value
          }
        })
      }
    },
    onInputChange(item) {
      if (item.duplicate == 'library') {
        this.associatedData[0].value = item.value
      } else if (item.duplicate == 'devicename') {
        this.equipmentData.forEach(ele => {
          if (ele.duplicate == 'devicename') {
            ele.value = item.value
          }
        })
      } else if (item.duplicate == 'component') {
        this.headData.forEach(ele => {
          if (ele.duplicate == 'component') {
            ele.value = item.value
          }
        })
      }
    },
    onSelectClick() {
      if (this.jywsDialogName == '关联任务') {
        this.getStandardExperiencePageList()
      } else if (this.jywsDialogName == '批量新增（适用于）') {
        this.queryEquipmentList()
      } else {
        this.queryList()
      }
    },
    // 展开设备树
    expandTree(item) {
      this.dynamicLoadingTree(item)
    },
    // 选中设备树的回调
    treeSelect(val, data) {
      if (val.length > 0) {
        if (data.type == 'equ') {
          this.relatedData.objectId = data.id
          this.relatedData.objectName = data.name
          this.relatedData.organizationId = data.parentId
          this.relatedData.organizationName = data.parentName
          this.queryForId()
          this.queryList()
        } else {
          this.relatedData.objectId = ''
          this.relatedData.objectName = ''
          this.relatedData.organizationId = data.id
          this.relatedData.organizationName = data.name
        }
      } else {
        this.relatedData.objectId = ''
        this.relatedData.objectName = ''
        this.relatedData.organizationId = ''
        this.relatedData.organizationName = ''
      }
      this.relatedData.id = ''
      this.relatedData.theirAssemblyId = undefined
      this.relatedData.assemblyId = ''
      this.relatedData.assemblyName = ''
      this.relatedData.updateAssemblyId = ''
      this.relatedData.updateAssemblyName = ''
      this.unitsTreeData.ids = []
    },
    // 选中部件树的回调
    treeSelect2(val, data) {
      if (val.length > 0) {
        this.unitsTreeData.id = data.id
        this.unitsTreeData.theirAssemblyId = data.supCode
        this.unitsTreeData.updateAssemblyId = data.value
        this.unitsTreeData.updateAssemblyName = data.title
        this.unitsTreeData.ids = []
        this.selectMapTree([data])
      } else {
        this.unitsTreeData.id = ''
        this.unitsTreeData.theirAssemblyId = ''
        this.unitsTreeData.updateAssemblyId = ''
        this.unitsTreeData.updateAssemblyName = ''
        this.unitsTreeData.ids = []
      }
      this.queryList()
    },
    // 递归选中部件树下的id
    selectMapTree(data) {
      data.forEach(items => {    //遍历树 拼入相应的disabled
        this.unitsTreeData.ids.push(items.id)
        if (items.components && items.components.length > 0) {
            this.selectMapTree(items.components)
        }
      })
    },
    // 部件树新增
    unitsTreeAdd() {
      if (this.relatedData.objectId) {
        this.showModel('treeAdd')
      } else {
        this.$message.error('提示：请先从左侧选择设备！');
      }
    },
    // 部件树修改
    unitsTreeEdit() {
      if (this.unitsTreeData.updateAssemblyId) {
        this.unitsTreeData.assemblyId = this.unitsTreeData.updateAssemblyId
        this.unitsTreeData.assemblyName = this.unitsTreeData.updateAssemblyName
        this.showModel('treeEdit')
      } else {
        this.$message.error('请选择需要修改的部件！');
      }
    },
    //打开 弹出窗
    showModel(type) {
      switch (type) {
        case "treeAdd":
          this.unitsTreeVisble = true
          this.jywsDialogName = '新增' // 弹窗名称
          this.jywsDialogWidth = '560px' // 弹窗名称
          break;
        case "treeEdit":
          this.unitsTreeVisble = true
          this.jywsDialogName = '修改' // 弹窗名称
          this.jywsDialogWidth = '560px' // 弹窗名称
          break;
        case "setUp":
          this.unitsTreeVisble = true
          this.jywsDialogName = '新增触发规则' // 弹窗名称
          this.jywsDialogWidth = '560px' // 弹窗名称
          break;
        case "update":
          this.unitsTreeVisble = true
          this.jywsDialogName = '修改触发规则' // 弹窗名称
          this.jywsDialogWidth = '560px' // 弹窗名称
          break;
        case "apply":
          this.unitsTreeVisble = true
          this.jywsDialogName = '批量新增（适用于）' // 弹窗名称
          this.jywsDialogWidth = '960px' // 弹窗名称
          break;
        case "associated":
          this.unitsTreeVisble = true
          this.jywsDialogName = '关联任务' // 弹窗名称
          this.jywsDialogWidth = '960px' // 弹窗名称
          break;
        case "associatedDetails":
          this.unitsTreeVisble2 = true
          this.jywsDialogName2 = '标准库详情' // 弹窗名称
          this.jywsDialogWidth2 = '960px' // 弹窗名称
          break;
        case "equipmentImport":
          this.importVisible = true,
          this.importTitle = '按设备导入'
          this.importUrl = 'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/%E9%83%A8%E4%BB%B6%E5%85%A8%E5%AF%BC%E5%85%A5%20.xls'
          break;
        case "partsImport":
          this.importVisible = true,
          this.importTitle = '按部件导入'
          this.importUrl = 'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/%E9%83%A8%E4%BB%B6%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5.xls'
          break;
        default:
          break;
      }
    },
    standardLibraryDetails(item) {
      this.detailsObjectId = item.experienceId
      this.queryStandardExperienceByObectId()
      this.showModel('associatedDetails')
    },
    //关闭弹窗
    updataClose() {
      this.unitsTreeVisble = false
      this.empty()
    },
    updataClose2() {
      this.unitsTreeVisble2 = false
    },
    // 提交回调
    updataWin() {
      if (this.jywsDialogName == '新增') {
        this.forIdAdd()
      } else if (this.jywsDialogName == '修改') {
        this.forIdEdit()
      } else if (this.jywsDialogName == '新增触发规则') {
        this.addRule()
      } else if (this.jywsDialogName == '修改触发规则') {
        this.updateRule()
      } else if (this.jywsDialogName == '关联任务') {
        this.addComponentExperience()
      } else if (this.jywsDialogName == '批量新增（适用于）') {
        this.batchApply()
      }
    },
    // 数据清空
    empty() {
      this.jywsDialogName= ''
      this.selectedRowKeys = []
      this.selectedRows = []
      this.unitsTreeData.id = ''
      this.unitsTreeData.theirAssemblyId = undefined
      this.unitsTreeData.assemblyId = ''
      this.unitsTreeData.assemblyName = ''
      this.unitsTreeData.sort = ''
      this.settingTableDate.type = '按时间'
      this.settingTableDate.num = ''
      this.settingTableDate.state = 1
      this.standardExperienceName = ''
      this.selectedRowKeys2 = []
      this.selectedRows2 = []
      this.equipmentData[0].value = undefined
      this.equipmentData[0].value2 = []
      this.equipmentData[1].value = ''
      this.equipmentData[2].value = ''
      this.selectedRowKeys3 = []
      this.selectedRows3 = []
    },
    cancelClick() {
      this.importVisible = false
      this.importTitle = ''
      this.current = 0
    },
    submitClick(flie) {
      if (this.importTitle == '按设备导入') {
        this.excelCompleteImportComponent(flie)
      } else if (this.importTitle == '按部件导入') {
        this.excelImportComponent(flie)
      }
    }
  },
}
</script>

<style scoped lang="less">
.numberOfDevices {
  background-color: #f0f2f5;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
}
.numberOfDevices-title {
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  color: #333333;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.title-icon {
  width: 4px;
  height: 16px;
  background: #1890ff;
  display: inline-block;
  margin-right: 12px;
}
.query-button {
  padding: 0px 21px 0px 17px;
  background: #ffffff;
}
.numberOfDevices-content {
  background: #ffffff;
  width: 100%;
  height: calc(100% - 185px);
  display: flex;
  padding: 32px 21px 36px 17px;
}
.flush {
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-left {
  width: 220px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  flex-shrink: 0;
  .content-left-title {
    height: 48px;
    width: 100%;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-left-btnGroup {
    height: 48px;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-left-hint {
    margin-top: 17px;
    font-size: 14px;
    font-weight: 400;
    color: #f87500;
    padding: 6px 7px;
    background: #fffbe6;
  }
  .content-left-tree  {
    height: calc(100% - 55px);
  }
  .content-left-tree2  {
    height: calc(100% - 115px);
  }
}
.content-right {
  height: 100%;
  width: 100%;
  margin-left: 22px;
}
.content-right-button {
  text-align: left;
  margin-bottom: 12px;
}
.marginb24 {
  margin-bottom: 24px;
}
.flex-leftName {
  font-size: 14px;
  text-align: right;
  color: #333333;
  flex: 1;
  margin-right: 24px;
  position: relative;
}
.flex-rightName {
  flex: 3;
}
.associatedTasks-head {
  background: #fffbe6;
  border-radius: 2px;
  padding: 12px;
  font-size: 14px;
  color: #f87500;
  display: flex;
  align-items: center;
}
.icon-img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.pagination-style {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.information-flex {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 12px;
}
.information-flex1 {
  flex: 1;
  display: flex;
  align-items: center;
}
</style>
