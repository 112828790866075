<template>
	<div class="oplList">
		<div class="oplList-title">
			<span class="title-icon"> </span>
			<span>分销商管理</span>
		</div>
		<div class="oplList-content">
			<div class="content-right">
				<!-- 查询区域 -->
				<div class="content-right-form">
					<conditionQuery
						:headData="headData"
						@onTimeChange="onTimeChange"
						@onInputChange="onInputChange"
						@onSelectClick="getOplList"
					/>
				</div>
				<!-- 功能按钮区域 -->
				<div class="content-right-btnGroup">
					<a-button-group>
						<a-button @click="addOplType" name="addOplType">佣金入账</a-button>
						<a-button @click="addOplType" name="editOplType">结算佣金</a-button>
						<a-button @click="addOplType" name="editOplListType">佣金记录</a-button>
					</a-button-group>
				</div>
				<!-- 表格区域 -->
				<div class="content-right-table">
					<a-table
						:columns="columns"
						:row-selection="rowSelection"
						:pagination="false"
						:data-source="dataSource"
					>
						<span slot="time" slot-scope="time">
							{{ $moment(time).format('YYYY-MM-DD HH:mm') }}
						</span>
						<span slot="action" slot-scope="scope">
							<a @click="openinfoOplList(scope)" name="infoOplList">查看</a>
						</span>
					</a-table>
					<a-pagination
						size="small"
						:total="total"
						:defaultCurrent="pageIndex"
						:defaultPageSize="pageSize"
						show-size-changer
						@change="pageChange"
						show-quick-jumper
						:show-total="total => `总共 ${total} 条`"
					/>
				</div>
			</div>
		</div>
		<!-- 弹窗区域 -->
		<jywsDialog
			:visible="visible"
			:title="modalTitle"
			:width="visibleWidth"
			:roger="jywsDialogBottomRoger"
			:typePrimary="typePrimary"
			:bottom-name="jywsDialogBottomName"
			@updataClose="closeModal"
			@updataWin="updataWin"
		>
			<a-spin :spinning="spinning">
				<div class="modal-body">
					<!-- 新增/修改opl类型弹窗 -->
					<div v-if="visibleType === 'addOplType'">
						<a-descriptions :column="1" bordered size="small">
							<a-descriptions-item label=" 成单客户：">
								<a-input @click="openTheNewModal" style="width: 100%" :value="dealPeoName"> </a-input>
							</a-descriptions-item>
						</a-descriptions>
						<a-table
							class="mar-t14"
							:columns="yjrzcolumns"
							:pagination="false"
							:data-source="yjrzdataSource"
						>
							<span slot="shareAmount" slot-scope="scope, record">
								<a-input :value="scope" @change="newInputChange($event.target.value, record)"></a-input>
							</span>
							<span slot="remark" slot-scope="scope">
								<a-input :value="scope"></a-input>
							</span>
							<span slot="level" slot-scope="scope">
								<div>
									{{ scope === 1 ? '销售佣金' : scope === 2 ? '销售分红' : '奖励金额' }}
								</div>
							</span>
						</a-table>
					</div>
					<!-- 新增opl课程列表弹窗 -->
					<div v-else-if="visibleType === 'editOplType'">
						<div class="dialog-title">
							<span class="title-icon"> </span>
							<span>待结算佣金明细</span>
						</div>
						<a-table
							class="mar-t14"
							:columns="djsyjcolumns"
							:row-selection="newrowSelection"
							:pagination="true"
							:data-source="djsyjdataSource"
						>
							<span slot="time" slot-scope="time">
								{{ time?$moment(time).format('YYYY-MM-DD HH:mm'):'-' }}
							</span>
							<span slot="state" slot-scope="scope">
								<a-badge v-if="scope === 1" status="error" text="待结算"></a-badge>
								<a-badge v-else status="default" text="已结算"></a-badge>
							</span>
							<span slot="level" slot-scope="scope">
								<div>
									{{ scope === 1 ? '销售佣金' : scope === 2 ? '销售分红' : '奖励金额' }}
								</div>
							</span>
						</a-table>
					</div>
					<!-- 关联课程弹窗 -->
					<div v-else-if="visibleType === 'editOplListType'">
						<a-tabs type="card" @change="tabsChange">
							<a-tab-pane key="1" tab="入账记录">
								<a-button type="primary" @click="gobackBtn"> 撤销 </a-button>
								<a-table
									class="mar-t14"
									:columns="rzjlcolumns"
									:row-selection="newrowSelection"
									:pagination="true"
									:data-source="rzjldataSource"
								>
									<span slot="time" slot-scope="time">
										{{ $moment(time).format('YYYY-MM-DD HH:mm') }}
									</span>
									<span slot="state" slot-scope="scope">
										<a-badge v-if="scope && scope === 3" status="error" text="已撤销"></a-badge>
										<a-badge v-else status="success" text="正常"></a-badge>
									</span>
								</a-table>
							</a-tab-pane>
							<a-tab-pane key="2" tab="结算记录">
								<a-button type="primary" @click="gobackBtn"> 撤销 </a-button>
								<a-table
									class="mar-t14"
									:columns="jsjlcolumns"
									:row-selection="newrowSelection"
									:pagination="true"
									:data-source="jsjldataSource"
								>
									<span slot="time" slot-scope="time">
										{{ $moment(time).format('YYYY-MM-DD HH:mm') }}
									</span>
									<span slot="state" slot-scope="scope">
										<a-badge v-if="scope && scope === 1" status="error" text="待结算"></a-badge>
										<a-badge v-else status="default" text="已结算"></a-badge>
									</span>
									<span slot="level" slot-scope="scope">
										<div>
											{{ scope === 1 ? '销售佣金' : scope === 2 ? '销售分红' : '奖励金额' }}
										</div>
									</span>
								</a-table>
							</a-tab-pane>
						</a-tabs>
					</div>
					<!-- opl课程详情 -->
					<div v-else-if="visibleType === 'infoOplList'">
						<a-tabs type="card">
							<a-tab-pane key="1" tab="基础信息">
								<div class="dialog-title">
									<span class="title-icon"> </span>
									<span>分销商信息</span>
								</div>
								<a-descriptions class="mar-t14" :column="2" bordered size="small">
									<a-descriptions-item label="分销商昵称：">
										{{ distributor.name }}
									</a-descriptions-item>
									<a-descriptions-item label="分销商头像："> - </a-descriptions-item>
									<a-descriptions-item label="分销商电话：">
										{{ distributor.phone }}
									</a-descriptions-item>
									<a-descriptions-item label="微信号："> - </a-descriptions-item>
									<a-descriptions-item label="直接推荐人：">
										{{ distributor.supname }}
									</a-descriptions-item>
									<a-descriptions-item label="间接推荐人：">
										{{ distributor.grandparentname }}
									</a-descriptions-item>
									<a-descriptions-item label="加盟时间：">
										{{ distributor.createtime }}
									</a-descriptions-item>
									<a-descriptions-item label="分销商海报："> 查看 </a-descriptions-item>
								</a-descriptions>
								<div class="dialog-title mar-t14">
									<span class="title-icon"> </span>
									<span>收款信息</span>
								</div>
								<a-descriptions class="mar-t14" :column="2" bordered size="small">
									<a-descriptions-item label="户名：">
										{{ distributor.accountName }}
									</a-descriptions-item>
									<a-descriptions-item label="银行：">
										{{ distributor.bankName }}
									</a-descriptions-item>
									<a-descriptions-item label="账户：">
										{{ distributor.account }}
									</a-descriptions-item>
									<a-descriptions-item label=""> </a-descriptions-item>
								</a-descriptions>
							</a-tab-pane>
							<a-tab-pane key="2" tab="佣金数">
								<a-descriptions :column="2" bordered size="small">
									<a-descriptions-item label="总佣金：">
										{{ modalInfoList.totalCommission }}
									</a-descriptions-item>
									<a-descriptions-item label="待结算佣金：">
										{{ modalInfoList.commissionToBeSettled }}
									</a-descriptions-item>
									<a-descriptions-item label="已结算佣金：">
										{{ modalInfoList.settledCommission }}
									</a-descriptions-item>
									<a-descriptions-item label=""> </a-descriptions-item>
								</a-descriptions>
								<div class="dialog-title mar-t14">
									<span class="title-icon"> </span>
									<span>佣金明细</span>
								</div>
								<a-table
									class="mar-t14"
									:columns="yjcolumns"
									:pagination="false"
									:data-source="shareList"
								>
									<span slot="time" slot-scope="time">
										{{ $moment(time).format('YYYY-MM-DD HH:mm') }}
									</span>
									<span slot="state" slot-scope="scope">
										<a-badge v-if="scope && scope === 1" status="error" text="待结算"></a-badge>
										<a-badge v-else status="default" text="已结算"></a-badge>
									</span>
									<span slot="level" slot-scope="scope">
										<div>
											{{ scope === 1 ? '销售佣金' : scope === 2 ? '销售分红' : '奖励金额' }}
										</div>
									</span>
								</a-table>
							</a-tab-pane>
							<a-tab-pane key="3" tab="子级分销数">
								<div class="dialog-title">
									<span class="title-icon"> </span>
									<span>子级分销商列表</span>
								</div>
								<a-table
									class="mar-t14"
									:columns="zjfxcolumns"
									:pagination="true"
									:data-source="childDistributor"
								>
									<span slot="time" slot-scope="time">
										{{ $moment(time).format('YYYY-MM-DD HH:mm') }}
									</span>
								</a-table>
								<div class="dialog-title mar-t14">
									<span class="title-icon"> </span>
									<span>渠道成单列表</span>
								</div>
								<a-descriptions class="mar-t14" :column="1" bordered size="small">
									<a-descriptions-item label="渠道成单数："> 0 单 </a-descriptions-item>
								</a-descriptions>
							</a-tab-pane>
							<a-tab-pane key="4" tab="报备客户数">
								<div class="dialog-title">
									<span class="title-icon"> </span>
									<span>报备客户数列表</span>
								</div>
								<a-table
									class="mar-t14"
									:columns="bbkhcolumns"
									:pagination="true"
									:data-source="reportCustomerList"
								>
									<span slot="time" slot-scope="time">
										{{ $moment(time).format('YYYY-MM-DD HH:mm') }}
									</span>
								</a-table>
							</a-tab-pane>
							<a-tab-pane key="5" tab="成单客户数">
								<div class="dialog-title">
									<span class="title-icon"> </span>
									<span>成单客户数列表</span>
								</div>
								<a-table
									class="mar-t14"
									:columns="cdkhcolumns"
									:pagination="true"
									:data-source="dealCustomerList"
								>
									<span slot="leaseBeginTime" slot-scope="scope">
										{{
											$moment(scope.leaseBeginTime).format('YYYY-MM-DD HH:mm') +
											'~' +
											$moment(scope.leaseEndTime).format('YYYY-MM-DD HH:mm')
										}}
									</span>
									<span slot="flag" slot-scope="scope">
										<a-badge v-if="scope && scope === 0" status="error" text="停用"></a-badge>
										<a-badge v-else status="success" text="启用"></a-badge>
									</span>
								</a-table>
							</a-tab-pane>
						</a-tabs>
					</div>
				</div>
			</a-spin>
		</jywsDialog>
		<!-- 二级弹窗 -->
		<jywsDialog
			:visible="newvisible"
			:title="newmodalTitle"
			:width="newvisibleWidth"
			:roger="newjywsDialogBottomRoger"
			:typePrimary="newtypePrimary"
			:bottom-name="newjywsDialogBottomName"
			@updataClose="newcloseModal"
			@updataWin="newupdataWin"
		>
			<div class="modal-body">
				<div class="dialog-title">
					<span class="title-icon"> </span>
					<span>成单客户列表</span>
				</div>
				<div class="title-smalltext">提示：请勾选成交企业信息，勾选后将自动带入企业信息，仅支持单选。</div>
				<a-table
					class="mar-t14"
					:columns="cdkhcolumns2"
					:row-selection="newrowSelection"
					:pagination="false"
					:data-source="cdkhdataSource2"
				>
					<span slot="leaseBeginTime" slot-scope="scope">
						{{ scope.leaseBeginTime ? $moment(scope.leaseBeginTime).format('YYYY-MM-DD HH:mm') +
							'~' +
							$moment(scope.leaseEndTime).format('YYYY-MM-DD HH:mm') : '-' }}
						<!-- {{
							$moment(scope.leaseBeginTime).format('YYYY-MM-DD HH:mm') +
							'~' +
							$moment(scope.leaseEndTime).format('YYYY-MM-DD HH:mm')
						}} -->
					</span>
					<span slot="flag" slot-scope="scope">
						<a-badge v-if="scope === 0" status="error" text="停用"></a-badge>
						<a-badge v-else status="success" text="启用"></a-badge>
					</span>
				</a-table>
			</div>
		</jywsDialog>
	</div>
</template>

<script>
import jywsDialog from '@/components/jywsDialog.vue'
import * as util from '@/core/util'
import conditionQuery from '@/components/conditionQuery.vue'
import '@wangeditor/editor/dist/css/style.css'
// import M from 'minimatch'
// import currency from '@/core/currency'
export default {
	components: {
		conditionQuery,
		jywsDialog,
	},
	data() {
		return {
			selectedRows: [],
			newselectedRows: [],
			selectedRowKeys: [],
			newselectedRowKeys: [],
			rzjldataSource: [],
			jsjldataSource: [],
			tabsKey: '1',
			jsjlcolumns: [
				{
					title: '成单客户',
					dataIndex: 'customerName',
					key: 'customerName',
				},
				{
					title: '成单分销商',
					dataIndex: 'distributorName',
					key: 'distributorName',
				},
				{
					title: '结算时间',
					dataIndex: 'settlementTime',
					key: 'settlementTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '类型',
					dataIndex: 'level',
					key: 'level',
					scopedSlots: { customRender: 'level' },
				},
				{
					title: '佣金金额',
					dataIndex: 'shareAmount',
					key: 'shareAmount',
				},
				{
					title: '结算状态',
					dataIndex: 'state',
					key: 'state',
					scopedSlots: { customRender: 'state' },
				},
				{
					title: '备注',
					dataIndex: 'remark',
					key: 'remark',
				},
			],
			rzjlcolumns: [
				{
					title: '入账时间',
					dataIndex: 'incomeTime',
					key: 'incomeTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '企业名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '记录状态',
					dataIndex: 'state',
					key: 'state',
					scopedSlots: { customRender: 'state' },
				},
				{
					title: '销售佣金（1）',
					dataIndex: 'shareOne',
					key: 'shareOne',
				},
				{
					title: '销售分红（2）',
					dataIndex: 'shareTwo',
					key: 'shareTwo',
				},
				{
					title: '奖励金额（3）',
					dataIndex: 'shareThree',
					key: 'shareThree',
				},
			],
			djsyjcolumns: [
				{
					title: '成单客户',
					dataIndex: 'customerName',
					key: 'customerName',
				},
				{
					title: '成单分销商',
					dataIndex: 'distributorName',
					key: 'distributorName',
				},
				{
					title: '入账时间',
					dataIndex: 'createTime',
					key: 'createTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '类型',
					dataIndex: 'level',
					key: 'level',
					scopedSlots: { customRender: 'level' },
				},
				{
					title: '佣金金额',
					dataIndex: 'shareAmount',
					key: 'shareAmount',
				},
				{
					title: '结算状态',
					dataIndex: 'state',
					key: 'state',
					scopedSlots: { customRender: 'state' },
				},
				{
					title: '备注',
					dataIndex: 'remark',
					key: 'remark',
				},
			],
			djsyjdataSource: [],
			cdkhcolumns2: [
				{
					title: '企业名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '租用状态',
					dataIndex: 'flag',
					key: 'flag',
					scopedSlots: { customRender: 'flag' },
				},
				{
					title: '租用时段',
					dataIndex: 'leaseBeginTime',
					key: 'leaseBeginTime',
					scopedSlots: { customRender: 'leaseBeginTime' },
				},
				{
					title: '备注',
					dataIndex: 'remark',
					key: 'remark',
				},
				{
					title: '联系人',
					dataIndex: 'contacter',
					key: 'contacter',
				},
				{
					title: '联系电话',
					dataIndex: 'contactPhone',
					key: 'contactPhone',
				},
			],
			cdkhdataSource2: [],
			yjrzdataSource: [],
			yjrzcolumns: [
				{
					title: '分销商',
					dataIndex: 'distributorName',
					key: 'distributorName',
				},
				{
					title: '类型',
					dataIndex: 'level',
					key: 'level',
					scopedSlots: { customRender: 'level' },
				},
				{
					title: '佣金金额',
					dataIndex: 'shareAmount',
					key: 'shareAmount',
					scopedSlots: { customRender: 'shareAmount' },
				},
				{
					title: '备注',
					dataIndex: 'remark',
					key: 'remark',
					scopedSlots: { customRender: 'remark' },
				},
			],
			cdkhdataSource: [],
			cdkhcolumns: [
				{
					title: '企业名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '租用状态',
					dataIndex: 'flag',
					key: 'flag',
					scopedSlots: { customRender: 'flag' },
				},
				{
					title: '租用时段',
					dataIndex: 'leaseBeginTime',
					key: 'leaseBeginTime',
					scopedSlots: { customRender: 'leaseBeginTime' },
				},
				{
					title: '备注',
					dataIndex: 'remark',
					key: 'remark',
				},
				{
					title: '联系部门',
					dataIndex: 'contactDep',
					key: 'contactDep',
				},
				{
					title: '联系人',
					dataIndex: 'contacter',
					key: 'contacter',
				},
				{
					title: '联系电话',
					dataIndex: 'contactPhone',
					key: 'contactPhone',
				},
				{
					title: '客户地址',
					dataIndex: 'address',
					key: 'address',
				},
			],
			bbkhdataSource: [],
			bbkhcolumns: [
				{
					title: '企业名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '报备时间',
					dataIndex: 'createTime',
					key: 'createTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '联系部门',
					dataIndex: 'contactDep',
					key: 'contactDep',
				},
				{
					title: '联系人',
					dataIndex: 'contacter',
					key: 'contacter',
				},
				{
					title: '联系电话',
					dataIndex: 'contactPhone',
					key: 'contactPhone',
				},
				{
					title: '客户地址',
					dataIndex: 'address',
					key: 'address',
				},
			],
			zjfxdataSource: [],
			zjfxcolumns: [
				{
					title: '分销商昵称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '微信号',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '分销商电话',
					dataIndex: 'phone',
					key: 'phone',
				},
				{
					title: '报备客户数',
					dataIndex: 'discountCount',
					key: 'discountCount',
				},
				{
					title: '成单客户数',
					dataIndex: 'shareDiscountCount',
					key: 'shareDiscountCount',
				},
				{
					title: '存续客户数',
					dataIndex: 'subsistCount',
					key: 'subsistCount',
				},
				{
					title: '加盟时间',
					dataIndex: 'createtime',
					key: 'createtime',
					scopedSlots: { customRender: 'time' },
				},
			],
			yjdataSource: [],
			yjcolumns: [
				{
					title: '成单客户',
					dataIndex: 'customerName',
					key: 'customerName',
				},
				{
					title: '成单分销商',
					dataIndex: 'distributorName',
					key: 'distributorName',
				},
				{
					title: '入账时间',
					dataIndex: 'entryTime',
					key: 'entryTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '类型',
					dataIndex: 'level',
					key: 'level',
					scopedSlots: { customRender: 'level' },
				},
				{
					title: '佣金金额',
					dataIndex: 'shareAmount',
					key: 'shareAmount',
				},
				{
					title: '结算状态',
					dataIndex: 'state',
					key: 'state',
					scopedSlots: { customRender: 'state' },
				},
				{
					title: '结算时间',
					dataIndex: 'settlementTime',
					key: 'settlementTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '备注',
					dataIndex: 'remark',
					key: 'remark',
				},
			],
			// rowSelection,
			columns: [
				{
					title: '分销商昵称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '微信号',
					dataIndex: 'oplTypeName',
					key: 'oplTypeName',
				},
				{
					title: '分销商电话',
					dataIndex: 'phone',
					key: 'phone',
				},
				{
					title: '报备客户数',
					dataIndex: 'discountCount',
					key: 'discountCount',
				},
				{
					title: '成单客户数',
					dataIndex: 'shareDiscountCount',
					key: 'shareDiscountCount',
				},
				{
					title: '子级分销商',
					dataIndex: 'childrenCount',
					key: 'childrenCount',
				},
				{
					title: '总佣金',
					dataIndex: 'totalCommission',
					key: 'totalCommission',
				},
				{
					title: '待结算佣金',
					dataIndex: 'pendingCommission',
					key: 'pendingCommission',
				},
				// {
				// 	title: '直接推荐人',
				// 	dataIndex: 'supname',
				// 	key: 'supname',
				// },
				// {
				// 	title: '间接推荐人',
				// 	dataIndex: 'grandparentname',
				// 	key: 'grandparentname',
				// },
				{
					title: '加盟时间',
					dataIndex: 'createtime',
					key: 'createtime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '详情',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
				},
			],
			headData: [
				{
					key: 1,
					name: '分销商昵称',
					component: 'a-input',
					value: '',
					duplicate: 'name',
					placeholder: '请输入',
				},
				{
					key: 2,
					name: '分销商电话',
					component: 'a-input',
					value: '',
					duplicate: 'phone',
					placeholder: '请输入',
				},
				{
					key: 3,
					name: '直接推荐人',
					component: 'a-input',
					value: '',
					duplicate: 'supName',
					placeholder: '请输入',
				},
			],
			distributor: {},
			dealPeoName: '',
			dealPeoId: '',
			dealCustomerList: [],
			reportCustomerList: [],
			shareList: [],
			childDistributor: [],
			tenantId: '',
			// 新增opl课程参数
			addoplForm: {
				oplTypeId: undefined,
				oplTypeName: '',
				title: '',
				accessoryUrl: '',
				accessoryName: '',
				content: '',
				tenantId: '',
			},
			// 查询opl课程列表参数
			searchForm: {
				name: '',
				phone: '',
				supName: '',
			},
			modalInfoList: {},
			dataSource: [{ name: '123' }],
			visible: false,
			newvisible: false,
			visibleType: '',
			visibleWidth: '520px',
			newvisibleWidth: '960px',
			typePrimary: 'primary',
			newtypePrimary: 'primary',
			modalTitle: '',
			newmodalTitle: '',
			oplTypeName: '',
			spinning: false,
			// 树组件 键值对
			replaceFields: {
				children: 'children',
				title: 'oplName',
				key: 'oplId',
				value: 'oplId',
			},
			treeData: [],
			oplId: '',
			selectTreeData: {},
			pageIndex: 1,
			pageSize: 5,
			total: 5,
			jywsDialogBottomRoger: '取消',
			newjywsDialogBottomRoger: '取消',
			jywsDialogBottomName: '确认',
			newjywsDialogBottomName: '确认',
			editor: null,
			html: '<p></p>',
			toolbarConfig: {},
			editorConfig: {
				placeholder: '请输入内容...',
				// 所有的菜单配置，都要在 MENU_CONF 属性下
				MENU_CONF: {},
			},
			mode: 'default', // or 'simple'
			infoOplList: {
				title: '',
				createUserName: '',
				accessoryName: '',
				createTime: '',
				content: '',
				accessoryUrl: '',
			},
			userId: '',
			userName: '',
		}
	},
	created() {
		// var str = this.$route.fullPath.split('=')
		// this.tenantId = str[1].split('&')[0]
		// this.userId = str[2].split('&')[0]
		// this.userName = decodeURIComponent(str[3].split('&')[0])
		// this.addoplForm.tenantId = str[1].split('&')[0]
		this.editorConfig.MENU_CONF['uploadImage'] = {
			server: '/api/upload-image',
			fieldName: 'custom-field-name',
			// 继续写其他配置...

			//【注意】不需要修改的不用写，wangEditor 会去 merge 当前其他配置
		}
	},
	computed: {
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys = selectedRowKeys
					this.selectedRows = selectedRows
				},
			}
		},
		newrowSelection() {
			return {
				selectedRowKeys: this.newselectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.newselectedRowKeys = selectedRowKeys
					this.newselectedRows = selectedRows
				},
			}
		},
	},
	mounted() {
		// 模拟 ajax 请求，异步渲染编辑器
		// setTimeout(() => {
		//   this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
		// }, 1500)
		// this.getOplTypeTree()
		this.getOplList()
	},
	beforeDestroy() {
		const editor = this.editor
		if (editor == null) return
		editor.destroy() // 组件销毁时，及时销毁编辑器
	},
	methods: {
		onCreated(editor) {
			this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
		},
		// 选择opl类型
		oplTypeChange(val, obj) {
			this.addoplForm.oplTypeId = val
			this.addoplForm.oplTypeName = obj[0]
		},
		tabsChange(e) {
			this.tabsKey = e
			if (e === '1') {
				util.postform('/share/incomeRecord', {}).then(res => {
					this.rzjldataSource = res.data
				})
			} else {
				util.postform('/share/listToBeSettled', { state: 2 }).then(res => {
					this.jsjldataSource = res.data
				})
			}
			this.newselectedRowKeys = []
			this.newselectedRows = []
		},
		gobackBtn() {
			console.log(this.newselectedRows)
			if (this.newselectedRows.length !== 1) {
				this.$message.error('请选择一条记录进行操作！')
				return
			}
			if (this.tabsKey === '1') {
				util.postform('share/incomeRevoke', {
					id: this.newselectedRows[0].id,
					orderid: this.newselectedRows[0].orderid,
				}).then(res => {
					if (res.code === 500) {
						this.$message.error('已结算佣金不可撤销！')
					}
					if (res.code === 200) {
						this.$message.success('撤销成功！')
						this.closeModal()
					}
					
				})
			} else {
				util.postform('share/settlementCancellation', {
					id: this.newselectedRows[0].id,
					orderid: this.newselectedRows[0].orderid,
				}).then(res => {
					if (res.code === 500) {
						this.$message.error('已结算佣金不可撤销！')
					}
					if (res.code === 200) {
						this.$message.success('撤销成功！')
						this.closeModal()
					}
				})
			}
		},
		// 递归给树增加icon图标
		addIconSlot(val) {
			val.title = val.oplName
			val.key = val.oplId
			if (val.parentOpl === '0') {
				val.slots = { icon: 'home' }
			} else {
				val.slots = { icon: 'hdd' }
			}
			if (val.children && val.children.length !== 0) {
				val.children.forEach(item => {
					this.addIconSlot(item)
				})
			}
		},
		// 上传附件 -- 获取oss地址
		setFileList(val) {
			this.addoplForm.accessoryUrl = val
		},
		setFileListName(val) {
			this.addoplForm.accessoryName = val
		},
		// 查询区域 - 时间范围变动
		onTimeChange(val) {
			this.searchForm.startTime = val.dateString[0]
			this.searchForm.endTime = val.dateString[1]
		},
		// 查询区域 - 输入框变动
		onInputChange(item) {
			if (item.duplicate == 'name') {
				this.searchForm.name = item.value
			} else if (item.duplicate == 'phone') {
				this.searchForm.phone = item.value
			} else if (item.duplicate == 'supName') {
				this.searchForm.supName = item.value
			}
		},
		// 获取opl类型树结构
		getOplTypeTree() {
			util.get('oplType/getTree/' + this.tenantId).then(res => {
				if (res.data) {
					res.data.forEach(item => {
						this.addIconSlot(item)
					})
				}
				this.treeData = res.data || []
			})
		},
		pageChange(pageIndex, pageSize) {
			this.pageIndex = pageIndex
			this.pageSize = pageSize
			this.getOplList()
		},
		// 获取opl课程分页列表
		getOplList() {
			let dataset = {
				pageIndex: this.pageIndex,
				pageSize: this.pageSize,
				name: this.searchForm.name,
				phone: this.searchForm.phone,
				supName: this.searchForm.supName,
			}
			util.postform('/distributor/queryPageList', dataset).then(res => {
				res.data.records.forEach(item => {
					item.key = item.id
				})
				this.dataSource = res.data.records
				this.total = res.data.total
			})
		},
		// 选中树的回调
		treeSelect(val, data) {
			this.selectTreeData = data
			this.oplId = val.length !== 0 ? val[0] : ''
			this.getOplList()
		},
		onChange(val) {
			console.log(val)
		},
		// 开启弹窗
		showModal(e) {
			let name = e.target.name
			this.jywsDialogBottomName = '确认'
			this.jywsDialogBottomRoger = '取消'
			this.typePrimary = 'primary'
			switch (name) {
				case 'addOplType':
					this.modalTitle = '佣金入账'
					this.visibleType = 'addOplType'
					this.visible = true
					this.visibleWidth = '960px'
					break
				case 'editOplType':
					this.modalTitle = '结算佣金'
					this.visibleType = 'editOplType'
					this.visible = true
					this.visibleWidth = '1060px'
					break
				case 'editOplListType':
					this.modalTitle = '佣金记录'
					this.visibleType = 'editOplListType'
					this.visible = true
					this.visibleWidth = '1060px'
					break
				case 'addOplList':
					this.modalTitle = '新 增'
					this.visibleType = 'addOplList'
					this.visible = true
					this.visibleWidth = '960px'
					break
				case 'editOplList':
					this.modalTitle = '修 改'
					this.visibleType = 'editOplList'
					this.visible = true
					this.visibleWidth = '960px'
					break
				default:
					break
			}
		},
		openTheNewModal() {
			this.newmodalTitle = '选择客户'
			this.newvisible = true
			util.postform('customer/getCustomerList', {}).then(res => {
				this.cdkhdataSource2 = res.data
			})
		},
		// 关闭弹窗
		closeModal() {
			this.visible = false
			if (this.addoplForm.accessoryName) {
				this.$refs.uploadOss.clearFileList()
			}
			this.oplTypeName = ''
			this.addoplForm = {
				oplTypeId: undefined,
				oplTypeName: '',
				title: '',
				accessoryUrl: '',
				accessoryName: '',
				content: '',
				tenantId: this.tenantId,
			}
			this.tabsKey = '1'
			this.html = '<p></p>'
			this.selectedRowKeys = []
			this.selectedRows = []
			this.newselectedRowKeys = []
			this.newselectedRows = []
		},
		// 打开弹窗按钮
		addOplType(e) {
			let name = e.target.name
			// 修改opl类型
			if (name === 'editOplList') {
				if (this.selectedRows.length !== 1) {
					this.$warning({
						title: '请选择一条记录进行修改操作！',
					})
					return
				}
				this.addoplForm = this.selectedRows[0]
				// this.html = `<p>${this.selectedRows[0].content}</p>`
				this.html = `<p>${this.selectedRows[0].content}</p>`
			}
			if (name === 'editOplListType') {
				util.postform('/share/incomeRecord', {}).then(res => {
					this.rzjldataSource = res.data
				})
			}
			if (name === 'editOplType') {
				util.postform('/share/listToBeSettled', { state: 1 }).then(res => {
					this.djsyjdataSource = res.data
				})
			}
			this.showModal(e)
		},
		// 查看课程详情弹窗
		openinfoOplList(scope) {
			this.modalTitle = '详 情'
			this.jywsDialogBottomName = '关闭'
			this.jywsDialogBottomRoger = ''
			this.typePrimary = ''
			this.visibleType = 'infoOplList'
			this.visibleWidth = '1060px'
			this.visible = true
			this.infoOplList = scope
			util.post('/distributor/details', { id: scope.id }).then(res => {
				this.modalInfoList = res.data
				this.distributor = res.data.distributor
				this.dealCustomerList = res.data.dealCustomerList
				this.reportCustomerList = res.data.reportCustomerList
				this.shareList = res.data.shareList
				this.childDistributor = res.data.childDistributor
			})
		},
		updataWin() {
			if (this.visibleType === 'addOplType') {
				this.addOplTypeBtn()
			} else if (this.visibleType === 'editOplType') {
				this.editOplTypeBtn()
			} else if (this.visibleType === 'addOplList') {
				// this.addoplForm.content = this.editor.getText()
				this.addoplForm.content = this.html
				this.addOplListBtn()
			} else if (this.visibleType === 'editOplList') {
				this.editOplListBtn()
			} else if (this.visibleType === 'infoOplList') {
				this.closeModal()
			} else if (this.visibleType === 'editOplListType') {
				this.editOplListTypeBtn()
			}
		},
		newcloseModal() {
			this.newvisible = false
		},
		newupdataWin() {
			this.newvisible = false
			if (this.newselectedRows.length !== 1) {
				this.$message.error('请选择一条成单客户记录！')
				return
			}
			this.dealPeoName = this.newselectedRows[0].name
			this.dealPeoId = this.newselectedRows[0].id
			util.post('/share/queryCommissionDetails', { id: this.dealPeoId }).then(res => {
				this.yjrzdataSource = res.data
			})
		},
		newInputChange(scope, e) {
			console.log(scope)
			console.log(e)
			e.shareAmount = scope
		},
		// 新增类型弹窗 确认
		addOplTypeBtn() {
			util.postform('/share/commissionRecorded', this.yjrzdataSource).then(res => {
				console.log(res)
				if (res.code === 200) {
					this.$message.success('操作成功！')
					this.spinning = false
					this.closeModal()
					this.getOplList()
					// this.getOplTypeTree()
				}
			})
		},
		// 修改类型弹窗 确认
		editOplTypeBtn() {
			if (this.newselectedRows.length !== 0) {
				let ids = []
				this.newselectedRows.forEach(item => {
					ids.push(item.id)
				})
				this.spinning = true
				util.postform('share/commissionSettlement', ids).then(res => {
					if (res.code === 200) {
						this.$message.success('结算成功！')
						this.spinning = false
						this.closeModal()
					}
				})
			} else {
				this.closeModal()
			}
		},
		// 删除opl类型接口
		delOplType() {
			let that = this
			if (!this.selectTreeData.oplId || this.selectTreeData.oplId === '') {
				this.$info({
					title: '提示',
					content: '请选择需要删除的类型！',
					onOk() {},
				})
				return
			}
			this.$confirm({
				title: '是否确认要删除此类型?',
				onOk() {
					util.postform('oplType/delete/' + that.selectTreeData.oplId).then(res => {
						if (res.code === 200) {
							that.$message.success('删除成功！')
							that.oplId = ''
							that.closeModal()
							that.getOplTypeTree()
							that.getOplList()
						}
					})
				},
				onCancel() {},
			})
		},
		// 新增opl课程接口
		addOplListBtn() {
			if (this.addoplForm.title === '') {
				this.$message.error('标题不能为空！')
				return
			}
			if (this.addoplForm.accessoryUrl === '') {
				this.$message.error('请选择附件上传！')
				return
			}
			this.addoplForm.createUserName = this.userName
			util.postform('oplCourse/add', this.addoplForm).then(res => {
				if (res.code === 200) {
					this.$message.success('新增成功！')
					this.closeModal()
					this.getOplList()
				}
			})
		},
		// 修改opl课程接口
		editOplListBtn() {
			if (this.addoplForm.title === '') {
				this.$message.error('标题不能为空！')
				return
			}
			if (this.addoplForm.accessoryUrl === '') {
				this.$message.error('请选择附件上传！')
				return
			}
			this.addoplForm.createUserName = this.userName
			util.postform('oplCourse/update', this.addoplForm).then(res => {
				if (res.code === 200) {
					this.$message.success('修改成功！')
					this.closeModal()
					this.getOplList()
				}
			})
		},
		// 删除opl课程接口
		delOplListBtn() {
			let that = this
			if (this.selectedRows.length === 0) {
				this.$info({
					title: '提示',
					content: '请选择需要删除的课程记录！',
					onOk() {},
				})
				return
			}
			this.$confirm({
				title: '是否确认要删除此课程?',
				onOk() {
					util.postform('oplCourse/removeBetch', that.selectedRowKeys).then(res => {
						if (res.code === 200) {
							that.$message.success('删除成功！')
							that.closeModal()
							that.getOplList()
						}
					})
				},
				onCancel() {},
			})
		},
		// 下载附件
		downFile() {
			if (this.selectedRows.length !== 1) {
				this.$warning({
					title: '请选择一条记录进行附件下载！',
				})
				return
			}
			if (!this.selectedRows[0].accessoryUrl) {
				this.$warning({
					title: '该记录暂无附件！',
				})
				return
			}
			window.open(this.selectedRows[0].accessoryUrl)
		},
		// 关联类型
		editOplListTypeBtn() {
			this.closeModal()
		},
	},
}
</script>

<style scoped lang="less">
.oplList {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	// display: flex;
	// justify-content: space-between;
	padding: 20px;
	box-sizing: border-box;
	overflow: auto;
}
.oplList-title {
	width: 100%;
	height: 56px;
	background: #ffffff;
	border-radius: 4px 4px 0px 0px;
	color: #333333;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.modal-body {
	/deep/.ant-descriptions-item-label {
		width: 160px;
		text-align: right;
	}
	/deep/.ant-descriptions-item-content {
		width: 300px;
	}
}

.mar-t14 {
	margin-top: 14px;
}
.dialog-title {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: #333333;
}
.infoOplList-title {
	font-size: 24px;
	color: #333333;
	text-align: left;
}
.title-smalltext {
	font-size: 14px;
	color: #b2b2b2;
	margin-top: 14px;
}
.infoOplList-smtitle {
	display: flex;
	color: #999999;
	font-size: 12px;
	text-align: left;
	margin-top: 16px;
}
.infoOplList-content {
	margin-top: 24px;
}
.mg-l-5 {
	margin-left: 5px;
}
.mg-l-50 {
	margin-left: 50px;
}
.title-icon {
	width: 4px;
	height: 16px;
	background: #1890ff;
	display: inline-block;
	margin-right: 12px;
}
.col-value {
	width: 200px;
}
.oplList-content {
	background: #ffffff;
	width: 100%;
	height: calc(100% - 56px);
	display: flex;
	padding-top: 32px;
}
.flush {
	font-size: 14px;
	color: #1890ff;
}
.content-right-btnGroup {
	height: 56px;
	width: 100%;
	line-height: 56px;
	margin-top: 10px;
}
.content-right-form {
	// width: 100%;
	background: #ffffff;
	padding: 0px 21px 0px 17px;
	.form-search {
		display: flex;
		align-items: center;
		width: 420px;
	}
}
.form-label {
	font-size: 14px;
	color: #333333;
	width: 70px;
	text-align: right;
	margin-right: 16px;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.content-left {
	width: 220px;
	height: calc(100% - 30px);
	background: #ffffff;
	border: 1px solid #e8e8e8;
	margin-left: 12px;
	.content-left-title {
		height: 48px;
		width: 100%;
		padding: 0 16px 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #333333;
		font-size: 16px;
		border-bottom: 1px solid #e8e8e8;
	}
	.content-left-btnGroup {
		height: 48px;
		width: 100%;
		border-bottom: 1px solid #e8e8e8;
	}
}
.content-right {
	height: calc(100% - 30px);
	width: 100%;
	margin-left: 27px;
}
</style>
