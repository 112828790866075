<!--
 * @Author: your name
 * @Date: 2022-04-27 16:45:02
 * @LastEditTime: 2023-02-07 17:44:52
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/components/importDialogPlus.vue
-->
<template>
	<div class="imgDialog">
		<!-- 弹窗区域 -->
		<jywsDialog
			:visible="visible"
			:title="title"
			:width="width"
			:roger="roger"
			:bottom-name="bottomName"
			@updataClose="updataClose"
			:typePrimary="typePrimary"
			@updataWin="updataClose"
		>
			<a-spin :spinning="spinning">
				<div class="flex">
					<div class="flex-left">
						<div :class="tableKey == 1 ? 'flex-left-bottom-check' : 'flex-left-bottom'" @click="tableKeyClick(1)">资产台帐</div>
						<div :class="tableKey == 2 ? 'flex-left-bottom-check' : 'flex-left-bottom'" @click="tableKeyClick(2)">点检信息</div>
						<div :class="tableKey == 3 ? 'flex-left-bottom-check' : 'flex-left-bottom'" @click="tableKeyClick(3)">保养信息</div>
						<div :class="tableKey == 4 ? 'flex-left-bottom-check' : 'flex-left-bottom'" @click="tableKeyClick(4)">维修信息</div>
						<div :class="tableKey == 5 ? 'flex-left-bottom-check' : 'flex-left-bottom'" @click="tableKeyClick(5)">检修信息</div>
						<div :class="tableKey == 6 ? 'flex-left-bottom-check' : 'flex-left-bottom'" @click="tableKeyClick(6)">备件信息</div>
						<div :class="tableKey == 7 ? 'flex-left-bottom-check' : 'flex-left-bottom'" @click="tableKeyClick(7)">隐患信息</div>
						<div :class="tableKey == 8 ? 'flex-left-bottom-check' : 'flex-left-bottom'" @click="tableKeyClick(8)">资料信息</div>
					</div>
					<div class="flex-right">
						<!-- 资产台帐 -->
						<div v-if="tableKey == 1" class="flex-right-account border1">
							<div class="flex-right-account-left">
								<div class="flex-right-account-left-title">设备详情</div>
								<div class="flex-right-account-left-content">
									<div class="flex-right-account-left-content-table">
										<div class="flex-right-account-left-content-table-item borderb1">
											<div class="table-item-left borderr1">所属组织：</div>
											<div class="table-item-right borderr1">{{ accountItem.organizationName || '-' }}</div>
											<div class="table-item-left borderr1">资产编码：</div>
											<div class="table-item-right">{{ accountItem.assetsCode || '-' }}</div>
										</div>
										<div class="flex-right-account-left-content-table-item borderb1">
											<div class="table-item-left borderr1">设备简称：</div>
											<div class="table-item-right borderr1">{{ accountItem.simpleName || '-' }}</div>
											<div class="table-item-left borderr1">设备全称：</div>
											<div class="table-item-right">{{ accountItem.name || '-' }}</div>
										</div>
										<div class="flex-right-account-left-content-table-item borderb1">
											<div class="table-item-left borderr1">型号规格：</div>
											<div class="table-item-right borderr1">{{ accountItem.standard || '-' }}</div>
											<div class="table-item-left borderr1">投运日期：</div>
											<div class="table-item-right">{{ accountItem.createTime ? $moment(accountItem.createTime, 'YYYY-MM-DD').format('YYYY-MM-DD') : '-' }}</div>
										</div>
										<div class="flex-right-account-left-content-table-item borderb1">
											<div class="table-item-left borderr1">投运状态：</div>
											<div class="table-item-right borderr1">
												<span>
													<a-badge
														v-if="accountItem.status === 1"
														color="green"
														text="正投运"
													/>
													<a-badge
														v-else-if="accountItem.status === 2"
														color="orange"
														text="已闲置"
													/>
													<a-badge
														v-else-if="accountItem.status === 3"
														color="orange"
														text="即将报废"
													/>
													<a-badge
														v-else-if="accountItem.status === 4"
														color="red"
														text="已报废"
													/>
													<a-badge
														v-else-if="accountItem.status === 5"
														color="orange"
														text="已封存"
													/>
													<a-badge
														v-else-if="accountItem.status === 6"
														color="red"
														text="已转让"
													/>
												</span>
											</div>
											<div class="table-item-left borderr1">启用状态：</div>
											<div class="table-item-right">
												<span>
													<a-badge
														v-if="accountItem.flag === 'true'"
														color="green"
														text="是"
													/>
													<a-badge
														v-else
														color="red"
														text="否"
													/>
												</span>
											</div>
										</div>
										<div class="flex-right-account-left-content-table-item">
											<div class="table-item-left borderr1">设备照片：</div>
											<div class="table-item-right borderr1">
												<span v-if="accountItem.img" style="color: #1890FF;cursor: pointer;" @click="queryImg">查看</span>
												<span v-else>-</span>
											</div>
											<div class="table-item-left borderr1">设备ID：</div>
											<div class="table-item-right"><span style="color: #1890FF;cursor: pointer;" @click="copyToClip">复制</span></div>
										</div>
									</div>
									<div class="flex-right-account-left-content-table">
										<div class="flex-right-account-left-content-table-item">
											<div class="table-item-left borderr1">特种设备：</div>
											<div class="table-item-right borderr1">
												<span>
													<a-badge
														v-if="accountItem.special === '1'"
														color="green"
														text="是"
													/>
													<a-badge
														v-else
														color="#B2B2B2"
														text="否"
													/>
												</span>
											</div>
											<div class="table-item-left borderr1">设备类型：</div>
											<div class="table-item-right">{{ accountItem.equpmentStyle || '-' }}</div>
										</div>
									</div>
									<div class="flex-right-account-left-content-table">
										<div class="flex-right-account-left-content-table-item borderb1">
											<div class="table-item-left borderr1">品牌：</div>
											<div class="table-item-right borderr1">{{ accountItem.brand || '-' }}</div>
											<div class="table-item-left borderr1">厂商信息：</div>
											<div class="table-item-right">{{ accountItem.enterprise || '-' }}</div>
										</div>
										<div class="flex-right-account-left-content-table-item borderb1">
											<div class="table-item-left borderr1">出厂日期：</div>
											<div class="table-item-right borderr1">{{ accountItem.produceTime ? $moment(accountItem.produceTime).format('YYYY-MM-DD') : '-' }}</div>
											<div class="table-item-left borderr1">出厂编码：</div>
											<div class="table-item-right">{{ accountItem.produceCode || '-' }}</div>
										</div>
										<div class="flex-right-account-left-content-table-item borderb1">
											<div class="table-item-left borderr1">原值：</div>
											<div class="table-item-right borderr1">{{ accountItem.orginalValue || '-' }}</div>
											<div class="table-item-left borderr1">折旧率：</div>
											<div class="table-item-right">{{ accountItem.depreciationRate ? Number(accountItem.depreciationRate) * 100 + '%' : '-' }}</div>
										</div>
										<div class="flex-right-account-left-content-table-item borderb1">
											<div class="table-item-left borderr1">折旧年限：</div>
											<div class="table-item-right borderr1">{{ accountItem.depreciableLife || '-' }}</div>
											<div class="table-item-left borderr1">SN号：</div>
											<div class="table-item-right">{{ accountItem.sn || '-' }}</div>
										</div>
										<div class="flex-right-account-left-content-table-item">
											<div class="table-item-left borderr1">安装位置：</div>
											<div class="table-item-right borderr1">{{ accountItem.position || '-' }}</div>
											<div class="table-item-left borderr1">备注：</div>
											<div class="table-item-right">{{ accountItem.remark || '-' }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="flex-right-account-right">
								<div class="flex-right-account-left-title">设备二维码</div>
								<img v-if="accountItem.scanning" style="width: 100%;" :src="accountItem.scanning">
							</div>
						</div>
            <!-- 点检、保养信息 -->
            <div v-if="tableKey == 2" class="flex-right-account">
              <a-tabs style="width: 100%;" type="card" :activeKey="searchForm.mode" @change="pointCallback($event, ['day', 'specialty', 'precision', 'planned'])">
                <a-tab-pane :key="1" tab="点检记录">
                  <!-- 查询区域 -->
                  <div class="content-right-form">
                    <conditionQuery
                      :headData="pointHeadData1"
                      @onHandleChange="onHandleChange"
                      @onTimeChange="onTimeChange"
                      @onSelectClick="queryForTaskIdList"
                    />
                  </div>
                  <!-- 表格区域 -->
                  <div class="content-right-table">
                    <a-table
                      :columns="pointColumns1"
                      :loading="searchForm.loading"
                      :data-source="searchForm.dataSource"
                      :transformCellText="({ text, column, record, index }) => text || '-'"
                      :pagination="false"
                      :scroll="{ x: 1300, y: 290 }"
                    >
                      <span slot="type" slot-scope="text">
                        {{ text == 'day' ? '日点检' : text == 'specialty' ? '周点检' : text == 'precision' ? '定期点检' : text == 'planned' ? '计划点检' : text == 'B1' ? '一级保养' : text == 'B2' ? '二级保养' : text == 'B3' ? '日常保养' : '-' }}
                      </span>
                      <span slot="time" slot-scope="text">
                        {{ text ? $moment(text).format('YYYY-MM-DD') : '-' }}
                      </span>
                      <span slot="taskOverTime" slot-scope="text">
                        {{ text ? $moment(text, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}
                      </span>
                      <span slot="state" slot-scope="text">
                        <a-badge
                          v-if="text === 0"
                          color="green"
                          text="执行完成"
                        />
                        <a-badge
                          v-else-if="text === 1"
                          color="orange"
                          text="待执行"
                        />
                        <a-badge
                          v-else-if="text === 2"
                          color="blue"
                          text="执行中"
                        />
                      </span>
                      <span slot="feedbackMesFlag" slot-scope="text">
                        <a-badge
                          v-if="text"
                          color="green"
                          text="是"
                        />
                        <a-badge
                          v-else
                          color="red"
                          text="否"
                        />
                      </span>
                      <span slot="action" slot-scope="scope">
                        <a
                          @click="openMissionDetails(scope)"
                          >查看</a>
                      </span>
                    </a-table>
                    <a-pagination
                      @change="pageonChange"
                      @showSizeChange="showSizeChange"
                      size="small"
                      :total="searchForm.total"
                      :defaultCurrent="searchForm.pageIndex"
                      :defaultPageSize="searchForm.pageSize"
                      show-size-changer
                      show-quick-jumper
                      :show-total="(total) => `总共 ${total} 条`"
                    />
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="0" tab="点检计划">
                  <!-- 查询区域 -->
                  <div class="content-right-form">
                    <conditionQuery
                      :headData="pointHeadData2"
                      @onHandleChange="onHandleChange"
                      @onSelectPeo="onSelectPeo"
                      @onSelectClick="queryForTaskIdList"
                    />
                  </div>
                  <!-- 表格区域 -->
                  <div class="content-right-table">
                    <a-table
                      :columns="pointColumns2"
                      :loading="searchForm.loading"
                      :data-source="searchForm.dataSource"
                      :transformCellText="({ text, column, record, index }) => text || '-'"
                      :pagination="false"
                      :scroll="{ x: 1300, y: 290 }"
                    >
                      <span slot="type" slot-scope="text">
                        {{ text == 'day' ? '日点检' : text == 'specialty' ? '周点检' : text == 'precision' ? '定期点检' : text == 'planned' ? '计划点检' : text == 'B1' ? '一级保养' : text == 'B2' ? '二级保养' : text == 'B3' ? '日常保养' : '-' }}
                      </span>
                      <span slot="remindDay" slot-scope="text">
                        {{ text }}天
                      </span>
                      <span slot="cycle" slot-scope="text, record">
                        {{ text }} {{ record.unit | unit(record.unit) }}
                      </span>
                      <span slot="createTime" slot-scope="text">
                        {{ text ? $moment(text, 'YYYY-MM-DD').format('YYYY-MM-DD') : '-' }}
                      </span>
                      <span slot="action" slot-scope="scope">
                        <a
                          @click="openInspectionPlan(scope)"
                          name="infospecialEqus"
                          >查看</a
                        >
                      </span>
                    </a-table>
                    <a-pagination
                      @change="pageonChange"
                      @showSizeChange="showSizeChange"
                      size="small"
                      :total="searchForm.total"
                      :defaultCurrent="searchForm.pageIndex"
                      :defaultPageSize="searchForm.pageSize"
                      show-size-changer
                      show-quick-jumper
                      :show-total="(total) => `总共 ${total} 条`"
                    />
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
						<div v-if="tableKey == 3" class="flex-right-account">
              <a-tabs style="width: 100%;" type="card" :activeKey="searchForm.mode" @change="pointCallback($event, ['B1', 'B2', 'B3'])">
                <a-tab-pane :key="1" tab="保养记录">
                  <!-- 查询区域 -->
                  <div class="content-right-form">
                    <conditionQuery
                      :headData="pointHeadData1"
                      @onHandleChange="onHandleChange"
                      @onTimeChange="onTimeChange"
                      @onSelectClick="queryForTaskIdList"
                    />
                  </div>
                  <!-- 表格区域 -->
                  <div class="content-right-table">
                    <a-table
                      :columns="pointColumns1"
                      :loading="searchForm.loading"
                      :data-source="searchForm.dataSource"
                      :transformCellText="({ text, column, record, index }) => text || '-'"
                      :pagination="false"
                      :scroll="{ x: 1300, y: 290 }"
                    >
                      <span slot="type" slot-scope="text">
                        {{ text == 'day' ? '日点检' : text == 'specialty' ? '周点检' : text == 'precision' ? '定期点检' : text == 'planned' ? '计划点检' : text == 'B1' ? '一级保养' : text == 'B2' ? '二级保养' : text == 'B3' ? '日常保养' : '-' }}
                      </span>
                      <span slot="time" slot-scope="text">
                        {{ text ? $moment(text).format('YYYY-MM-DD') : '-' }}
                      </span>
                      <span slot="taskOverTime" slot-scope="text">
                        {{ text ? $moment(text, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}
                      </span>
                      <span slot="state" slot-scope="text">
                        <a-badge
                          v-if="text === 0"
                          color="green"
                          text="执行完成"
                        />
                        <a-badge
                          v-else-if="text === 1"
                          color="orange"
                          text="待执行"
                        />
                        <a-badge
                          v-else-if="text === 2"
                          color="blue"
                          text="执行中"
                        />
                      </span>
                      <span slot="feedbackMesFlag" slot-scope="text">
                        <a-badge
                          v-if="text"
                          color="green"
                          text="是"
                        />
                        <a-badge
                          v-else
                          color="red"
                          text="否"
                        />
                      </span>
                      <span slot="action" slot-scope="scope">
                        <a
                          @click="openMissionDetails(scope)"
                          >查看</a>
                      </span>
                    </a-table>
                    <a-pagination
                      @change="pageonChange"
                      @showSizeChange="showSizeChange"
                      size="small"
                      :total="searchForm.total"
                      :defaultCurrent="searchForm.pageIndex"
                      :defaultPageSize="searchForm.pageSize"
                      show-size-changer
                      show-quick-jumper
                      :show-total="(total) => `总共 ${total} 条`"
                    />
                  </div>
                </a-tab-pane>
                <a-tab-pane :key="0" tab="保养计划">
                  <!-- 查询区域 -->
                  <div class="content-right-form">
                    <conditionQuery
                      :headData="pointHeadData2"
                      @onHandleChange="onHandleChange"
                      @onSelectPeo="onSelectPeo"
                      @onSelectClick="queryForTaskIdList"
                    />
                  </div>
                  <!-- 表格区域 -->
                  <div class="content-right-table">
                    <a-table
                      :columns="pointColumns2"
                      :loading="searchForm.loading"
                      :data-source="searchForm.dataSource"
                      :transformCellText="({ text, column, record, index }) => text || '-'"
                      :pagination="false"
                      :scroll="{ x: 1300, y: 290 }"
                    >
                      <span slot="type" slot-scope="text">
                        {{ text == 'day' ? '日点检' : text == 'specialty' ? '周点检' : text == 'precision' ? '定期点检' : text == 'planned' ? '计划点检' : text == 'B1' ? '一级保养' : text == 'B2' ? '二级保养' : text == 'B3' ? '日常保养' : '-' }}
                      </span>
                      <span slot="remindDay" slot-scope="text">
                        {{ text }}天
                      </span>
                      <span slot="cycle" slot-scope="text, record">
                        {{ text }} {{ record.unit | unit(record.unit) }}
                      </span>
                      <span slot="createTime" slot-scope="text">
                        {{ text ? $moment(text, 'YYYY-MM-DD').format('YYYY-MM-DD') : '-' }}
                      </span>
                      <span slot="action" slot-scope="scope">
                        <a
                          @click="openInspectionPlan(scope)"
                          name="infospecialEqus"
                          >查看</a
                        >
                      </span>
                    </a-table>
                    <a-pagination
                      @change="pageonChange"
                      @showSizeChange="showSizeChange"
                      size="small"
                      :total="searchForm.total"
                      :defaultCurrent="searchForm.pageIndex"
                      :defaultPageSize="searchForm.pageSize"
                      show-size-changer
                      show-quick-jumper
                      :show-total="(total) => `总共 ${total} 条`"
                    />
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
						<!-- 维修信息 -->
            <div v-if="tableKey == 4" class="flex-right-account">
							<div style="width: 100%;">
								<!-- 查询区域 -->
								<div class="content-right-form">
									<conditionQuery
										:headData="pointHeadData3"
										@onHandleChange="onHandleChange"
										@onTimeChange="onTimeChange"
										@onSelectClick="queryForRepairIdList"
									/>
								</div>
								<!-- 表格区域 -->
								<div class="content-right-table">
									<a-table
										:columns="pointColumns3"
										:loading="searchForm.loading"
										:data-source="searchForm.dataSource"
										:transformCellText="({ text, column, record, index }) => text || '-'"
										:pagination="false"
										:scroll="{ x: 1300, y: 290 }"
									>
										<span slot="repairRank" slot-scope="text">
											{{ text == 1 ? '停机故障维修' : text == 2 ? '不停机维修' : text == 3 ? '设备不良反馈' : '-' }}
										</span>
										<span slot="createTime" slot-scope="text">
											{{ text ? $moment(text, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}
										</span>
										<span slot="state" slot-scope="text">
											<a-badge
												v-if="text === 'first'"
												color="orange"
												text="待执行"
											/>
											<a-badge
												v-else-if="text === 'dealing'"
												color="blue"
												text="执行中"
											/>
											<a-badge
												v-else-if="text === 'audit'"
												color="blue"
												text="审核中"
											/>
											<a-badge
												v-else-if="text === 'again'"
												color="red"
												text="审核不通过"
											/>
											<a-badge
												v-else-if="text === 'evaluation'"
												color="blue"
												text="评价中"
											/>
											<a-badge
												v-else-if="text === 'evaluationnot'"
												color="red"
												text="评价不通过"
											/>
											<a-badge
												v-else-if="text === 'end'"
												color="green"
												text="执行完成"
											/>
											<a-badge
												v-else-if="text === 'refuse'"
												color="red"
												text="已拒绝"
											/>
											<a-badge
												v-else-if="text === 'pause'"
												color="red"
												text="维修暂停"
											/>
											<a-badge
												v-else-if="text === 'pausepart'"
												color="red"
												text="维修待备件"
											/>
										</span>
										<span slot="feedbackMesFlag" slot-scope="text">
											<a-badge
												v-if="text"
												color="red"
												text="有"
											/>
											<a-badge
												v-else
												color="#D8D8D8"
												text="无"
											/>
										</span>
										<span slot="action" slot-scope="scope">
											<a
												@click="openMaintenanceDetails(scope)"
											>查看</a>
										</span>
									</a-table>
									<a-pagination
										@change="pageonChange"
										@showSizeChange="showSizeChange"
										size="small"
										:total="searchForm.total"
										:defaultCurrent="searchForm.pageIndex"
										:defaultPageSize="searchForm.pageSize"
										show-size-changer
										show-quick-jumper
										:show-total="(total) => `总共 ${total} 条`"
									/>
								</div>
							</div>
            </div>
						<!-- 检修信息 -->
            <div v-if="tableKey == 5" class="flex-right-account">
              <a-tabs :activeKey="searchForm.mode" style="width: 100%;" type="card" @change="pointCallback2">
                <a-tab-pane :key="1" tab="检修记录">
                  <div class="content-right-form">
										<conditionQuery
											:headData="pointHeadData4"
											@onHandleChange="onHandleChange"
											@onTimeChange="onTimeChange"
											@onSelectClick="queryForRepairIdList"
										/>
									</div>
									<!-- 表格区域 -->
									<div class="content-right-table">
										<a-table
											:columns="pointColumns4"
											:loading="searchForm.loading"
											:data-source="searchForm.dataSource"
											:transformCellText="({ text, column, record, index }) => text || '-'"
											:pagination="false"
											:scroll="{ x: 1300, y: 290 }"
										>
											<span slot="createTime" slot-scope="text">
												{{ text ? $moment(text, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}
											</span>
											<span slot="repairDeadline" slot-scope="text">
												{{ text ? $moment(text, 'YYYY-MM-DD').format('YYYY-MM-DD') : '-' }}
											</span>
											<span slot="state" slot-scope="text">
												<a-badge
													v-if="text === 'first'"
													color="orange"
													text="待执行"
												/>
												<a-badge
													v-else-if="text === 'dealing'"
													color="blue"
													text="执行中"
												/>
												<a-badge
													v-else-if="text === 'audit'"
													color="blue"
													text="审核中"
												/>
												<a-badge
													v-else-if="text === 'again'"
													color="red"
													text="审核不通过"
												/>
												<a-badge
													v-else-if="text === 'evaluation'"
													color="blue"
													text="评价中"
												/>
												<a-badge
													v-else-if="text === 'evaluationnot'"
													color="red"
													text="评价不通过"
												/>
												<a-badge
													v-else-if="text === 'end'"
													color="green"
													text="执行完成"
												/>
												<a-badge
													v-else-if="text === 'refuse'"
													color="red"
													text="已拒绝"
												/>
												<a-badge
													v-else-if="text === 'pause'"
													color="red"
													text="维修暂停"
												/>
												<a-badge
													v-else-if="text === 'pausepart'"
													color="red"
													text="维修待备件"
												/>
											</span>
											<span slot="feedbackMesFlag" slot-scope="text">
												<a-badge
													v-if="text"
													color="red"
													text="有"
												/>
												<a-badge
													v-else
													color="#D8D8D8"
													text="无"
												/>
											</span>
											<span slot="action" slot-scope="scope">
												<a
													@click="openMaintenanceDetails(scope)"
												>查看</a>
											</span>
										</a-table>
										<a-pagination
											@change="pageonChange"
											@showSizeChange="showSizeChange"
											size="small"
											:total="searchForm.total"
											:defaultCurrent="searchForm.pageIndex"
											:defaultPageSize="searchForm.pageSize"
											show-size-changer
											show-quick-jumper
											:show-total="(total) => `总共 ${total} 条`"
										/>
									</div>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="检修计划">
                  <!-- 查询区域 -->
                  <div class="content-right-form">
                    <conditionQuery
                      :headData="pointHeadData5"
                      @onSelectPeo="onSelectPeo"
                      @onSelectClick="queryForRepairIdList"
                    />
                  </div>
                  <!-- 表格区域 -->
                  <div class="content-right-table">
                    <a-table
                      :columns="pointColumns5"
                      :loading="searchForm.loading"
                      :data-source="searchForm.dataSource"
                      :transformCellText="({ text, column, record, index }) => text || '-'"
                      :pagination="false"
                      :scroll="{ x: 1300, y: 290 }"
                    >
											<span slot="repairText" slot-scope="text, scope">
												<img v-if="scope.repairImg" style="width: 16px;height: 16px;" src="@/assets/icon_image.png" @click="queryImg2(scope.repairImg)">
                        {{ text || '-' }}
                      </span>
											<span slot="cycleDay" slot-scope="text">
                        {{ text ? $moment(text, 'YYYY-MM-DD').format('YYYY-MM-DD') : '-' }}
                      </span>
                      <span slot="remindDay" slot-scope="text">
                        {{ text }}天
                      </span>
                      <span slot="cycle" slot-scope="text">
                        {{ text }}天
                      </span>
                    </a-table>
                    <a-pagination
                      @change="pageonChange"
                      @showSizeChange="showSizeChange"
                      size="small"
                      :total="searchForm.total"
                      :defaultCurrent="searchForm.pageIndex"
                      :defaultPageSize="searchForm.pageSize"
                      show-size-changer
                      show-quick-jumper
                      :show-total="(total) => `总共 ${total} 条`"
                    />
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
						<!-- 备件信息 -->
            <div v-if="tableKey == 6" class="flex-right-account">
              <a-tabs :activeKey="searchForm.mode" style="width: 100%;" type="card" @change="pointCallback3">
                <a-tab-pane :key="0" tab="消耗备件">
                  <div class="content-right-form">
										<conditionQuery
											:headData="pointHeadData6"
											@onHandleChange="onHandleChange"
											@onInputChange="onInputChange"
											@onTimeChange="onTimeChange"
											@onSelectClick="queryForPartIdList"
										/>
									</div>
									<!-- 表格区域 -->
									<div class="content-right-table">
										<a-table
											:columns="pointColumns6"
											:loading="searchForm.loading"
											:data-source="searchForm.dataSource"
											:transformCellText="({ text, column, record, index }) => text || '-'"
											:pagination="false"
											:scroll="{ x: 1300, y: 290 }"
										>
											<span slot="createTime" slot-scope="text">
												{{ text ? $moment(text, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}
											</span>
											<span slot="repairType" slot-scope="text">
												{{ text ? text == 'repairWeb' ? '检修任务' : '维修任务' : '-' }}
											</span>
											<span slot="action" slot-scope="scope">
												<a
													@click="openMaintenanceDetails(scope)"
												>查看</a>
											</span>
										</a-table>
										<a-pagination
											@change="pageonChange"
											@showSizeChange="showSizeChange"
											size="small"
											:total="searchForm.total"
											:defaultCurrent="searchForm.pageIndex"
											:defaultPageSize="searchForm.pageSize"
											show-size-changer
											show-quick-jumper
											:show-total="(total) => `总共 ${total} 条`"
										/>
									</div>
                </a-tab-pane>
                <a-tab-pane :key="1" tab="关联备件">
                  <!-- 查询区域 -->
                  <div class="content-right-form">
                    <conditionQuery
                      :headData="pointHeadData7"
                      @onInputChange="onInputChange"
                      @onSelectClick="queryForPartIdList"
                    />
                  </div>
                  <!-- 表格区域 -->
                  <div class="content-right-table">
                    <a-table
                      :columns="pointColumns7"
                      :loading="searchForm.loading"
                      :data-source="searchForm.dataSource"
                      :transformCellText="({ text, column, record, index }) => text || '-'"
                      :pagination="false"
                      :scroll="{ x: 1300, y: 290 }"
                    >
											<span slot="action" slot-scope="scope">
												<a
													@click="openPartsDetails(scope)"
												>查看</a>
											</span>
                    </a-table>
                    <a-pagination
                      @change="pageonChange"
                      @showSizeChange="showSizeChange"
                      size="small"
                      :total="searchForm.total"
                      :defaultCurrent="searchForm.pageIndex"
                      :defaultPageSize="searchForm.pageSize"
                      show-size-changer
                      show-quick-jumper
                      :show-total="(total) => `总共 ${total} 条`"
                    />
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
						<!-- 隐患信息 -->
            <div v-if="tableKey == 7" class="flex-right-account">
							<div style="width: 100%;">
								<!-- 查询区域 -->
								<div class="content-right-form">
									<conditionQuery
										:headData="pointHeadData8"
										@onHandleChange="onHandleChange"
										@onSelectPeo="onSelectPeo"
										@onTimeChange="onTimeChange"
										@onSelectClick="queryForHiddenRroubleIdList"
									/>
								</div>
								<!-- 表格区域 -->
								<div class="content-right-table">
									<a-table
										:columns="pointColumns8"
										:loading="searchForm.loading"
										:data-source="searchForm.dataSource"
										:transformCellText="({ text, column, record, index }) => text || '-'"
										:pagination="false"
										:scroll="{ x: 1300, y: 290 }"
									>
										<span slot="type" slot-scope="text">
											{{ text == 'day' ? '日点检' : text == 'specialty' ? '周点检' : text == 'precision' ? '定期点检' : text == 'planned' ? '计划点检' : text == 'B1' ? '一级保养' : text == 'B2' ? '二级保养' : text == 'B3' ? '日常保养' : '-' }}
										</span>
										<span slot="feedbackMes" slot-scope="text">
											{{ text == 'feedBack' ? '隐患反馈' : text == 'repair' ? '报修' : text == 'selfDeal' ? '自行处置' : '-' }}
										</span>
										<span slot="createTime" slot-scope="text">
											{{ text ? $moment(text, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}
										</span>
										<span slot="feedbackMesFlag" slot-scope="text">
											<a-badge
												v-if="text"
												color="green"
												text="是"
											/>
											<a-badge
												v-else
												color="#D8D8D8"
												text="否"
											/>
										</span>
										<span slot="action" slot-scope="scope">
											<a
												@click="openDangerDetails(scope)"
											>查看</a>
										</span>
									</a-table>
									<a-pagination
										@change="pageonChange"
										@showSizeChange="showSizeChange"
										size="small"
										:total="searchForm.total"
										:defaultCurrent="searchForm.pageIndex"
										:defaultPageSize="searchForm.pageSize"
										show-size-changer
										show-quick-jumper
										:show-total="(total) => `总共 ${total} 条`"
									/>
								</div>
							</div>
            </div>
						<!-- 资料信息 -->
            <div v-if="tableKey == 8" class="flex-right-account">
              <a-tabs :activeKey="searchForm.mode" style="width: 100%;" type="card" @change="pointCallback4">
                <a-tab-pane :key="1" tab="设备资料">
                  <div class="content-right-form">
										<conditionQuery
											:headData="pointHeadData9"
											@onHandleChange="onHandleChange"
                      @onInputChange="onInputChange"
											@onTimeChange="onTimeChange"
											@onSelectClick="getPageList"
										/>
									</div>
									<!-- 表格区域 -->
									<div class="content-right-table">
										<a-table
											:columns="pointColumns9"
											:loading="searchForm.loading"
											:data-source="searchForm.dataSource"
											:transformCellText="({ text, column, record, index }) => text || '-'"
											:pagination="false"
											:scroll="{ y: 290 }"
										>
											<span slot="createTime" slot-scope="text">
												{{ text ? $moment(text, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}
											</span>
											<span slot="pdfName" slot-scope="text, scope">
												<template v-if="scope.format">
													<span v-if="scope.format == 'jpg' || scope.format == 'png' || scope.format == 'jpeg'"><img style="width: 16px;height: 16px;" src="@/assets/icon_image.png" @click="queryImg2(text)"></span>
													<span v-else style="color: #1980FF;" @click="download(text)">{{ text }}</span>
												</template>
												<span v-else>无</span>
											</span>
											<span slot="action" slot-scope="scope">
												<a
													@click="openMaterialDetails(scope)"
												>查看</a>
											</span>
										</a-table>
										<a-pagination
											@change="pageonChange"
											@showSizeChange="showSizeChange"
											size="small"
											:total="searchForm.total"
											:defaultCurrent="searchForm.pageIndex"
											:defaultPageSize="searchForm.pageSize"
											show-size-changer
											show-quick-jumper
											:show-total="(total) => `总共 ${total} 条`"
										/>
									</div>
                </a-tab-pane>
                <a-tab-pane :key="0" tab="作业基准书">
                  <!-- 查询区域 -->
                  <div class="content-right-form">
                    <conditionQuery
                      :headData="pointHeadData10"
                      @onSelectPeo="onSelectPeo"
                      @onInputChange="onInputChange"
											@onHandleChange="onHandleChange"
                      @onSelectClick="getTaskBenchmarkPageList"
                    />
                  </div>
                  <!-- 表格区域 -->
                  <div class="content-right-table">
                    <a-table
                      :columns="pointColumns10"
                      :loading="searchForm.loading"
                      :data-source="searchForm.dataSource"
                      :transformCellText="({ text, column, record, index }) => text || '-'"
                      :pagination="false"
                      :scroll="{ y: 290 }"
                    >
											<span slot="createTime" slot-scope="text">
												{{ text ? $moment(text, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-' }}
											</span>
                      <span slot="benchmarkType" slot-scope="text">
                        {{ text == 'day' ? '日点检' : text == 'specialty' ? '周点检' : text == 'precision' ? '定期点检' : text == 'planned' ? '计划点检' : text == 'B1' ? '一级保养' : text == 'B2' ? '二级保养' : text == 'B3' ? '日常保养' : '-' }}
                      </span>
											<span slot="action" slot-scope="scope">
												<a
													@click="openBookReferenceDetails(scope)"
												>查看</a>
											</span>
                    </a-table>
                    <a-pagination
                      @change="pageonChange"
                      @showSizeChange="showSizeChange"
                      size="small"
                      :total="searchForm.total"
                      :defaultCurrent="searchForm.pageIndex"
                      :defaultPageSize="searchForm.pageSize"
                      show-size-changer
                      show-quick-jumper
                      :show-total="(total) => `总共 ${total} 条`"
                    />
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
					</div>
				</div>
			</a-spin>
		</jywsDialog>
		<imgDialog title="查看图片" bottomName="关闭" :visible="imgVisble" :imgSrc="imgSrc" :itemSrcIndex="itemSrcIndex" :itemSrc="itemSrc" @selecedImgClick="selecedImgClick" @updataWin="close"></imgDialog>
    <!-- 点检、保养任务详情 -->
    <missionDetails v-if="missionVisble" :id="missionId" :tenantId="tenantId" :visible="missionVisble" @closeModal="closenMissionDetails"></missionDetails>
		<!-- 查看执行项 -->
    <viewingExecutionItems v-if="viewingVisble" :infoList="viewingInfo" :visible="viewingVisble" @closeModal="closenInspectionPlan"></viewingExecutionItems>
		<!-- 维修工单详情 -->
    <maintenanceDetails v-if="maintenanceVisble" :id="maintenanceId" :tenantId="tenantId" :visible="maintenanceVisble" @closeModal="closenMaintenanceDetails"></maintenanceDetails>
    <partsDetails v-if="partsVisble" :id="partsId" :tenantId="tenantId" :visible="partsVisble" @closeModal="closenPartsDetails"></partsDetails>
    <dangerDetails v-if="dangerVisble" :id="dangerId" :tenantId="tenantId" :visible="dangerVisble" @closeModal="closenDangerDetails"></dangerDetails>
    <materialDetails v-if="materialVisble" :item="materialItem" :visible="materialVisble" @closeModal="closenMaterialDetails"></materialDetails>
    <bookReferenceDetails v-if="bookReferenceVisble" :tenantId="tenantId" :item="bookReferenceItem" :visible="bookReferenceVisble" @closeModal="closenBookReferenceDetails"></bookReferenceDetails>
	</div>
</template>
<script>
import conditionQuery from '@/components/conditionQuery.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import imgDialog from '@/components/imgDialog.vue'
import missionDetails from '@/components/detailComponent/missionDetails.vue'
import viewingExecutionItems from '@/components/detailComponent/viewingExecutionItems.vue'
import maintenanceDetails from '@/components/detailComponent/maintenanceDetails.vue'
import partsDetails from '@/components/detailComponent/partsDetails.vue'
import dangerDetails from '@/components/detailComponent/dangerDetails.vue'
import materialDetails from '@/components/detailComponent/materialDetails.vue'
import bookReferenceDetails from '@/components/detailComponent/bookReferenceDetails.vue'
import moment from 'moment'
import * as util from '@/core/util'
export default {
	name: 'equipmentOverview',
	props: {
    id: {
      type: String,
      default: ''
    },
    tenantId: {
      type: String,
      default: ''
    },
    visible: {
			type: Boolean,
			default: false,
		},
    title: {
      type: String,
      default: '设备全生命周期履历'
    },
    width: {
      type: String,
      default: '90%'
    },
    roger: {
      type: String,
      default: ''
    },
    bottomName: {
      type: String,
      default: '关闭'
    },
    typePrimary: {
      type: String,
      default: 'primary'
    }
	},
	components: {
    conditionQuery,
		jywsDialog,
    imgDialog,
    missionDetails,
		viewingExecutionItems,
		maintenanceDetails,
		partsDetails,
		dangerDetails,
		materialDetails,
		bookReferenceDetails
	},
  filters: {
    unit(text) {
			if (text == 'day') {
        return '天'
      } else if (text == 'week') {
        return '周'
      } else if (text == 'month') {
        return '月'
      } else {
        return '-'
      }
		},
  },
	data() {
		return {
      spinning: false,
      tableKey: 1,
      // 资产台帐数据
			accountItem: {}, //资产台帐数据
			imgVisble: false,
			imgSrc: [],
			itemSrcIndex: 0,
			itemSrc: '',
      // 点检、保养信息数据
      pointHeadData1: [
        {
					key: 1,
					name: '时间范围',
					component: 'a-range-picker',
					value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
					duplicate: 'time',
					placeholder: '',
				},
				{
					key: 2,
					name: '类型',
					component: 'a-select',
					value: undefined,
					duplicate: 'type',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: ['day', 'specialty', 'precision', 'planned'],
						},
						{
							name: '日点检',
							value: ['day'],
						},
						{
							name: '周点检',
							value: ['specialty'],
						},
            {
							name: '定期点检',
							value: ['precision'],
						},
            {
							name: '计划点检',
							value: ['planned'],
						}
					],
				},
        {
					key: 3,
					name: '状态',
					component: 'a-select',
					value: undefined,
					duplicate: 'state',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: '',
						},
						{
							name: '待执行',
							value: 1,
						},
						{
							name: '执行中',
							value: 2,
						},
						{
							name: '执行完成',
							value: 3,
						}
					],
				},
			],
      pointColumns1: [
				{
					title: '类型',
					dataIndex: 'type',
					key: 'type',
					width: 150,
					fixed: 'left',
          scopedSlots: { customRender: 'type' },
				},
				{
					title: '执行人/组',
					dataIndex: 'userName',
					key: 'userName',
					width: 150,
					fixed: 'left'
				},
				{
					title: '计划完成日期',
					dataIndex: 'planOverTime',
					key: 'planOverTime',
					width: 150,
          scopedSlots: { customRender: 'time' },
				},
				{
					title: '实际点检时间',
					dataIndex: 'taskOverTime',
					key: 'taskOverTime',
					width: 180,
          scopedSlots: { customRender: 'taskOverTime' },
				},
				{
					title: '状态',
					dataIndex: 'state',
					key: 'state',
          width: 150,
					scopedSlots: { customRender: 'state' },
				},
				{
					title: '发现异常',
					dataIndex: 'feedbackMesFlag',
					key: 'feedbackMesFlag',
					width: 150,
					scopedSlots: { customRender: 'feedbackMesFlag' },
				},
				{
					title: '操作',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
					fixed: 'right',
					width: 120,
				},
			],
      pointHeadData2: [
				{
					key: 1,
					name: '类型',
					component: 'a-select',
					value: undefined,
					duplicate: 'type',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: ['day', 'specialty', 'precision', 'planned'],
						},
						{
							name: '日点检',
							value: ['day'],
						},
						{
							name: '周点检',
							value: ['specialty'],
						},
            {
							name: '定期点检',
							value: ['precision'],
						},
            {
							name: '计划点检',
							value: ['planned'],
						}
					],
				},
        {
					key: 2,
					name: '执行人/组',
					component: 'a-selectPeo',
					value: '',
          radioFlag: false,
          showGroup: true,
					duplicate: 'users',
					placeholder: '请选择',
				}
			],
      pointColumns2: [
				{
					title: '类型',
					dataIndex: 'type',
					key: 'type',
					width: 150,
					fixed: 'left',
          scopedSlots: { customRender: 'type' },
				},
				{
					title: '计划下发日期',
					dataIndex: 'planIssuedTime',
					key: 'planIssuedTime',
					width: 150,
				},
				{
					title: '计划截止时间',
					dataIndex: 'planOverTime',
					key: 'planOverTime',
					width: 180,
				},
				{
					title: '提前下发天数',
					dataIndex: 'remindDay',
					key: 'remindDay',
          width: 150,
					scopedSlots: { customRender: 'remindDay' },
				},
				{
					title: '执行周期',
					dataIndex: 'cycle',
					key: 'cycle',
					width: 150,
					scopedSlots: { customRender: 'cycle' },
				},
        {
					title: '执行人/组',
					dataIndex: 'userName',
					key: 'userName',
					width: 150,
				},
        {
					title: '创建日期',
					dataIndex: 'createTime',
					key: 'createTime',
					width: 150,
          scopedSlots: { customRender: 'createTime' },
				},
				{
					title: '操作',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
					fixed: 'right',
					width: 120,
				},
			],
			missionId: '',
      missionVisble: false,
			viewingVisble: false,
			viewingInfo: {},
			// 维修信息数据
      pointHeadData3: [
        {
					key: 1,
					name: '时间范围',
					component: 'a-range-picker',
					value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
					duplicate: 'time',
					placeholder: '',
				},
				{
					key: 2,
					name: '报修等级',
					component: 'a-select',
					value: undefined,
					duplicate: 'type',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: ['1','2','3'],
						},
						{
							name: '停机故障维修',
							value: ['1'],
						},
						{
							name: '不停机维修',
							value: ['2'],
						},
            {
							name: '设备不良反馈',
							value: ['3'],
						}
					],
				},
        {
					key: 3,
					name: '工单状态',
					component: 'a-select',
					value: undefined,
					duplicate: 'state',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: '',
						},
						{
							name: '待执行',
							value: 'first',
						},
						{
							name: '执行中',
							value: 'dealing',
						},
						{
							name: '审核中',
							value: 'audit',
						},
						{
							name: '审核不通过',
							value: 'again',
						},
						{
							name: '评价中',
							value: 'evaluation',
						},
						{
							name: '评价不通过',
							value: 'evaluationnot',
						},
						{
							name: '执行完成',
							value: 'end',
						},
						{
							name: '已拒绝',
							value: 'refuse',
						},
						{
							name: '维修暂停',
							value: 'pause',
						},
						{
							name: '维修待备件',
							value: 'pausepart',
						},
					],
				},
				{
					key: 4,
					name: '更换备件',
					component: 'a-select',
					value: undefined,
					duplicate: 'isPart',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: '',
						},
						{
							name: '有',
							value: 1,
						},
						{
							name: '无',
							value: 0,
						}
					],
				},
			],
			pointColumns3: [
				{
					title: '报修等级',
					dataIndex: 'repairRank',
					key: 'repairRank',
					width: 150,
					fixed: 'left',
          scopedSlots: { customRender: 'repairRank' },
				},
				{
					title: '报修时间',
					dataIndex: 'createTime',
					key: 'createTime',
					width: 150,
					fixed: 'left',
          scopedSlots: { customRender: 'createTime' },
				},
				{
					title: '故障描述',
					dataIndex: 'repairText',
					key: 'repairText',
					width: 150,
				},
				{
					title: '报修人',
					dataIndex: 'recipientsName',
					key: 'recipientsName',
					width: 180,
				},
				{
					title: '工单状态',
					dataIndex: 'state',
					key: 'state',
          width: 150,
					scopedSlots: { customRender: 'state' },
				},
				{
					title: '更换备件',
					dataIndex: 'isPart',
					key: 'isPart',
					width: 150,
					scopedSlots: { customRender: 'feedbackMesFlag' },
				},
				{
					title: '操作',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
					fixed: 'right',
					width: 120,
				},
			],
			// 检修信息数据
			pointHeadData4: [
        {
					key: 1,
					name: '时间范围',
					component: 'a-range-picker',
					value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
					duplicate: 'time',
					placeholder: '',
				},
        {
					key: 3,
					name: '工单状态',
					component: 'a-select',
					value: undefined,
					duplicate: 'state',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: '',
						},
						{
							name: '待执行',
							value: 'first',
						},
						{
							name: '执行中',
							value: 'dealing',
						},
						{
							name: '审核中',
							value: 'audit',
						},
						{
							name: '审核不通过',
							value: 'again',
						},
						{
							name: '评价中',
							value: 'evaluation',
						},
						{
							name: '评价不通过',
							value: 'evaluationnot',
						},
						{
							name: '执行完成',
							value: 'end',
						},
						{
							name: '已拒绝',
							value: 'refuse',
						},
						{
							name: '维修暂停',
							value: 'pause',
						},
						{
							name: '维修待备件',
							value: 'pausepart',
						},
					],
				},
				{
					key: 4,
					name: '更换备件',
					component: 'a-select',
					value: undefined,
					duplicate: 'isPart',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: '',
						},
						{
							name: '有',
							value: 1,
						},
						{
							name: '无',
							value: 0,
						}
					],
				},
			],
			pointColumns4: [
				{
					title: '任务开始时间',
					dataIndex: 'createTime',
					key: 'createTime',
					width: 150,
					fixed: 'left',
          scopedSlots: { customRender: 'createTime' },
				},
				{
					title: '任务结束时间',
					dataIndex: 'endTime',
					key: 'endTime',
					width: 150,
					fixed: 'left',
          scopedSlots: { customRender: 'createTime' },
				},
				{
					title: '检修内容',
					dataIndex: 'repairText',
					key: 'repairText',
					ellipsis: true,
					width: 150,
				},
				{
					title: '计划截止日期',
					dataIndex: 'repairDeadline',
					key: 'repairDeadline',
					width: 180,
          scopedSlots: { customRender: 'repairDeadline' },
				},
				{
					title: '执行人/组',
					dataIndex: 'recipientsName',
					key: 'recipientsName',
          width: 150,
				},
				{
					title: '工单状态',
					dataIndex: 'state',
					key: 'state',
          width: 150,
					scopedSlots: { customRender: 'state' },
				},
				{
					title: '更换备件',
					dataIndex: 'isPart',
					key: 'isPart',
					width: 150,
					scopedSlots: { customRender: 'feedbackMesFlag' },
				},
				{
					title: '操作',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
					fixed: 'right',
					width: 120,
				},
			],
			pointHeadData5: [
				{
					key: 1,
					name: '执行人/组',
					component: 'a-selectPeo',
					value: '',
          radioFlag: false,
          showGroup: true,
					duplicate: 'jxusers',
					placeholder: '请选择',
				}
			],
			pointColumns5: [
				{
					title: '检修内容',
					dataIndex: 'repairText',
					key: 'repairText',
					width: 220,
					ellipsis: true,
					fixed: 'left',
					scopedSlots: { customRender: 'repairText' },
				},
				{
					title: '计划下发日期',
					dataIndex: 'repairDeadline',
					key: 'repairDeadline',
					width: 150,
				},
				{
					title: '计划截止日期',
					dataIndex: 'cycleDay',
					key: 'cycleDay',
					width: 180,
          scopedSlots: { customRender: 'cycleDay' },
				},
				{
					title: '提前下发天数',
					dataIndex: 'remindDay',
					key: 'remindDay',
					width: 180,
          scopedSlots: { customRender: 'remindDay' },
				},
				{
					title: '执行周期',
					dataIndex: 'cycle',
					key: 'cycle',
					width: 180,
          scopedSlots: { customRender: 'cycle' },
				},
				{
					title: '执行人/组',
					dataIndex: 'recipientsName',
					key: 'recipientsName',
          width: 150,
				},
				{
					title: '评价人',
					dataIndex: 'assessUserName',
					key: 'assessUserName',
          width: 150,
				},
				{
					title: '创建日期',
					dataIndex: 'createTime',
					key: 'createTime',
					width: 150,
          scopedSlots: { customRender: 'cycleDay' },
				},
			],
			maintenanceId: '',
			maintenanceVisble: false,
			// 备件信息数据
			pointHeadData6: [
        {
					key: 1,
					name: '时间范围',
					component: 'a-range-picker',
					value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
					duplicate: 'time',
					placeholder: '',
				},
        {
					key: 2,
					name: '工单类型',
					component: 'a-select',
					value: undefined,
					duplicate: 'type',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: ['repairApp', 'repairWei', 'repairPc', 'repairWeb'],
						},
						{
							name: '维修任务',
							value: ['repairApp', 'repairWei', 'repairPc'],
						},
						{
							name: '检修任务',
							value: ['repairWeb'],
						},
					],
				},
				{ key: 3, duplicate: 'devicename' , name: '备件信息', component: 'a-input', placeholder: '备件编码/名称/品牌/型号', value: '' }
			],
			pointColumns6: [
				{
					title: '更换时间',
					dataIndex: 'createTime',
					key: 'createTime',
					width: 150,
					fixed: 'left',
          scopedSlots: { customRender: 'createTime' },
				},
				{
					title: '备件编码',
					dataIndex: 'code',
					key: 'code',
					width: 150,
					fixed: 'left',
				},
				{
					title: '备件名称',
					dataIndex: 'name',
					key: 'name',
					ellipsis: true,
					width: 150,
					fixed: 'left',
				},
				{
					title: '型号',
					dataIndex: 'version',
					key: 'version',
					width: 180,
				},
				{
					title: '规格',
					dataIndex: 'norms',
					key: 'norms',
          width: 150,
				},
				{
					title: '工单类型',
					dataIndex: 'repairType',
					key: 'repairType',
          width: 150,
					scopedSlots: { customRender: 'repairType' },
				},
				{
					title: '消耗数',
					dataIndex: 'num',
					key: 'num',
					width: 150,
				},
				{
					title: '关联工单',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
					fixed: 'right',
					width: 120,
				},
			],
			pointHeadData7: [
        { key: 1, duplicate: 'devicename' , name: '备件信息', component: 'a-input', placeholder: '备件编码/名称/品牌/型号', value: '' },
        // {
				// 	key: 2,
				// 	name: '排序方式',
				// 	component: 'a-select',
				// 	value: undefined,
				// 	duplicate: 'type',
				// 	placeholder: '请选择',
				// 	provinceData: [
				// 		{
				// 			name: '全部',
				// 			value: '',
				// 		},
				// 		{
				// 			name: '按库存过剩排序',
				// 			value: 0,
				// 		},
				// 		{
				// 			name: '按库存短缺排序',
				// 			value: 1,
				// 		},
				// 	],
				// },
			],
			pointColumns7: [
				{
					title: '备件编码',
					dataIndex: 'code',
					key: 'code',
					width: 150,
					fixed: 'left',
				},
				{
					title: '备件名称',
					dataIndex: 'name',
					key: 'name',
					ellipsis: true,
					width: 150,
					fixed: 'left',
				},
				{
					title: '型号',
					dataIndex: 'version',
					key: 'version',
					width: 180,
				},
				{
					title: '规格',
					dataIndex: 'norms',
					key: 'norms',
          width: 150,
				},
				{
					title: '品牌',
					dataIndex: 'brand',
					key: 'brand',
          width: 150,
				},
				{
					title: '库存下限',
					dataIndex: 'lower',
					key: 'lower',
					width: 150,
				},
				{
					title: '在库数',
					dataIndex: 'num',
					key: 'num',
					width: 150,
				},
				{
					title: '库存上限',
					dataIndex: 'upper',
					key: 'upper',
					width: 150,
				},
				{
					title: '详情',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
					fixed: 'right',
					width: 120,
				},
			],
			partsId: '',
			partsVisble: false,
			// 隐患信息数据
      pointHeadData8: [
				{
					key: 1,
					name: '工单类型',
					component: 'a-select',
					value: undefined,
					duplicate: 'type',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: ['day', 'specialty', 'precision', 'planned', 'B1', 'B2', 'B3'],
						},
						{
							name: '日点检',
							value: ['day'],
						},
						{
							name: '周点检',
							value: ['specialty'],
						},
            {
							name: '定期点检',
							value: ['precision'],
						},
            {
							name: '计划点检',
							value: ['planned'],
						},
						{
							name: '一级保养',
							value: ['B1'],
						},
						{
							name: '二级保养',
							value: ['B2'],
						},
            {
							name: '日常保养',
							value: ['B3'],
						}
					],
				},
        {
					key: 2,
					name: '时间范围',
					component: 'a-range-picker',
					value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
					duplicate: 'time',
					placeholder: '',
				},
				{
					key: 3,
					name: '发现人',
					component: 'a-selectPeo',
					value: '',
          radioFlag: false,
          showGroup: true,
					duplicate: 'czusers',
					placeholder: '请选择',
				},
				{
					key: 4,
					name: '处置方式',
					component: 'a-select',
					value: undefined,
					duplicate: 'feedbackMes',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: '',
						},
						{
							name: '隐患反馈',
							value: 'feedBack',
						},
						{
							name: '报修',
							value: 'repair',
						},
            {
							name: '自行处置',
							value: 'selfDeal',
						}
					],
				},
			],
			pointColumns8: [
				{
					title: '工单类型',
					dataIndex: 'type',
					key: 'type',
					width: 150,
					fixed: 'left',
          scopedSlots: { customRender: 'type' },
				},
				{
					title: '发现隐患时间',
					dataIndex: 'createTime',
					key: 'createTime',
					ellipsis: true,
					width: 200,
					fixed: 'left',
          scopedSlots: { customRender: 'createTime' },
				},
				{
					title: '发现人',
					dataIndex: 'sendUserName',
					key: 'sendUserName',
					width: 180,
				},
				{
					title: '隐患描述',
					dataIndex: 'text',
					key: 'text',
					ellipsis: true,
          width: 260,
				},
				{
					title: '处置隐患时间',
					dataIndex: 'disposeTime',
					key: 'disposeTime',
					ellipsis: true,
          width: 200,
          scopedSlots: { customRender: 'createTime' },
				},
				{
					title: '处置人',
					dataIndex: 'userName',
					key: 'userName',
					width: 150,
				},
				{
					title: '处置方式',
					dataIndex: 'feedbackMes',
					key: 'feedbackMes',
					width: 150,
          scopedSlots: { customRender: 'feedbackMes' },
				},
				{
					title: '是否生成检修计划',
					dataIndex: 'feedbackMesFlag',
					key: 'feedbackMesFlag',
					width: 150,
          scopedSlots: { customRender: 'feedbackMesFlag' },
				},
				{
					title: '详情',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
					fixed: 'right',
					width: 120,
				},
			],
			dangerId: '',
			dangerVisble: false,
			// 资料信息数据
			pointHeadData9: [
				{ key: 1, duplicate: 'devicename' , name: '标题', component: 'a-input', placeholder: '请输入', value: '' }
			],
			pointColumns9: [
				{
					title: '更新时间',
					dataIndex: 'createTime',
					key: 'createTime',
          scopedSlots: { customRender: 'createTime' },
				},
				{
					title: '标题',
					dataIndex: 'title',
					key: 'title',
				},
				{
					title: '附件',
					dataIndex: 'pdfName',
					key: 'pdfName',
					ellipsis: true,
          scopedSlots: { customRender: 'pdfName' },
				},
				{
					title: '详情',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
					width: 120,
				},
			],
			pointHeadData10: [
				{ key: 1, duplicate: 'devicename' , name: '基准书名称', component: 'a-input', placeholder: '请输入', value: '' },
				{
					key: 2,
					name: '类型',
					component: 'a-select',
					value: undefined,
					duplicate: 'type',
					placeholder: '请选择',
					provinceData: [
						{
							name: '全部',
							value: [],
						},
						{
							name: '日点检',
							value: ['day'],
						},
						{
							name: '周点检',
							value: ['specialty'],
						},
            {
							name: '定期点检',
							value: ['precision'],
						},
            {
							name: '计划点检',
							value: ['planned'],
						},
						{
							name: '一级保养',
							value: ['B1'],
						},
						{
							name: '二级保养',
							value: ['B2'],
						},
            {
							name: '日常保养',
							value: ['B3'],
						}
					],
				},
			],
			pointColumns10: [
				{
					title: '更新时间',
					dataIndex: 'createTime',
					key: 'createTime',
          scopedSlots: { customRender: 'createTime' },
				},
				{
					title: '基准书名称',
					dataIndex: 'benchmarkBookName',
					key: 'benchmarkBookName',
				},
				{
					title: '类型',
					dataIndex: 'benchmarkType',
					key: 'benchmarkType',
					ellipsis: true,
          scopedSlots: { customRender: 'benchmarkType' },
				},
				{
					title: '详情',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
					width: 120,
				},
			],
			materialItem: {},
			materialVisble: false,
			bookReferenceId: '',
			bookReferenceVisble: false,
      searchForm: {
        loading:false,
				pageIndex: 1,
				pageSize: 10,
				beginTime: moment().subtract(30, "days").format("YYYYMMDD"),
				endTime: moment().format("YYYYMMDD"),
				type: ['day', 'specialty', 'precision', 'planned'],
        userId: [],
				state: '',
				isPart: '',
				text: '',
				feedbackMes: '',
        mode: 1,
				total: 10,
        dataSource: []
			},
		}
	},
	created() {
    this.queryEquipmentInfo()
  },
	mounted() {},
	methods: {
    // 资产台帐
		queryEquipmentInfo() {
			let dataset = {
				id: this.id
			}
			this.spinning = true
			util.postform('ZNequipment/queryEquipmentInfo', dataset).then(
				(res) => {
					this.spinning = false
					this.accountItem = res.data
				}
			)
		},
    // 点检信息、保养信息
    queryForTaskIdList() {
      let dataset = {
        pageIndex: this.searchForm.pageIndex,
        pageSize: this.searchForm.pageSize,
        id: this.id,
        tenantId: this.tenantId,
				beginTime: this.searchForm.beginTime ? moment(this.searchForm.beginTime).format('YYYYMMDD') : '',
				endTime: this.searchForm.endTime ? moment(this.searchForm.endTime).format('YYYYMMDD') : '',
        type: this.searchForm.type, // 类型
        userId: this.searchForm.userId,
				state: this.searchForm.state, // 状态
				mode: this.searchForm.mode // 1任务 0计划
      }
      util.postform('ZNequipment/queryForTaskIdList', dataset).then((res) => {
        let data = res.data.records
        if (data.length > 0) {
          data.forEach((element) => {
            element.key = element.c_id
          })
        }
        this.searchForm.dataSource = data
        this.searchForm.total = res.data.total
      })
    },
		// 维修、检修信息
		queryForRepairIdList() {
      let dataset = {
        pageIndex: this.searchForm.pageIndex,
        pageSize: this.searchForm.pageSize,
        id: this.id,
        tenantId: this.tenantId,
				beginTime: this.searchForm.beginTime ? moment(this.searchForm.beginTime).format('YYYYMMDD') : '',
				endTime: this.searchForm.endTime ? moment(this.searchForm.endTime).format('YYYYMMDD') : '',
        repairType: this.tableKey == 4 ? ['repairApp', 'repairWei', 'repairPc'] : ['repairWeb'], // 类型
				repairRank: this.tableKey == 4 ? this.searchForm.type : [], // 报修等级
        userId: this.searchForm.userId,
        state: this.searchForm.state,
				isPart: this.searchForm.isPart, // 状态
				mode: this.searchForm.mode // 1 故障维修及检修任务  2 检修计划
      }
      util.postform('ZNequipment/queryForRepairIdList', dataset).then((res) => {
        let data = res.data.records
        if (data.length > 0) {
          data.forEach((element) => {
            element.key = element.c_id
          })
        }
        this.searchForm.dataSource = data
        this.searchForm.total = res.data.total
      })
    },
		// 备件信息
		queryForPartIdList() {
      let dataset = {
        pageIndex: this.searchForm.pageIndex,
        pageSize: this.searchForm.pageSize,
        id: this.id,
        tenantId: this.tenantId,
				beginTime: this.searchForm.beginTime ? moment(this.searchForm.beginTime).format('YYYYMMDD') : '',
				endTime: this.searchForm.endTime ? moment(this.searchForm.endTime).format('YYYYMMDD') : '',
				text: this.searchForm.text,
        repairType: this.searchForm.type, // 类型
				mode: this.searchForm.mode // 1 关联备件  0 备件消耗
      }
      util.postform('ZNequipment/queryForPartIdList', dataset).then((res) => {
        let data = res.data.records
        if (data.length > 0) {
          data.forEach((element) => {
            element.key = element.c_id
          })
        }
        this.searchForm.dataSource = data
        this.searchForm.total = res.data.total
      })
    },
		// 隐患信息
		queryForHiddenRroubleIdList() {
      let dataset = {
        pageIndex: this.searchForm.pageIndex,
        pageSize: this.searchForm.pageSize,
        id: this.id,
        tenantId: this.tenantId,
				beginTime: this.searchForm.beginTime ? moment(this.searchForm.beginTime).format('YYYYMMDD') : '',
				endTime: this.searchForm.endTime ? moment(this.searchForm.endTime).format('YYYYMMDD') : '',
				userId: this.searchForm.userId,
				feedbackMes: this.searchForm.feedbackMes,
        type: this.searchForm.type, // 类型
      }
      util.postform('ZNequipment/queryForHiddenRroubleIdList', dataset).then((res) => {
        let data = res.data.records
        if (data.length > 0) {
          data.forEach((element) => {
            element.key = element.c_id
          })
        }
        this.searchForm.dataSource = data
        this.searchForm.total = res.data.total
      })
    },
		// 资料信息-设备资料
		getPageList() {
      let dataset = {
        pageIndex: this.searchForm.pageIndex,
        pageSize: this.searchForm.pageSize,
        id: this.id,
        tenantId: this.tenantId,
				text: this.searchForm.text,
      }
      util.postform('repairGuidance/getPageList', dataset).then((res) => {
        let data = res.data.records
        if (data.length > 0) {
          data.forEach((element) => {
            element.key = element.id
						element.format = element.pdfName && element.pdfName.split('.').pop()
          })
        }
        this.searchForm.dataSource = data
        this.searchForm.total = res.data.total
      })
    },
		// 资料信息-作业基准书
		getTaskBenchmarkPageList() {
      let dataset = {
        pageIndex: this.searchForm.pageIndex,
        pageSize: this.searchForm.pageSize,
        objectIds: this.id,
        tenantId: this.tenantId,
				benchmarkBookName: this.searchForm.text,
				benchmarkType: this.searchForm.type.toString()
      }
      util.postform('taskBenchmark/getTaskBenchmarkPageList', dataset).then((res) => {
        let data = res.data.records
        if (data.length > 0) {
          data.forEach((element) => {
            element.key = element.id
          })
        }
        this.searchForm.dataSource = data
        this.searchForm.total = res.data.total
      })
    },
    // 翻页
		pageonChange(page) {
			this.searchForm.pageIndex = page
			if (this.tableKey == 2 || this.tableKey == 3) {
				this.queryForTaskIdList()
			} else if (this.tableKey == 4 || this.tableKey == 5) {
				this.queryForRepairIdList()
			} else if (this.tableKey == 6) {
				this.queryForPartIdList()
			} else if (this.tableKey == 7) {
				this.queryForHiddenRroubleIdList()
			} else if (this.tableKey == 8) {
				if (this.searchForm.mode === 1) {
					this.getPageList()
				} else {
					this.getTaskBenchmarkPageList()
				}
			}
		},
		// 页数size改变
		showSizeChange(key, size) {
			this.searchForm.pageSize = size
			if (this.tableKey == 2 || this.tableKey == 3) {
				this.queryForTaskIdList()
			} else if (this.tableKey == 4 || this.tableKey == 5) {
				this.queryForRepairIdList()
			} else if (this.tableKey == 6) {
				this.queryForPartIdList()
			} else if (this.tableKey == 7) {
				this.queryForHiddenRroubleIdList()
			} else if (this.tableKey == 8) {
				if (this.searchForm.mode === 1) {
					this.getPageList()
				} else {
					this.getTaskBenchmarkPageList()
				}
			}
		},
    pointCallback(key, type) {
      console.log(key)
			this.searchForm = {
				loading:false,
				pageIndex: 1,
				pageSize: 10,
				beginTime: moment().subtract(30, "days").format("YYYYMMDD"),
				endTime: moment().format("YYYYMMDD"),
				type: type,
				userId: [],
				state: '',
				isPart: '',
				text: '',
				feedbackMes: '',
				mode: key,
				total: 10,
				dataSource: []
			}
			this.queryForTaskIdList()
    },
		pointCallback2(key) {
      console.log(key)
			this.searchForm = {
				loading:false,
				pageIndex: 1,
				pageSize: 10,
				beginTime: moment().subtract(30, "days").format("YYYYMMDD"),
				endTime: moment().format("YYYYMMDD"),
				type: [],
				userId: [],
				state: '',
				isPart: '',
				text: '',
				feedbackMes: '',
				mode: key,
				total: 10,
				dataSource: []
			}
			this.queryForRepairIdList()
    },
		pointCallback3(key) {
      console.log(key)
			this.searchForm = {
				loading:false,
				pageIndex: 1,
				pageSize: 10,
				beginTime: moment().subtract(30, "days").format("YYYYMMDD"),
				endTime: moment().format("YYYYMMDD"),
				type: [],
				userId: [],
				state: '',
				isPart: '',
				text: '',
				feedbackMes: '',
				mode: key,
				total: 10,
				dataSource: []
			}
			this.queryForPartIdList()
    },
		pointCallback4(key) {
      console.log(key)
			this.searchForm = {
				loading:false,
				pageIndex: 1,
				pageSize: 10,
				beginTime: moment().subtract(30, "days").format("YYYYMMDD"),
				endTime: moment().format("YYYYMMDD"),
				type: [],
				userId: [],
				state: '',
				isPart: '',
				text: '',
				feedbackMes: '',
				mode: key,
				total: 10,
				dataSource: []
			}
			if (this.searchForm.mode === 1) {
				this.getPageList()
			} else {
				this.getTaskBenchmarkPageList()
			}
    },
    // 打开点检、保养任务详情
    openMissionDetails(item) {
      this.missionId = item.id
      this.missionVisble = true
    },
    // 关闭点检、保养任务详情
    closenMissionDetails() {
      this.missionVisble = false
    },
    // 打开点检、保养计划详情
		openInspectionPlan(item) {
			this.viewingInfo = item
			this.viewingVisble = true
		},
    // 关闭点检、保养计划详情
		closenInspectionPlan() {
      this.viewingVisble = false
    },
		// 打开维修、检修任务详情
    openMaintenanceDetails(item) {
      this.maintenanceId = item.id
      this.maintenanceVisble = true
    },
		// 关闭维修、检修任务详情
    closenMaintenanceDetails() {
      this.maintenanceVisble = false
    },
		// 打开备件详情
    openPartsDetails(item) {
      this.partsId = item.id
      this.partsVisble = true
    },
		// 关闭备件详情
    closenPartsDetails() {
      this.partsVisble = false
    },
		// 打开备件详情
    openDangerDetails(item) {
      this.dangerId = item.id
      this.dangerVisble = true
    },
		// 关闭备件详情
    closenDangerDetails() {
      this.dangerVisble = false
    },
		// 打开资料详情
    openMaterialDetails(item) {
      this.materialItem = item
      this.materialVisble = true
    },
		// 关闭资料详情
    closenMaterialDetails() {
      this.materialVisble = false
    },
		// 打开资料详情
    openBookReferenceDetails(item) {
      this.bookReferenceItem = item
      this.bookReferenceVisble = true
    },
		// 关闭资料详情
    closenBookReferenceDetails() {
      this.bookReferenceVisble = false
    },
    // 日期选择框
		onTimeChange(val) {
			this.searchForm.beginTime = val.dateString[0]
      this.searchForm.endTime = val.dateString[1]
		},
		// 输入框
		onInputChange(item) {
      if (item.duplicate == 'devicename') {
				this.searchForm.text = item.value
        this.pointHeadData6.forEach(ele => {
          if (ele.duplicate == 'devicename') {
            ele.value = item.value
          }
        })
      }
    },
    // 下拉框选中
		onHandleChange(item) {
			if (item.duplicate == 'type') {
				this.searchForm.type = item.value
			} else if (item.duplicate == 'state') {
				this.searchForm.state = item.value
			} else if (item.duplicate == 'isPart') {
				this.searchForm.isPart = item.value
			} else if (item.duplicate == 'feedbackMes') {
				this.searchForm.feedbackMes = item.value
			}
		},
    // 执行人/组
    onSelectPeo(item) {
      let valueNames = []
      item.checkData.forEach(ele => {
        valueNames.push(ele.c_name)
      })
      if (item.duplicate == 'users') {
				this.searchForm.userId = item.value
        this.pointHeadData2.forEach(ele2 => {
          if (ele2.duplicate == 'users') {
            ele2.value = valueNames && valueNames.toString()
          }
        })
			} else if (item.duplicate == 'jxusers') {
				this.searchForm.userId = item.value
        this.pointHeadData5.forEach(ele2 => {
          if (ele2.duplicate == 'jxusers') {
            ele2.value = valueNames && valueNames.toString()
          }
        })
			} else if (item.duplicate == 'czusers') {
				this.searchForm.userId = item.value
        this.pointHeadData8.forEach(ele2 => {
          if (ele2.duplicate == 'czusers') {
            ele2.value = valueNames && valueNames.toString()
          }
        })
			}
    },
    // 关闭弹窗
		updataClose() {
      this.$emit('updataClose')
		},
    // table栏切换
    tableKeyClick(key) {
			this.tableKey = key
			this.searchForm.state = ''
      if (key === 1) {
        this.queryEquipmentInfo()
      } else if (key === 2) {
				this.searchForm = {
        loading:false,
				pageIndex: 1,
				pageSize: 10,
				beginTime: moment().subtract(30, "days").format("YYYYMMDD"),
				endTime: moment().format("YYYYMMDD"),
				type: ['day', 'specialty', 'precision', 'planned'],
        userId: [],
				state: '',
				isPart: '',
				text: '',
				feedbackMes: '',
        mode: 1,
				total: 10,
        dataSource: []
				}
				this.pointHeadData1[1].provinceData = [
						{
							name: '全部',
							value: ['day', 'specialty', 'precision', 'planned'],
						},
						{
							name: '日点检',
							value: ['day'],
						},
						{
							name: '周点检',
							value: ['specialty'],
						},
            {
							name: '定期点检',
							value: ['precision'],
						},
            {
							name: '计划点检',
							value: ['planned'],
						}
					]
				this.pointHeadData2[0].provinceData = [
					{
						name: '全部',
						value: ['day', 'specialty', 'precision', 'planned'],
					},
					{
						name: '日点检',
						value: ['day'],
					},
					{
						name: '周点检',
						value: ['specialty'],
					},
          {
						name: '定期点检',
						value: ['precision'],
					},
          {
						name: '计划点检',
						value: ['planned'],
					}
				]
        this.queryForTaskIdList()
      } else if (key === 3) {
				this.searchForm = {
        loading:false,
				pageIndex: 1,
				pageSize: 10,
				beginTime: moment().subtract(30, "days").format("YYYYMMDD"),
				endTime: moment().format("YYYYMMDD"),
				type: ["B1", "B2", "B3"],
        userId: [],
				state: '',
				isPart: '',
				text: '',
				feedbackMes: '',
        mode: 1,
				total: 10,
        dataSource: []
				}
				this.pointHeadData1[1].provinceData = [
						{
							name: '全部',
							value: ['B1', 'B2', 'B3'],
						},
						{
							name: '一级保养',
							value: ['B1'],
						},
						{
							name: '二级保养',
							value: ['B2'],
						},
            {
							name: '日常保养',
							value: ['B3'],
						}
					]
				this.pointHeadData2[0].provinceData = [
					{
						name: '全部',
						value: ['B1', 'B2', 'B3'],
					},
					{
						name: '一级保养',
						value: ['B1'],
					},
					{
						name: '二级保养',
						value: ['B2'],
					},
          {
						name: '日常保养',
						value: ['B3'],
					}
				]
        this.queryForTaskIdList()
      } else if (key === 4) {
				this.searchForm = {
					loading:false,
					pageIndex: 1,
					pageSize: 10,
					beginTime: moment().subtract(30, "days").format("YYYYMMDD"),
					endTime: moment().format("YYYYMMDD"),
					type: ["1", "2", "3"],
					userId: [],
					state: '',
					isPart: '',
					text: '',
					feedbackMes: '',
					mode: 1,
					total: 10,
					dataSource: []
				}
				this.queryForRepairIdList()
			} else if (key === 5) {
				this.searchForm = {
					loading:false,
					pageIndex: 1,
					pageSize: 10,
					beginTime: moment().subtract(30, "days").format("YYYYMMDD"),
					endTime: moment().format("YYYYMMDD"),
					type: [],
					userId: [],
					state: '',
					isPart: '',
					text: '',
					feedbackMes: '',
					mode: 1,
					total: 10,
					dataSource: []
				}
				this.queryForRepairIdList()
			} else if (key === 6) {
				this.searchForm = {
					loading:false,
					pageIndex: 1,
					pageSize: 10,
					beginTime: moment().subtract(30, "days").format("YYYYMMDD"),
					endTime: moment().format("YYYYMMDD"),
					type: ['repairApp', 'repairWei', 'repairPc', 'repairWeb'],
					userId: [],
					state: '',
					isPart: '',
					text: '',
					feedbackMes: '',
					mode: 0,
					total: 10,
					dataSource: []
				}
				this.queryForPartIdList()
			} else if (key === 7) {
				this.searchForm = {
					loading:false,
					pageIndex: 1,
					pageSize: 10,
					beginTime: moment().subtract(30, "days").format("YYYYMMDD"),
					endTime: moment().format("YYYYMMDD"),
					type: ["day", "specialty", "precision", "planned", "B1", "B2", "B3"],
					userId: [],
					state: '',
					isPart: '',
					text: '',
					feedbackMes: '',
					mode: 1,
					total: 10,
					dataSource: []
				}
				this.queryForHiddenRroubleIdList()
			} else if (key === 8) {
				this.searchForm = {
					loading:false,
					pageIndex: 1,
					pageSize: 10,
					beginTime: moment().subtract(30, "days").format("YYYYMMDD"),
					endTime: moment().format("YYYYMMDD"),
					type: [],
					userId: [],
					state: '',
					isPart: '',
					text: '',
					feedbackMes: '',
					mode: 1,
					total: 10,
					dataSource: []
				}
				this.getPageList()
			}
		},
    // 归档查询数据
    archiveData() {
      this.searchForm = {
				pageIndex: 1,
				pageSize: 10,
				beginTime: '',
				endTime: '',
				type: [],
				state: '',
        mode: 1,
				total: 10,
			}
    },
    queryImg() {
			this.imgVisble = true
			this.imgSrc = (this.accountItem.img && this.accountItem.img.split(',')) || []
			this.itemSrcIndex = 0
			this.itemSrc = (this.accountItem.img && this.accountItem.img.split(',')[0]) || ''
		},
		queryImg2(img) {
			this.imgVisble = true
			this.imgSrc = (img && img.split(',')) || []
			this.itemSrcIndex = 0
			this.itemSrc = (img && img.split(',')[0]) || ''
		},
    copyToClip() {
			var dom = document.createElement("input");
			dom.value = this.accountItem.id; // 要复制的文本框的内容（此处是后端返回的内容）
			document.body.appendChild(dom);
			dom.select();
			document.execCommand('copy');
			document.body.removeChild(dom);
			this.$message.success('复制成功')
		},
		download(url){
			window.open(url)
		},
    selecedImgClick(key) {
			this.itemSrcIndex = key
			this.itemSrc = this.imgSrc[key]
		},
		close() {
			this.imgVisble = false
		}
	},
}
</script>

<style scoped lang="less">
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.flex-left {
	width: 204px;
	height: 584px;
	padding: 8px 12px;
	background: #F0F2F5;
	margin-right: 20px;
}
.flex-left-bottom-check {
	width: 180px;
	height: 40px;
	background: #1890FF;
	border-radius: 2px;
	margin-bottom: 8px;
	font-size: 16px;
	font-weight: 400;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.flex-left-bottom {
	width: 180px;
	height: 40px;
	background: #FFFFFF;
	border-radius: 2px;
	margin-bottom: 8px;
	font-size: 16px;
	font-weight: 400;
	color: #333333;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.flex-left-bottom:hover {
	color: #1890FF;
}
.flex-right {
	width: calc(100% - 224px);
	height: 584px;
	overflow: auto;
}
.flex-right-account {
	width: 100%;
	height: 100%;
	display: flex;
}
.flex-right-account-left {
	width: calc(100% - 203px);
	border-right: 1px solid #E8E8E8;
}
.flex-right-account-left-title {
	height: 40px;
	padding-left: 16px;
	background: #F0F2F5;
	border-bottom: 1px solid #E8E8E8;
	display: flex;
	align-items: center;
}
.flex-right-account-left-content {
	padding: 20px;
	font-size: 14px;
	font-weight: 400;
	color: #333333;
}
.flex-right-account-left-content-table {
	border: 1px solid #E8E8E8;
	margin-bottom: 16px;
}
.flex-right-account-left-content-table-item {
	min-height: 40px;
	display: flex;
}
.table-item-left {
	flex: 1;
	height: 100%;
	padding: 10px 12px;
	text-align: right;
	background: #FAFAFA;
}
.table-item-right {
	flex: 2;
	height: 100%;
	padding: 10px 12px;
	text-align: left;
	color: #666666;
	overflow: hidden; //溢出隐藏
	text-overflow:ellipsis; //超出显示省略号
	white-space: nowrap;//禁止自动换行
}
.flex-right-account-right {
	width: 202px;
	flex-shrink: 0;
}
.border1 {
	border: 1px solid #E8E8E8;
}
.borderr1 {
	border-right: 1px solid #E8E8E8;
}
.borderb1 {
	border-bottom: 1px solid #E8E8E8;
}
</style>
