/*
 * @Author: your name
 * @Date: 2021-05-19 11:15:37
 * @LastEditTime: 2021-12-31 15:04:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /jingyiweishi/vue-src/src/core/utils.js
 */
const utils = {}

utils.isAndroidOrIOS = () => {
  var u = navigator.userAgent
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  if (isAndroid) {
    return 'Android'
  } else if (isiOS) {
    return 'iOS'
  } else {
    return 'web'
  }
}

utils.setLocalStorageItem = (key, data) => {
  if (window.localStorage) {
    window.localStorage.setItem(key, data)
  }
}

utils.getLocalStorageItem = (key) => {
  if (window.localStorage) {
    return window.localStorage.getItem(key)
  }
  return ''
}
utils.getsessionStorageItem = (key) => {
  if (window.sessionStorage) {
    return window.sessionStorage.getItem(key)
  }
  return ''
}

utils.removeLocalStorageItem = (key) => {
  if (window.localStorage) {
    window.localStorage.removeItem(key)
  }
}
utils.getCaption = (obj) => {
  var list = obj.split('/')
  return list.pop()
}
// utils.getCaption2 = (obj) => {
// var index = obj.lastIndexOf('\/')
// obj = obj.substring(index + 1, obj.length)
// //  console.log(obj);
// return obj
// }
export default utils
