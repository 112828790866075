<!--
 * @Author: your name
 * @Date: 2021-10-15 09:23:08
 * @LastEditTime: 2021-12-07 09:59:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /jingyiweishi_web/src/pages/productionManagement/productionMonitoring.vue
-->
<template>
  <div
    :style="{
      'transformOrigin':'center top',
      'transform':`scale(${1},${1})`,'-webkit-transform':`scale(${1},${1})`,'-moz-transform':`scale(${1},${1})`,'-o-transform':`scale(${1},${1})`,'-ms-transform':`scale(${1},${1})`
    }"
    class="productionMonitoring">
    <div class="productionMonitoring-title">
      <div class="productionMonitoring-time">{{ currentDate }}</div>
      <el-switch
        v-model="shift"
        style="margin-top: 19px;margin-right: 15px;"
        active-text="语音播报"
        @change="switchClick"/>
    </div>
    <div class="productionMonitoring-statistics">
      <div class="productionMonitoring-bg">
        <div class="productionMonitoring-statistics-name">订单数</div>
        <div style="color: #00E5FD;" class="productionMonitoring-statistics-num">{{ orderNum || 0 }}</div>
      </div>
      <div class="productionMonitoring-bg">
        <div class="productionMonitoring-statistics-name">工单数</div>
        <div style="color: #00E5FD;" class="productionMonitoring-statistics-num">{{ workNum || 0 }}</div>
      </div>
      <div class="productionMonitoring-bg">
        <div class="productionMonitoring-statistics-name">派工数</div>
        <div style="color: #2FC25B;" class="productionMonitoring-statistics-num">{{ allotNum || 0 }}</div>
      </div>
      <div class="productionMonitoring-bg">
        <div class="productionMonitoring-statistics-name">派工率</div>
        <div style="color: #2FC25B;" class="productionMonitoring-statistics-num">{{ allotRatio || 0 }}%</div>
      </div>
      <div class="productionMonitoring-bg">
        <div class="productionMonitoring-statistics-name">完成数</div>
        <div style="color: #F87500;" class="productionMonitoring-statistics-num">{{ endNum || 0 }}</div>
      </div>
      <div class="productionMonitoring-bg">
        <div class="productionMonitoring-statistics-name">完成率</div>
        <div style="color: #F87500;" class="productionMonitoring-statistics-num">{{ endRatio || 0 }}%</div>
      </div>
      <div class="productionMonitoring-bg">
        <div class="productionMonitoring-statistics-name">当前作业人数</div>
        <div style="color: #FFFFFF;" class="productionMonitoring-statistics-num">{{ currentNumber || 0 }}</div>
      </div>
    </div>
    <div class="productionMonitoring-table">
      <div style="background: rgba(90,216,166,0.65);border-bottom: 0;" class="productionMonitoring-table-item">
        <div style="flex: 2;border-right: 0;" class="productionMonitoring-table-item-data">工单号</div>
        <div style="flex: 2;flex-wrap: wrap;border-right: 0;" class="productionMonitoring-table-item-data">
          产品
          <div style="width: 100%;font-size: 20px;">（工单状态）</div>
        </div>
        <div style="flex: 7;flex-wrap: wrap;" class="productionMonitoring-table-item-data">
          <div style="display: flex;width: 100%;" class="subtable-item-data-item">
            <div style="flex: 2;" class="productionMonitoring-table-item-data-it">工序</div>
            <div style="flex: 3;" class="productionMonitoring-table-item-data-it">人员</div>
            <div style="flex: 3;" class="productionMonitoring-table-item-data-it">开始时间</div>
            <div style="flex: 2;" class="productionMonitoring-table-item-data-it">计划数/完成数</div>
            <div style="flex: 2;" class="productionMonitoring-table-item-data-it">生产进度</div>
          </div>
        </div>
        <!-- <div style="flex: 1;border-right: 0;" class="productionMonitoring-table-item-data">工序</div>
        <div style="flex: 2;" class="productionMonitoring-table-item-data">人员</div>
        <div style="flex: 1;" class="productionMonitoring-table-item-data">开始时间</div>
        <div style="flex: 1;" class="productionMonitoring-table-item-data">计划数/完成数</div>
        <div style="flex: 1;" class="productionMonitoring-table-item-data">生产进度</div> -->
      </div>
      <div class="content-l-t">
        <div id="content-l-table" class="content-l-table" @mouseenter="mEnter" @mouseleave="mLeave">
          <div v-for="(item, index) in tableData" :key="index" :style=" index%2 == 1 ? 'background: rgba(24,144,255,0.15);' : ''" :class="{anim:animate==true}" class="productionMonitoring-table-item">
            <div style="flex: 2;" class="productionMonitoring-table-item-data">{{ item.workId }}</div>
            <div style="flex: 2;" >
              {{ item.productName }}
              <div :style="`font-size: 20px;font-weight: 600;color: ${item.updateState == 2 ? '#F5222D' : item.updateState == 1 ? '#F87500' : '#2fc25b'};`">({{ item.updateState == 2 ? '取消' : item.updateState == 1 ? '变更' : '正常' }})</div>
            </div>
            <div style="flex: 7;flex-wrap: wrap;" class="productionMonitoring-table-item-data">
              <div v-for="(item2, index2) in item.workCrafts" :key="index2" style="display: flex;width: 100%;" class="subtable-item-data-item">
                <div style="flex: 2;" class="productionMonitoring-table-item-data-it">{{ item2.craftName }}</div>
                <div style="flex: 3;" class="productionMonitoring-table-item-data-it">{{ item2.userName || '无' }}</div>
                <div style="flex: 3;" class="productionMonitoring-table-item-data-it">{{ item2.startTime | getDate(item2.startTime) }}</div>
                <div style="flex: 2;" class="productionMonitoring-table-item-data-it">{{ item2.planNum || '-' }}/{{ item2.okQualified || '-' }}</div>
                <div style="flex: 2;" class="productionMonitoring-table-item-data-it"><a-progress :showInfo='false' :percent="item2.productionSchedule" :strokeWidth="16" :strokeColor="item2.color"/></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="productionMonitoring-paging">
      <!-- <div v-for="item2 of pageNumber" :key="item2" :class="item2 == pageIndex ? 'blueCircle' : 'blackCircle'" @click="getpageListData(item2)"/> -->
    </div>
    <div class="productionMonitoring-speaker">
      <img src="@/assets/laba.png" class="laba">{{ voice }}
    </div>
    <audio ref="audio" :src="sound" style="display: none" controls="controls"/>
  </div>
</template>

<script>
import * as util from '@/core/util'
import moment from 'moment'
export default {
  name: 'ProductionMonitoring',
  filters: {
    getDate(date) {
      if (date) {
        return moment(date).format('MM-DD HH:mm')
      }
      return '未开始'
    }
  },
  data() {
    return {
      tenantId: '',
      scalseNumW: 1, // 缩放比例
      scalseNumH: 1, // 缩放比例
      orderNum: '', // 订单数
      workNum: '', // 工单数
      allotNum: '', // 派工数
      allotRatio: '', // 派工率
      endNum: '', // 完成数
      endRatio: '', // 完成率
      currentNumber: '', // 当前作业人数
      tableData: [],
      tableListData: [],
      pageIndex: 1,
      pageSize: 7,
      // pageNumber: 0,
      websocket: null,
      isConnect: false,
      rec: null,
      sound: '',
      shift: false,
      currentDate: '',
      voice: '',
      offsetHeight: true,
      animate: false,
      timer: null
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
    console.log(this.tenantId);
    // 页面刚进入时开启长连接
    this.createWebSocket()
  },
  mounted() {
    this.dynamicTime() // 获取当前时间
    this.load()
    setTimeout(() => {
      this.scroll()
    }, 1000)
    // 计算缩放比例
    setInterval(()=>{
      this.load()
    },6000)
    this.resize_window()
    window.addEventListener('resize', () => {
      this.resize_window()
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    // 看板自动滚动
    scroll() {
      var element = document.getElementById('content-l-table')
      var isCanScroll = element.clientHeight !== element.scrollHeight // ? '不可用滚动' : '可以滚动'

      if (isCanScroll) {
        this.timer = setInterval(() => {
          if (element.clientHeight + element.scrollTop < element.scrollHeight) {
            element.scrollTop = element.scrollTop + 2
          } else {
            element.scrollTop = 0
          }
        }, 50)
      } else {
        clearInterval(this.timer)
      }
    },
    mEnter () {
      clearInterval(this.timer)
    },
    mLeave () {
      this.scroll()
    },
    dynamicTime() {
      var date = new Date() // 实例化时间
      var y = date.getFullYear() // 年
      var m = date.getMonth() + 1 // 月
      var d = date.getDate() // 日
      var week = date.getDay() // 星期几
      var tet = date.getHours() // 小时
      var min = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes() // 分
      // var s  = date.getSeconds();   // 秒
      var weeke = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      // console.log(y,m,d,week,tet,min,s);
      const mmt = weeke[week]
      this.currentDate = `${y}年${m}月${d}日  ${mmt}  ${tet}:${min}`
    },
    createWebSocket() {
      try {
        // 尝试连接
        // const wsuri = 'ws://192.168.110.170:2301/pubsub/' + this.tenantId + '-' + parseInt(new Date().getTime() / 1000) // ws地址
        // const wsuri = 'wss://socket.prod.jingyiweishi.com:2301/pubsub/' + this.tenantId + '-' + parseInt(new Date().getTime() / 1000) // ws地址
        // const wsuri = 'ws://175.10.25.32:2301/pubsub/' + this.tenantId + '-' + parseInt(new Date().getTime() / 1000) // ws地址
        const wsuri = 'wss://socket.prod.jingyiweishi.com/pubsub/' + this.tenantId + '-' + parseInt(new Date().getTime() / 1000) // ws地址
        this.websocket = new WebSocket(wsuri)
        this.initWebSocket()
      } catch (e) {
        console.log('尝试创建连接失败')
        // 如果无法连接上webSocket 那么重新连接！可能会因为服务器重新部署，或者短暂断网等导致无法创建连接
        this.reConnect()
      }
    },
    initWebSocket() {
      // 初始化weosocket
      this.isConnect = true
      this.websocket.onopen = this.websocketonopen
      this.websocket.onerror = this.websocketonerror
      this.websocket.onmessage = this.websocketonmessage
      this.websocket.onclose = this.websocketclose
    },
    reConnect() {
      // 重连函数
      var that = this
      if (this.isConnect) return
      this.rec && clearTimeout(this.rec)
      // 延迟5秒重连  避免过多次过频繁请求重连
      this.rec = setTimeout(function() {
        that.createWebSocket()
      }, 5000)
    },
    // 连接成功提示字样
    websocketonopen() {
      console.log('WebSocket连接成功')
    },
    // 连接错误
    websocketonerror(e) {
      console.log(e)
      this.isConnect = false
      // 调用重连函数
      this.reConnect()
      console.log('WebSocket连接发生错误')
    },
    // 数据接收
    websocketonmessage(e) {
      console.log(e)
      if (e !== undefined) {
        var json = JSON.parse(e.data)
        if (json.type === 1) {
          this.orderNum += json.num
        } else if (json.type === 2) {
          this.workNum += json.num
        } else if (json.type === 5) {
          const index = this.tableData.findIndex(item => {
            return item.workId + item.productId === json.kanBanWorkProduct.workId + json.kanBanWorkProduct.productId
          })
          const state = json.kanBanWorkProduct.status === 2 ? '已完成！请注意质量把控！' : json.kanBanWorkProduct.updateState === 0 ? '已派发！请在APP端及时扫码生产！' : '有变更！请在APP端留意变更信息！'
          const url = '跟单助手温馨提示：' + json.kanBanWorkProduct.workId + '的生产计划' + state
          if (index === -1) {
            this.allotNum += 1
            this.allotRatio = parseInt((this.allotNum / this.workNum) * 100)
            this.tableData.unshift(json.kanBanWorkProduct)
            this.switchClick(['http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&spd=6&text=' + url])
          } else {
            if (json.kanBanWorkProduct.status === 2) {
              this.endNum += 1
              this.endRatio = parseInt((this.endNum / this.allotNum) * 100)
              this.tableData.splice(index, 1)
              this.switchClick(['http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&spd=6&text=' + url])
            } else {
              this.tableData.splice(index, 1, json.kanBanWorkProduct)
              if (json.kanBanWorkProduct.updateState !== 0) {
                this.switchClick(['http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&spd=6&text=' + url])
              }
            }
          }
          this.voice = url
          const userList = []
          this.tableData.forEach(element => {
            element.workCrafts.forEach(element2 => {
              element2.productionSchedule = Object.is(parseInt((element2.okQualified / element2.planNum) * 100), NaN) ? 0 : Number(parseInt((element2.okQualified / element2.planNum) * 100))
              element2.color = element2.productionSchedule > 100 ? '#F5222D' : element2.productionSchedule === 100 ? '#1890FF' : '#2FC25B'
              const user = element2.userName && element2.userName.split(',')
              userList.push.apply(userList, user)
            })
          })
          this.currentNumber = this.unique(userList).length
        }
      }
    },
    // 服务关闭
    websocketclose() {
      this.isConnect = false
      // 重连
      this.reConnect()
    },
    // 计算缩放比例
    resize_window() {
      const w_height = Number(document.documentElement.clientHeight / 1080)
      this.scalseNumH = w_height
    },
    switchClick(arr) {
      if (!this.shift) {
        this.$refs.audio.pause()// 这个就是暂停
      } else {
      // this.voicePrompt()
        this.playMusic(arr)
      }
    },
    playMusic(arr) {
      var repeat = 0
      var myAudio = this.$refs.audio
      myAudio.preload = false
      myAudio.controls = true
      myAudio.hidden = true
      var src = arr.pop()
      myAudio.src = src
      myAudio.addEventListener('ended', playEndedHandler, false)
      myAudio.play()
      myAudio.loop = false
      function playEndedHandler() {
        if (repeat >= arr.length) { return false }
        src = arr.pop()
        myAudio.src = src
        arr.unshift(src)
        myAudio.play()
        repeat++
      }
    },
    unique(arr) {
      return arr.filter(function(item, index, arr) {
        // 当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
        return arr.indexOf(item, 0) === index
      })
    },
    load() {
      util.get('/product/kanban/query',{tenantId:this.tenantId}).then(res => {
        if (res.code === 200) {
          this.orderNum = res.data.orderNum
          this.workNum = res.data.workNum
          this.allotNum = res.data.allotNum
          this.allotRatio = parseInt(res.data.allotRatio)
          this.endNum = res.data.endNum
          this.endRatio = parseInt(res.data.endRatio)
          const userList = []
          res.data.list.forEach(element => {
            element.workCrafts.forEach(element2 => {
              element2.productionSchedule = Object.is(parseInt((element2.okQualified / element2.planNum) * 100), NaN) ? 0 : Number(parseInt((element2.okQualified / element2.planNum) * 100))
              element2.color = element2.productionSchedule > 100 ? '#F5222D' : element2.productionSchedule === 100 ? '#1890FF' : '#2FC25B'
              console.log(element2.productionSchedule);

              const user = element2.userName && element2.userName.split(',')
              userList.push.apply(userList, user)
            })
          })
          this.tableData = res.data.list
          this.currentNumber = this.unique(userList).length
          // setTimeout(() => {
          //   const offsetHeight1 = document.getElementsByClassName('content-l-t')[0].offsetHeight
          //   const offsetHeight2 = document.getElementsByClassName('content-l-table')[0].children[0].clientHeight
          //   this.offsetHeight = (offsetHeight2 > offsetHeight1)
          // }, 50)
          
        }
      }).catch(err => {
        console.log(err.response)
        this.$message.error(err.message)
      })
    }
  }
}
</script>

<style scoped>
.productionMonitoring {
  height: 100%;
  width: 100%;
  background: url(../../assets/SC_BG.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
}
.productionMonitoring-title {
  height: 83px;
  width: 100%;
  background: url(../../assets/SC_title.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: end;
}
.productionMonitoring-time {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  margin-right: 30px;
  margin-top: 15px;
}
.productionMonitoring-statistics {
  height: 128px;
  padding: 27px 15px;
  display: flex;
}
.productionMonitoring-bg {
  flex: 1;
  height: 100%;
  background: url(../../assets/bigdata_bg_1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  font-weight: 500;
  margin-right: 15px;
}
.productionMonitoring-bg:first-of-type {
  background: url(../../assets/bigdata_bg_3.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.productionMonitoring-bg:last-of-type {
  background: url(../../assets/bigdata_bg_4.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-right: 0px;
}
.productionMonitoring-statistics-name {
  margin-top: 17px;
  height: 37px;
  line-height: 37px;
  font-size: 26px;
  color: #ffffff;
}
.productionMonitoring-statistics-num {
  height: 67px;
  font-size: 48px;
  text-align: center;
  line-height: 67px;
}
.productionMonitoring-table {
  margin: 0px 15px;
  height: calc(100% - 388px);
  overflow: auto;
  background: url(../../assets/bigdata_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.content-l-t {
  height: calc(100% - 86px);
  position: relative;
}
.vertical {
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.65);
    position: absolute;
    left: 325px;
    top: 0;
}
.content-l-table {
  /* max-height: calc(100% - 86px); */
  height: 100%;
  overflow:scroll;
  /* overflow: hidden;
  scrollbar-width:none;
  -ms-overflow-style:none; */
}
.content-l-table::-webkit-scrollbar {
  display:none;/*ChromeSafari*/
}
.productionMonitoring-table-item {
  /* height: 86px; */
  width: 100%;
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255,255,255,0.65);
  position: relative;
}
.productionMonitoring-table-item-data {
  height: 100%;
  flex: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  /* margin-right: 10px;
  margin-left: 10px; */
  flex-shrink: 0;
}
.productionMonitoring-table-item-data >>> .el-progress-bar__outer {
  background: rgba(0,0,0,0.45);
}
.productionMonitoring-table-item-data >>> .el-progress__text {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
.subtable-item-data-item {
  width: 100%;
  border-bottom: 1px solid rgba(255,255,255,0.65);
}
.subtable-item-data-item:last-of-type {
  border-bottom: 0;
}
.productionMonitoring-table-item-data-it {
  padding: 24px 0px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.productionMonitoring-table-item-data-it:nth-child(1) {
  border-right: 1px solid rgba(255,255,255,0.65);
  border-left: 1px solid rgba(255,255,255,0.65);
}
.productionMonitoring-table-item-data-it >>> .el-progress-bar {
  padding-right: 60px;
  margin-right: -65px;
}
.productionMonitoring-paging {
  padding: 13px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blueCircle {
  width: 18px;
  height: 18px;
  background: #00e5fd;
  border-radius: 50%;
  margin-right: 15px;
  cursor: pointer;
}
.blackCircle {
  width: 18px;
  height: 18px;
  background: rgba(0,0,0,0.45);
  border-radius: 50%;
  margin-right: 15px;
  cursor: pointer;
}
.productionMonitoring-speaker {
  height: 78px;
  padding: 0px 57px;
  background: rgba(59,0,248,0.45);
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
  color: #fdff00;
}
.laba {
  width: 48px;
  height: 48px;
  margin-right: 24px;
}
*, *::before, *::after {
    box-sizing: initial;
}
</style>
