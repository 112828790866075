var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bookReferenceDetails"},[_c('jywsDialog',{attrs:{"visible":_vm.visible,"title":"工单详情","width":"960px","roger":"","bottom-name":"关闭","typePrimary":"primary"},on:{"updataClose":_vm.closeModal,"updataWin":_vm.updataWin}},[_c('div',{staticClass:"bookReferenceDetails-content"},[_c('div',{staticClass:"flex-right-account-left-content-table"},[_c('div',{staticClass:"flex-right-account-left-content-table-item borderb1"},[_c('div',{staticClass:"table-item-left borderr1"},[_vm._v("基准书名称：")]),_c('div',{staticClass:"table-item-right borderr1"},[_vm._v(_vm._s(_vm.item.benchmarkBookName || '-'))]),_c('div',{staticClass:"table-item-left borderr1"},[_vm._v("类型：")]),_c('div',{staticClass:"table-item-right"},[_vm._v(_vm._s(_vm._f("type")(_vm.item.benchmarkType,_vm.item.benchmarkType)))])]),_c('div',{staticClass:"flex-right-account-left-content-table-item"},[_c('div',{staticClass:"table-item-left borderr1"},[_vm._v("适用于：")]),_c('div',{staticClass:"table-item-right borderr1",staticStyle:{"flex":"5.35"}},[_vm._v(_vm._s(_vm.item.objectsName || '-'))])])]),_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"content-right-table"},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"transformCellText":function (ref) {
	var text = ref.text;
	var column = ref.column;
	var record = ref.record;
	var index = ref.index;

	return text || '-';
},"pagination":false,"scroll":{ y: 340 }},scopedSlots:_vm._u([{key:"createTime",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(text ? _vm.$moment(text, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') : '-')+" ")])}},{key:"action",fn:function(scope){return _c('span',{},[_c('a',{attrs:{"href":'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/pdf/' + scope.pdfName,"target":"_blank"}},[_vm._v("查看")])])}}])})],1)])])]),_c('imgDialog',{attrs:{"title":"查看图片","bottomName":"关闭","visible":_vm.imgVisble,"imgSrc":_vm.imgSrc,"itemSrcIndex":_vm.itemSrcIndex,"itemSrc":_vm.itemSrc},on:{"selecedImgClick":_vm.selecedImgClick,"updataWin":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }