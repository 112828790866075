<!--
 * @Author: your name
 * @Date: 2022-04-22 14:10:47
 * @LastEditTime: 2022-12-09 11:25:11
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/components/conditionQuery.vue
-->
<template>
  <div class="conditionQuery">
    <div class="conditionQuery-head">
      <div class="conditionQuery-head-left">
        <div v-for="(item, index) in headData" :key="index" class="conditionQuery-head-item">
          <template v-if="item.component == 'a-range-picker'">
            <span class="flexShrink marginRight12">{{ item.name }}</span>
            <a-range-picker :defaultValue="item.value" class="width220" @change="onChange" />
          </template>
          <template v-if="item.component == 'a-input'">
            <span class="flexShrink marginRight12">{{ item.name }}</span>
            <a-input class="width150" :placeholder="item.placeholder" :v-model='item.value' @input="onInputChange($event, item.duplicate)"/>
          </template>
          <template v-if="item.component == 'a-radio'">
            <span class="flexShrink marginRight12">{{ item.name }}</span>
            <a-radio-group :options="item.options" :default-value="item.value" @change="radioOnChange($event, item.duplicate)" />
          </template>
          <template v-if="item.component == 'a-checkbox'">
            <span class="flexShrink marginRight12">{{ item.name }}</span>
            <a-checkbox-group v-model="item.value" :options="item.options" @change="checkboxOnChange($event, item.duplicate)"
            />
          </template>
          <template v-if="item.component == 'a-select'">
            <span class="flexShrink marginRight12">{{ item.name }}</span>
            <a-select :placeholder='item.placeholder' :default-value="item.value" class="width150" @change="handleChange($event, item.duplicate)">
              <a-select-option v-for="province in item.provinceData" :key="province.value">
                {{ province.name }}
              </a-select-option>
            </a-select>
          </template>
          <template v-if="item.component == 'a-tree-select'">
            <span class="flexShrink marginRight12">{{ item.name }}</span>
            <a-tree-select
              class="width150"
              :value="item.value"
              :tree-data="item.treeData"
              show-search
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :placeholder="item.placeholder"
              allow-clear
              tree-default-expand-all
              tree-node-filter-prop="title"
              @change="(value, label, extra) => treeSelectChange(value, label, extra, item.duplicate)"
            >
            </a-tree-select>
          </template>
          <template v-if="item.component == 'a-selectPeo'">
            <span class="flexShrink marginRight12">{{ item.name }}</span>
            <selectPeo :inputName="item.value" :radioFlag="item.radioFlag" :showGroup="item.showGroup" @onSelect="onSelectPeo($event, item.duplicate)" ></selectPeo>
          </template>
          <template v-if="item.component == 'a-selectEqus'">
            <span class="flexShrink marginRight12">{{ item.name }}</span>
            <selectEqus :inputName="item.value" :radioFlag="item.radioFlag" :mode="item.mode" :modalTitle="(item.mode === 0 ? '组织/区域' : '组织/设备')" @onSelect="onselectEqus($event, item.duplicate)" ></selectEqus>
          </template>
        </div>
      </div>
      <div style="text-align: right;" class="flexShrink">
        <a-button v-if="exportState" class="marginl20" icon="export" @click="exportExcel">导出</a-button>
        <a-button class="marginl20" type="primary" icon="search" @click="selectClick">查询</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { TreeSelect } from 'ant-design-vue';
import selectPeo from '@/components/selectPeo.vue'
import selectEqus from '@/components/selectEqus.vue'
export default {
  name: 'conditionQuery',
  props: {
    // 所用组件
    headData: {
      type: Array,
      default: () => {
        return []
      }
    },
    exportState: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  components: {
		selectPeo,
    selectEqus
	},
  data() {
    return {
      SHOW_PARENT: TreeSelect.SHOW_PARENT,
      typeName: ''
    }
  },
  created() {},
  mounted() {},
  methods: {
    onChange(date, dateString) {
      this.$emit('onTimeChange',{ date , dateString})
    },
    onInputChange(e, duplicate) {
      this.$emit('onInputChange', {duplicate: duplicate, value: e.target.value })
    },
    radioOnChange(e, duplicate){
      this.$emit('radioOnChange', {duplicate: duplicate, value: e.target.value })
    },
    checkboxOnChange(e, duplicate){
      this.$emit('checkboxOnChange', {duplicate: duplicate, value: e })
    },
    handleChange(value, duplicate) {
      this.$emit('onHandleChange', {duplicate: duplicate, value: value })
    },
    onSelectPeo(value, duplicate) {
      this.$emit('onSelectPeo', {duplicate: duplicate, value: value.key, checkData: value.obj })
		},
    onselectEqus(value, duplicate) {
      this.$emit('onselectEqus', {duplicate: duplicate, value: value.key, checkData: value.obj })
		},
    selectClick() {
      this.$emit('onSelectClick')
    },
    treeSelectChange(value, label, extra, duplicate) {
      this.$emit('onTreeSelectChange', {duplicate: duplicate, value: value, object: extra.triggerNode && extra.triggerNode.dataRef })
    },
    exportExcel(){
       this.$emit('exportExcel')
    }
  },
}
</script>

<style scoped lang="less">
.conditionQuery {
  padding: 32px 0px 16px;
  border-bottom: 1px solid #E8E8E8;
}
.conditionQuery-head {
  display: flex;
}
.conditionQuery-head-left {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.conditionQuery-head-item {
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-bottom: 16px;
}
.flexShrink {
  flex-shrink: 0;
}
.marginRight12 {
  margin-right: 12px;
}
.marginl20 {
  margin-left: 20px;
}
.width150 {
  width: 150px;
}
.width220 {
  width: 220px;
}
</style>
